import React from 'react';
import { styles } from '@sdir/sds';
import styled from 'styled-components';

const NoRequirementsFallback = () => {
  return (
    <Container>
      <NoRequirementsIconWrapper>
        <NoRequirementsIcon />
      </NoRequirementsIconWrapper>
      <Header>Ingen regelverk er generert</Header>
      <Text>Fyll ut informasjon for å se tilhørende fagområder og regelverk</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${styles.colors.font.text85};
  text-align: center;
  width: 55rem;
  align-items: center;
`;

const Header = styled.h2`
  margin-bottom: 0;
`;

const Text = styled.p`
  margin-bottom: 0;
`;

const NoRequirementsIconWrapper = styled.div`
  width: 299.255px;
  height: 295px;
  background-color: ${styles.colors.font.text10};
  border-radius: 299.255px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRequirementsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240"
    height="138"
    viewBox="0 0 240 138"
    fill="none"
  >
    <mask id="mask0_4928_2865" maskUnits="userSpaceOnUse" x="0" y="0" width="240" height="138">
      <path d="M239.139 0H0.372559V137.344H239.139V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4928_2865)">
      <path
        d="M232.155 99.9352C231.767 99.9347 231.38 99.8884 231.003 99.7971C229.812 99.5064 228.777 98.7757 228.111 97.7538C227.444 96.7318 227.196 95.4958 227.417 94.2984C227.636 93.1009 228.31 92.032 229.297 91.3099C230.285 90.588 231.514 90.2674 232.732 90.4136C233.95 90.5599 235.066 91.1618 235.851 92.0966C236.638 93.0314 237.034 94.2287 236.961 95.4439C236.887 96.6591 236.349 97.8005 235.456 98.6351C234.563 99.4699 233.382 99.9348 232.155 99.9352ZM232.155 92.5341C231.516 92.5365 230.901 92.7704 230.424 93.1917C229.947 93.613 229.643 94.1926 229.566 94.8215C229.492 95.4502 229.65 96.0847 230.014 96.6056C230.376 97.1266 230.918 97.4977 231.538 97.6493C231.896 97.735 232.27 97.7442 232.634 97.6764C232.996 97.6088 233.339 97.4657 233.642 97.2564C233.944 97.0474 234.201 96.7769 234.39 96.4631C234.579 96.1492 234.702 95.799 234.745 95.4356C234.789 95.072 234.754 94.7034 234.644 94.354C234.535 94.0046 234.351 93.6822 234.106 93.4081C233.861 93.134 233.562 92.9145 233.224 92.7638C232.888 92.6131 232.524 92.5348 232.155 92.5341Z"
        fill="#E7F6FF"
      />
      <path
        d="M227.76 105.002C228.033 103.882 227.34 102.754 226.21 102.482C225.081 102.21 223.945 102.898 223.67 104.019C223.397 105.139 224.089 106.267 225.218 106.539C226.349 106.81 227.485 106.122 227.76 105.002Z"
        fill="#E7F6FF"
      />
      <path
        d="M10.6191 99.4799C9.34394 99.4522 8.13119 98.9267 7.24418 98.0173C6.35715 97.1079 5.86745 95.8882 5.88139 94.6229C5.89532 93.3575 6.41177 92.1486 7.31861 91.2587C8.22543 90.3687 9.44947 89.8697 10.725 89.8697C12.0005 89.8697 13.2245 90.3687 14.1314 91.2587C15.0382 92.1486 15.5546 93.3575 15.5686 94.6229C15.5825 95.8882 15.0928 97.1079 14.2058 98.0173C13.3188 98.9267 12.106 99.4522 10.8308 99.4799H10.6191ZM10.6191 92.0806H10.5096C9.82088 92.0955 9.16584 92.3792 8.68668 92.8703C8.20755 93.3613 7.94298 94.02 7.95045 94.7036C7.95789 95.387 8.23677 96.0398 8.72653 96.5205C9.21626 97.0011 9.87734 97.2708 10.5662 97.2708C11.2551 97.2708 11.9162 97.0011 12.406 96.5205C12.8957 96.0398 13.1746 95.387 13.182 94.7036C13.1895 94.02 12.9249 93.3613 12.4458 92.8703C11.9666 92.3792 11.3116 92.0955 10.6229 92.0806H10.6191Z"
        fill="#E7F6FF"
      />
      <path
        d="M7.16566 107.544C8.32634 107.495 9.22666 106.521 9.17659 105.369C9.12652 104.218 8.14502 103.325 6.98435 103.374C5.82369 103.424 4.92337 104.398 4.97343 105.549C5.0235 106.701 6.00499 107.594 7.16566 107.544Z"
        fill="#E7F6FF"
      />
      <path
        d="M132.021 21.7828C130.865 21.7837 129.747 21.3742 128.869 20.6282C127.991 19.8822 127.411 18.849 127.235 17.7157C127.058 16.5824 127.296 15.4238 127.906 14.4496C128.516 13.4755 129.458 12.7502 130.56 12.405C131.662 12.0597 132.852 12.1174 133.915 12.5676C134.979 13.0177 135.844 13.8306 136.355 14.8591C136.867 15.8875 136.99 17.0637 136.703 18.1745C136.416 19.2854 135.738 20.2576 134.791 20.9152C133.979 21.4788 133.012 21.7817 132.021 21.7828ZM132.006 14.3853C131.329 14.3846 130.679 14.6429 130.19 15.1063C129.701 15.5698 129.411 16.2026 129.381 16.8729C129.35 17.5432 129.582 18.1993 130.027 18.7044C130.472 19.2095 131.096 19.5247 131.77 19.5842C132.444 19.6437 133.115 19.4431 133.643 19.0238C134.171 18.6048 134.516 17.9996 134.605 17.3346C134.695 16.6695 134.522 15.9958 134.123 15.4539C133.724 14.912 133.13 14.5437 132.464 14.4258C132.313 14.399 132.16 14.3855 132.006 14.3853Z"
        fill="#E7F6FF"
      />
      <path
        d="M122.144 21.5205C123.096 20.8587 123.326 19.5573 122.659 18.6135C121.992 17.6698 120.68 17.4413 119.729 18.103C118.778 18.7649 118.548 20.0662 119.214 21.0101C119.881 21.9537 121.193 22.1823 122.144 21.5205Z"
        fill="#E7F6FF"
      />
      <path
        d="M239.132 46.5391C239.132 44.5139 238.32 42.5713 236.878 41.1391C235.433 39.7068 233.477 38.9022 231.434 38.9022C231.263 38.9022 231.095 38.9022 230.927 38.9188C230.939 38.753 230.944 38.5854 230.944 38.416C230.944 37.3307 230.71 36.2577 230.259 35.2689C229.808 34.28 229.151 33.3978 228.328 32.6813C227.506 31.9648 226.54 31.4302 225.493 31.1134C224.448 30.7964 223.345 30.7045 222.261 30.8435C221.175 30.9828 220.133 31.3497 219.201 31.92C218.271 32.4905 217.473 33.2512 216.862 34.1514C216.251 35.0517 215.841 36.0709 215.657 37.1409C215.475 38.2111 215.523 39.3077 215.802 40.3574C214.872 39.6882 213.802 39.234 212.671 39.0282C211.541 38.8225 210.379 38.8706 209.27 39.1688C208.16 39.4668 207.133 40.0076 206.261 40.7514C205.39 41.4952 204.699 42.423 204.235 43.4667C203.773 44.5105 203.552 45.6437 203.589 46.7834C203.626 47.923 203.919 49.0398 204.449 50.0523C204.976 51.0647 205.726 51.9468 206.643 52.6339C207.56 53.3211 208.62 53.7958 209.747 54.0231C210.208 55.2329 210.969 56.3072 211.962 57.1451C212.954 57.9828 214.146 58.557 215.421 58.8136C216.698 59.0702 218.022 59.0009 219.264 58.6122C220.506 58.2234 221.63 57.5279 222.528 56.5908C223.447 57.279 224.509 57.7539 225.638 57.9804C226.767 58.2072 227.931 58.1799 229.047 57.9007C230.164 57.6214 231.202 57.0972 232.088 56.367C232.973 55.6367 233.681 54.7187 234.162 53.6805C235.623 53.131 236.88 52.1537 237.769 50.8781C238.656 49.6025 239.132 48.0893 239.132 46.5391Z"
        fill="white"
      />
      <path
        d="M35.9173 28.182C35.9182 27.1787 35.7199 26.1849 35.3337 25.2577C34.9474 24.3304 34.3807 23.4877 33.6659 22.7778C32.9513 22.0679 32.1027 21.5046 31.1684 21.1204C30.2344 20.736 29.2329 20.538 28.2216 20.5378C28.0508 20.5378 27.8819 20.5378 27.7148 20.5544C27.7259 20.3886 27.7315 20.221 27.7315 20.0515C27.7313 18.9662 27.4979 17.8932 27.0468 16.9043C26.5958 15.9155 25.9375 15.0334 25.1157 14.3168C24.294 13.6003 23.3276 13.0657 22.2813 12.7488C21.2349 12.4319 20.1324 12.34 19.0474 12.4791C17.9625 12.6182 16.9198 12.9852 15.989 13.5556C15.0582 14.126 14.2607 14.8867 13.6496 15.7869C13.0384 16.6872 12.6277 17.7064 12.4448 18.7765C12.262 19.8467 12.3111 20.9432 12.5891 21.993C11.6587 21.3236 10.5892 20.8693 9.45884 20.6638C8.3285 20.458 7.16608 20.5061 6.05687 20.8041C4.94768 21.1024 3.91985 21.6432 3.04882 22.387C2.17779 23.1307 1.48567 24.0586 1.02324 25.1022C0.560817 26.1461 0.339821 27.2793 0.376475 28.4189C0.41313 29.5584 0.706505 30.6753 1.23507 31.6877C1.76363 32.7002 2.51397 33.5824 3.43102 34.2694C4.34807 34.9565 5.40856 35.4314 6.53467 35.6587C6.99488 36.8674 7.75668 37.9405 8.74876 38.7775C9.74084 39.6147 10.9308 40.1883 12.2072 40.4449C13.4836 40.7015 14.8047 40.6324 16.0469 40.2445C17.2892 39.8566 18.4119 39.1621 19.31 38.2264C20.2288 38.9146 21.2915 39.3894 22.4198 39.616C23.548 39.8427 24.7132 39.8155 25.8294 39.536C26.9456 39.2568 27.9846 38.7328 28.8696 38.0024C29.7546 37.2721 30.4633 36.3542 30.9434 35.3161C32.4038 34.7677 33.6617 33.7916 34.5501 32.5175C35.4384 31.2434 35.9152 29.7313 35.9173 28.182Z"
        fill="white"
      />
      <path
        d="M177.526 136.847H66.7795V134.452H177.526C177.846 134.452 178.153 134.578 178.379 134.803C178.606 135.027 178.733 135.332 178.733 135.65C178.733 135.967 178.606 136.272 178.379 136.496C178.153 136.721 177.846 136.847 177.526 136.847Z"
        fill="#426572"
      />
      <path
        d="M61.8262 136.847H55.0552C54.7351 136.847 54.4282 136.721 54.2019 136.496C53.9756 136.272 53.8484 135.967 53.8484 135.65C53.8484 135.332 53.9756 135.027 54.2019 134.803C54.4282 134.578 54.7351 134.452 55.0552 134.452H61.8262C62.1463 134.452 62.4532 134.578 62.6795 134.803C62.9058 135.027 63.033 135.332 63.033 135.65C63.033 135.967 62.9058 136.272 62.6795 136.496C62.4532 136.721 62.1463 136.847 61.8262 136.847Z"
        fill="#426572"
      />
      <path
        d="M50.6605 136.847H48.5032C48.1831 136.847 47.8762 136.721 47.6499 136.496C47.4236 136.272 47.2964 135.967 47.2964 135.65C47.2964 135.332 47.4236 135.027 47.6499 134.803C47.8762 134.578 48.1831 134.452 48.5032 134.452H50.6605C50.9806 134.452 51.2875 134.578 51.5138 134.803C51.7402 135.027 51.8673 135.332 51.8673 135.65C51.8673 135.967 51.7402 136.272 51.5138 136.496C51.2875 136.721 50.9806 136.847 50.6605 136.847Z"
        fill="#426572"
      />
      <path
        d="M216.359 136.847H182.32V134.452H216.359C216.678 134.452 216.986 134.578 217.213 134.803C217.439 135.027 217.565 135.332 217.565 135.65C217.565 135.967 217.439 136.272 217.213 136.496C216.986 136.721 216.678 136.847 216.359 136.847Z"
        fill="#426572"
      />
      <path
        d="M42.3318 136.847H8.29102V134.452H42.3318C42.6519 134.452 42.9588 134.578 43.1851 134.803C43.4115 135.027 43.5386 135.332 43.5386 135.65C43.5386 135.967 43.4115 136.272 43.1851 136.496C42.9588 136.721 42.6519 136.847 42.3318 136.847Z"
        fill="#426572"
      />
      <path
        d="M53.1186 43.3897H177.106C179.344 43.3897 181.49 44.2713 183.073 45.8408C184.656 47.4106 185.545 49.5393 185.546 51.7597V131.381H44.6785V51.7597C44.6794 49.5393 45.5691 47.4106 47.1518 45.8408C48.7346 44.2713 50.8808 43.3897 53.1186 43.3897Z"
        fill="#EBF0F9"
      />
      <path d="M180.651 49.4955H50.1333V123.47H180.651V49.4955Z" fill="#637F91" />
      <path
        d="M49.1549 123.332H181.206C181.206 123.919 180.971 124.482 180.554 124.899C180.136 125.315 179.57 125.55 178.978 125.553H51.3921C50.7982 125.553 50.2288 125.319 49.8088 124.903C49.3891 124.486 49.1531 123.921 49.1531 123.332H49.1549Z"
        fill="white"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M89.135 49.2192L69.6202 123.886H59.7932L79.308 49.2192H89.135Z"
          fill="#A7BCD5"
        />
        <path
          opacity="0.5"
          d="M114.182 49.2192L94.6696 123.886H74.0613L93.576 49.2192H114.182Z"
          fill="#A7BCD5"
        />
      </g>
      <path
        d="M186.66 132.487H43.5647V48.1899C43.5667 46.6242 44.1945 45.1234 45.3103 44.0161C46.4261 42.9091 47.939 42.2863 49.517 42.2845H180.708C182.286 42.2863 183.799 42.9091 184.915 44.0161C186.031 45.1234 186.659 46.6242 186.66 48.1899V132.487ZM45.7926 130.276H184.433V48.1899C184.432 47.2101 184.039 46.2709 183.341 45.5781C182.642 44.8854 181.696 44.4958 180.708 44.4949H49.517C48.5296 44.4958 47.5828 44.8854 46.8845 45.5781C46.1864 46.2709 45.7935 47.2101 45.7926 48.1899V130.276Z"
        fill="#426572"
      />
      <path
        d="M26.9128 128.746H207.091V129.333C207.091 131.239 206.327 133.066 204.97 134.413C203.611 135.76 201.77 136.517 199.85 136.517H34.1536C32.2333 136.517 30.3916 135.76 29.0336 134.413C27.6757 133.066 26.9128 131.239 26.9128 129.333V128.746Z"
        fill="#EBF0F9"
      />
      <path
        opacity="0.5"
        d="M206.168 132.492C205.772 133.514 205.072 134.393 204.162 135.012C203.253 135.632 202.174 135.963 201.071 135.962H31.8085C30.7057 135.962 29.6286 135.631 28.7195 135.011C27.8103 134.392 27.1118 133.514 26.7158 132.492H206.168Z"
        fill="#A7BCD5"
      />
      <path
        d="M199.009 137.344H33.3144C31.0993 137.341 28.9757 136.467 27.4095 134.913C25.8431 133.359 24.9621 131.252 24.9597 129.055V127.362H207.365V129.055C207.364 131.253 206.482 133.36 204.915 134.914C203.348 136.468 201.224 137.342 199.009 137.344ZM27.2081 129.572C27.3388 131.089 28.0385 132.502 29.1688 133.532C30.2993 134.562 31.7779 135.133 33.3126 135.133H199.009C200.544 135.133 202.022 134.562 203.153 133.532C204.283 132.502 204.983 131.089 205.113 129.572H27.2081Z"
        fill="#426572"
      />
      <path
        d="M181.484 124.159H48.7371V48.3903H181.484V124.159ZM50.965 121.948H179.256V50.6007H50.965V121.948Z"
        fill="#426572"
      />
      <path
        d="M160.7 25.7489V123.054H71.7292V25.7489C71.7326 25.3104 71.9097 24.8906 72.2224 24.5804C72.535 24.2702 72.9581 24.0945 73.4002 24.0912H159.021C159.122 24.0912 159.223 24.1005 159.322 24.1188C159.707 24.1893 160.055 24.3907 160.306 24.6881C160.557 24.9856 160.697 25.3608 160.7 25.7489Z"
        fill="white"
      />
      <path
        d="M73.1293 32.1335H160.98V26.2796L159.3 24.084H82.3622H74.8077L72.0098 26.2796V32.1335H73.1293Z"
        fill="#E2F2FF"
      />
      <path
        d="M162.094 124.159H70.8958V25.7489C70.8987 25.0171 71.193 24.3161 71.7147 23.7985C72.2364 23.2809 72.943 22.9889 73.6807 22.986H159.298C160.036 22.9889 160.742 23.2809 161.264 23.7985C161.786 24.3161 162.08 25.0171 162.083 25.7489L162.094 124.159ZM73.1237 121.949H159.866V25.7489C159.862 25.6036 159.802 25.4651 159.699 25.3623C159.595 25.2595 159.456 25.2 159.309 25.1963H73.6881C73.5416 25.2 73.402 25.2595 73.2984 25.3623C73.1948 25.4651 73.1348 25.6036 73.1311 25.7489L73.1237 121.949Z"
        fill="#426572"
      />
      <path
        d="M155.507 27.4954H77.2433C76.6251 27.4954 76.1238 27.9925 76.1238 28.6061C76.1238 29.2195 76.6251 29.7168 77.2433 29.7168H155.507C156.125 29.7168 156.626 29.2195 156.626 28.6061C156.626 27.9925 156.125 27.4954 155.507 27.4954Z"
        fill="#CADCE8"
      />
      <path d="M101.021 42.5571H82.7278V63.2371H101.021V42.5571Z" fill="#53A35D" />
      <path d="M146.123 42.5571H102.748V63.2371H146.123V42.5571Z" fill="#095913" />
      <path d="M146.123 65.5265H125.643V86.2065H146.123V65.5265Z" fill="#095913" />
      <path d="M123.917 65.5265H82.958V86.2065H123.917V65.5265Z" fill="#53A35D" />
      <path d="M146.238 88.4961H82.958V109.176H146.238V88.4961Z" fill="#BCE8BD" />
      <path d="M146.797 111.955H130.571V114.166H146.797V111.955Z" fill="#DDE4ED" />
      <path
        d="M58.1537 117.784H31.5932C31.1558 117.787 30.7248 117.681 30.34 117.474C29.783 117.172 29.4265 116.677 29.4265 116.119L29.4414 79.8942C29.4417 79.6572 29.5058 79.4245 29.627 79.2201C29.9649 78.638 30.728 78.2291 31.608 78.2291H58.1686C59.0969 78.2291 59.8785 78.6748 60.1904 79.2974C60.2849 79.4818 60.3345 79.6855 60.3352 79.8924L60.3222 116.119C60.3222 116.955 59.5072 117.653 58.4545 117.767C58.3546 117.779 58.2542 117.784 58.1537 117.784Z"
        fill="#53A35D"
      />
      <path
        d="M58.8761 117.506L32.3063 117.5C31.8691 117.504 31.438 117.397 31.0531 117.191C30.4961 116.889 30.1396 116.393 30.1396 115.835L30.1545 79.6107C30.1549 79.3737 30.2189 79.141 30.3402 78.9365C30.6781 78.3545 31.4411 77.9456 32.3212 77.9456H32.6832L32.6776 113.614C32.6776 114.166 33.0378 114.667 33.5911 114.97C33.9759 115.176 34.407 115.282 34.8443 115.279H61.0447V115.832C61.0447 116.668 60.2296 117.366 59.1769 117.48C59.0772 117.494 58.9768 117.503 58.8761 117.506Z"
        fill="#BCE8BD"
      />
      <path
        d="M58.514 118.611H31.9516C31.3254 118.614 30.7082 118.46 30.1581 118.163C29.2298 117.653 28.6729 116.784 28.6729 115.841L28.6877 79.616C28.6899 79.1841 28.8071 78.7603 29.0275 78.3874C29.5844 77.4388 30.6984 76.8475 31.9702 76.8475H58.5307C59.8304 76.8475 60.9907 77.483 61.518 78.4703L61.6665 78.6969V78.8C61.7627 79.0629 61.8117 79.3403 61.8114 79.6197L61.7965 115.844C61.7965 117.233 60.5656 118.423 58.9355 118.591C58.7955 118.605 58.6548 118.612 58.514 118.611ZM31.9646 79.0487C31.4652 79.0487 31.0753 79.2679 30.9509 79.4853C30.9266 79.5221 30.913 79.5648 30.9119 79.6087L30.8971 115.833C30.8971 115.955 31.0326 116.11 31.235 116.222C31.4557 116.338 31.7019 116.397 31.9516 116.395H58.5122C58.5705 116.395 58.6288 116.392 58.6867 116.386C59.2437 116.327 59.5667 116.017 59.5667 115.833L59.5816 79.6087C59.5816 79.5868 59.5779 79.565 59.5704 79.5442L59.4665 79.3858C59.2097 79.1561 58.8725 79.0351 58.527 79.0487H31.9665H31.9646Z"
        fill="#426572"
      />
      <path d="M55.4505 93.904H37.4377V96.1144H55.4505V93.904Z" fill="#426572" />
      <path d="M55.4505 89.2918H37.4377V91.5022H55.4505V89.2918Z" fill="#426572" />
      <path d="M55.4505 99.1299H37.4377V101.34H55.4505V99.1299Z" fill="#426572" />
    </g>
  </svg>
);

export default NoRequirementsFallback;
