import { CaseWorkerResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { SdirButton, TextLink } from '@sdir/sds';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PersonIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import { Signer } from '../../store/constants/types';

interface Props {
  signer: Signer | undefined;
  setSigneeModalVisible: (visible: boolean) => void;
  setCaseWorkerModalVisible?: (visible: boolean) => void;
  loading: boolean;
  isOptional: boolean;
  caseWorker: CaseWorkerResult | undefined;
  width?: string;
  hideSigner?: boolean;
  editMode?: boolean;
  disableChangeSigner?: boolean;
}

const Signerbox: React.FC<Props> = ({
  signer,
  setSigneeModalVisible,
  setCaseWorkerModalVisible,
  loading,
  isOptional,
  caseWorker,
  width = '108rem',
  hideSigner,
  editMode = true,
  disableChangeSigner
}) => {
  const intl = useIntl();
  const currentUser = useCurrentUser();

  return (
    <CaseworkerBox width={width}>
      <PersonIcon />
      <CaseWorkerContainer>
        <span className="label">
          {`${intl.formatMessage({
            id: 'caseworker.reply.exemption.caseworker'
          })}/${intl.formatMessage({ id: 'caseworker.reply.inspector' })}:`}
        </span>
        <span>
          <TextLink
            strong
            text={caseWorker?.name || currentUser?.profile?.name || 'Ukjent'}
            disabled={!caseWorker?.name && !currentUser?.profile?.name}
          />
          {setCaseWorkerModalVisible && editMode && (
            <TextLink
              text={intl.formatMessage({ id: 'vesselsimulator.button.change' })}
              onClick={() => setCaseWorkerModalVisible(true)}
              disabled={loading}
            />
          )}
        </span>
      </CaseWorkerContainer>
      {!hideSigner && !signer && editMode && (
        <SdirButton
          type="default"
          onClick={() => setSigneeModalVisible(true)}
          disabled={loading || disableChangeSigner}
        >
          {intl.formatMessage({ id: 'caseworker.reply.addsigner' })}{' '}
          {isOptional && `(${intl.formatMessage({ id: 'caseworker.reply.optional' })})`}
        </SdirButton>
      )}
      {!hideSigner && signer && (
        <CaseWorkerContainer>
          <span className="label">
            {intl.formatMessage({ id: 'caseworker.reply.exemption.signer' })}
          </span>
          <span>
            <TextLink strong text={signer.username} />
            {editMode && (
              <TextLink
                text={intl.formatMessage({ id: 'vesselsimulator.button.change' })}
                onClick={() => setSigneeModalVisible(true)}
                disabled={disableChangeSigner}
              />
            )}
          </span>
        </CaseWorkerContainer>
      )}
    </CaseworkerBox>
  );
};

const CaseworkerBox = styled.div<{ width: string }>`
  height: 13rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  border-radius: 6px;
  width: ${({ width }) => width};
  margin: 4rem auto;
  display: flex;
  align-items: center;

  svg {
    margin: 4rem;
    transform: scale(1.5);
  }
`;

const CaseWorkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 7.5rem;
  justify-content: space-between;
  height: 50%;

  .label {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    color: ${({ theme }) => theme.colors.font};
    font-size: 2rem;
  }

  button {
    display: inline-block;
    margin-right: 1rem;
  }
`;

export default Signerbox;
