/* eslint-disable @typescript-eslint/indent */
import { useRef } from 'react';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { DownloadAttachmentPayload } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { downloadAttachmentApi } from '../../httpclient';

interface useFileDownloadProps {
  projectUid: string;
}

export function useDownloadByFilename(
  props: useFileDownloadProps
): {
  downloadByFilename: (filename: string) => void;
} {
  const { projectUid } = props;
  const fileNameRef = useRef('');
  const { setError } = useToast();
  const intl = useIntl();

  const { callApi } = useApi({
    apiFunction: fileName => {
      const payload: DownloadAttachmentPayload = {
        fileName
      };
      return downloadAttachmentApi.v1DownloadattachmentGetprojectattachmentPayloadPost(
        projectUid,
        payload
      );
    },
    successCallback: res => {
      triggerDownload(res.sasUrl);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'fil' })}: ${err}`);
      }
    }
  });

  const downloadByFilename = filename => {
    const filenameParts = filename.split('/');
    fileNameRef.current = filenameParts[filenameParts.length - 1];
    callApi(filename);
  };

  const triggerDownload = url => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', fileNameRef.current);

    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  };

  return { downloadByFilename };
}
