/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import { SdirModal, Loader } from '@sdir/sds';
import { ApplicationSeagoingResult } from '@sdir/httpclient/lib/clients/aps/seagoing';
import { ErrorMessage } from '@sdir/utilities/lib/components';

interface SeagoingHistoryModalProps {
  display: boolean;
  error?: string;
  history?: ApplicationSeagoingResult[];
  loading?: boolean;
  onCancelClick: () => void;
}

const SeagoingHistoryModal: React.FC<SeagoingHistoryModalProps> = ({
  display,
  error,
  history,
  loading = false,
  onCancelClick
}) => {
  const intl = useIntl();

  const changes = (current: ApplicationSeagoingResult, i: number) => {
    const previous = history?.[i + 1] || {};
    const result: ApplicationSeagoingResult = {};
    Object.keys(current)?.forEach(key => {
      const currentValue =
        current?.[key]?.name?.[intl.locale] || current?.[key]?.value || current?.[key];
      const previousValue =
        previous?.[key]?.name?.[intl.locale] || previous?.[key]?.value || previous?.[key];
      const isObjects = typeof currentValue === 'object' && typeof previousValue === 'object';
      if (!(key in previous) || (currentValue !== previousValue && !isObjects)) {
        result[key] = current[key];
      }
    });
    result.modifiedAt = current.modifiedAt;
    result.modifiedBy = current.modifiedBy;
    result.changeComment = current.changeComment;
    return result;
  };

  return (
    <StyledModal width="450px" visible={display} onCancel={onCancelClick}>
      <Wrapper>
        <h2>{intl.formatMessage({ id: 'seagoing.history.title' })}</h2>
        <Container>
          {history?.map((h, i) => {
            const itemChanges = changes(h, i);
            return (
              <HistoryItem
                key={`${h.modifiedAt}-${i}`}
                data={itemChanges}
                fm={intl.formatMessage}
                locale={intl.locale}
              />
            );
          })}
        </Container>
        {loading && <Loader />}
        {error && <ErrorMessage error={error} />}
      </Wrapper>
    </StyledModal>
  );
};

type HistoryItemProps = {
  data?: ApplicationSeagoingResult;
  fm: (payload: any) => string;
  locale: string;
};

const HistoryItem = (props: HistoryItemProps) => {
  const { data, fm, locale } = props;

  const nrOfChanges = useMemo(() => {
    const countKeys = Object.keys(data || {})?.length || 0;
    // modifiedAt, modifiedBy, changeComment are always there, do not count these.
    return countKeys - 3;
  }, [data]);

  if (!data) return <></>;

  return (
    <HistoryItemWrapper>
      <h3>
        {moment(data?.modifiedAt || '').format('DD.MM.YYYY - HH:mm')} - {data?.modifiedBy}
      </h3>
      {nrOfChanges > 0 ? (
        <>
          {'vesselName' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.name' })}:</span>
              <span>{data?.vesselName || ''}</span>
            </div>
          )}
          {'imoNumber' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.imoNumber' })}:</span>
              <span>{data?.imoNumber || ''}</span>
            </div>
          )}
          {'callSign' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.callSign' })}:</span>
              <span>{data?.callSign || ''}</span>
            </div>
          )}
          {'vesselGrossTonnage' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.tonnage' })}:</span>
              <span>{data?.vesselGrossTonnage || ''}</span>
            </div>
          )}
          {'vesselPropulsionPower' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.effect' })}:</span>
              <span>{data?.vesselPropulsionPower || ''}</span>
            </div>
          )}
          {'vesselLengthOverall' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.length' })}:</span>
              <span>{data?.vesselLengthOverall || ''}</span>
            </div>
          )}
          {'vesselPropulsionType' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.propulsion' })}:</span>
              <span>
                {data?.vesselPropulsionType?.name?.[locale] ||
                  data?.vesselPropulsionType?.value ||
                  ''}
              </span>
            </div>
          )}
          {'vesselType' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.type' })}:</span>
              <span>{data?.vesselType?.name?.[locale] || data?.vesselType?.value || ''}</span>
            </div>
          )}
          {'vesselTradeArea' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.vessel.newVessel.tradeArea' })}:</span>
              <span>
                {data?.vesselTradeArea?.name?.[locale] || data?.vesselTradeArea?.value || ''}
              </span>
            </div>
          )}
          {'signOnDate' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.startDate' })}:</span>
              <span>
                {data?.signOnDate ? moment(data?.signOnDate || '').format('DD.MM.YYYY') : ''}
              </span>
            </div>
          )}
          {'signOffDate' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.endDate' })}:</span>
              <span>
                {data?.signOffDate ? moment(data?.signOffDate || '').format('DD.MM.YYYY') : ''}
              </span>
            </div>
          )}
          {'serviceCode' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.occupation' })}:</span>
              <span>{data?.serviceCode?.name?.[locale] || data?.serviceCode?.value || ''}</span>
            </div>
          )}
          {'totalServiceHours' in data && (
            <div>
              <span>{fm({ id: 'addseagoing.hours' })}:</span>
              <span>{data?.totalServiceHours || ''}</span>
            </div>
          )}
          {'processingState' in data && (
            <div>
              <span>{fm({ id: 'seagoing.history.status' })}:</span>
              <span>
                {data?.processingState
                  ? fm({ id: `seagoing.history.status.${data.processingState}` })
                  : ''}
              </span>
            </div>
          )}
        </>
      ) : (
        <div>{fm({ id: 'seagoing.history.nochanges' })}</div>
      )}
      {'changeComment' in data && (
        <div>
          <span>{fm({ id: 'seagoing.history.comment' })}:</span>
          <span>{data?.changeComment || ''}</span>
        </div>
      )}
    </HistoryItemWrapper>
  );
};

const StyledModal = styled(SdirModal)`
  font-size: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin: 0px 0;

  h2 {
    margin: 0;
  }
`;

const HistoryItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 15px 0;

  h3 {
    margin: 0;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span:last-child {
      word-break: break-all;
      max-width: 290px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: auto;
  padding-right: 10px;
`;

export default SeagoingHistoryModal;
