/* eslint-disable @typescript-eslint/indent */
import { GetReactionResult, ReactionType } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { DataCard } from '@sdir/sds';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled, { css, ThemeContext } from 'styled-components';

interface ReactionDraftTextProps {
  reactionType: ReactionType | undefined;
  reactionText: string | undefined | null;
}

const ReactionDraftText: React.FC<ReactionDraftTextProps> = ({ reactionType, reactionText }) => {
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  const reactionTypeText = type => {
    switch (type) {
      case ReactionType.Comment:
        return intl.formatMessage({ id: 'vessel.activity.reaction.draft.comment' });
      case ReactionType.Mandate:
        return intl.formatMessage({ id: 'vessel.activity.reaction.draft.mandate' });
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <DataCard>
        <DataCardContent>
          <Preview barColor={theme.colors.secondary.grey1}>
            <span>
              <b>{reactionTypeText(reactionType)}</b>: {reactionText}
            </span>
          </Preview>
        </DataCardContent>
      </DataCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;

  section {
    margin-bottom: 0;
    div {
      line-height: 1;
    }
  }
`;

const Preview = styled.div<{ barColor?: string }>`
  display: flex;
  justify-content: space-between;

  span {
    max-width: 90%;
    white-space: pre-wrap;
    height: 1.5em;
    overflow: hidden;

    ${({ barColor }) =>
      barColor &&
      css`
        &::before {
          content: '';
          border-radius: 6px;
          margin-right: 1rem;
          border-left: 4px solid ${barColor};
        }
      `}
  }
`;

const DataCardContent = styled.div`
  padding: 2rem;
  color: ${({ theme }) => theme.colors.font.text};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 1.75rem;
`;

export default ReactionDraftText;
