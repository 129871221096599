import moment from 'moment';
import { AxiosResponse } from 'axios';
import { SeagoingQueryResult } from '@sdir/httpclient/lib/clients/aps/application';
import { RegisterSeagoingInput } from '@sdir/blueprint.aps/lib/components';
import {
  ApprovedApplicationSeagoing,
  ServiceReportResult
} from '@sdir/httpclient/lib/clients/aps/seagoing';
import { applicationSeagoingApi } from '../httpclient';

export const formatBackendValidationErrors = apiErrors => {
  const errors = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(apiErrors)) {
    let subkey = key;
    // Remove this when backend changes are done, only needed temporarily
    if (key?.indexOf('$.approvedApplicationSeagoing.') !== -1) {
      subkey = key.replace('$.approvedApplicationSeagoing.', '');
    }
    const formattedKey = `${subkey.charAt(0).toLowerCase()}${subkey.substring(1, subkey.length)}`;
    errors[formattedKey] = value?.[0] || undefined;
  }
  return errors;
};

export const createUpdateSeagoingPayload = (values, selectedSeagoing: SeagoingQueryResult) => {
  const { seagoing, vessel } = values;
  const grossTonnage = (`${vessel?.vesselGrossTonnage || ''}`.replaceAll(
    '.',
    ','
  ) as unknown) as number;
  const lengthOverall = (`${vessel?.vesselLengthOverall || ''}`.replaceAll(
    '.',
    ','
  ) as unknown) as number;
  const propulsionPower = `${vessel?.vesselPropulsionPower || ''}`.replaceAll('.', ',');
  return {
    uid: selectedSeagoing?.uid || '',
    personNumber: selectedSeagoing.personNumber || '',
    signOnDate: seagoing.signOnDate ? moment(seagoing.signOnDate)?.format('YYYY-MM-DD') : '',
    signOffDate: seagoing.signOffDate ? moment(seagoing.signOffDate)?.format('YYYY-MM-DD') : '',
    totalServiceHours: seagoing?.totalServiceHours,
    serviceCode: seagoing?.occupationCode,
    callSign: vessel?.callSign,
    vesselName: vessel?.vesselName,
    dataAccumulatedAt: new Date().toISOString(),
    vesselGrossTonnage: grossTonnage,
    vesselLengthOverall: lengthOverall,
    vesselLengthOverallUnit: vessel.vesselLengthOverallUnit || 'Meter',
    vesselPropulsionPower: propulsionPower,
    applicationGroupUid: selectedSeagoing.applicationGroupUid || '',
    applicationReference: selectedSeagoing.applicationReference || '',
    imoNumber: vessel?.imoNumber,
    vesselId: vessel?.vesselId || '',
    vesselType: vessel?.vesselType,
    vesselTypeCode: vessel.vesselTypeCode || '',
    vesselTradeArea: vessel?.vesselTradeArea,
    vesselTradeAreaUri: vessel.vesselTradeAreaUri || '',
    vesselPropulsionType: vessel?.vesselPropulsionType,
    vesselPropulsionTypeDescription: vessel.vesselPropulsionTypeDescription || ''
  };
};

export const submitSeagoingUpdate = (
  values: RegisterSeagoingInput,
  comment: string,
  seagoing: any
): Promise<AxiosResponse<ServiceReportResult>> => {
  const updatePayload = createUpdateSeagoingPayload(values, seagoing);
  const seagoingValues: ApprovedApplicationSeagoing = {
    signOnDate: updatePayload.signOnDate,
    signOffDate: updatePayload.signOffDate,
    totalServiceHours: updatePayload.totalServiceHours,
    serviceCode: updatePayload.serviceCode,
    callSign: updatePayload.callSign,
    vesselName: updatePayload.vesselName,
    dataAccumulatedAt: updatePayload.dataAccumulatedAt,
    vesselType: updatePayload.vesselType,
    vesselGrossTonnage: updatePayload.vesselGrossTonnage,
    vesselTradeArea: updatePayload.vesselTradeArea,
    vesselLengthOverall: updatePayload.vesselLengthOverall,
    vesselLengthOverallUnit: updatePayload.vesselLengthOverallUnit,
    vesselPropulsionType: updatePayload.vesselPropulsionType,
    vesselPropulsionPower: updatePayload.vesselPropulsionPower,
    imoNumber: updatePayload.imoNumber,
    vesselId: updatePayload.vesselId
  };
  return applicationSeagoingApi.v1ApplicationseagoingUpdateapprovedapplicationseagoingCommandPut(
    updatePayload.uid,
    {
      approvedApplicationSeagoing: seagoingValues,
      changeComment: comment || ''
    }
  );
};
