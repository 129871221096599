import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import moment from 'moment';
import { SdirModal, SdirButton, Loader, AlertSingleLine } from '@sdir/sds';
import { Field, Formik, Form } from 'formik';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { sortObjects } from '../../../helpers';
import { SortingDirection } from '../../../types/enums';
import { courceApi, institutionCourseApi } from '../../../httpclient';
import FormDate from '../../Molecules/FormDate';
import FormSelect from '../../Molecules/FormSelect';

export type ReportingTimeData = {
  legalEntityId: string;
  serviceCode: string | null | undefined;
};

export interface SelectOption {
  name: string;
  value: string;
}

export interface EditCourseModalProps {
  display: boolean;
  row: any;
  onCancelClick: () => void;
  onSuccessEdit: () => void;
}

const courseUpdateApi = courceApi?.v1CourseUpdateseafarercompletedcourseUidPut.bind(courceApi);

const courseGetApi = courceApi?.v1CourseByuidUidGet.bind(courceApi);

const EditCourseModal: React.FC<EditCourseModalProps> = ({
  display,
  row,
  onCancelClick,
  onSuccessEdit
}) => {
  const intl = useIntl();

  const requiredText = intl.formatMessage({ id: 'vessel.general.form.error' });

  const { callApi: performGet } = useApi({
    apiFunction: uidToEdit => courseGetApi(uidToEdit),
    callApiOnInit: false
  });

  const { loading, error, callApi: performEdit } = useApi({
    apiFunction: (uidToEdit, payload) => courseUpdateApi(uidToEdit, payload),
    callApiOnInit: false
  });

  const { result: courses, error: courseError, loading: loadingCourse } = useApi({
    apiFunction: () =>
      // eslint-disable-next-line max-len
      institutionCourseApi.v1InstitutioncourseGetvalidcoursesbyorgnumberanddateInstitutionWithCoursesPost(
        {
          orgNumber: row?.organizationNumber,
          date: row?.issuedDate
        }
      ),
    callApiOnInit: true
  });

  const courseOptions = useMemo(() => {
    const options =
      courses?.map<SelectOption>(v => ({
        name: v.description || v.name || '',
        secondValue: v.code || undefined,
        value: v.code || ''
      })) || [];
    return sortObjects(options, { key: 'name', direction: SortingDirection.ASC });
  }, [courses]);

  const DateDisplayFormat = 'DD.MM.YYYY';

  const handleSubmit = useCallback(
    async values => {
      const res = await performGet(row?.uid);
      if (res.success) {
        res.data.courseCode = values?.courseCode;
        res.data.completedAt = moment(values?.completedAt).format('YYYY-MM-DD');
        const apiResult = await performEdit(row?.uid, res.data);
        if (apiResult.success) {
          onSuccessEdit();
        }
      }
    },
    [onSuccessEdit]
  );

  const initialValues = {
    courseCode: row?.courseCode,
    completedAt: moment(row?.issuedDate)
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      courseCode: Yup.string().required(requiredText),
      completedAt: Yup.string().required(requiredText)
    });
  }, [requiredText]);

  return display ? (
    <SdirModal width="600px" height="250px" visible={display} onCancel={onCancelClick}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <Form>
              <FieldWrapperCourse
                name="courseCode"
                component={FormSelect}
                grouped
                searchable
                options={courseOptions}
                defaultValue={row?.courseCode}
              />
              <FieldWrapper
                format={DateDisplayFormat}
                maxDate={moment()}
                name="completedAt"
                placeholder={intl.formatMessage({
                  id: 'table.report.completionDate'
                })}
                type="number"
                showYearDropdown
                showMonthDropdown
                component={FormDate}
              />
              <Container>
                <ButtonsSection>
                  <SdirButton
                    onClick={onCancelClick}
                    type="default"
                    text={intl.formatMessage({ id: 'vessel.editmodal.close' })}
                    htmlType="button"
                  />
                  <SdirButton
                    onClick={submitForm}
                    type="primary"
                    text={intl.formatMessage({ id: 'vessel.editmodal.save' })}
                    htmlType="button"
                  />
                </ButtonsSection>
              </Container>
              {(loading || loadingCourse) && <Loader />}
              {(error || courseError) && (
                <AlertSingleLine
                  type="error"
                  title="Error"
                  text={intl.formatMessage({ id: 'vessel.editmodalcourse.edit.error' })}
                />
              )}
            </Form>
          )}
        </Formik>
      </Wrapper>
    </SdirModal>
  ) : null;
};

const FieldWrapperCourse = styled(Field)`
  width: 75em;
  margin-bottom: 16px;
`;

const FieldWrapper = styled(Field)`
  width: 30em;
  margin-bottom: 16px;
`;

const ButtonsSection = styled.div`
  display: flex;
  width: 100%;
  height: 20rem;
  justify-content: space-between;
  margin: 0px 0;
`;

const Container = styled.div`
  display: flex;
  margin-top: 50px;
  height: 7rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 19rem;
  margin: 0px 0;
`;

export default EditCourseModal;
