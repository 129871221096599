import styled from 'styled-components';
import IncidentSection from '@sdir/blueprint/lib/Views/ET/Incidents/IncidentSection';
import React from 'react';
import { InspectionToggle } from '../../constants/permissions';
import { incidentsApi } from '../../httpclient';
import { ToggleContent } from '../Atoms';

const IncidentContainer: React.FC = () => {
  return (
    // This featuretoggle is just for the purpose of testing incidents.
    // If Incidents are to be used, a real imonumber must be submitted
    <Container>
      <ToggleContent
        toggleName={InspectionToggle}
        showLoader
        alternativeChildren={
          <IncidentSection
            incidentApiFunction={(...args) =>
              incidentsApi.v1IncidentsGetincidentsforvesselImoNumberGet(...args) as any
            }
            incidentDetailsApiFunction={args =>
              incidentsApi.v1IncidentsGetincidentdetailsIncidentIdGet(args) as any
            }
          />
        }
      >
        <IncidentSection
          specifiedImoNumber="9349863"
          incidentApiFunction={(...args) =>
            incidentsApi.v1IncidentsGetincidentsforvesselImoNumberGet(...args) as any
          }
          incidentDetailsApiFunction={args =>
            incidentsApi.v1IncidentsGetincidentdetailsIncidentIdGet(args) as any
          }
        />
      </ToggleContent>
    </Container>
  );
};

const Container = styled.article`
  grid-area: 4 / 1 / auto / fill;
  margin-top: 4rem;
`;

export default IncidentContainer;
