/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useToast } from '@sdir/utilities/lib/hooks';
import { aDGroupApi } from '../../httpclient';
import { setETCaseworker, setETCaseworkerLoading } from '../../store/actions/action';

const useETCaseworker = () => {
  const intl = useIntl();
  const { setError } = useToast();
  const dispatch = useDispatch();

  const getEtCaseWorkers = () => {
    dispatch(setETCaseworkerLoading(true));
    callApi();
  };

  const { callApi } = useApi({
    apiFunction: () =>
      aDGroupApi.v1AdgroupGetcaseworkerwithmembershipinsAdgroupidGet('ET_CASEWORKER'),
    successCallback: res => {
      dispatch(setETCaseworker(res));
      dispatch(setETCaseworkerLoading(false));
    },
    errorCallback: err => {
      dispatch(setETCaseworkerLoading(false));
      err.length > 0 &&
        setError(
          `${intl.formatMessage({ id: 'error.load' }, { error: 'saksbehandlere' })}: ${err}`
        );
    }
  });

  return { getEtCaseWorkers };
};

export default useETCaseworker;
