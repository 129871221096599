import React from 'react';
import axios from 'axios';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { FeatureToggleContext } from '../../../../services/contexts/FeatureToggleContext';
import { Toggle, TogglesResponse } from '../../../../types/toggles';
import { Loadable } from '../../../../types/commonTypes';

interface IFeatureToggleProvider {
  children?: React.ReactNode;
}

const config = {
  headers: {
    Authorization: process.env.REACT_APP_UNLEASH_AUTHORIZATION as string
  }
};

export const FeatureToggleProvider: React.FC<IFeatureToggleProvider> = ({ children }) => {
  const { result, error } = useApi({
    apiFunction: () =>
      axios.get<TogglesResponse>(process.env.REACT_APP_UNLEASH_URL as string, config),
    callApiOnInit: true
  });

  let value: Loadable<Toggle[]>;

  if (result) {
    value = {
      result: result.toggles
    };
  } else if (error) {
    value = {
      error
    };
  } else {
    value = {
      loading: true
    };
  }

  return <FeatureToggleContext.Provider value={value}> {children} </FeatureToggleContext.Provider>;
};
