import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SdirButton, SdiClock } from '@sdir/sds';
import { GetProjectResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { generatePath, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Routes } from '../Templates/Routes';
import { vesselTabOptions } from '../Templates/Routes/constants';

interface ProjectTileProps {
  project: GetProjectResult;
}

const ProjectTile: React.FC<ProjectTileProps> = ({ project }) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const intl = useIntl();

  const pushHistory = () => {
    navigate(
      generatePath(Routes.vesselWorkspace, {
        projectuid: project.uid,
        tab: vesselTabOptions.projectmanagement
      })
    );
  };
  return (
    <>
      <SmallTileWrapper>
        <StyledProjectStatus>{project.status}</StyledProjectStatus>
        <Content>
          <div>
            <VesselDetails>
              <Name>{project.name}</Name>
              <Timer>
                <SdiClock size="s" color={theme.colors.primary} />
                <TimeDetails>2d:12t:5m</TimeDetails>
              </Timer>
            </VesselDetails>
            <Callsign>{intl.formatMessage({ id: 'vesselpreview.callsign' })}</Callsign>
            <VesselType>{project.vesselTypeLabel}</VesselType>
          </div>
          <ButtonContainer>
            <SdirButton
              htmlType="button"
              type="primary"
              text="Sett opp team"
              onClick={() => pushHistory()}
            />
          </ButtonContainer>
        </Content>
      </SmallTileWrapper>
    </>
  );
};

export default ProjectTile;

const SmallTileWrapper = styled.div`
  width: 55rem;
  height: 33rem;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  position: relative;
`;

const Content = styled.div`
  padding: 3rem 4rem 5rem 4rem;
  min-height: 29rem;
`;

const VesselDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Timer = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  grid-gap: 1rem;
  font-size: 1.4rem;
  grid-template-columns: auto auto;
  color: ${({ theme }) => theme.colors.font.primary.blue};
  align-content: flex-start;
  margin-top: 1rem;
`;

const Name = styled.div`
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
`;

const Callsign = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const TimeDetails = styled.span`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const VesselType = styled.div`
  font-size: 2rem;
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const StyledProjectStatus = styled.div`
  background: ${({ theme }) => theme.colors.primaryLightBlue6};
  height: 4rem;
  padding: 0.5rem 0 0 2rem;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  border-radius: ${({ theme }) => theme.card.borderRadius} ${({ theme }) => theme.card.borderRadius}
    0 0;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 4rem;
  bottom: 5rem;
`;
