import { Loader } from '@sdir/sds';
import React, { useMemo } from 'react';
import { useFeatureToggles } from '../../services/hooks/useFeatureToggles';

interface ToggleContentProps {
  toggleName: string;
  children: any;
  showLoader?: boolean;
  alternativeChildren?: any;
}

const ToggleContent: React.FC<ToggleContentProps> = ({
  toggleName,
  children,
  showLoader = false,
  alternativeChildren
}) => {
  const { result, loading } = useFeatureToggles();
  const prereleaseFeaturesEnabled = useMemo(() => {
    if (!result) return false;
    return result.findIndex(t => t.name === toggleName) > -1;
  }, [result, toggleName]);
  if (loading && showLoader) return <Loader />;
  return prereleaseFeaturesEnabled ? children : alternativeChildren || <div />;
};

export default ToggleContent;
