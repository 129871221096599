import React, { useMemo } from 'react';
import { SdirModal, Loader, AlertSingleLine } from '@sdir/sds';
import styled from 'styled-components';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { SeagoingDetailsQuery, Detail } from '@sdir/httpclient/lib/clients/aps/seagoing';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import { ReportTimeHeader, ReportTimeRow } from '../../../helpers/TableHeaders';
import { seagoingTimeCalculatorApi } from '../../../httpclient';
import { DisplayDateFormat } from '../../../helpers';

export type ReportingTimeData = Detail & {
  legalEntityId: string;
  serviceCode: string | null | undefined;
};

interface RequirementsModalProps {
  display: boolean;
  onCancelClick: () => void;
  reportingTime: ReportingTimeData;
}
const ReportingTime: React.FC<RequirementsModalProps> = ({
  reportingTime,
  display,
  onCancelClick
}) => {
  const intl = useIntl();

  const payload: SeagoingDetailsQuery = {
    skip: 0,
    take: 100,
    personLegalEntityId: reportingTime.legalEntityId,
    organizationNumber: reportingTime.organizationNumber,
    imoNumber: reportingTime.vessel?.imoNumber || null,
    callSign: reportingTime.vessel?.callSign || null,
    name: reportingTime.vessel?.name || null,
    startDate: moment(reportingTime.signOnDate).format('YYYY-MM-DD'),
    endDate: moment(reportingTime.signOffDate).format('YYYY-MM-DD'),
    serviceCode: reportingTime.serviceCode
  };
  const { result, error, loading } = useApi({
    apiFunction: () =>
      seagoingTimeCalculatorApi.v1SeagoingtimecalculatorGetseagoingdetailsSeagoingDetailsQueryPost(
        payload
      ),
    callApiOnInit: true
  });
  const TableData = useMemo(() => {
    if (result && result.details && result.details.length > 0) {
      const data = result.details.map<ReportTimeRow>(i => {
        const startDate = i.period?.signOnDate && DisplayDateFormat(i.period.signOnDate);
        const endDate = i.period?.signOffDate && DisplayDateFormat(i.period.signOffDate);
        const period = startDate && endDate ? `${startDate} -${endDate}` : '-';
        return {
          createdBy: i.createdBy,
          date: i.createDate && DisplayDateFormat(i.createDate),
          dateValue: i.createDate ? moment(i.createDate) : null,
          period,
          periodStartValue: i.period?.signOnDate ? moment(i.period.signOnDate) : null,
          organization: i.organizationName,
          hours: i.hours
        };
      });
      return data;
    }
    return [];
  }, [result]);
  return display ? (
    <SdirModal width="800px" visible={display} onCancel={onCancelClick}>
      <SortableDataTable
        data-testid="report-time-table"
        columns={ReportTimeHeader}
        data={TableData}
        sortableKeys="*"
      />
      {loading && (
        <LoaderContainer data-testid="ap-loader">
          <Loader />
        </LoaderContainer>
      )}
      {error && (
        <AlertSingleLine
          type="error"
          title="Error"
          text={error || intl.formatMessage({ id: 'general.errors.api.unexpectedresponse' })}
        />
      )}
    </SdirModal>
  ) : null;
};

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  div {
    line-height: 0 !important;
  }
`;

export default ReportingTime;
