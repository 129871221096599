import {
  V1ConstructionnoticeCreateconstructionnoticeCommandPostRequest,
  ProjectInformationDto,
  VesselDataDtoV3,
  UpdateConstructionNoticeInfoPayload,
  RequestConstructionNoticeBlobUploadTokenCommand
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { constructionNoticeSuccessCallback } from '@sdir/blueprint.et/lib/helpers';
import { BlockBlobClient } from '@azure/storage-blob';
import { useToast } from '@sdir/utilities/lib/hooks';
import { saveAs } from 'file-saver';
import ErrorWithRecovery from '../../types/ErrorWithRecovery';
import {
  getFormatedDateString,
  getFormatedTimeStringWithSeconds,
  makeDateString
} from '../../helpers';
import { constructionNoticeApi, specificationsApi } from '../../httpclient';
import { Routes } from '../../components/Templates/Routes';
import ApiError from '../../types/ApiError';
import { setSpecificationOptions } from '../../store/actions/action';

const useConstruction = (formik: FormikProps<any>) => {
  const { constructionnoticeid } = useParams<{ constructionnoticeid?: string }>();
  const [savedAt, setSavedAt] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadingGADrawing, setDownloadingGADrawing] = useState(false);
  const [disableFileDownload, setDisableFileDownload] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { setError } = useToast();

  const now = new Date();

  const datestring = `${getFormatedDateString(now, intl)} ${getFormatedTimeStringWithSeconds(
    now,
    intl
  )}`;

  const navigate = useNavigate();

  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );

  const { callApi: getSpecificationsOptions } = useApi({
    apiFunction: () => specificationsApi.v1SpecificationsGetvesselspecificationoptionsGet(),
    successCallback: res => dispatch(setSpecificationOptions(res))
  });

  const onFileError = error => {
    error.length > 0 &&
      setError(`${intl.formatMessage({ id: 'error.upload' }, { error: '' })}: ${error}`);
  };

  const getProgress = (loadedBytes: number, size: number) => {
    const percent = Math.round((loadedBytes / size) * 100);
    setUploadProgress(percent);
  };

  useEffect(() => {
    if (!specificationsOptions) getSpecificationsOptions();
  }, []);

  const {
    callApi: startNewConstructionNotice,
    loading: startNewConstructionNoticeLoading,
    errorObject: startNewConstructionNoticeError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;
      const payload: V1ConstructionnoticeCreateconstructionnoticeCommandPostRequest = {
        /* Even though the number types are numbers,
          they are POSTed as strings. So parse them before POSTing */
        contactName: fv.contactPersonName ?? '',
        contactPhoneNumber: fv.contactPersonTelephone
          ? fv.contactPersonTelephoneCountryCode
            ? `${fv.contactPersonTelephoneCountryCode.replace(
                ' ',
                ''
              )} ${fv.contactPersonTelephone.replace(' ', '')}`
            : fv.contactPersonTelephone.replace(' ', '')
          : '',
        contactEmail: fv.contactPersonEmail ?? '',
        constructionNumber: fv.constructionNumber ?? '',
        constructionShipyard: fv.constructionShipyardName ?? ''
      };
      return constructionNoticeApi.v1ConstructionnoticeCreateconstructionnoticeCommandPost(payload);
    },
    successCallback: constructionNoticeId => {
      navigate(
        generatePath(Routes.constructionUid, {
          currentStep: 2,
          constructionnoticeid: constructionNoticeId
        }),
        {
          replace: true
        }
      );
      setSavedAt(datestring);
    }
  });

  if (startNewConstructionNoticeError) throw new ApiError(startNewConstructionNoticeError.response);

  const {
    callApi: updateContactInformation,
    loading: updateContactInformationLoading,
    errorObject: updateContactInformationError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;
      const payload: UpdateConstructionNoticeInfoPayload = {
        contactName: fv.contactPersonName ?? '',
        contactPhoneNumber: fv.contactPersonTelephone
          ? fv.contactPersonTelephoneCountryCode
            ? `${fv.contactPersonTelephoneCountryCode.replace(
                ' ',
                ''
              )} ${fv.contactPersonTelephone.replace(' ', '')}`
            : fv.contactPersonTelephone.replace(' ', '')
          : '',
        contactEmail: fv.contactPersonEmail ?? '',
        constructionNumber: fv.constructionNumber ?? '',
        constructionShipyard: fv.constructionShipyardName ?? ''
      };
      return constructionNoticeApi.v1ConstructionnoticeUpdateconstructionnoticeinfoPayloadPatch(
        constructionnoticeid || '',
        payload
      );
    },
    successCallback: () => {
      setSavedAt(datestring);
    }
  });

  if (updateContactInformationError) throw new ApiError(updateContactInformationError.response);

  const {
    callApi: getConstructionNotice,
    loading: getConstructionNoticeLoading,
    errorObject: getConstructionNoticeError
  } = useApi({
    apiFunction: prjuid =>
      constructionNoticeApi.v3ConstructionnoticeGetconstructionnoticev3IdGet(
        prjuid || constructionnoticeid || ''
      ),
    successCallback: res => {
      if (res.isSubmitted) {
        setIsSubmitted(true);
      }

      if (
        formik.values.constructionNoticeUid &&
        constructionnoticeid &&
        formik.values.constructionNoticeUid !== constructionnoticeid
      ) {
        navigate(
          generatePath(Routes.constructionUid, {
            currentStep: 1,
            constructionnoticeid
          })
        );
        location.reload();
      }
      constructionNoticeSuccessCallback(res, formik);
    }
  });

  if (getConstructionNoticeError) throw new ApiError(getConstructionNoticeError.response);

  const {
    callApi: updateVesselData,
    loading: updateVesselDataLoading,
    errorObject: updateVesselDataError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;
      const payload: VesselDataDtoV3 = {
        /* Even though the number types are numbers,
          they are POSTed as strings. So parse them before POSTing */
        vesselType: fv.vesselType,
        length: fv.length && parseFloat(fv.length as any),
        lengthOverall: fv.lengthOverall && parseFloat(fv.lengthOverall as any),
        tradeArea: fv.tradingArea,
        grossTonnage: fv.grossTonnage && parseFloat(fv.grossTonnage as any),
        mainCertificates: fv.mainCertificates,
        otherCertificates: fv.otherCertificates,
        hullMaterials: fv.hullMaterials,
        radioCoverage: fv.radioCoverage,
        numberOfPassengers: fv.numberOfPassengers && parseFloat(fv.numberOfPassengers as any),
        maxNumberOfPeopleOnBoard:
          fv.maxNumberOfPeopleOnBoard && parseFloat(fv.maxNumberOfPeopleOnBoard as any),
        propulsionPower: fv.propulsionPower && parseFloat(fv.propulsionPower as any),
        energySources: fv.energySources,
        waterBallast: fv.waterBallast ? fv.waterBallast === 'true' : undefined,
        energyCarriers: fv.energyCarriers,
        intendedOperation: fv.intendedOperation,
        contractedSpeed: fv.contractedSpeed && parseFloat(fv.contractedSpeed as any)
      };
      return constructionNoticeApi.v3ConstructionnoticeUpdateconstructionnoticevesseldatav3VesselDataPut(
        constructionnoticeid || '',
        payload
      );
    },
    successCallback: () => setSavedAt(datestring)
  });

  if (updateVesselDataError)
    throw new ErrorWithRecovery(updateVesselDataError.response, {
      text: intl.formatMessage({ id: 'common.goback' }),
      action: () => location.reload(),
      title: `${intl.formatMessage({ id: 'error.save' }, { error: 'fartøy' })}`
    });

  const {
    callApi: updateProjectInfo,
    loading: updateProjectInfoLoading,
    errorObject: updateProjectInfoError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;

      const constructionShipyardData = {
        name: fv.constructionShipyardName ?? undefined,
        country:
          fv.constructionShipyardRegisteredInNorway &&
          fv.constructionShipyardRegisteredInNorway === 'false' &&
          fv.constructionShipyardCountry
            ? fv.constructionShipyardCountry
            : undefined,
        organizationNumber:
          fv.constructionShipyardRegisteredInNorway &&
          fv.constructionShipyardRegisteredInNorway === 'true' &&
          fv.constructionShipyardOrgNr
            ? fv.constructionShipyardOrgNr?.trim()
            : undefined,
        streetName: fv.constructionShipyardAddress ?? undefined,
        postalCode: fv.constructionShipyardPostalCode ?? undefined,
        city: fv.constructionShipyardCity ?? undefined
      };

      const payload: ProjectInformationDto = {
        imo: fv.imo ?? undefined,
        homeport: fv.homeport ?? undefined,
        constructionNumber: fv.constructionNumber ?? undefined,
        classificationSociety: fv.classificationSociety ?? undefined,
        classNotations: fv.classNotations ?? undefined,
        sisterShipConstructionNumber: fv.sisterShipConstructionNumber ?? undefined,
        constructionShipyard: constructionShipyardData,
        hullShipyard: fv.samehullyardasconstructionyard
          ? constructionShipyardData
          : {
              name: fv.hullShipyardName ?? undefined,
              country:
                fv.hullShipyardRegisteredInNorway &&
                fv.hullShipyardRegisteredInNorway === 'false' &&
                fv.hullShipyardCountry
                  ? fv.hullShipyardCountry
                  : undefined,
              organizationNumber:
                fv.hullShipyardRegisteredInNorway &&
                fv.hullShipyardRegisteredInNorway === 'true' &&
                fv.hullShipyardOrgNr
                  ? fv.hullShipyardOrgNr?.trim()
                  : undefined,
              streetName: fv.hullShipyardAddress ?? undefined,
              postalCode: fv.hullShipyardPostalCode ?? undefined,
              city: fv.hullShipyardCity ?? undefined
            },
        shipDesigner: {
          name: fv.shipDesignerName ?? undefined,
          country:
            fv.shipDesignerRegisteredInNorway &&
            fv.shipDesignerRegisteredInNorway === 'false' &&
            fv.shipDesignerCountry
              ? fv.shipDesignerCountry
              : undefined,
          organizationNumber:
            fv.shipDesignerRegisteredInNorway &&
            fv.shipDesignerRegisteredInNorway === 'true' &&
            fv.shipDesignerOrgNr
              ? fv.shipDesignerOrgNr?.trim()
              : undefined,
          streetName: fv.shipDesignerAddress ?? undefined,
          postalCode: fv.shipDesignerPostalCode ?? undefined,
          city: fv.shipDesignerCity ?? undefined
        },
        futureOwner: {
          name: fv.futureOwnerName ?? undefined,
          country:
            fv.futureOwnerRegisteredInNorway &&
            fv.futureOwnerRegisteredInNorway === 'false' &&
            fv.futureOwnerCountry
              ? fv.futureOwnerCountry
              : undefined,
          organizationNumber:
            fv.futureOwnerRegisteredInNorway &&
            fv.futureOwnerRegisteredInNorway === 'true' &&
            fv.futureOwnerOrgNr
              ? fv.futureOwnerOrgNr?.trim()
              : undefined,
          streetName: fv.futureOwnerAddress ?? undefined,
          postalCode: fv.futureOwnerPostalCode ?? undefined,
          city: fv.futureOwnerCity ?? undefined
        },
        contractDate: fv.contractDate ? makeDateString(fv.contractDate) : undefined,
        plannedKeelLayingDate: fv.plannedCoolingDate
          ? makeDateString(fv.plannedCoolingDate)
          : undefined,
        hullArrivesNorway: fv.hullArrivesNorway ? makeDateString(fv.hullArrivesNorway) : undefined,
        plannedDeliveryDate: fv.plannedDeliveryDate
          ? makeDateString(fv.plannedDeliveryDate)
          : undefined,
        generalArrangementDrawing: fv.generalArrangementDrawing ?? undefined
      };
      return constructionNoticeApi.v1ConstructionnoticeUpdateconstructionnoticeprojectinformationProjectInformationDtoPut(
        constructionnoticeid || '',
        payload
      );
    },

    successCallback: () => {
      setDisableFileDownload(false);
      setSavedAt(datestring);
    }
  });

  if (updateProjectInfoError)
    throw new ErrorWithRecovery(updateProjectInfoError.response, {
      text: intl.formatMessage({ id: 'common.goback' }),
      action: () => location.reload(),
      title: `${intl.formatMessage({ id: 'error.save' }, { error: 'prosjektinformasjon' })}`
    });

  const {
    callApi: submitConstructionNotice,
    loading: submitConstructionNoticeLoading,
    errorObject: submitConstructionNoticeError
  } = useApi({
    apiFunction: prjuid => {
      return constructionNoticeApi.v1ConstructionnoticeSubmitconstructionnoticeIdPut(
        prjuid || constructionnoticeid || ''
      );
    },
    successCallback: () => {
      setIsSubmitted(true);
    }
  });

  if (submitConstructionNoticeError)
    throw new ErrorWithRecovery(submitConstructionNoticeError.response, {
      text: intl.formatMessage({ id: 'common.goback' }),
      action: () => location.reload(),
      title: `${intl.formatMessage({ id: 'error.create' }, { error: 'fartøy' })}`
    });

  const { callApi: getBlobUrl } = useApi({
    apiFunction: (payload: RequestConstructionNoticeBlobUploadTokenCommand | undefined) => {
      return constructionNoticeApi.v1ConstructionnoticeRequestuploadtokenCommandPost(payload);
    },
    errorCallback: err => onFileError(err)
  });

  const uploadGeneralArrangementDrawing = async (file: File) => {
    const payload: RequestConstructionNoticeBlobUploadTokenCommand = {
      constructionNoticeId: constructionnoticeid,
      fileName: file.name
    };
    const response = await getBlobUrl(payload);

    if (response.success) {
      const token = response.data.sasUrl || '';

      try {
        const blockBlobClient = new BlockBlobClient(token);
        await blockBlobClient
          .upload(file, file.size, {
            onProgress: p => getProgress(p.loadedBytes, file.size)
          })
          .then(
            () => {
              setDisableFileDownload(true);
              setUploadProgress(0);
              formik.setFieldValue('generalArrangementDrawing', file.name);
            },

            onrejected => {
              onFileError(onrejected);
            }
          );
      } catch (exception) {
        if (exception instanceof Error) {
          onFileError(exception.message);
        } else if (typeof exception === 'string') {
          onFileError(exception);
        }
      }
    }
  };

  const {
    callApi: downloadGeneralArrangementDrawing,
    loading: downloadGeneralArrangementDrawingLoading
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeRequestdownloadtokenCommandPost({
        constructionNoticeId: constructionnoticeid,
        getConfirmedGADrawing: false
      }),
    successCallback: res => {
      if (res.sasUrl) {
        setDownloadingGADrawing(true);
        saveAs(res.sasUrl, formik.values.generalArrangementDrawing);
        setTimeout(() => {
          setDownloadingGADrawing(false);
        }, 5000);
      }
    }
  });

  const downloadingGA = downloadingGADrawing || downloadGeneralArrangementDrawingLoading;

  return {
    startNewConstructionNotice,
    startNewConstructionNoticeLoading,
    updateContactInformation,
    updateContactInformationLoading,
    getConstructionNotice,
    getConstructionNoticeLoading,
    savedAt,
    updateVesselData,
    updateVesselDataLoading,
    updateProjectInfo,
    updateProjectInfoLoading,
    submitConstructionNotice,
    submitConstructionNoticeLoading,
    specificationsOptions,
    isSubmitted,
    uploadGeneralArrangementDrawing,
    uploadProgress,
    downloadGeneralArrangementDrawing,
    downloadingGA,
    disableFileDownload
  };
};

export default useConstruction;
