/* eslint-disable @typescript-eslint/indent */
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SetActivityNotRelevantCommand } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { SuggestedScopeActivity } from '@sdir/httpclient/lib/clients/et/survey';
import { SurveyScopeActions } from '@sdir/blueprint.et/lib/components';
import { tagList } from '@sdir/blueprint.et/lib/helpers';
import { AlertSingleLine } from '@sdir/sds';
import { ScopedActivity } from '../SurveyScopeSection';
import NotRelevantCard from '../../Organisms/NotRelevantCard';
import {
  AccordionWrapper,
  TaskCard,
  ToggleState,
  ActivityCardLeftColumn,
  ActivityCardRightColumn
} from './ActivityCardHelpers';

interface ScopeTaskCardProps {
  activity: ScopedActivity;
  setSurveyScope: (
    activitiesChanged: SuggestedScopeActivity[],
    action?: SurveyScopeActions
  ) => void;
  setRelevance: (
    scopedActivity: ScopedActivity[],
    isNotRelevant: boolean,
    relevanceComment?: string
  ) => void;
}

const ScopeTaskCard: React.FC<ScopeTaskCardProps> = ({
  activity,
  setSurveyScope,
  setRelevance
}) => {
  const intl = useIntl();
  const { projectuid } = useParams() as { projectuid: string; surveyuid: string };
  const [toggleState, setToggleState] = useState<ToggleState>(ToggleState.None);

  const handleToggleState = (state: ToggleState) => {
    if (state === toggleState) setToggleState(ToggleState.None);
    else setToggleState(state);
  };

  return (
    <Wrapper id={activity.uid}>
      <AccordionWrapper width="100%">
        <TaskCard width="100%">
          {ActivityCardLeftColumn(
            intl,
            activity,
            undefined,
            toggleState,
            undefined,
            handleToggleState,
            true,
            tagList(activity.certificates),
            true
          )}
          {ActivityCardRightColumn(
            intl,
            activity,
            projectuid,
            handleToggleState,
            undefined,
            setSurveyScope,
            setRelevance
          )}
        </TaskCard>

        {(toggleState === ToggleState.NotRelevant || toggleState === ToggleState.Relevant) && (
          <ToggledCard data-testid="Accordion">
            {activity.isPartOfOtherSurveyScope && (
              <AlertSingleLine
                type="info"
                title=""
                text={intl.formatMessage({
                  id: 'notrelevantcard.warning'
                })}
                hideClose
              />
            )}
            <NotRelevantCard
              toggleState={toggleState}
              submitNotRelevant={(notRelevantCommand?: SetActivityNotRelevantCommand) =>
                setRelevance([activity], true, notRelevantCommand?.reason ?? '')
              }
              submitRelevant={() => setRelevance([activity], false)}
              activityUid={activity.approvedActivityId!}
              projectUid={projectuid}
              onAction={() => {
                setToggleState(ToggleState.None);
              }}
              onCancel={() => setToggleState(ToggleState.None)}
              buttonLabel={intl.formatMessage({ id: 'notrelevantcard.button.set' })}
            />
          </ToggledCard>
        )}
      </AccordionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ToggledCard = styled.div`
  width: 107rem !important;
  box-shadow: 0 3px 3px #00000029;
  border-radius: 6px;
  background-color: white;
  position: relative;
  top: -4rem;
  width: 93%;
  z-index: 1;
  padding: 4rem;

  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
  transform: scaleY(1);
  visibility: inherit;
`;

export default React.memo(ScopeTaskCard);
