/* eslint-disable import/no-cycle */
import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Icon } from '@sdir/sds';
import { faEye, faCog } from '@fortawesome/free-solid-svg-icons';
import MainTiles from './MainTiles';
import TileExtensionPanel from './TilesExtensionPanel';
import { dummyTiles } from '../../../helpers/dummyInfoTiles';

export interface PanelSizeInterface {
  maxWidth?: string;
  maxHeight?: string;
}

interface PanelStateInterface {
  displayPanel: boolean;
  displayExtentions: boolean;
}

const InfoTilesPanel: React.FC = () => {
  const [panelState, setPanelState] = useState<PanelStateInterface>({
    displayPanel: true,
    displayExtentions: false
  });
  const intl = useIntl();
  const { displayPanel, displayExtentions } = panelState;

  const displayButtonText = displayPanel
    ? intl.formatMessage({ id: 'dashboard.infotile.control.hide' })
    : intl.formatMessage({ id: 'dashboard.infotile.control.show' });
  const editData = intl.formatMessage({ id: 'dashboard.infotile.control.edit' });

  const toggleExtensions = useCallback(
    () => setPanelState(prev => ({ ...prev, displayExtentions: !displayExtentions })),
    [displayExtentions]
  );

  const togglePanel = useCallback(() => {
    if (displayExtentions) toggleExtensions();
    setPanelState(prev => ({
      ...prev,
      displayPanel: !displayPanel
    }));
  }, [displayPanel, displayExtentions, toggleExtensions]);

  return (
    <Section>
      <Controls>
        <ControlButton onClick={togglePanel} role="button">
          <span>{displayButtonText}</span>
          <Icon name={faEye as any} />
        </ControlButton>
        {displayPanel && (
          <ControlButton onClick={toggleExtensions} role="button">
            <span>{editData}</span>
            <Icon name={faCog as any} />
          </ControlButton>
        )}
      </Controls>
      {displayPanel && !displayExtentions && <MainTiles data={dummyTiles.mainTiles} />}
      {displayExtentions && <TileExtensionPanel />}
    </Section>
  );
};

const Section = styled.section<PanelSizeInterface>`
  height: auto;
  width: 100%;
  padding: ${({ theme }) => theme.overlay.padding}; 
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  max-height: ${({ maxHeight }) => maxHeight || '47rem'};
`;

const Controls = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: right;
  padding-top: 4.25rem;
  grid-gap: 4rem;
  min-height: 12rem;
  max-height: 29.78%;
  margin-right: 10%;
`;

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  height: 3.25rem;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  > span {
    padding-right: 1rem;
    text-decoration: underline;
  }
`;

export default InfoTilesPanel;
