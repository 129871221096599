import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RequestBlobWriteTokenPayload } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useFileUpload } from '../../../services/hooks/useFileUpload';

import { ActivityDetailType } from '../../../types/enums';
import { activityApi, exemptionApi, notRelevantApi, replyToCustomerApi } from '../../../httpclient';
import { Routes } from '../../Templates/Routes/constants';
import { Signer } from '../../../store/constants/types';
import { UID_NULL } from './useReaction';

const useReply = (actionUid: string) => {
  const intl = useIntl();
  const { setError } = useToast();
  const navigate = useNavigate();
  const { projectuid, activityuid, type } = useParams() as {
    projectuid: string;
    activityuid: string;
    type: string;
  };
  const [signer, setSigner] = useState<Signer>();
  const [allUsers, setAllUsers] = useState<Signer[]>([]);

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);

  useEffect(() => {
    if (etCaseWorkers) {
      const users = etCaseWorkers.map(user => {
        return {
          username: `${user.name}`,
          signersId: user.uid || ''
        };
      });
      setAllUsers(users);
    }
  }, [etCaseWorkers]);

  const goToTask = () => {
    navigate(
      generatePath(Routes.vesselWorkspaceScrollTo, {
        tab: 'activitylist',
        projectuid,
        scrollToId: activityuid
      })
    );
  };

  const {
    callApi: callUploadApi,
    uploadedFiles,
    progress,
    loading: isUploading,
    removeFileFromState,
    setUploadedFiles
  } = useFileUpload({
    blobUrlFunction: d =>
      replyToCustomerApi.v1ReplyGetuploadtokenPayloadPost(
        actionUid,
        d as RequestBlobWriteTokenPayload
      ),
    attachFunction: d =>
      replyToCustomerApi.v1ReplyAttachtoreplyPayloadPost(
        actionUid,
        d as RequestBlobWriteTokenPayload
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'fil' })}: ${err}`);
      }
    }
  });

  const uploadFile = file => {
    callUploadApi({
      fileArgs: {
        file,
        requestId: actionUid
      },
      blobUrlArgs: {
        blobName: file.name
      },
      attachFunctionArgs: {
        blobName: file.name
      }
    });
  };

  const {
    result: activityDetailResult,
    callApi: getActivityDetails,
    loading: activityDetailsLoading
  } = useApi({
    apiFunction: () =>
      activityApi.v2ActivityGetprojectactivitydetailsv2ActivityUidGet(projectuid, activityuid),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'svar' })}: ${err}`);
      }
    }
  });

  useEffect(() => {
    // get signer if set

    const getSignerUid = () => {
      if (activityDetailResult) {
        switch (type) {
          case ActivityDetailType.exemption:
            return activityDetailResult.actionExemptionResult?.reply?.signerUid;
          case ActivityDetailType.notrelevant:
            return activityDetailResult.actionNotRelevantResult?.reply?.signerUid;
          default:
            return '';
        }
      }
      return '';
    };

    if (activityDetailResult && allUsers.length > 0) {
      const thesigner = allUsers.find(user => {
        return user.signersId === getSignerUid();
      });

      if (thesigner) {
        setSigner(thesigner);
      } else if (getSignerUid() === UID_NULL) {
        // No signer
        setSigner(undefined);
      } else {
        // Handle when no matching username
        setSigner({
          username: intl.formatMessage({ id: 'signer.unknown' }),
          signersId: getSignerUid() || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetailResult, type, allUsers]);

  const { callApi: addSigner, loading: addSignerLoading } = useApi({
    apiFunction: payload =>
      replyToCustomerApi.v1ReplyAddasignerforreplyPayloadPut(actionUid, payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: removeSigner, loading: removeSignerLoading } = useApi({
    apiFunction: () => replyToCustomerApi.v1ReplyRemovesignerforreplyRequestIdDelete(actionUid),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: changeSigner, loading: changeSignerLoading } = useApi({
    apiFunction: payload =>
      replyToCustomerApi.v1ReplyChangesignerforreplyPayloadPut(actionUid, payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: sendToSigner, loading: sendToSignerLoading } = useApi({
    apiFunction: payload =>
      replyToCustomerApi.v1ReplySubmitreplyforsigningPayloadPut(actionUid || '', payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: handleAcceptNotRelevant, loading: acceptLoading } = useApi({
    apiFunction: payload => notRelevantApi.v1NotrelevantAcceptCommandPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'svar' })}: ${err}`);
      }
    },
    successCallback: () => goToTask()
  });

  const { callApi: handleRejectNotRelevant, loading: rejectLoading } = useApi({
    apiFunction: payload => notRelevantApi.v1NotrelevantRejectCommandPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'svar' })}: ${err}`);
      }
    },
    successCallback: () => goToTask()
  });

  const { callApi: handleAcceptExemption, loading: acceptExemptionLoading } = useApi({
    apiFunction: payload => exemptionApi.v1ExemptionAcceptCommandPut(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'svar' })}: ${err}`);
      }
    },
    successCallback: () => goToTask()
  });

  const {
    callApi: handleAcceptExemptionWithCondition,
    loading: acceptExemptionWithConditionLoading
  } = useApi({
    apiFunction: payload => exemptionApi.v1ExemptionAcceptwithconditionCommandPut(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'svar' })}: ${err}`);
      }
    },
    successCallback: () => goToTask()
  });

  const { callApi: handleExemptionReject, loading: rejectExemptionLoading } = useApi({
    apiFunction: payload => exemptionApi.v1ExemptionRejectCommandPut(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'svar' })}: ${err}`);
      }
    },
    successCallback: () => goToTask()
  });

  const exemptionLoading =
    acceptExemptionLoading ||
    acceptExemptionWithConditionLoading ||
    rejectExemptionLoading ||
    sendToSignerLoading ||
    activityDetailsLoading;

  const loading = rejectLoading || acceptLoading || sendToSignerLoading || activityDetailsLoading;

  const getSignStatus = useCallback(() => {
    if (activityDetailResult) {
      switch (type) {
        case ActivityDetailType.exemption:
          return activityDetailResult.actionExemptionResult?.signStatus;
        case ActivityDetailType.notrelevant:
          return activityDetailResult.actionNotRelevantResult?.signStatus;
        default:
          return '';
      }
    }
    return '';
  }, [activityDetailResult, type]);

  const signStatus = getSignStatus();

  return {
    handleAcceptNotRelevant,
    handleRejectNotRelevant,
    loading,
    handleAcceptExemption,
    handleAcceptExemptionWithCondition,
    handleExemptionReject,
    exemptionLoading,
    activityDetailResult,
    getActivityDetails,
    signer,
    setSigner,
    sendToSigner,
    signStatus,
    uploadFile,
    uploadedFiles,
    isUploading,
    progress,
    removeFileFromState,
    addSigner,
    addSignerLoading,
    setUploadedFiles,
    allUsers,
    removeSigner,
    removeSignerLoading,
    changeSigner,
    changeSignerLoading
  };
};

export default useReply;
