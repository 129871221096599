import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { BlockBlobClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';
import {
  ProjectInformation,
  RequestConstructionNoticeBlobUploadTokenCommand,
  VesselDataDtoV3
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { constructionNoticeSuccessCallback } from '@sdir/blueprint.et/lib/helpers';

import { constructionNoticeApi, specificationsApi } from '../../httpclient';
import { setSpecificationOptions } from '../../store/actions/action';
import { Routes } from '../../components/Templates/Routes';
import { TabOptions, caseWorkerTabStorageKey } from '../../components/Pages/ET/CasworkerPage';
import { getFormatedDateString, getFormatedTimeStringWithSeconds } from '../../helpers';

const useConstructionNoticeReview = (formik: FormikProps<any>) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { setError, setSuccess } = useToast();
  const navigate = useNavigate();
  const { constructionnoticeid } = useParams();
  const [savedAt, setSavedAt] = useState('');
  const [downloadingSubmittedGADrawing, setDownloadingSubmittedGADrawing] = useState(false);
  const [downloadingConfirmedGADrawing, setDownloadingConfirmedGADrawing] = useState(false);
  const [disableFileDownload, setDisableFileDownload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const now = new Date();

  const makeDateString = (date: Date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
  };

  const datestring = `${getFormatedDateString(now, intl)} ${getFormatedTimeStringWithSeconds(
    now,
    intl
  )}`;

  const {
    result: constructionNotice,
    loading: constructionNoticeLoading,
    error: constructionNoticeError,
    callApi: getConstructionNotice
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v3ConstructionnoticeGetconstructionnoticev3IdGet(
        constructionnoticeid || ''
      ),
    successCallback: res => {
      constructionNoticeSuccessCallback(res, formik);
    }
  });

  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );

  const { callApi: getSpecificationsOptions, loading: getSpecificationsOptionsLoading } = useApi({
    apiFunction: () => specificationsApi.v1SpecificationsGetvesselspecificationoptionsGet(),
    successCallback: res => dispatch(setSpecificationOptions(res))
  });

  useEffect(() => {
    if (!specificationsOptions) getSpecificationsOptions();
  }, []);

  const { callApi: cancelConstructionNotice, loading: cancelConstructionNoticeLoading } = useApi({
    apiFunction: remark =>
      constructionNoticeApi.v1ConstructionnoticeCancelconstructionnoticeRemarkDelete(
        constructionnoticeid || '',
        { remark }
      ),
    successCallback: () => {
      setSuccess(intl.formatMessage({ id: 'constructionnoticereview.toast.cancellation.success' }));
      localStorage.setItem(caseWorkerTabStorageKey, TabOptions.CONSTRUCTIONNOTICES.toString());
      navigate(Routes.FRES);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(intl.formatMessage({ id: 'constructionnoticereview.toast.cancellation.error' }));
      }
    }
  });

  const { callApi: confirmConstructionNotice, loading: confirmConstructionNoticeLoading } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeConfirmconstructionnoticeIdPost(
        constructionnoticeid || ''
      ),
    successCallback: () => {
      setSuccess(intl.formatMessage({ id: 'constructionnoticereview.toast.approve.success' }));
      localStorage.setItem(caseWorkerTabStorageKey, TabOptions.CONSTRUCTIONNOTICES.toString());
      navigate(Routes.FRES);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(intl.formatMessage({ id: 'constructionnoticereview.toast.approve.error' }));
      }
    }
  });

  const {
    callApi: updateVesselData,
    loading: updateVesselDataLoading,
    errorObject: updateVesselDataError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;
      const payload: VesselDataDtoV3 = {
        vesselType: fv.vesselType ?? '',
        length: parseFloat(fv.length as any),
        lengthOverall: parseFloat(fv.lengthOverall as any),
        tradeArea: fv.tradingArea ?? '',
        grossTonnage: parseFloat(fv.grossTonnage as any),
        mainCertificates: fv.mainCertificates,
        otherCertificates: fv.otherCertificates,
        radioCoverage: fv.radioCoverage ?? '',
        numberOfPassengers: parseFloat(fv.numberOfPassengers as any),
        maxNumberOfPeopleOnBoard: parseFloat(fv.maxNumberOfPeopleOnBoard as any),
        propulsionPower: parseFloat(fv.propulsionPower as any),
        energySources: fv.energySources ?? '',
        waterBallast: fv.waterBallast === 'true',
        energyCarriers: fv.energyCarriers ?? '',
        intendedOperation: fv.intendedOperation ?? '',
        contractedSpeed: parseFloat(fv.contractedSpeed as any),
        hullMaterials: fv.hullMaterials
      };
      return constructionNoticeApi.v3ConstructionnoticeUpdateconstructionnoticevesseldatav3VesselDataPut(
        constructionnoticeid || '',
        payload
      );
    },
    successCallback: () => setSavedAt(datestring),
    errorCallback: error => {
      if (error.length > 0) {
        setError(error);
      }
    }
  });

  const {
    callApi: updateProjectInfo,
    loading: updateProjectInfoLoading,
    errorObject: updateProjectInfoError
  } = useApi({
    apiFunction: () => {
      const fv = formik.values;

      const constructionShipyardData = {
        name: fv.constructionShipyardName ?? undefined,
        country:
          fv.constructionShipyardRegisteredInNorway &&
          fv.constructionShipyardRegisteredInNorway === 'false' &&
          fv.constructionShipyardCountry
            ? fv.constructionShipyardCountry
            : undefined,
        organizationNumber:
          fv.constructionShipyardRegisteredInNorway &&
          fv.constructionShipyardRegisteredInNorway === 'true' &&
          fv.constructionShipyardOrgNr
            ? fv.constructionShipyardOrgNr?.trim()
            : undefined,
        streetName: fv.constructionShipyardAddress ?? undefined,
        postalCode: fv.constructionShipyardPostalCode ?? undefined,
        city: fv.constructionShipyardCity ?? undefined
      };

      const payload: ProjectInformation = {
        imo: fv.imo ?? undefined,
        homeport: fv.homeport ?? undefined,
        constructionNumber: fv.constructionNumber ?? undefined,
        classificationSociety: fv.classificationSociety ?? undefined,
        classNotations: fv.classNotations ?? undefined,
        sisterShipConstructionNumber: fv.sisterShipConstructionNumber ?? undefined,
        constructionShipyard: constructionShipyardData,
        hullShipyard: fv.samehullyardasconstructionyard
          ? constructionShipyardData
          : {
              name: fv.hullShipyardName ?? undefined,
              country:
                fv.hullShipyardRegisteredInNorway &&
                fv.hullShipyardRegisteredInNorway === 'false' &&
                fv.hullShipyardCountry
                  ? fv.hullShipyardCountry
                  : undefined,
              organizationNumber:
                fv.hullShipyardRegisteredInNorway &&
                fv.hullShipyardRegisteredInNorway === 'true' &&
                fv.hullShipyardOrgNr
                  ? fv.hullShipyardOrgNr?.trim()
                  : undefined,
              streetName: fv.hullShipyardAddress ?? undefined,
              postalCode: fv.hullShipyardPostalCode ?? undefined,
              city: fv.hullShipyardCity ?? undefined
            },
        shipDesigner: {
          name: fv.shipDesignerName ?? undefined,
          country:
            fv.shipDesignerRegisteredInNorway &&
            fv.shipDesignerRegisteredInNorway === 'false' &&
            fv.shipDesignerCountry
              ? fv.shipDesignerCountry
              : undefined,
          organizationNumber:
            fv.shipDesignerRegisteredInNorway &&
            fv.shipDesignerRegisteredInNorway === 'true' &&
            fv.shipDesignerOrgNr
              ? fv.shipDesignerOrgNr?.trim()
              : undefined,
          streetName: fv.shipDesignerAddress ?? undefined,
          postalCode: fv.shipDesignerPostalCode ?? undefined,
          city: fv.shipDesignerCity ?? undefined
        },
        futureOwner: {
          name: fv.futureOwnerName ?? undefined,
          country:
            fv.futureOwnerRegisteredInNorway &&
            fv.futureOwnerRegisteredInNorway === 'false' &&
            fv.futureOwnerCountry
              ? fv.futureOwnerCountry
              : undefined,
          organizationNumber:
            fv.futureOwnerRegisteredInNorway &&
            fv.futureOwnerRegisteredInNorway === 'true' &&
            fv.futureOwnerOrgNr
              ? fv.futureOwnerOrgNr?.trim()
              : undefined,
          streetName: fv.futureOwnerAddress ?? undefined,
          postalCode: fv.futureOwnerPostalCode ?? undefined,
          city: fv.futureOwnerCity ?? undefined
        },
        contractDate: fv.contractDate ? makeDateString(fv.contractDate) : undefined,
        plannedKeelLayingDate: fv.plannedCoolingDate
          ? makeDateString(fv.plannedCoolingDate)
          : undefined,
        hullArrivesNorway: fv.hullArrivesNorway ? makeDateString(fv.hullArrivesNorway) : undefined,
        plannedDeliveryDate: fv.plannedDeliveryDate
          ? makeDateString(fv.plannedDeliveryDate)
          : undefined,
        confirmedGeneralArrangementDrawing: fv.confirmedGeneralArrangementDrawing
      };
      return constructionNoticeApi.v1ConstructionnoticeUpdateconstructionnoticeprojectinformationProjectInformationDtoPut(
        constructionnoticeid || '',
        payload
      );
    },
    successCallback: () => {
      setDisableFileDownload(false);
      setSavedAt(datestring);
    },
    errorCallback: error => {
      if (error.length > 0) {
        setError(error);
      }
    }
  });

  const getProgress = (loadedBytes: number, size: number) => {
    const percent = Math.round((loadedBytes / size) * 100);
    setUploadProgress(percent);
  };

  const onFileError = error => {
    error.length > 0 &&
      setError(`${intl.formatMessage({ id: 'error.upload' }, { error: '' })}: ${error}`);
  };

  const { callApi: getBlobUrl } = useApi({
    apiFunction: (payload: RequestConstructionNoticeBlobUploadTokenCommand | undefined) => {
      return constructionNoticeApi.v1ConstructionnoticeRequestuploadtokenCommandPost(payload);
    },
    errorCallback: err => onFileError(err)
  });

  const uploadConfirmedGeneralArrangementDrawing = async (file: File) => {
    const payload: RequestConstructionNoticeBlobUploadTokenCommand = {
      constructionNoticeId: constructionnoticeid,
      fileName: file.name
    };
    const response = await getBlobUrl(payload);

    if (response.success) {
      const token = response.data.sasUrl || '';

      try {
        const blockBlobClient = new BlockBlobClient(token);
        await blockBlobClient
          .upload(file, file.size, {
            onProgress: p => getProgress(p.loadedBytes, file.size)
          })
          .then(
            () => {
              setDisableFileDownload(true);
              setUploadProgress(0);
              formik.setFieldValue('confirmedGeneralArrangementDrawing', file.name);
            },

            onrejected => {
              onFileError(onrejected);
            }
          );
      } catch (exception) {
        if (exception instanceof Error) {
          onFileError(exception.message);
        } else if (typeof exception === 'string') {
          onFileError(exception);
        }
      }
    }
  };

  const {
    callApi: downloadGeneralArrangementDrawing,
    loading: downloadGeneralArrangementDrawingLoading
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeRequestdownloadtokenCommandPost({
        constructionNoticeId: constructionnoticeid,
        getConfirmedGADrawing: false
      }),
    successCallback: res => {
      if (res.sasUrl) {
        setDownloadingSubmittedGADrawing(true);
        saveAs(res.sasUrl, formik.values.generalArrangementDrawing);
        setTimeout(() => {
          setDownloadingSubmittedGADrawing(false);
        }, 5000);
      }
    }
  });

  const {
    callApi: downloadConfirmedGeneralArrangementDrawing,
    loading: downloadConfirmedGeneralArrangementDrawingLoading
  } = useApi({
    apiFunction: () =>
      constructionNoticeApi.v1ConstructionnoticeRequestdownloadtokenCommandPost({
        constructionNoticeId: constructionnoticeid,
        getConfirmedGADrawing: true
      }),
    successCallback: res => {
      if (res.sasUrl) {
        setDownloadingConfirmedGADrawing(true);
        saveAs(res.sasUrl, formik.values.confirmedGeneralArrangementDrawing);
        setTimeout(() => {
          setDownloadingConfirmedGADrawing(false);
        }, 5000);
      }
    }
  });

  const downloadingGASubmitted =
    downloadingSubmittedGADrawing || downloadGeneralArrangementDrawingLoading;

  const downloadingGAConfirmed =
    downloadingConfirmedGADrawing || downloadConfirmedGeneralArrangementDrawingLoading;

  return {
    getConstructionNotice,
    constructionNotice,
    constructionNoticeLoading,
    constructionNoticeError,
    confirmConstructionNotice,
    confirmConstructionNoticeLoading,
    cancelConstructionNotice,
    cancelConstructionNoticeLoading,
    specificationsOptions,
    getSpecificationsOptionsLoading,
    savedAt,
    updateVesselData,
    updateVesselDataLoading,
    updateVesselDataError,
    updateProjectInfo,
    updateProjectInfoLoading,
    updateProjectInfoError,
    uploadConfirmedGeneralArrangementDrawing,
    uploadProgress,
    downloadGeneralArrangementDrawing,
    downloadConfirmedGeneralArrangementDrawing,
    disableFileDownload,
    downloadingGASubmitted,
    downloadingGAConfirmed
  };
};

export default useConstructionNoticeReview;
