/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';

import { getProjectCaseWorkersApi, getProjectTeamMembersApi } from '../../../httpclient';

const useAccess = uid => {
  const { setError } = useToast();
  const intl = useIntl();

  const { result: teamMembers, loading: teamMembersLoading, callApi: reloadTeamMembers } = useApi({
    apiFunction: () =>
      getProjectTeamMembersApi.v1GetprojectteammembersGetprojectteammembersProjectIdGet(uid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'teammedlemmer' })}: ${err}`);
    }
  });

  const { result: sdirTeam, loading: sdirTeamLoading, callApi: reloadSdirTeam } = useApi({
    apiFunction: () =>
      getProjectCaseWorkersApi.v1GetprojectcaseworkersGetprojectteammembersProjectIdGet(uid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'sdirteam' })}: ${err}`);
    }
  });

  return {
    teamMembers,
    teamMembersLoading,
    reloadTeamMembers,
    sdirTeam,
    sdirTeamLoading,
    reloadSdirTeam
  };
};

export default useAccess;
