import styled from 'styled-components';
import React from 'react';
import { SdirIconButton, SdiGrid, SdiList } from '@sdir/sds';

export type ViewState = 'grid' | 'list';
interface Props {
  state: ViewState;
  onChange: (state: ViewState) => void;
}

const ToggleListViewButtons: React.FC<Props> = ({ state, onChange }) => {
  return (
    <ButtonGroup>
      <span className="gridbtn">
        <SdirIconButton
          type={state === 'grid' ? 'primary' : 'default'}
          icon={SdiGrid}
          onClick={() => onChange('grid')}
        />
      </span>
      <span className="listbtn">
        <SdirIconButton
          type={state === 'list' ? 'primary' : 'default'}
          icon={SdiList}
          onClick={() => onChange('list')}
        />
      </span>
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  .gridbtn button {
    border-radius: 4px 0 0 4px;
  }
  .listbtn button {
    border-radius: 0 4px 4px 0;
  }
`;

export default ToggleListViewButtons;
