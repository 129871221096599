import { SET_SELECTED_CERTIFICATE_DATA } from '../constants/action-types';
import { surveyState } from '../constants/types';

const initialSurveyState: surveyState = {
  selectedSurveyCertificateData: {
    type: '',
    surveyType: '',
    certificateType: '',
    surveyId: '',
    interval: ''
  }
};

export default function surveyReducer(state = initialSurveyState, action) {
  switch (action.type) {
    case SET_SELECTED_CERTIFICATE_DATA:
      return {
        ...state,
        selectedSurveyCertificateData: action.payload
      };
    default:
      return state;
  }
}
