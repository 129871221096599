export const dummyTiles = {
  mainTiles: [
    {
      id: 123,
      data: 'Hello',
      tileType: 1
    },
    {
      id: 124,
      data: 'Hey',
      tileType: 0
    },
    {
      id: 132,
      data: 'Hola',
      tileType: 2
    },
    {
      id: 242,
      data: 'Aloha',
      tileType: 3
    }
  ],
  extensions: [
    {
      id: 25,
      data: 'Hei',
      tileType: 0
    },
    {
      id: 321,
      data: [
        { name: 'Jan', value: 74 },
        { name: 'Feb', value: 81 },
        { name: 'Mar', value: 45 },
        { name: 'Apr', value: 63 }
      ],
      tileType: 14
    },
    {
      id: 456,
      data: [
        { name: 'Nye', value: 35 },
        { name: 'Ventende', value: 135 },
        { name: 'Ferdigstilt', value: 104 }
      ],
      tileType: 5
    },
    {
      id: 36,
      data: 'Heisann',
      tileType: 1
    },
    {
      id: 47,
      data: [
        { name: 'Siste mnd', value: 5 },
        { name: 'Siste 12 mnd', value: 20 },
        { name: 'Siste 5 år', value: 70 }
      ],
      tileType: 6
    },
    {
      id: 58,
      data: 'Salut',
      tileType: 1
    },
    {
      id: 69,
      data: 'Vi ses',
      tileType: 1
    },
    {
      id: 121,
      data: 'Laters',
      tileType: 0
    },
    {
      id: 222,
      data: 'See ya',
      tileType: 1
    },
    {
      id: 323,
      data: 'Bye',
      tileType: 0
    },
    {
      id: 555,
      data: 'Yolo',
      tileType: 1
    }
  ]
};
