import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useState } from 'react';
import {
  ActivityWithRisk,
  ActivityStatus,
  EventType
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { ActivityMetadataResult } from '@sdir/httpclient/lib/clients/et/activity';
import { MeatballMenuItem } from '@sdir/blueprint.et/lib/components/Molecules/MeatballMenu';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  RegulationReducerType,
  useRegulationText
} from '@sdir/blueprint.et/lib/services/contexts/useRegulationText';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { getReferanceIdFromUri } from '@sdir/blueprint.et/lib/components/Molecules/ActivityCard/ActivityCard';
import {
  activityMetadataApi,
  activityApi,
  reactionApi,
  notRelevantApi,
  getRegulationLawTextApi
} from '../../../httpclient';
import { Routes } from '../../Templates/Routes';
import { setActivityStatus } from '../../../store/actions/action';

type useTaskCardProps = {
  activity: ActivityWithRisk;
  projectUid: string;
  activityUid: string;
  surveyUid?: string;
  setViewMetadata?: (boolean) => void;
  requirementEliRefs?: string | null;
  approvalCallback?: () => void;
  closeReactionCallback?: () => void;
  activityCheckpointUri?: string | null;
  requirementsListTabEnabled?: boolean;
};

const useTaskCard = ({
  activity,
  projectUid,
  activityUid,
  surveyUid,
  activityCheckpointUri,
  requirementEliRefs,
  approvalCallback,
  closeReactionCallback,
  requirementsListTabEnabled,
  setViewMetadata
}: useTaskCardProps) => {
  const dispatch = useDispatch();
  const [numberOfMessages, setNumberOfMessages] = useState(0);
  const { setError, setSuccess } = useToast();
  const intl = useIntl();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  let updateActivitiesOverview = false;

  const { state: regulationTextState, dispatch: regulationTextDispatch } = useRegulationText();

  const { callApi: getLawText } = useApi({
    apiFunction: referenceId => {
      const [year, month, day, regulationNumber] = referenceId.split('/');
      return getRegulationLawTextApi.regulationGetregulationlawtextGetlawtextregulationRegulationVersionGet(
        parseInt(year, 10),
        parseInt(month, 10),
        parseInt(day, 10),
        regulationNumber
      );
    },
    successCallback: res => {
      regulationTextDispatch({
        type: RegulationReducerType.REMOVEREGULATIONSLOADING,
        uri: getReferanceIdFromUri(res.url)
      });
      regulationTextDispatch({
        type: RegulationReducerType.ADDREGULATIONSDATA,
        lawText: res
      });
    }
  });
  const { callApi: getLawTextAct } = useApi({
    apiFunction: referenceId => {
      const [year, month, day, regulationNumber] = referenceId.split('/');
      return getRegulationLawTextApi.actGetregulationlawtextGetlawtextregulationRegulationVersionGet(
        parseInt(year, 10),
        parseInt(month, 10),
        parseInt(day, 10),
        regulationNumber
      );
    },
    successCallback: res => {
      regulationTextDispatch({
        type: RegulationReducerType.REMOVEREGULATIONSLOADING,
        uri: getReferanceIdFromUri(res.url)
      });
      regulationTextDispatch({
        type: RegulationReducerType.ADDREGULATIONSDATA,
        lawText: res
      });
    }
  });

  const activityMeatballMenuItems = () => {
    const meatballMenuItems: Array<MeatballMenuItem> = [];
    if (requirementsListTabEnabled) {
      meatballMenuItems.push({
        title: intl.formatMessage({ id: 'activitycard.header.title.gotorequirement' }),
        onClickCallback: async () => {
          if (requirementEliRefs) {
            const eliref = requirementEliRefs;
            localStorage.setItem('ELIREF_REDIRECT', eliref);
            navigate(
              generatePath(Routes.vesselWorkspace, {
                tab: 'requirementlist',
                projectuid: projectUid
              })
            );
          }
        }
      });
    }
    // Not required for survey scope page
    if (!surveyUid && setViewMetadata) {
      meatballMenuItems.push({
        title: intl.formatMessage({ id: 'activitycard.header.title.viewmetadata' }),
        onClickCallback: () => {
          setViewMetadata(true);
        }
      });
    }
    return meatballMenuItems;
  };

  const { callApi: sendForApproval, loading: approvalLoading } = useApi({
    apiFunction: () => activityApi.v1ActivityAcceptactivityActivityIdPost(projectUid, activityUid),
    successCallback: () => {
      if (approvalCallback) {
        approvalCallback();
      }
      if (activity.lastEvent) {
        activity.lastEvent.status = ActivityStatus.Accepted;
        activity.lastEvent.eventType = EventType.Accepted;
      }

      setTimeout(() => {
        getActivityDetails(true);
      }, 2000);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'godkjennelse' })}: ${err}`);
      }
    }
  });

  const { callApi: submitNotRelevant, loading: notRelevantLoading } = useApi({
    apiFunction: payload => notRelevantApi.v1NotrelevantSetnotrelevantCommandPost(payload),
    successCallback: () => {
      getActivityDetails(true);
    },
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'ikke relevant' })}: ${err}`);
    }
  });

  const { callApi: submitRelevant, loading: relevantLoading } = useApi({
    apiFunction: payload =>
      activityApi.v1ActivitySetactivityrelevantPayloadPost(projectUid, activityUid, payload),
    successCallback: () => {
      getActivityDetails(true);
    },
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'relevant' })}: ${err}`);
    }
  });

  const {
    callApi: getActivityDetails,
    result: activityDetails,
    loading: activityDetailsLoading
  } = useApi({
    apiFunction: (update: boolean = false) => {
      updateActivitiesOverview = update;
      return activityApi.v2ActivityGetprojectactivitydetailsv2ActivityUidGet(
        projectUid,
        activityUid
      );
    },
    successCallback: res => {
      let length = 0;
      if (res) {
        length = res.events?.length || 0;
        if (updateActivitiesOverview) {
          dispatch(
            setActivityStatus({
              activityUid,
              events: res.events,
              subjectAreaUri: res.subjectAreaUri
            })
          );
        }
      }
      setNumberOfMessages(length);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'oppgave' })}: ${err}`);
      }
    }
  });

  const {
    callApi: getActivityMetadata,
    result: activityMetadata,
    loading: activityMetadataLoading
  } = useApi({
    apiFunction: () => {
      if (activityCheckpointUri) {
        return activityMetadataApi.v1ActivityGetactivitymetadatabyidCheckpointUriGet(
          encodeURIComponent(activityCheckpointUri)
        );
      }
      return Promise.resolve({} as ActivityMetadataResult) as Promise<
        AxiosResponse<ActivityMetadataResult>
      >;
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'oppgave' })}: ${err}`);
      }
    }
  });

  const { callApi: closeReaction, loading: closingReaction } = useApi({
    apiFunction: reactionUid =>
      reactionApi.v1ReactionClosereactionCloseReactionDtoPost(
        projectUid,
        activityUid,
        reactionUid,
        { closedByUid: currentUser?.profile.sub }
      ),
    successCallback: () => {
      if (closeReactionCallback) {
        closeReactionCallback();
      }

      setTimeout(() => {
        setSuccess(
          intl.formatMessage({
            id: 'vessel.activity.action.closereaction.success'
          })
        );
        getActivityDetails(true);
      }, 1000);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'oppgave' })}: ${err}`);
      }
    }
  });

  return {
    sendForApproval,
    approvalLoading,
    getActivityDetails,
    activityDetails,
    activityDetailsLoading,
    numberOfMessages,
    activityMetadata,
    activityMetadataLoading,
    getActivityMetadata,
    activityMeatballMenuItems,
    closeReaction,
    closingReaction,
    submitNotRelevant,
    submitRelevant,
    notRelevantLoading,
    relevantLoading,
    getLawText,
    getLawTextAct,
    regulationTextState,
    regulationTextDispatch
  };
};

export default useTaskCard;
