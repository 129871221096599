import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { VesselSimulatorV2 } from '@sdir/blueprint';
import { Loader } from '@sdir/sds';
import '@sdir/sds/lib/components/index.css';
import styled from 'styled-components';
import { VesselRequirementsSearchQuery } from '@sdir/httpclient/lib/clients/core/requirement';
import { SectionList, useDynamicHeight } from '@sdir/blueprint.et/lib/components';
import RegulationsListMenu from '../../Molecules/Requirements/RegulationsListMenu';
import useSimulator from '../../../services/hooks/useSimulator';
import NoRequirementsFallback from '../../Atoms/NoRequirementsFallback';

const SimpleSimulatorPage: React.FC = () => {
  const intl = useIntl();

  const { ref, height } = useDynamicHeight();

  const {
    requirementsLoading,
    requirementList,
    sectionsWithLawTexts,
    getRequirementList,
    cancelGetRequirementList,
    handleChapterClick,
    specificationOptions,
    specificationOptionsLoading,
    sectionsLoading
  } = useSimulator();

  useEffect(() => {
    // Load first chapter on load
    if (
      requirementList &&
      requirementList.regulations &&
      requirementList.regulations[0] &&
      requirementList.regulations[0].chapters
    ) {
      handleChapterClick(requirementList?.regulations[0].chapters[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementList]);

  const handleSearch = values => {
    const payload: VesselRequirementsSearchQuery = {
      ...values,
      lengthOverall: values.lengthOverall ? parseFloat(values.lengthOverall) : undefined,
      lengthL: values.lengthL ? parseFloat(values.lengthL) : undefined,
      grossTonnage: values.grossTonnage ? parseFloat(values.grossTonnage) : undefined,
      cargo: values.cargo ? parseFloat(values.cargo) : undefined,
      flashPoint: values.flashPoint ? parseFloat(values.flashPoint) : undefined,
      tradingArea: values.tradingArea ?? undefined,
      radioCoverage: values.radioCoverage ?? undefined,
      electricalInstallation: values.electricalInstallation
        ? parseFloat(values.electricalInstallation)
        : undefined,
      numberOfPassengers: values.numberOfPassengers
        ? parseFloat(values.numberOfPassengers)
        : undefined,
      propulsionPower: values.propulsionPower ? parseFloat(values.propulsionPower) : undefined
    };
    getRequirementList(payload);
  };

  return (
    <Container>
      <StyledVesselSimulator>
        {specificationOptions && (
          <VesselSimulatorV2
            intl={intl}
            onVesselValuesChanged={handleSearch}
            cancelSimulation={cancelGetRequirementList}
            formContent={specificationOptions}
            activeSimulation={requirementsLoading}
          />
        )}
      </StyledVesselSimulator>
      {requirementsLoading || specificationOptionsLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {requirementList && requirementList?.regulations?.length !== 0 ? (
            <RequirementListWrapper>
              <div ref={ref}>
                <RegulationsListMenu
                  data={requirementList}
                  handleChapterClick={handleChapterClick}
                />
              </div>
              <div>
                {sectionsLoading ? (
                  <Loader />
                ) : (
                  <SectionList list={sectionsWithLawTexts} chapterTitle="" height={height} />
                )}
              </div>
            </RequirementListWrapper>
          ) : (
            <NoRequirementsFallback />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  height: 100%;
  max-width: 188rem;
  min-width: 85rem;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding: 4rem 2rem 2rem 2rem;
  font-size: 2rem;
`;

const StyledVesselSimulator = styled.div``;

const RequirementListWrapper = styled.div`
  display: flex;
  gap: 4rem;
  justify-content: flex-start;
  width: 100%;
`;

const LoaderContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  div {
    line-height: 0 !important;
  }
`;

export default SimpleSimulatorPage;
