import React from 'react';
import styled from 'styled-components';
import { TextLink } from '@sdir/sds';
import { useIntl } from 'react-intl';

interface RiskValueProps {
  onExpand?: () => void;
  numberOfRiskModels?: number;
  title?: string;
  children?: number;
  threshold?: number;
}

const RiskValue: React.FC<RiskValueProps> = ({
  onExpand,
  numberOfRiskModels = 0,
  title,
  children,
  threshold
}) => {
  const intl = useIntl();
  const thresholdtext = threshold
    ? `/${intl.formatMessage({ id: 'riskassessment.thresholdvalue' })} ${threshold}`
    : `\xa0$${intl.formatMessage({ id: 'riskassessment.noriskmodel' })}`;
  return (
    <RiskValueContainer>
      <RiskNumber threshold={threshold}>
        {children === null || undefined ? 'NA' : children}
      </RiskNumber>
      <span className="title">&nbsp;{title}</span>
      <span className="threshold">{thresholdtext}</span>
      <span className="link">
        {numberOfRiskModels > 0 && (
          <TextLink
            text={`+${numberOfRiskModels} ${intl.formatMessage({
              id: 'riskassessment.riskmodels'
            })}`}
            click={onExpand}
          />
        )}
      </span>
    </RiskValueContainer>
  );
};

export default RiskValue;

const RiskValueContainer = styled.div`
  display: grid;
  justify-self: end;
  margin-right: 4rem;
  grid-template-areas:
    'value title'
    'value threshold'
    'link link';

  .title {
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fonts.stfMedium};
    grid-area: title;
    align-self: end;
  }

  .threshold {
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fonts.stfBook};
    grid-area: threshold;
  }

  .link {
    grid-area: link;
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fonts.stfBook};
    justify-self: center;
  }
`;

const RiskNumber = styled.span<{ children; threshold? }>`
  color: ${({ theme, children, threshold }) =>
    children >= threshold ? theme.colors.alert.errorDark : theme.colors.primary};

  grid-area: value;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 4rem;
`;
