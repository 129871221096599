import { ActivityWithRisk } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { tagList } from '@sdir/blueprint.et/lib/helpers';
import { SdiFile } from '@sdir/sds';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import useTaskCard from './useTaskCard';
import {
  AccordionWrapper,
  ActivityCardLeftColumn,
  ActivityCardRightColumn,
  CloseReactionModal,
  TaskCard,
  taskCardAccordion,
  taskMetaCard,
  ToggleState
} from './ActivityCardHelpers';

interface DocumentTaskCardProps {
  activity: ActivityWithRisk;
  isLast: boolean;
  requirementsListTabEnabled: boolean | undefined;
  approvalCalBack?: (uri) => void;
}

const DocumentTaskCard: React.FC<DocumentTaskCardProps> = ({
  activity,
  isLast,
  approvalCalBack,
  requirementsListTabEnabled
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { projectuid, surveyuid } = useParams() as { projectuid: string; surveyuid: string };
  // for survey scope page, we are reusing component but log is not required
  const [toggleState, setToggleState] = useState<ToggleState | null>(
    surveyuid ? null : ToggleState.None
  );
  const [viewMetadata, setViewMetadata] = useState<boolean>(false);
  const [viewCloseReactionModal, setViewCloseReactionModal] = useState(false);

  const handleToggleState = (state: ToggleState) => {
    if (state === toggleState) setToggleState(ToggleState.None);
    else setToggleState(state);
  };

  const approvalCallback = () => {
    setToggleState(ToggleState.None);
    if (approvalCalBack) approvalCalBack(activity.subjectAreaUri);
  };
  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid } as {
    projectUid: string;
  });

  const closeReactionCallback = () => {
    setViewCloseReactionModal(false);
  };

  const {
    sendForApproval,
    approvalLoading,
    getActivityDetails,
    activityDetailsLoading,
    activityDetails,
    numberOfMessages,
    activityMetadata,
    activityMetadataLoading,
    getActivityMetadata,
    activityMeatballMenuItems,
    closeReaction,
    closingReaction,
    submitNotRelevant,
    submitRelevant,
    notRelevantLoading,
    relevantLoading,
    getLawText,
    getLawTextAct,
    regulationTextState,
    regulationTextDispatch
  } = useTaskCard({
    projectUid: projectuid,
    activityUid: activity.uid!,
    activityCheckpointUri: activity.checkpointUri,
    requirementEliRefs: activity.requirementEliRefs?.[0],
    requirementsListTabEnabled,
    approvalCallback,
    setViewMetadata,
    closeReactionCallback,
    activity
  });

  const reactionDraft = activityDetails?.reactionDraft || activity.reactionDraft;

  const events = activityDetails?.events;

  return (
    <>
      <Wrapper id={activity.uid}>
        <AccordionWrapper width="100%">
          <TaskCard width="100%">
            {ActivityCardLeftColumn(
              intl,
              activity,
              events,
              toggleState,
              getActivityDetails,
              handleToggleState,
              isLast,
              tagList(activity.certificates),
              false
            )}
            {ActivityCardRightColumn(
              intl,
              activity,
              projectuid,
              handleToggleState,
              events,
              undefined,
              undefined,
              activityMeatballMenuItems,
              downloadByFilename,
              reactionDraft,
              <SdiFile />,
              approvalLoading,
              activityDetailsLoading,
              closingReaction,
              setViewCloseReactionModal,
              sendForApproval,
              navigate,
              getLawText,
              getLawTextAct,
              regulationTextState,
              regulationTextDispatch
            )}
          </TaskCard>

          {toggleState &&
            taskCardAccordion(
              activityDetails,
              activityDetailsLoading,
              toggleState,
              activity.uid,
              projectuid,
              submitNotRelevant,
              submitRelevant,
              setToggleState,
              notRelevantLoading,
              relevantLoading,
              downloadByFilename,
              numberOfMessages,
              getActivityDetails
            )}
          {activityMetadataLoading}
          {viewMetadata &&
            taskMetaCard(
              activity.uid,
              activityMetadataLoading,
              activityMetadata,
              getActivityMetadata,
              setViewMetadata
            )}
        </AccordionWrapper>
      </Wrapper>
      {viewCloseReactionModal && (
        <CloseReactionModal
          visible={viewCloseReactionModal}
          loading={closingReaction}
          activity={activity}
          events={events}
          onCancel={() => setViewCloseReactionModal(false)}
          onOk={reactionUid => closeReaction(reactionUid)}
        />
      )}
    </>
  );
};

export default React.memo(DocumentTaskCard);

const Wrapper = styled.div`
  margin-left: 5.5rem;
  width: calc(100% - 5.5rem);
  display: flex;
  justify-content: flex-end;
`;
