/* eslint-disable @typescript-eslint/indent */
import { BlockBlobClient } from '@azure/storage-blob';

import {
  AttachReplyAttachmentResult,
  RequestBlobWriteTokenCommandResult,
  RequestBlobWriteTokenPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { AxiosResponse } from 'axios';
import { useState } from 'react';

interface useFilesProps {
  blobUrlFunction: (
    args: RequestBlobWriteTokenPayload
  ) => Promise<AxiosResponse<RequestBlobWriteTokenCommandResult>>;
  attachFunction?: (
    args: RequestBlobWriteTokenPayload
  ) => Promise<AxiosResponse<AttachReplyAttachmentResult>>;
  errorCallback?: (error: string) => void;
  successCallback?: (filename) => void;
  loadingCallback?: (loading: boolean) => void;
}

export interface fileArgs {
  file: File;
  requestId: string;
}

export type blobUrlArgs = RequestBlobWriteTokenPayload;
export type attachFunctionArgs = RequestBlobWriteTokenPayload;

export function useFileUpload(
  props: useFilesProps
): {
  callApi: (args: {
    fileArgs: fileArgs;
    blobUrlArgs: blobUrlArgs;
    attachFunctionArgs: attachFunctionArgs | undefined;
  }) => void;
  error: string;
  loading: boolean;
  progress: number;
  uploadedFiles: any[];
  setUploadedFiles: (arg0: any) => void;
  removeFileFromState: (attachmentId: string) => void;
} {
  const {
    blobUrlFunction,
    attachFunction,
    errorCallback,
    successCallback,
    loadingCallback
  } = props;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([] as any);

  const onLoading = (value: boolean) => {
    setLoading(value);
    if (loadingCallback) loadingCallback(value);
  };

  const onSuccess = (fileName: string, attachmentId: string, blobUrl?: string) => {
    let newUploadedFiles = [
      ...uploadedFiles,
      { fileName, uid: attachmentId, ...(blobUrl && { blobUrl }) }
    ];
    // Filter out duplicates
    newUploadedFiles = newUploadedFiles.filter(
      (v, i, a) => a.findIndex(t => t.fileName === v.fileName) === i
    );

    setUploadedFiles(newUploadedFiles);
    if (successCallback) successCallback(fileName);
  };

  const removeFileFromState = attachmentId => {
    const newUploadedFiles = uploadedFiles.filter(file => file.uid !== attachmentId);
    setUploadedFiles(newUploadedFiles);
  };

  const onError = (value: string) => {
    setError(value);
    if (errorCallback) errorCallback(value);
  };

  const getProgress = (loadedBytes: number, size: number) => {
    const percent = Math.round((loadedBytes / size) * 100);
    setProgress(percent);
  };

  const getSasToken = async (blobUrlArgs: blobUrlArgs): Promise<string> => {
    onLoading(true);
    onError('');
    try {
      const response = await blobUrlFunction(blobUrlArgs);
      return response.data.blobUrl || '';
    } catch (exception) {
      if (exception.response?.data?.Message) {
        onError(exception.response.data.Message);
      } else if (exception instanceof Error) {
        onError(exception.message);
      } else if (typeof exception === 'string') {
        onError(exception);
      }
      return '';
    }
  };

  const attach = async (attachFunctionArgs: attachFunctionArgs, blobUrl) => {
    if (attachFunction) {
      try {
        const response = await attachFunction(attachFunctionArgs);
        const fileName = attachFunctionArgs.blobName;
        onSuccess(fileName, response.data.attachmentId || '', blobUrl);
      } catch (exception) {
        if (exception.response?.data?.Message) {
          onError(exception.response.data.Message);
        } else if (exception instanceof Error) {
          onError(exception.message);
        } else if (typeof exception === 'string') {
          onError(exception);
        }
      } finally {
        setLoading(false);
        setProgress(0);
      }
    }
  };

  const uploadToBlobStorage = async (
    fileArgs: fileArgs,
    blobUrlArgs: blobUrlArgs,
    attachFunctionArgs: attachFunctionArgs | undefined
  ) => {
    const token = await getSasToken(blobUrlArgs);
    try {
      const blockBlobClient = new BlockBlobClient(token);
      return blockBlobClient
        .upload(fileArgs.file, fileArgs.file.size, {
          onProgress: p => getProgress(p.loadedBytes, fileArgs.file.size)
        })
        .then(
          () => {
            if (attachFunctionArgs) {
              attach(attachFunctionArgs, token);
            } else {
              // No attach function needed for messages
              // Supply empty string for UID here as we dont get it from API
              onSuccess(fileArgs.file.name, '', token);
              setLoading(false);
              setProgress(0);
            }
          },
          onrejected => {
            onError(onrejected);
          }
        );
    } catch (exception) {
      if (exception instanceof Error) {
        onError(exception.message);
      } else if (typeof exception === 'string') {
        onError(exception);
      }
    }
    return null;
  };

  const callApi = ({ fileArgs, blobUrlArgs, attachFunctionArgs }) => {
    uploadToBlobStorage(fileArgs, blobUrlArgs, attachFunctionArgs);
  };

  return {
    callApi,
    error,
    loading,
    progress,
    uploadedFiles,
    setUploadedFiles,
    removeFileFromState
  };
}
