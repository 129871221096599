import { useEffect, useState } from 'react';
import { FileLink, SdiTrash, SdirButton, SdirModal, TextLink, Textarea } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { VesselCertificatesCardFields } from '@sdir/blueprint.et/lib/components/Pages/ConstructionNotice/Forms/VesselCertificatesCard';
import { VesselDataCardFields } from '@sdir/blueprint.et/lib/components/Pages/ConstructionNotice/Forms/VesselDataCard';
import { VesselCardFields } from '@sdir/blueprint.et/lib/components/Organisms/ConstructionNotice/VesselCard';
import { VesselDrawingFields } from '@sdir/blueprint.et/lib/components/Organisms/ConstructionNotice/VesselDrawing';
import { ContactInfoCardFields } from '@sdir/blueprint.et/lib/components/Organisms/ConstructionNotice/ContactInfoCard';
import { DatesAndMilestonesCardFields } from '@sdir/blueprint.et/lib/components/Organisms/ConstructionNotice/DatesAndMilestonesCard';
import { useFormik } from 'formik';
import {
  FartoyCertificatesSchema,
  FartoyDataSchema,
  DatesAndMilestonesSchema,
  ConstructionStep3VesselSchema,
  ContactInformatinSchema,
  VesselContactInformationSchema
} from '@sdir/blueprint.et/lib/helpers/schemas/VesselSchema';
import {
  IContactPersonInformation,
  IVesselData,
  IVesselIdentification,
  IVessel,
  IContactInformation,
  IDatesAndMilestones,
  constructionNoticeInitialValues
} from '@sdir/blueprint.et/lib/components/Pages/ConstructionNotice/interfaces';
import { useParams } from 'react-router-dom';

import { ToggleContentCard } from '@sdir/blueprint.et/lib/components';
import Page from '../../../Templates/Page';
import useConstructionNoticeReview from '../../../../services/hooks/useConstructionNoticeReview';

const ConstructionNoticeReviewPage = () => {
  const intl = useIntl();
  const [initializing, setInitializing] = useState(true);
  const [readyForUpdating, setReadyForUpdating] = useState(false);
  const [showCancelationModal, setShowCancelationModal] = useState(false);
  const [cancelationRemark, setCancelationRemark] = useState('');
  const { constructionnoticeid } = useParams();

  const formik = useFormik<
    IVesselData &
      IVesselIdentification &
      IVessel &
      IContactInformation &
      IDatesAndMilestones &
      IContactPersonInformation
  >({
    initialValues: constructionNoticeInitialValues,
    onSubmit: val => console.log(val),
    validationSchema: () =>
      FartoyDataSchema(intl)
        .concat(FartoyCertificatesSchema(intl))
        .concat(VesselContactInformationSchema(intl))
        .concat(DatesAndMilestonesSchema(intl))
        .concat(ConstructionStep3VesselSchema(intl))
        .concat(ContactInformatinSchema(intl, formik.values))
  });

  const {
    specificationsOptions,
    getSpecificationsOptionsLoading,
    constructionNotice,
    constructionNoticeLoading,
    constructionNoticeError,
    confirmConstructionNotice,
    confirmConstructionNoticeLoading,
    cancelConstructionNotice,
    cancelConstructionNoticeLoading,
    getConstructionNotice,
    savedAt,
    updateVesselData,
    updateVesselDataLoading,
    updateProjectInfo,
    updateProjectInfoLoading,
    uploadConfirmedGeneralArrangementDrawing,
    uploadProgress,
    downloadGeneralArrangementDrawing,
    downloadConfirmedGeneralArrangementDrawing,
    disableFileDownload,
    downloadingGAConfirmed,
    downloadingGASubmitted
  } = useConstructionNoticeReview(formik);

  useEffect(() => {
    if (constructionnoticeid) {
      setTimeout(() => {
        setInitializing(false);
        getConstructionNotice();
      }, 500);
    }
  }, [constructionnoticeid]);

  const formIsValid = () => {
    return FartoyDataSchema(intl)
      .concat(FartoyCertificatesSchema(intl))
      .concat(VesselContactInformationSchema(intl))
      .concat(DatesAndMilestonesSchema(intl))
      .concat(ConstructionStep3VesselSchema(intl))
      .concat(ContactInformatinSchema(intl, formik.values))
      .isValidSync(formik.values);
  };

  useEffect(() => {
    const validForm = formIsValid();
    if (validForm && formik.values.isSubmitted) {
      if (readyForUpdating) {
        updateVesselData();
        updateProjectInfo();
      } else {
        setReadyForUpdating(true);
      }
    }
  }, [formik.values]);

  const handleCancelationButtonClick = () => {
    setShowCancelationModal(true);
    setTimeout(() => document.getElementById('cancelationRemark')?.focus(), 100);
  };

  return (
    <Page
      error={constructionNoticeError}
      loading={constructionNoticeLoading || initializing || getSpecificationsOptionsLoading}
    >
      <WithWrapper>
        <Container>
          <Title>{`${intl.formatMessage({ id: 'constructionnoticereview.header' })} ${
            constructionNotice?.projectInformation?.constructionNumber
          } ${constructionNotice?.projectInformation?.constructionShipyard?.name}`}</Title>
          <ContactPerson>
            <ContactPersonLine>
              {intl.formatMessage({ id: 'constructionnoticereview.contactperson' })}
            </ContactPersonLine>
            <ContactPersonLine bold>{constructionNotice?.contactName}</ContactPersonLine>
            <ContactPersonLine>{`${intl.formatMessage({
              id: 'constructionnoticereview.phone'
            })}: ${constructionNotice?.contactPhoneNumber}`}</ContactPersonLine>
            <ContactPersonLine>{`${intl.formatMessage({
              id: 'constructionnoticereview.email'
            })}: ${constructionNotice?.contactEmail}`}</ContactPersonLine>
          </ContactPerson>
          <ReviewContent>
            <ToggleContentCard
              title={intl.formatMessage({ id: 'constructionnoticereview.cardheader.vesseldata' })}
            >
              <VesselCertificatesCardFields
                formik={formik}
                specificationOptions={specificationsOptions}
              />
            </ToggleContentCard>
            <ToggleContentCard
              title={intl.formatMessage({
                id: 'constructionnoticereview.cardheader.vesseldetails'
              })}
            >
              <VesselDataCardWrapper>
                <VesselDataCardFields
                  formik={formik}
                  specificationOptions={specificationsOptions}
                />
              </VesselDataCardWrapper>
            </ToggleContentCard>
            <ToggleContentCard
              title={intl.formatMessage({ id: 'constructionnoticereview.cardheader.vessel' })}
            >
              <WidthWrapper>
                <VesselCardFields formik={formik} />
              </WidthWrapper>
            </ToggleContentCard>
            <ToggleContentCard
              title={intl.formatMessage({
                id: 'constructionnoticereview.cardheader.generalarrangement'
              })}
            >
              <WidthWrapper>
                <DrawingWrapper>
                  {formik.values.generalArrangementDrawing && (
                    <FileLink
                      text={formik.values.generalArrangementDrawing}
                      width="50rem"
                      loading={downloadingGASubmitted}
                      onClick={() => downloadGeneralArrangementDrawing()}
                    />
                  )}
                  <VesselDrawingFields
                    formik={formik}
                    uploadProgress={uploadProgress}
                    onFileUpload={uploadConfirmedGeneralArrangementDrawing}
                    formikKey="confirmedGeneralArrangementDrawing"
                    disableFileDownload={disableFileDownload}
                    downloading={downloadingGAConfirmed}
                    onFileClick={() => downloadConfirmedGeneralArrangementDrawing()}
                  />
                </DrawingWrapper>
              </WidthWrapper>
            </ToggleContentCard>
            <ToggleContentCard
              title={intl.formatMessage({ id: 'constructionnoticereview.cardheader.contactinfo' })}
            >
              <ContactInfoWrapper>
                <ContactInfoCardFields formik={formik} />
              </ContactInfoWrapper>
            </ToggleContentCard>
            <ToggleContentCard
              title={intl.formatMessage({
                id: 'constructionnoticereview.cardheader.importantdates'
              })}
            >
              <ImportantDatesWrapper>
                <DatesAndMilestonesCardFields formik={formik} />
              </ImportantDatesWrapper>
            </ToggleContentCard>
          </ReviewContent>
          <ButtonWrapper>
            <TextLink
              text={intl.formatMessage({ id: 'constructionnoticereview.cancelbutton.label' })}
              icon={<SdiTrash size="s" height="1em" />}
              onClick={handleCancelationButtonClick}
              disabled={
                confirmConstructionNoticeLoading ||
                cancelConstructionNoticeLoading ||
                updateVesselDataLoading ||
                updateProjectInfoLoading
              }
            />
            <ConfirmButtonWrapper>
              <AutoSaveInfo>
                {savedAt &&
                  `${intl.formatMessage({
                    id: 'constructionnoticereview.automaticsave'
                  })} ${savedAt}`}
              </AutoSaveInfo>
              <SdirButton
                disabled={
                  Boolean(constructionNotice?.projectUid) ||
                  confirmConstructionNoticeLoading ||
                  cancelConstructionNoticeLoading ||
                  updateVesselDataLoading ||
                  updateProjectInfoLoading ||
                  !formIsValid() ||
                  !constructionNotice?.isSubmitted
                }
                text={intl.formatMessage({ id: 'constructionnoticereview.approvebutton.label' })}
                type="primary"
                onClick={confirmConstructionNotice}
              />
            </ConfirmButtonWrapper>
          </ButtonWrapper>
        </Container>
      </WithWrapper>
      <SdirModal
        visible={showCancelationModal}
        onCancel={() => {
          setShowCancelationModal(false);
        }}
        isFooter={false}
        width="75rem"
      >
        <Wrapper>
          <ModalTitle>
            {intl.formatMessage({ id: 'constructionnoticereview.cancelationmodal.header' })}
          </ModalTitle>
          <Textarea
            id="cancelationRemark"
            contentValue={cancelationRemark}
            placeholder={intl.formatMessage({
              id: 'constructionnoticereview.cancelationmodal.placeholder'
            })}
            onChange={e => setCancelationRemark(e.target.value)}
            rows={7}
          />
          <ButtonsSection>
            <SdirButton
              onClick={() => {
                setShowCancelationModal(false);
              }}
              type="default"
              text={intl.formatMessage({ id: 'common.cancel' })}
              htmlType="button"
            />
            <SdirButton
              disabled={cancelationRemark === ''}
              onClick={() => cancelConstructionNotice(cancelationRemark)}
              type="primary"
              text={intl.formatMessage({
                id: 'constructionnoticereview.cancelationmodal.confirmbutton'
              })}
              htmlType="button"
            />
          </ButtonsSection>
        </Wrapper>
      </SdirModal>
    </Page>
  );
};

const Container = styled.div`
  margin: 5rem 0;
  max-width: 188rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

const VesselDataCardWrapper = styled.div`
  display: grid;
  width: 60%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem 8rem;
`;

const WidthWrapper = styled.div`
  width: 40rem;
`;

const ContactInfoWrapper = styled.div`
  width: 119rem;
  display: grid;
  gap: 5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
`;

const ImportantDatesWrapper = styled.div`
  width: 119rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem 8rem;
`;

const WithWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  margin: 0;
`;

const ContactPerson = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const AutoSaveInfo = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBookItalic};
`;

const ContactPersonLine = styled.span<{ bold?: boolean }>`
  font-family: ${({ bold, theme }) => (bold ? theme.fonts.stfMedium : theme.fonts.stfBook)};
`;

const DrawingWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default ConstructionNoticeReviewPage;
