/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* ^ these rules are here to remove error for label with onClick - it is adjacent to button */
import React, { useState } from 'react';
import styled from 'styled-components';
import { SdirToggle } from '@sdir/sds';
import {
  ActivityVerificationMethod,
  GetActivityRiskResult,
  UpdateVerificationMethodPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';

import { ToggleButton } from '@sdir/blueprint.et/lib/components';
import { activityApi } from '../../../httpclient';
import RiskValue from './RiskValue';
import RiskFeedbackModal from '../../Molecules/RiskFeedbackModal';

interface RiskItemProps {
  projectUid: string;
  verificationMethod: string | null | undefined;
  id: string | null | undefined;
  title: string | null | undefined;
  requirementText: string | null | undefined;
  risks: GetActivityRiskResult[] | null | undefined;
  onFeedBackSubmit: (values) => void;
}
const RiskItem: React.FC<RiskItemProps> = ({
  id,
  verificationMethod,
  projectUid,
  title,
  requirementText,
  risks,
  onFeedBackSubmit
}) => {
  const { setError } = useToast();
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  const [checked, setChecked] = useState(
    verificationMethod === ActivityVerificationMethod.NmaApprovalRequired
  );

  const handleCheck = e => {
    setChecked(!checked);
    const payload: UpdateVerificationMethodPayload = {
      includeInCheck: !e
    };
    postIncludeInCheck(payload);
  };

  const { callApi: postIncludeInCheck } = useApi({
    apiFunction: payload =>
      activityApi.v1ActivityUpdateactivityverificationmethodPayloadPost(
        projectUid,
        id || '',
        payload
      ),
    errorCallback: err => {
      err.length > 0 &&
        setError(
          `${intl.formatMessage({ id: 'error.update' }, { error: 'verifiseringsmetode' })}: ${err}`
        );
    }
  });

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return (
    <RiskItemContainer>
      <GridContainer>
        <Title>{title}</Title>
        <StyledToggleButton
          isToggled={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          text={intl.formatMessage({ id: 'common.seemore' })}
          toggledText={intl.formatMessage({ id: 'common.seeless' })}
        />
        <ToggleContainer
          disabled={verificationMethod === ActivityVerificationMethod.WaitingForSeparation}
        >
          <label onClick={e => handleCheck(e)} htmlFor={`toggle-${id}`}>
            Inkluder i sjekk
          </label>
          <span id={`toggle-${id}`}>
            <SdirToggle
              checked={checked}
              onClick={e => handleCheck(e)}
              disabled={verificationMethod === ActivityVerificationMethod.WaitingForSeparation}
            />
            {checked ? 'PÅ' : 'AV'}
          </span>
        </ToggleContainer>
      </GridContainer>
      {isExpanded && (
        <ExpandedSection>
          <h2>{intl.formatMessage({ id: 'riskassessment.task' })}</h2>
          <p data-testid="requirementText">{requirementText}</p>
          <h3>{intl.formatMessage({ id: 'riskassessment.basisdata' })}</h3>
          <ParameterSection onFeedBackSubmit={onFeedBackSubmit} />
          {risks &&
            risks?.map((risk, i) => (
              <ExpandingParameter
                key={i}
                onFeedBackSubmit={onFeedBackSubmit}
                riskValue={risk.score}
                threshold={risk.threshold}
                title={risk.riskFactor}
              />
            ))}
        </ExpandedSection>
      )}
    </RiskItemContainer>
  );
};

const ParameterSection = ({ onFeedBackSubmit }) => {
  const [feedbackVisible, setFeedbackVisible] = useState(false);

  return (
    <>
      <RiskFeedbackModal
        modalVisible={feedbackVisible}
        handleModalCancel={() => setFeedbackVisible(false)}
        onFeedbackSubmit={onFeedBackSubmit}
        setVisible={() => setFeedbackVisible(true)}
      />
    </>
  );
};

const ExpandingParameter = ({ riskValue, threshold, title, onFeedBackSubmit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();

  return (
    <>
      <ExpandingParameterSection className="bordertop">
        <ToggleButton
          disabled
          isToggled={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          text={intl.formatMessage({ id: 'riskassessment.basisdata' })}
        />
        <RiskValue threshold={threshold} title={title}>
          {riskValue}
        </RiskValue>
      </ExpandingParameterSection>
      {isExpanded && <ParameterSection onFeedBackSubmit={onFeedBackSubmit} />}
    </>
  );
};

const ExpandingParameterSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;

  div {
    justify-self: start;
  }
`;

const ExpandedSection = styled.section`
  h2,
  h3 {
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.stfMedium};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 2rem;
    white-space: pre-line;
  }

  .bordertop {
    border-top: 1px solid black;
    margin-top: 2rem;
  }
`;

const ToggleContainer = styled.div<{ disabled: boolean }>`
  grid-area: 1 / 2 / span 2 / 2;
  display: grid;
  justify-self: end;
  margin-right: 2rem;
  label {
    pointer-events: ${({ disabled }) => (disabled === true ? 'none' : '')};
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.stfBook};
    padding-right: 2rem;
  }

  span {
    align-items: center;
    display: flex;
    gap: 5rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const RiskItemContainer = styled.article`
  margin: 3rem;
  padding: 3rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.primary10};

  h1 {
    margin: 0;
    font-size: 3rem;
    font-family: ${({ theme }) => theme.fonts.stfMedium};
    color: ${({ theme }) => theme.colors.font.text};
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  grid-area: 2 / 1 / 2 / 1;
  div {
    font-family: ${({ theme }) => theme.fonts.stfBook};
  }
`;

const Title = styled.h1`
  grid-area: 1 / 1 / 1 / 1;
`;

export default RiskItem;
