/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyScopeActions } from '@sdir/blueprint.et/lib/components';
import { UpdateScopeActivity } from '@sdir/httpclient/lib/clients/et/survey';
import { ScopedActivity } from '../../components/Molecules/SurveyScopeSection';
import {
  SET_SCOPE_ACTIVITIES_LIST,
  SET_SUBJECTAREA_LIST,
  UPDATE_SCOPE_ACTIVITY_RELEVANCE
} from '../../store/constants/action-types';
import { scopeEndpointsApi, checkpointsApi } from '../../httpclient';

const useSurveyScope = (projectUid: string, surveyUid: string) => {
  const { setError, setSuccess } = useToast();
  const dispatch = useDispatch();
  const intl = useIntl();
  const activities = useSelector((state: any) => state.appState.scopeActivitiesList);
  const activitiesChangedRelevance = useSelector(
    (state: any) => state.appState.scopeActivitiesListChanged
  );

  const {
    callApi: updateSurveyScope,
    loading: updateSurveyScopeLoading,
    errorObject: updateSurveyScopeError
  } = useApi({
    apiFunction: () => {
      const newScopeActivities = activities
        .filter(activity => {
          return (
            activity.isMandatory ||
            activity.isIncludedInCurrentSurvey ||
            activitiesChangedRelevance?.[activity.approvedActivityId] !== undefined
          );
        })
        .map(activity => {
          const mappedActivity: UpdateScopeActivity = {
            parentActivityId: activity.parentActivityId,
            approvedActivityId: activity.approvedActivityId,
            isMandatory: activity.isMandatory,
            isNotRelevant:
              activitiesChangedRelevance?.[activity.approvedActivityId]?.isNotRelevant ??
              activity.isNotRelevant
          };
          if (activitiesChangedRelevance?.[activity.approvedActivityId] !== undefined) {
            mappedActivity.notRelevantReason =
              activitiesChangedRelevance[activity.approvedActivityId].notRelevantReason;
          }

          return mappedActivity;
        });
      return scopeEndpointsApi.surveysScopeendpointsUpdatescopeUpdateScopePost(surveyUid, {
        projectId: projectUid,
        surveyId: surveyUid,
        activities: newScopeActivities
      });
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'survey.setscope.error' })}`);
      }
    },
    successCallback: () => {
      setSuccess(`${intl.formatMessage({ id: 'survey.setscope.success' })}`);
    }
  });

  // update changed activities in survey scope in redux state
  const setSurveyScope = (
    scopeActivitiesChanged: UpdateScopeActivity[],
    action?: SurveyScopeActions
  ) => {
    const activitiesChangedUids = scopeActivitiesChanged.map(
      activity => activity.approvedActivityId
    );
    dispatch({
      type: SET_SCOPE_ACTIVITIES_LIST,
      payload: activities?.map(activity => {
        if (activitiesChangedUids.includes(activity.approvedActivityId))
          return { ...activity, isIncludedInCurrentSurvey: action === SurveyScopeActions.Add };
        return activity;
      })
    });
  };

  // Set activities relevance in survey scope in redux state
  const setRelevance = (
    scopedActivities: Array<ScopedActivity>,
    isNotRelevant,
    relevanceComment?
  ) => {
    let relevanceChangeActivities = {};
    scopedActivities.forEach(scopedActivity => {
      relevanceChangeActivities = {
        ...relevanceChangeActivities,
        ...{
          [scopedActivity.approvedActivityId!]: {
            isNotRelevant,
            notRelevantReason: relevanceComment
          }
        }
      };
    });

    dispatch({
      type: UPDATE_SCOPE_ACTIVITY_RELEVANCE,
      payload: {
        ...activitiesChangedRelevance,
        ...relevanceChangeActivities
      }
    });
  };

  const { loading: activitiesLoading, callApi: getActivities } = useApi({
    apiFunction: () =>
      scopeEndpointsApi.surveysScopeendpointsGetsuggestedscopeProjectIdGet(surveyUid, projectUid),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'aktiviteter' })}: ${err}`);
    },
    successCallback: data => {
      dispatch({ type: SET_SCOPE_ACTIVITIES_LIST, payload: data.suggestedScopeActivities });
    }
  });

  const {
    loading: subjectAreaListLoading,
    callApi: getSubjectAreas,
    errorObject: subjectAreasError
  } = useApi({
    apiFunction: () => checkpointsApi.v1CheckpointsGetsubjectareasGet(),
    successCallback: data =>
      dispatch({ type: SET_SUBJECTAREA_LIST, payload: data, prop: 'subjectAreas' })
  });

  return {
    subjectAreaListLoading,
    getSubjectAreas,
    subjectAreasError,
    activitiesLoading,
    getActivities,
    updateSurveyScopeLoading,
    updateSurveyScope,
    updateSurveyScopeError,
    setSurveyScope,
    setRelevance
  };
};

export default useSurveyScope;
