import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { FieldProps } from 'formik';
import { Select } from '@sdir/sds';

export interface SelectOptions<T> {
  value: T;
  name: string;
  secondValue?: string | null;
  key?: string | null;
  group?: string;
}

interface FormSelectProps<T> extends FieldProps {
  field: any;
  options?: SelectOptions<T>[];
  disabled?: boolean;
  grouped?: boolean;
  defaultVal: string;
  className: string;
  onChange?: (val: string | T) => void;
  label: string;
  searchable?: boolean;
  defaultValue?: T | null;
}

function FormSelect<T>({
  options,
  field,
  className,
  disabled,
  defaultVal,
  grouped,
  onChange,
  form,
  label,
  searchable = false,
  defaultValue = null
}: PropsWithChildren<FormSelectProps<T>>): React.ReactElement {
  const meta = form.getFieldMeta(field.name);

  useEffect(() => {
    if (field.value !== null && !options?.find(option => option.value === field.value)) {
      if (field.value !== defaultValue) {
        form.setFieldTouched(field.name, false);
        form.setFieldValue(field.name, defaultValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleChange = useCallback(
    (value: string | T) => {
      form.setFieldTouched(field.name, true);
      form.setFieldValue(field.name, value);
      return onChange?.(value);
    },
    [field.name, form, onChange]
  );

  return (
    <div className={className}>
      <Select
        {...field}
        disabled={disabled}
        grouped={grouped}
        id={field.name}
        listLabel={label}
        options={options}
        defaultVal={defaultVal || label}
        error={!disabled && meta && meta.touched && meta.error}
        value={field.value}
        handleChange={handleChange}
        searchable={searchable}
      />
    </div>
  );
}

export default FormSelect;
