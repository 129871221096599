import React, { useContext, useState } from 'react';
import { SdiMeatballs, DropdownMenu, TextLink } from '@sdir/sds';
import styled, { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../Templates/Routes';

const RequirementsMeatballMenu = () => {
  const [otherOptionsIsOpen, setOtherOptionsIsOpen] = useState(false);
  const styles = useContext(ThemeContext);
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectuid } = useParams() as { projectuid: string };

  return (
    <>
      <MeatballsSection onClick={() => setOtherOptionsIsOpen(!otherOptionsIsOpen)}>
        <SdiMeatballs size="m" color={styles.colors.primary} style={{ cursor: 'pointer' }} />
        {otherOptionsIsOpen && (
          <DropdownmenuContainer>
            <DropdownMenu arrowPosition="right" spacing="compact">
              {/* Hide PDF download link as it is not implemented yet */}
              {/* <TextLink text={intl.formatMessage({ id: 'riskassessment.downloadpdf' })} /> */}
              <TextLink
                text={intl.formatMessage({ id: 'riskassessment.show' })}
                click={() => navigate(generatePath(Routes.riskAssessment, { projectuid }))}
              />
            </DropdownMenu>
          </DropdownmenuContainer>
        )}
      </MeatballsSection>
    </>
  );
};

const MeatballsSection = styled.div``;

const DropdownmenuContainer = styled.div`
  position: relative;
  z-index: 101;
  section {
    right: -2.5rem;
    position: absolute;
    width: 28rem;

    button {
      padding: 0 1rem;
      width: 100%;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};
      font-family: ${({ theme }) => theme.fonts.stfBook};
      font-size: 2rem;
    }

    button:hover {
      border-radius: 2.375rem;
      background-color: ${({ theme }) => theme.colors.background.light};
    }
  }
`;

export default RequirementsMeatballMenu;
