import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BlockBlobClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';
import {
  GetAttachmentResult,
  GetReactionResult,
  ReactionType
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useSelector } from 'react-redux';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { reactionApi, signingReactionApi } from '../../../httpclient';
import { Routes } from '../../Templates/Routes';
import { Signer } from '../../../store/constants/types';

export const UID_NULL = '00000000-0000-0000-0000-000000000000';

const useReaction = (
  reaction: GetReactionResult | null | undefined,
  closeReactionCallback?,
  rejectReactionResponseCallback?
) => {
  const intl = useIntl();
  const { setError, setSuccess } = useToast();
  const navigate = useNavigate();
  const { projectuid, activityuid } = useParams() as {
    projectuid: string;
    activityuid: string;
  };
  const currentUser = useCurrentUser();

  const [reactionUid, setReactionUid] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedAttachments, setUploadedAttachments] = useState<GetAttachmentResult[]>([]);
  const [newAttachments, setNewAttachments] = useState<File[]>([]);

  const [signer, setSigner] = useState<Signer | undefined>();
  const [allUsers, setAllUsers] = useState<Signer[]>([]);

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);

  useEffect(() => {
    if (etCaseWorkers) {
      const users = etCaseWorkers.map(user => {
        return {
          username: `${user.name}`,
          signersId: user.uid || ''
        };
      });
      setAllUsers(users);
    }
  }, [etCaseWorkers]);

  const navigateBack = () => {
    navigate(
      generatePath(Routes.vesselWorkspaceScrollTo, {
        tab: 'activitylist',
        projectuid,
        scrollToId: activityuid
      })
    );
  };

  const getBlobName = url => {
    const urlParts = url.split('?')[0].split(activityuid)[1];
    return `${activityuid}${urlParts}`;
  };

  const { callApi: downloadAttachment } = useApi({
    apiFunction: (reactionuid, attachmentId) =>
      reactionApi.v1ReactionGetdownloadtokenAttachmentUidGet(
        projectuid,
        activityuid,
        reactionuid,
        attachmentId
      ),
    successCallback: res => {
      if (res.blobUrl) {
        const fileName = res.blobUrl.split('?')[0].split('/')[7];
        saveAs(res.blobUrl, fileName);
      }
    }
  });

  const { callApi: addSigner, loading: addSignerLoading } = useApi({
    apiFunction: payload =>
      signingReactionApi.v1SigningreactionAddsignerforreactionPayloadPost(
        reactionUid || '',
        payload
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: removeSigner, loading: removeSignerLoading } = useApi({
    apiFunction: () =>
      signingReactionApi.v1SigningreactionDeletesignerforreactionReactionUidDelete(
        reactionUid || ''
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: changeSigner, loading: changeSignerLoading } = useApi({
    apiFunction: payload =>
      signingReactionApi.v1SigningreactionChangesignerforreactionPayloadPut(
        reactionUid || '',
        payload
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const { callApi: submitForSigning, loading: submitForSigningLoading } = useApi({
    apiFunction: payload =>
      signingReactionApi.v1SigningreactionSubmitreactionforsigningPayloadPost(
        reactionUid || '',
        payload
      ),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'signerer' })}: ${err}`);
      }
    }
  });

  const createDraftReactionAndUploadAttachments = async (
    payload,
    getActivityDetailsCallback?,
    disableSuccessToast?
  ) => {
    let newReactionUid;

    await createDraftReaction(payload)
      .then(async (res: any) => {
        if (newAttachments.length) {
          await updateDraftReactionAndUploadAttachments(
            payload,
            getActivityDetailsCallback,
            res.data
          );
        } else {
          !disableSuccessToast &&
            setSuccess(
              intl.formatMessage({
                id: 'vessel.activity.action.savedraft.success'
              })
            );
        }
        getActivityDetailsCallback && getActivityDetailsCallback();
        newReactionUid = res;
      })
      .catch(err => {
        if (err.length > 0) {
          setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'reaksjon' })}: ${err}`);
        }
      });

    return newReactionUid;
  };

  const { callApi: createDraftReaction, loading: createDraftLoading } = useApi({
    apiFunction: payload => {
      return reactionApi.v2ReactionCreatereactiondraftv2ReactionDraftPost(
        projectuid,
        activityuid,
        payload
      );
    },
    successCallback: result => {
      setReactionUid(result as any);
      setNewAttachments([]);
      return result;
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.create' }, { error: 'reaksjon' })}: ${err}`);
      }
    }
  });

  const updateDraftReactionAndUploadAttachments = async (
    payload,
    getActivityDetailsCallback?,
    reactionuid?,
    disableSuccessToast?
  ) => {
    if (newAttachments.length) {
      const uploadedFilesBlobUrls = await Promise.all(
        newAttachments.map(async at => {
          return uploadFile(at, reactionuid);
        })
      );

      const filteredBlobUrls = uploadedFilesBlobUrls
        .filter(url => url !== null)
        .map(url => ({ blobName: getBlobName(url) }));
      const mergedAttachments = payload.attachments.concat(filteredBlobUrls);
      payload.attachments = mergedAttachments;
      await updateDraftReaction(payload, reactionuid).then(res => {
        if (res.success) {
          !disableSuccessToast &&
            setSuccess(
              intl.formatMessage({
                id: 'vessel.activity.action.savedraft.success'
              })
            );
          setNewAttachments([]);
          getActivityDetailsCallback && getActivityDetailsCallback();
        }
      });
    } else {
      await updateDraftReaction(payload, reactionuid).then(res => {
        !disableSuccessToast &&
          setSuccess(
            intl.formatMessage({
              id: 'vessel.activity.action.savedraft.success'
            })
          );
        res.success && getActivityDetailsCallback && getActivityDetailsCallback();
      });
    }
  };

  const { callApi: updateDraftReaction, loading: updateDraftLoading } = useApi({
    apiFunction: (payload, reactionuid?) =>
      reactionApi.v2ReactionSavereactiondraftReactionDraftPut(
        projectuid,
        activityuid,
        reactionUid || reactionuid || '',
        payload
      ),
    successCallback: () => {
      setNewAttachments([]);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'reaksjon' })}: ${err}`);
      }
    }
  });

  const { callApi: replaceReaction, loading: replaceReactionLoading } = useApi({
    apiFunction: (payload, reactionuid) =>
      reactionApi.v1ReactionReplacesubmittedreactionReactionDtoPost(
        projectuid,
        activityuid,
        reactionuid,
        payload
      ),
    successCallback: () => {
      setTimeout(() => {
        setSuccess(
          intl.formatMessage({
            id: 'vessel.activity.action.replacereaction.success'
          })
        );
        setNewAttachments([]);
        navigateBack();
      }, 1000);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'reaksjon' })}: ${err}`);
      }
    }
  });

  const { callApi: deleteDraftReaction } = useApi({
    apiFunction: () =>
      reactionApi.v1ReactionDeletereactiondraftReactionUidDelete(
        projectuid,
        activityuid,
        reactionUid || ''
      ),
    successCallback: () => {
      setSuccess(
        intl.formatMessage({
          id: 'vessel.activity.action.deletedraft.success'
        })
      );
      navigateBack();
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.delete' }, { error: 'reaksjon' })}: ${err}`);
      }
    }
  });

  /* Load signer from draft reaction */
  useEffect(() => {
    if (reaction && reaction.reactionUid && reaction.reactionUid !== UID_NULL) {
      if (reaction.signer && reaction.signer.uid && reaction.signer.name) {
        setSigner({ signersId: reaction.signer?.uid, username: reaction.signer?.name });
      }

      /* If signer is missing name */
      if (reaction.signer && reaction.signer.uid && !reaction.signer.name) {
        setSigner({
          signersId: reaction.signer?.uid,
          username: intl.formatMessage({ id: 'signer.unknown' })
        });
      }
      setReactionUid(reaction.reactionUid as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reaction, allUsers]);

  const submitDraftReaction = payload => {
    if (!reactionUid) {
      createDraftReactionAndUploadAttachments(payload, undefined, true).then(
        res => res.data && submitReaction(res.data)
      );
    } else {
      updateDraftReactionAndUploadAttachments(payload, undefined, undefined, true).then(() =>
        submitReaction(reactionUid)
      );
    }
  };

  const handleReplaceReaction = async (payload, reactionuid) => {
    if (reactionuid) {
      if (newAttachments.length) {
        const uploadedFilesBlobUrls = await Promise.all(
          newAttachments.map(async at => {
            return uploadFile(at, reactionuid);
          })
        );

        const filteredBlobUrls = uploadedFilesBlobUrls
          .filter(url => url !== null)
          .map(url => ({ url: getBlobName(url) }));
        const mergedAttachments = payload.attachments.concat(filteredBlobUrls);
        payload.attachments = mergedAttachments;
        replaceReaction(payload, reactionuid);
      } else {
        replaceReaction(payload, reactionuid);
      }
    }
  };

  const { callApi: submitReaction, loading: submitDraftLoading } = useApi({
    apiFunction: reactionuid => {
      return reactionApi.v2ReactionSubmitreactionv2ReactionUidPut(
        projectuid,
        activityuid,
        reactionuid
      );
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'reaksjon' })}: ${err}`);
      }
    },
    successCallback: () => {
      setTimeout(() => {
        setSuccess(
          intl.formatMessage({
            id: 'vessel.activity.action.submitreaction.success'
          })
        );
        navigateBack();
      }, 1000);
    }
  });

  const { callApi: getBlobUrl } = useApi({
    apiFunction: (filename: string | undefined, newReactionUid?: string) => {
      return reactionApi.v1ReactionGetuploadtokenFileNamePost(
        projectuid,
        activityuid,
        reactionUid || newReactionUid || '',
        `"${filename}"`
      );
    },
    errorCallback: err => onFileError(err)
  });

  const getProgress = (loadedBytes: number, size: number) => {
    const percent = Math.round((loadedBytes / size) * 100);
    setProgress(percent);
  };

  const onFileError = error => {
    if (error.length > 0) {
      setError(`${intl.formatMessage({ id: 'error.save' }, { error: 'fil' })}: ${error}`);
    }
  };

  const uploadFile = async (file: File, newReactionUid?: string) => {
    let blobUrl: string | null = null;
    setIsUploading(true);
    const response = await getBlobUrl(file.name, newReactionUid);

    if (response.success) {
      const token = response.data.blobUrl || '';

      try {
        const blockBlobClient = new BlockBlobClient(token);
        await blockBlobClient
          .upload(file, file.size, {
            onProgress: p => getProgress(p.loadedBytes, file.size)
          })
          .then(
            () => {
              blobUrl = token;
              setIsUploading(false);
              setProgress(0);
            },

            onrejected => {
              onFileError(onrejected);
            }
          );
      } catch (exception) {
        if (exception instanceof Error) {
          onFileError(exception.message);
        } else if (typeof exception === 'string') {
          onFileError(exception);
        }
      }
    }
    return blobUrl;
  };

  const storeFilesForUpload = (files: File[]) => {
    const filesForUpload: File[] = [];

    files.forEach(file => {
      const fileAlreadyUploaded = newAttachments?.some(a => a.name === file.name);
      if (!fileAlreadyUploaded) {
        filesForUpload.push(file);
      }
    });

    const newAttachmentsArray = [...newAttachments];
    setNewAttachments(newAttachmentsArray.concat(filesForUpload));
  };

  const deleteFile = (uploaded, key) => {
    if (key) {
      if (uploaded) {
        const filteredUploadedAttachements = uploadedAttachments.filter(at => at.uid !== key);
        setUploadedAttachments(filteredUploadedAttachements);
      } else {
        const filteredNewAttachements = newAttachments.filter(at => at.name !== key);
        setNewAttachments(filteredNewAttachements);
      }
    }
  };

  const { callApi: closeReaction, loading: closingReaction } = useApi({
    apiFunction: reactionuid =>
      reactionApi.v1ReactionClosereactionCloseReactionDtoPost(
        projectuid,
        activityuid,
        reactionuid,
        { closedByUid: currentUser?.profile.sub }
      ),
    successCallback: () => {
      setSuccess(
        intl.formatMessage({
          id: 'vessel.activity.action.closereaction.success'
        })
      );

      if (closeReactionCallback) {
        closeReactionCallback();
      }
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'oppgave' })}: ${err}`);
      }
    }
  });

  const { callApi: rejectReactionResponse, loading: rejectingReactionResponse } = useApi({
    apiFunction: reactionuid =>
      reactionApi.v1ReactionRejectreactionReactionUidPost(projectuid, activityuid, reactionuid),
    successCallback: () => {
      setSuccess(
        intl.formatMessage({
          id: 'vessel.activity.action.rejectresponse.success'
        })
      );
      if (rejectReactionResponseCallback) {
        rejectReactionResponseCallback();
      }
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'oppgave' })}: ${err}`);
      }
    }
  });

  const getReactionType = (reactionType: string) => {
    switch (reactionType) {
      case ReactionType.Comment:
        return `${intl.formatMessage({ id: 'caseworker.reply.comment.title' })}`;
      case ReactionType.NonConformity:
        return `${intl.formatMessage({ id: 'sendreaction.selectoption.deviation' })}`;
      case ReactionType.DocumentationReceivedFollowupRequired:
        return `${intl.formatMessage({ id: 'sendreaction.selectoption.askfordocumentation' })}`;
      case ReactionType.Deficiencies:
      case ReactionType.Mandate:
        return `${intl.formatMessage({ id: 'sendreaction.selectoption.mandate' })}`;
      default:
        return '';
    }
  };

  const loading =
    createDraftLoading ||
    submitDraftLoading ||
    updateDraftLoading ||
    replaceReactionLoading ||
    closingReaction ||
    rejectingReactionResponse;

  return {
    loading,
    createDraftReactionAndUploadAttachments,
    updateDraftReactionAndUploadAttachments,
    submitDraftReaction,
    deleteDraftReaction,
    handleReplaceReaction,
    closeReaction,
    isUploading,
    progress,
    setUploadedAttachments,
    storeFilesForUpload,
    uploadedAttachments,
    newAttachments,
    deleteFile,
    signer,
    setSigner,
    allUsers,
    addSigner,
    addSignerLoading,
    removeSigner,
    removeSignerLoading,
    changeSigner,
    changeSignerLoading,
    submitForSigning,
    submitForSigningLoading,
    downloadAttachment,
    rejectReactionResponse,
    getReactionType
  };
};

export default useReaction;
