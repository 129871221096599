import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetProjectActivitiesOverviewV3Result,
  ProjectActivityV3
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  FocusAreaFilterOptions,
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import { SortingDirection } from '@sdir/utilities/lib/types/sorting';
import {
  ActiveFilters,
  Certificate,
  IntervalsCertificatesAndInspectionTypes,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { sortObjects } from '@sdir/utilities/lib/helpers/sorting';
import { UPDATE_ACTIVITY_STATUS_COMPLETED } from '../../store/constants/action-types';

const useTaskFilter = (
  activities: ProjectActivityV3[] | null | undefined,
  getActivityList?: () => void,
  resetSelectedActivities?: () => void
) => {
  const dispatch = useDispatch();
  const activityStatusChanged = useSelector((state: any) => state.appState.activityStatusChanged);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(defaultActiveFilters);
  const [filteredActivities, setFilteredActivities] = useState<ProjectActivityV3[]>();

  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const plannedVesselCertificates = useSelector(
    (state: any) => state.appState.plannedVesselCertificates
  );

  const subjectAreaList: GetProjectActivitiesOverviewV3Result = useSelector(
    (state: any) => state.appState.activitiesOverview
  );

  const availableIntervalsAndInspectionTypes: IntervalsCertificatesAndInspectionTypes = useMemo(() => {
    const uniqueIntervals = new Set();
    const uniqueInspectionTypes = new Set();
    subjectAreaList?.subjectAreaDocuments?.forEach(subArea => {
      subArea.activityOrActivityGroupOverview?.forEach(overview => {
        if (overview.type === 'Activity' && overview.activityDetail) {
          overview.activityDetail.intervals?.forEach(i => uniqueIntervals.add(i));
          overview.activityDetail.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i));
          overview.activityDetail.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i));
        }

        if (overview.type === 'ActivityGroup' && overview.documentActivitiesOverview) {
          overview.documentActivitiesOverview.activityOverview?.forEach(ac => {
            ac.intervals?.forEach(i => uniqueIntervals.add(i));
            ac.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i));
          });
        }
      });
    });

    const intervals = Array.from(uniqueIntervals) as string[];
    const inspectionTypes = Array.from(uniqueInspectionTypes) as string[];
    return { intervals, inspectionTypes };
  }, [subjectAreaList]);

  useEffect(() => updateFilteredActivities(), [activities, activeFilters]);

  const updateFilteredActivities = () => {
    // To refresh activity list if activity status was changed by Bekreft Oppgave/Klar for inspection
    if (activityStatusChanged?.subjectAreaUri && getActivityList) {
      getActivityList();
      dispatch({ type: UPDATE_ACTIVITY_STATUS_COMPLETED });
    }
    let newFilteredActivities: ProjectActivityV3[] = filterTasksByStatus();
    newFilteredActivities = filterByType(newFilteredActivities);
    newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
    newFilteredActivities = filterTasksByScope(newFilteredActivities);
    newFilteredActivities = filterTasksByFocusArea(newFilteredActivities);
    newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
    newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
    setFilteredActivities(newFilteredActivities);
  };

  const filterByType = (activitiesByStatus: ProjectActivityV3[]) => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('DocumentControl')
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('Inspection')
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities) return [];
    if (
      activeFilters.status.length === 0 ||
      activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (
        (activity?.lastEvent?.status && selectedStatusList.includes(activity.lastEvent.status)) ||
        (!activity?.lastEvent &&
          activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = (activitiesOrActivityGroups: ProjectActivityV3[]) => {
    if (!activitiesOrActivityGroups) return [];
    if (
      activeFilters?.certificates?.length === 0 ||
      activeFilters.certificates.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (activeFilters.certificates.some(ai => activity.certificates?.includes(ai))) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = (activitiesOrActivityGroups: ProjectActivityV3[]) => {
    if (!activitiesOrActivityGroups) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (
        (activeFilters.scope === ScopeFilterOptions.Mandatory && activity?.scope === true) ||
        (activeFilters.scope === ScopeFilterOptions.Expanded && activity?.scope === false)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByFocusArea = (activitiesOrActivityGroups: ProjectActivityV3[]) => {
    if (!activitiesOrActivityGroups || activeFilters.focusArea === FocusAreaFilterOptions.No)
      return [];
    return [...activitiesOrActivityGroups];
  };

  const filterTaskByIntervals = (activitiesOrActivityGroups: ProjectActivityV3[]) => {
    if (!activitiesOrActivityGroups) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (
        activeFilters.intervals.some(ai =>
          activity.intervals?.some(interval => interval.value === ai)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = (activitiesOrActivityGroups: ProjectActivityV3[]) => {
    if (!activitiesOrActivityGroups) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai =>
          activity.surveyTypes?.some(sType => sType.value === ai)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    setActiveFilters(newActiveFilters);
    updateFilteredActivities();
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const availableVesselCertificates: Certificate[] = useMemo(() => {
    if (plannedVesselCertificates) {
      let mainCertificates: Certificate[] = [];
      plannedVesselCertificates?.mainCertificates &&
        plannedVesselCertificates.mainCertificates
          ?.filter(cer => cer.uri && cer.isActive)
          ?.forEach(c => {
            const certificateFound = specificationsOptions?.mainCertificates?.find(cert => {
              return cert.code === c.uri;
            });
            if (certificateFound) {
              mainCertificates.push({
                name: certificateFound?.code?.split('#')[1],
                value: certificateFound?.code
              });
            }
          });
      const otherCertificates: Certificate[] = [];
      plannedVesselCertificates?.otherCertificates &&
        plannedVesselCertificates.otherCertificates
          ?.filter(cer => cer.uri && cer.isActive)
          ?.forEach(c => {
            const certificateFound = specificationsOptions?.otherCertificates?.find(cert => {
              return cert.code === c.uri;
            });
            if (certificateFound) {
              otherCertificates.push({
                name: certificateFound?.code?.split('#')[1],
                value: certificateFound?.code
              });
            }
          });
      mainCertificates = sortObjects(
        [...mainCertificates, ...otherCertificates],
        'name',
        SortingDirection.ASC
      );
      return mainCertificates;
    }
    return [];
  }, [plannedVesselCertificates, specificationsOptions]);

  const taskFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableVesselCertificates,
    availableIntervalsAndInspectionTypes
  };

  return taskFilterData;
};

export default useTaskFilter;
