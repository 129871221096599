import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { TextLink } from '@sdir/sds';
import { useIntl } from 'react-intl';

const SearchHistory: React.FC = () => {
  const intl = useIntl();
  const styles = useContext(ThemeContext);
  return (
    <Container>
      <Heading>{intl.formatMessage({ id: 'dashboard.mytasks.mySearch' })}</Heading>

      <HistoryLinks>
        <ButtonLink text="Misje managment" size="md" click={() => {}} />
        <ButtonLink text="010180" size="md" click={() => {}} />
        <ButtonLink text="Ms Fjord" size="md" click={() => {}} />
        <ButtonLink text="§ 2.12" size="md" click={() => {}} />
        <DeleteHistoryLink
          text="Fjern historikk"
          size="md"
          color={styles.colors.alert.errorDark}
          click={() => {}}
        />
      </HistoryLinks>
    </Container>
  );
};
export default SearchHistory;

const Container = styled.section`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 1rem;
`;
const Heading = styled.div`
  padding: 3rem 3rem 1.5rem 3rem;
  display: grid;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.font.PBM};
`;
const HistoryLinks = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  padding: 0 3rem;
`;
const ButtonLink = styled(TextLink)`
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
`;
const DeleteHistoryLink = styled(TextLink)`
  text-align: left;
  font-size: 1.6rem;
  margin: 0.6rem 0;
`;
