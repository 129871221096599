import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AlertSingleLine, DataCard, Loader, SdirToggle, SearchBar } from '@sdir/sds';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useCurrentUserHasAccess } from '@sdir/auth/lib/hooks';
import { ErrorMessage } from '@sdir/utilities/lib/components';
// eslint-disable-next-line max-len
import {
  CommonAddresseeServiceContractsV1ResponsesGetPersonExtendedResponse,
  V1PersonreplacementCreatereplacementRequestPostRequest
} from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { formatFullName } from '@sdir/blueprint.aps/lib/helpers/formattingHelpers';
import { AppPermissions } from '../../../constants/permissions';
import { commonPersonApi, personReplacementApi, searchApiApi } from '../../../httpclient';
import { DisplayDateFormat } from '../../../helpers';
import PersonMergeCard, { PersonData } from '../../Organisms/PersonMerge/PersonMergeCard';
import PersonMergePreview from '../../Organisms/PersonMerge/PersonMergePreview';
import { Routes } from '../../Templates/Routes';

const PersonMerge: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { commonAddresseeId } = useParams() as { commonAddresseeId: string };
  const personPermission = useCurrentUserHasAccess(AppPermissions.ViewAccessToPerson);
  const [currentPerson, setCurrentPerson] = useState<PersonData>();
  const [selectedPerson, setSelectedPerson] = useState<PersonData>();
  const [showDifferences, setShowDifferences] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const {
    result: personResult,
    loading: personLoading,
    error: personError,
    callApi: callPersonApi
  } = useApi({
    apiFunction: () => commonPersonApi.v1PersonGetpersonextendedbyidIdGet(commonAddresseeId)
  });

  const { result: searchResult, callApi: callSearch } = useApi({
    apiFunction: query => searchApiApi.v1SearchapiSearchSearchObjectPost({ query }),
    callApiOnInit: false
  });

  const { loading: mergeLoading, error: mergeError, callApi: callMerge } = useApi({
    apiFunction: payload =>
      personReplacementApi.v1PersonreplacementCreatereplacementRequestPost(payload),
    callApiOnInit: false,
    successCallback: () => {
      setSelectedPerson(undefined);
      onClosePreview();
      navigate(
        generatePath(Routes.person, { commonAddresseeId: currentPerson?.commonAddresseeId })
      );
    }
  });

  const formatPerson = useCallback(
    (p: CommonAddresseeServiceContractsV1ResponsesGetPersonExtendedResponse) => {
      const dateOfBirth = `${p?.birthDate || ''}`.substring(0, 10);
      return {
        firstName: p?.firstname || '',
        lastName: p?.lastname || '',
        dob: DisplayDateFormat(dateOfBirth),
        email: p?.email || '',
        personalIdentificationNumber: p?.personalIdentityNumber || '',
        nationality: p?.nationality || '',
        address: p?.addresses?.[0],
        legalEntityId: p?.legalEntityId || '',
        commonAddresseeId: p?.commonAddresseeId || ''
      } as PersonData;
    },
    []
  );

  const onSelectSearchResult = async (v: any) => {
    const personCommonAddresseeId = v?.[2];
    const res = await commonPersonApi.v1PersonGetpersonextendedbyidIdGet(personCommonAddresseeId);
    const person = formatPerson(res?.data);
    setSelectedPerson(person);
  };

  const onMerge = () => {
    setShowPreview(true);
  };

  const onMergeConfirm = () => {
    const payload: V1PersonreplacementCreatereplacementRequestPostRequest = {
      from: selectedPerson?.commonAddresseeId,
      to: currentPerson?.commonAddresseeId
    };
    callMerge(payload);
  };

  const onClosePreview = () => {
    setShowPreview(false);
  };

  const onReset = () => {
    setSelectedPerson(undefined);
  };

  const formattedSearchResults = useMemo(() => {
    return (searchResult as any)?.persons?.hits?.map(p => {
      const { firstName, middleName, lastName } = p?.document || {};
      const fullName = formatFullName(firstName, middleName, lastName);
      const dob = `${p?.document?.dateOfBirth || ''}`.substring(0, 10);
      return [fullName, DisplayDateFormat(dob), p?.document?.commonAddresseeId];
    });
  }, [searchResult]);

  const differences = useMemo(() => {
    if (!currentPerson || !selectedPerson || !showDifferences) return [];
    const diffs: string[] = [];
    if (currentPerson?.firstName !== selectedPerson?.firstName) diffs.push('firstName');
    if (currentPerson?.lastName !== selectedPerson?.lastName) diffs.push('lastName');
    if (currentPerson?.dob !== selectedPerson?.dob) diffs.push('dob');
    if (
      currentPerson?.personalIdentificationNumber !== selectedPerson?.personalIdentificationNumber
    ) {
      diffs.push('personalIdentificationNumber');
    }
    if (currentPerson?.email !== selectedPerson?.email) diffs.push('email');
    if (currentPerson?.nationality !== selectedPerson?.nationality) diffs.push('nationality');
    if (JSON.stringify(currentPerson?.address) !== JSON.stringify(selectedPerson?.address)) {
      diffs.push('address');
    }
    return diffs;
  }, [currentPerson, selectedPerson, showDifferences]);

  useEffect(() => {
    if (personResult) {
      const person = formatPerson(personResult);
      setCurrentPerson(person);
    }
  }, [personResult]);

  useEffect(() => {
    if (commonAddresseeId) {
      callPersonApi(commonAddresseeId);
    }
  }, [commonAddresseeId]);

  if (!personPermission?.hasAccess) {
    return (
      <AlertSingleLine
        title="Error"
        type="error"
        text={intl.formatMessage({ id: 'utils.errors.insufficientaccess' })}
      />
    );
  }

  if (personLoading) {
    return <Loader />;
  }

  if (personError) {
    return (
      <Wrapper>
        <ErrorMessage error={personError} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'aps.person.merge.title' })}</Title>
      <Differences>
        <SdirToggle
          checked={showDifferences}
          onClick={() => setShowDifferences(!showDifferences)}
          title={intl.formatMessage({ id: 'aps.person.merge.diff' })}
        />
      </Differences>
      <SeafarerContainer>
        <LeftSection>
          <PersonMergeCard
            person={currentPerson}
            title={intl.formatMessage({ id: 'aps.person.merge.current' })}
            onMerge={selectedPerson ? onMerge : undefined}
            differences={differences}
          />
        </LeftSection>
        <RightSection>
          {selectedPerson ? (
            <PersonMergeCard
              title={intl.formatMessage({ id: 'aps.person.merge.selected' })}
              person={selectedPerson}
              onClose={onReset}
              differences={differences}
            />
          ) : (
            <DataCard title={intl.formatMessage({ id: 'aps.person.merge.search' })}>
              <Container>
                <SearchBar
                  placeholder={intl.formatMessage({ id: 'aps.person.merge.search.placeholder' })}
                  searchCallback={callSearch}
                  searchResults={formattedSearchResults}
                  onSearchResultClick={onSelectSearchResult}
                />
              </Container>
            </DataCard>
          )}
        </RightSection>
      </SeafarerContainer>
      <PersonMergePreview
        loading={mergeLoading}
        error={mergeError}
        person={currentPerson}
        show={showPreview}
        onClose={onClosePreview}
        onConfirm={onMergeConfirm}
      />
    </Wrapper>
  );
};

export default PersonMerge;

const Wrapper = styled.div`
  padding: 20px;
`;

const SeafarerContainer = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 3rem;
  font-weight: 700;
  margin: 5px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const Differences = styled.div`
  margin: 20px 0;
  label span:first-child {
    margin-left: 30px;
  }
`;

const LeftSection = styled.div`
  width: calc(50% - 10px);
  color: ${({ theme }) => theme.colors.font.PBM};
  margin-right: 10px;
`;

const RightSection = styled.div`
  width: calc(50% - 10px);
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const Container = styled.div`
  padding: 16px 24px;
`;
