import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { SdirModal, SdirButton, Loader, AlertSingleLine } from '@sdir/sds';
import { AssessmentsReportByLegalEntityId } from '@sdir/httpclient/lib/clients/aps/assessor';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { assessmentApi } from '../../../httpclient';

export type ReportingTimeData = {
  legalEntityId: string;
  serviceCode: string | null | undefined;
};

interface DeleteModalProps {
  display: boolean;
  assessment?: AssessmentsReportByLegalEntityId;
  onCancelClick: () => void;
  onSuccessDelete: () => void;
}

// eslint-disable-next-line max-len
const deleteAssessmentApi = assessmentApi?.v1AssessmentDeletesingleassessmentsUidDelete.bind(
  assessmentApi
);

const DeleteAssessmentModal: React.FC<DeleteModalProps> = ({
  display,
  assessment,
  onCancelClick,
  onSuccessDelete
}) => {
  const intl = useIntl();

  const canDelete = true;
  const canOpen = true;

  const { loading, error, callApi: performDelete } = useApi({
    apiFunction: deleteUid => deleteAssessmentApi(deleteUid),
    callApiOnInit: false
  });

  const deleteApplication = useCallback(async () => {
    if (!canDelete) return;
    const apiResult = await performDelete(assessment?.uid || '');
    if (apiResult.success) {
      onSuccessDelete();
    }
  }, [canDelete, performDelete, onSuccessDelete]);

  return display ? (
    <SdirModal width="300px" visible={display} onCancel={onCancelClick}>
      <Wrapper>
        <Title>{intl.formatMessage({ id: 'vessel.deleteassessmentmodal.header' })}</Title>
        <Container>
          <ButtonsSection>
            {canDelete && (
              <SdirButton
                onClick={onCancelClick}
                type="primary"
                text={intl.formatMessage({ id: 'vessel.deletemodal.close' })}
                htmlType="button"
              />
            )}
            {canOpen && (
              <SdirButton
                onClick={deleteApplication}
                type="danger"
                text={intl.formatMessage({ id: 'vessel.deletemodal.delete' })}
                htmlType="button"
              />
            )}
          </ButtonsSection>
        </Container>
        {loading && <Loader />}
        {error && (
          <AlertSingleLine
            type="error"
            title="Error"
            text={intl.formatMessage({ id: 'vessel.deleteassessmentmodal.delete.error' })}
          />
        )}
      </Wrapper>
    </SdirModal>
  ) : null;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  justify-content: space-between;
  margin: 0px 0;
`;

const ButtonsSection = styled.div`
  display: flex;
  width: 100%;
  height: 20rem;
  justify-content: space-between;
  margin: 0px 0;
`;

const Container = styled.div`
  display: flex;
  height: 8rem;
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 2.2rem;
  color: ${({ theme }) => theme.fonts.warning};
  margin: 0;
  font-weight: normal;
  text-align: center;
`;

export default DeleteAssessmentModal;
