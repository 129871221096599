import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { saveAs } from 'file-saver';
import {
  DeleteActivityAttachmentPayload,
  RequestReadTokenPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';

import { replyToCustomerApi } from '../../../httpclient';

const useReplyFiles = ({ onDeleteSuccess }) => {
  const { setError } = useToast();
  const intl = useIntl();

  const { callApi: getBlobUrl } = useApi({
    apiFunction: ({ requestId, attachmentId }) => {
      const payload: RequestReadTokenPayload = {
        attachmentUid: attachmentId
      };
      return replyToCustomerApi.v1ReplyGetdownloadurlPayloadPost(requestId, payload);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'fil' })}: ${err}`);
      }
    }
  });

  const { callApi: callDeleteApi } = useApi({
    apiFunction: ({ requestId, attachmentId }) => {
      const payload: DeleteActivityAttachmentPayload = {
        attachmentId
      };
      return replyToCustomerApi.v1ReplyRequestdeletePayloadPost(requestId, payload);
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.update' }, { error: 'fil' })}: ${err}`);
      }
    }
  });

  const deleteFile = async ({ requestId, attachmentId }) => {
    const res = await callDeleteApi({ requestId, attachmentId });
    if (res.success) {
      onDeleteSuccess(attachmentId);
    }
  };

  const downloadFile = async ({ requestId, attachmentId, fileName }) => {
    const response = await getBlobUrl({ requestId, attachmentId });

    if (response.success) {
      saveAs(response.data.blobUrl, fileName);
    }
  };

  return { downloadFile, deleteFile };
};

export default useReplyFiles;
