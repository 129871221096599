import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { PermitStatus } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';

interface VesselPermitStatusProps {
  validity?: PermitStatus;
}

const VesselPermitStatus: React.FC<VesselPermitStatusProps> = ({
  validity = PermitStatus.Yellow
}) => {
  const intl = useIntl();

  const getLabel = () => {
    switch (validity) {
      case PermitStatus.Green:
        return `${intl.formatMessage({ id: 'vesselheader.permit.valid' })}`;
      case PermitStatus.Yellow:
        return `${intl.formatMessage({ id: 'vesselheader.permit.limitedvalidity' })}`;
      case PermitStatus.Red:
        return `${intl.formatMessage({ id: 'vesselheader.permit.invalid' })}`;
      default:
        return ` `;
    }
  };

  return (
    <StatusContainer validity={validity}>
      <StatusDot validity={validity} />
      <Text>{getLabel()}</Text>
    </StatusContainer>
  );
};

const StatusContainer = styled.div<{ validity: string }>`
  display: flex;
  align-items: center;
  height: 6rem;
  border: 1px solid ${({ theme }) => theme.colors.background.secondary};
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: 6px;
  ${({ theme, validity }) => {
    switch (validity) {
      case PermitStatus.Green:
        return `
          background-color: ${theme.colors.alert.successLight};
        `;
      case PermitStatus.Yellow:
        return `
          background-color: ${theme.colors.alert.warningLight};
        `;
      case PermitStatus.Red:
        return `
          background-color: ${theme.colors.alert.errorLight};
        `;
      default:
        return `
         background-color: ${theme.colors.alert.discInfoLight};
        `;
    }
  }}
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  margin-right: 1rem;
`;

const StatusDot = styled.span<{ validity: string }>`
  height: 4rem;
  width: 1rem;
  border-radius: 2.5rem;
  display: inline-block;
  margin: 0 1rem;

  ${({ theme, validity }) => {
    switch (validity) {
      case PermitStatus.Green:
        return `
          background-color: ${theme.colors.alert.successDark};
        `;
      case PermitStatus.Yellow:
        return `
          background-color: ${theme.colors.alert.warningDark};
        `;
      case PermitStatus.Red:
        return `
          background-color: ${theme.colors.alert.errorDark};
        `;
      default:
        return `
          background-color: ${theme.colors.alert.discInfoDark};
        `;
    }
  }}
`;

export default VesselPermitStatus;
