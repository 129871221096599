/* eslint-disable @typescript-eslint/indent */
import { AlertShort } from '@sdir/sds';
import React, { useEffect, useState } from 'react';

import styled, { css, keyframes } from 'styled-components';

interface Props {
  type: string;
  text: string;
}

const DisapperingAlert: React.FC<Props> = ({ type, text }) => {
  const decayTime = 10;
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, decayTime * 1000);
  }, []);

  return show ? (
    <ApiMessage decaytime={decayTime}>
      <AlertShort type={type} text={text} dismissable />
      <span className="progress" />
    </ApiMessage>
  ) : null;
};

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const slideOut = keyframes`
    from {
      transform: scaleX(1);
      opacity: 1;
    }
  
    to {
      transform: scaleX(0);
    }
  `;

const ApiMessage = styled.div<{ decaytime: number }>`
  position: fixed;
  z-index: 2000;
  top: 2rem;
  right: 2rem;

  animation: ${({ decaytime }) =>
    decaytime &&
    css`
      ${fadeOut} ${decaytime}s linear forwards
    `};

  .progress {
    border: 2px solid ${({ theme }) => theme.colors.primary8};
    border-radius: 0 0 6px 6px;
    width: 100%;
    bottom: 2px;
    position: absolute;
    bottom: 0;
    animation: ${({ decaytime }) =>
      decaytime &&
      css`
        ${slideOut} ${decaytime}s linear forwards
      `};
    transform-origin: top right;
    opacity: 0.7;
  }
`;

export default DisapperingAlert;
