import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface InfoTileInterface {
  tileTitle: ReactNode;
  tileBody?: ReactNode;
  children?: ReactNode;
  editing?: boolean;
  onRemoveTileClick?: () => void;
  isDraggable?: boolean;
  onDragStartHandler?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEndHandler?: (e: React.DragEvent<HTMLDivElement>) => void;
}

const InfoTile: React.FC<InfoTileInterface> = ({
  tileTitle,
  tileBody,
  children,
  editing,
  onRemoveTileClick,
  isDraggable = false
}) => {
  const onRemoveClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemoveTileClick?.();
  };

  return (
    <BasicInfoTile isDraggable={isDraggable}>
      {editing && <Clear onClick={onRemoveClick} role="presentation" />}
      <BodyFrame>
        {!tileBody && children && children}
        {tileBody && tileBody}
      </BodyFrame>
      {tileTitle && tileTitle}
    </BasicInfoTile>
  );
};

const BasicInfoTile = styled.div<{ isDraggable: boolean }>`
  font-size: 2rem;
  position: relative;
  display: grid;
  grid-template-rows: auto 6rem;
  width: ${({ isDraggable }) => (isDraggable ? '44rem' : '100%')};
  height: ${({ isDraggable }) => (isDraggable ? '32rem' : '100%')};
  cursor: ${({ isDraggable }) => (isDraggable ? 'pointer' : 'default')};
`;

const BodyFrame = styled.article`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const Clear = styled.div`
  top: -${({ theme }) => theme.overlay.gap};
  right: -${({ theme }) => theme.overlay.gap};
  position: absolute;
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.alert.errorHot};
  border-radius: 50%;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 0.45rem;
    right: 1.7rem;
    content: ' ';
    height: 3rem;
    width: 0.5rem;
    background-color: ${({ theme }) => theme.colors.font.white};
    border-radius: ${({ theme }) => theme.card.borderRadius};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export default InfoTile;
