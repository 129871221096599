import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TableColumn } from '@sdir/utilities/lib/types/tables';
import { DocumentAttachment } from '@sdir/httpclient/lib/clients/et/construction';
import { useIntl } from 'react-intl';
import { DataTable } from '@sdir/sds';
import { useDataSorting } from '@sdir/utilities/lib/hooks';

interface FileTableProps {
  files: DocumentAttachment[] | null | undefined;
  downloadFile: (fileName: string) => void;
}

interface FileRow {
  fileName: string | null | undefined;
  size: string | null | undefined;
  uploadedBy: string | null | undefined;
  uploadedDate: string | null | undefined;
}

const getColumns = intl => {
  const fileTableColumns: TableColumn<FileRow>[] = [
    {
      label: intl.formatMessage({ id: 'filetable.filename' }),
      key: 'fileName'
    },
    {
      label: intl.formatMessage({ id: 'filetable.size' }),
      key: 'size'
    },
    {
      label: intl.formatMessage({ id: 'filetable.uploadedby' }),
      key: 'uploadedBy'
    },
    {
      label: intl.formatMessage({ id: 'filetable.date' }),
      key: 'uploadedDate'
    }
  ];

  return fileTableColumns;
};

const FileTable: React.FC<FileTableProps> = ({ files, downloadFile }) => {
  const intl = useIntl();

  const onCellClicked = (e, key) => {
    if (key === 'fileName') {
      const found = files?.find(el => el.fileName?.includes(e.fileName));
      if (found && found.fileName) {
        downloadFile(found.fileName);
      }
    }
  };

  const tableData = useMemo(() => {
    return files?.map<FileRow>(file => {
      const filenameParts = file.fileName?.split('/') || '';
      const size = file.sizeInBytes ? `${file.sizeInBytes} bytes` : '';
      const date = file.uploadedDate ? new Date(file.uploadedDate).toLocaleDateString() : '';

      return {
        fileName: filenameParts[filenameParts.length - 1],
        size,
        uploadedBy: file.uploadedBy,
        uploadedDate: date
      };
    });
  }, [files]);

  const { sortedData } = useDataSorting(tableData || [], getColumns(intl));
  if (!tableData) return null;

  return (
    <StyledDataTable
      sortableKeys="*"
      columns={getColumns(intl)}
      data={sortedData}
      onCellClick={onCellClicked}
    />
  );
};

const StyledDataTable = styled(DataTable)`
  &&& {
    th {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: auto;
    }

    & {
      background: none;
    }
  }
`;

export const FiletableBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.primary10};
  border-radius: 6px;
  margin-bottom: 4rem;

  /* Override default large padding */
  td {
    padding-top: 0 !important;
    padding-bottom: 1rem !important;
  }

  button {
    margin: 0 4rem;
    padding-bottom: 4rem;
  }

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.stfBook};
    height: 27rem;
    text-align: center;
    padding-top: 11rem;
  }

  /* Style the file name */
  tr {
    td:first-child {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default FileTable;
