/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import { VesselRequirementsSearchResult } from '@sdir/httpclient/lib/clients/core/requirement';
import { useRef, useState } from 'react';
import axios from 'axios';

import { baseUrl, specificationsApi, getConfigHeaders } from '../../httpclient';
import useLawText from './useLawTexts';

const useSimulator = () => {
  const intl = useIntl();
  const { setError, setWarning } = useToast();
  const [requirementsLoading, setRequirementsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [requirementList, setRequirementList] = useState<
    VesselRequirementsSearchResult | undefined
  >();

  const getRequirementList = payload => {
    setRequirementsLoading(true);
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    const config = {
      headers: getConfigHeaders(),
      signal
    };

    axios
      .post(`${baseUrl}/core-requirement-internal/v3/vesselrequirements/search`, payload, config)
      .then(response => {
        if (response.data) {
          if (!response.data.regulations?.length) {
            setWarning(
              intl.formatMessage({
                id: 'allekravpage.page.nosimulationresult'
              })
            );
          }
          setRequirementList(response.data);
        }
      })
      .catch(err => {
        err.length > 0 &&
          setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
      })
      .finally(function() {
        setRequirementsLoading(false);
      });
  };

  const cancelGetRequirementList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  const { sectionsWithLawTexts, sectionsLoading, handleChapterClick } = useLawText();

  const { result: specificationOptions, loading: specificationOptionsLoading } = useApi({
    apiFunction: () => specificationsApi.v1SpecificationsGetvesselspecificationoptionsGet(),
    callApiOnInit: true
  });

  const simulatorData = {
    requirementsLoading,
    requirementList,
    sectionsWithLawTexts,
    getRequirementList,
    handleChapterClick,
    sectionsLoading,
    specificationOptions,
    specificationOptionsLoading,
    cancelGetRequirementList
  };

  return simulatorData;
};

export default useSimulator;
