/* eslint-disable @typescript-eslint/indent */
import {
  ActivityWithRisk,
  ExemptionRequestedDomainEvent,
  GetActivityDetailsV2ResultEventsInner
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { DataCard, FileLink } from '@sdir/sds';
import {
  ToggleButton,
  DataCardContent,
  Preview,
  ValidityOfTemporaryExemptionText
} from '@sdir/blueprint.et/lib/components';
import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';

interface ExemptionActionMessageProps {
  activity: ActivityWithRisk;
  events: GetActivityDetailsV2ResultEventsInner[] | null | undefined;
  downloadFile: (filename) => void;
}

const ExemptionActionMessage: React.FC<ExemptionActionMessageProps> = ({
  activity,
  events,
  downloadFile
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  const event: ExemptionRequestedDomainEvent | null | undefined = events?.length
    ? events[events.length - 1]
    : activity.lastEvent;

  if (!event) return <div />;

  const { validFrom, validTo, description, attachments, innovativeDescription } = event;

  const title = intl.formatMessage({ id: 'actionmessage.exemption' });

  return (
    <Wrapper>
      <DataCard>
        <DataCardContent>
          <Preview barColor={theme.colors.alert.warningDark}>
            <span>
              {isExpanded ? (
                <>
                  <b>{title}</b>
                </>
              ) : (
                <>
                  <b>{title}</b>: {description}
                </>
              )}
            </span>

            <ToggleButton
              isToggled={isExpanded}
              text=""
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Preview>
          {isExpanded && (
            <div>
              {validFrom && validTo && (
                <ValidityOfTemporaryExemptionText validFrom={validFrom} validTo={validTo} />
              )}

              {description && <p>{description}</p>}
              {innovativeDescription && <p>{innovativeDescription}</p>}

              {attachments?.map(filename => {
                const filenameParts = filename.split('/');
                const readableFilename = filenameParts[filenameParts.length - 1];

                return (
                  <FileLink
                    key={filename}
                    width="45rem"
                    text={readableFilename || ''}
                    onClick={() => {
                      if (downloadFile) downloadFile(filename);
                    }}
                  />
                );
              })}
            </div>
          )}
        </DataCardContent>
      </DataCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem 0 0;

  section {
    div {
      line-height: 1;
    }
  }
`;

export default ExemptionActionMessage;
