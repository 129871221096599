import React from 'react';
import styled from 'styled-components';
import { AlertType } from '../../types/enums';

interface AlertMessageInterface {
  message: string;
  alert?: AlertType;
}

const AlertMessage: React.FC<AlertMessageInterface> = ({ message, alert = AlertType.None }) => {
  return (
    <MessageContainer alert={alert}>
      <AlertText>{message}</AlertText>
    </MessageContainer>
  );
};

const MessageContainer = styled.div<{ alert: AlertType }>`
  width: 100%;
  height: 20rem;
  display: grid;
  align-items: center;
  justify-content: center;
  color: ${({ alert, theme }) =>
    alert === AlertType.Error ? theme.colors.font.errorHot : theme.colors.font.primary.blue2};
  background-color: ${({ alert, theme }) =>
    alert === AlertType.Error ? theme.colors.alert.errorLight : theme.colors.background.light};
`;

const AlertText = styled.p`
  font-size: 4rem;
  font-weight: 600;
`;

export default AlertMessage;
