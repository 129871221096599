/* eslint-disable max-len */
import { ETpack } from '@sdir/language-pack';
import { messages as utilMessages } from '@sdir/utilities/lib/localization';
import { vesselPreviewMessages } from '@sdir/blueprint/lib/localization';
import {
  generalMessages,
  healthMessages,
  seagoingMessages
} from '@sdir/blueprint.aps/lib/components';

export default {
  nb: {
    'home.mvpplaceholder': 'Søk etter personer, fartøy eller organisasjoner i søkeboksen over.',
    'dashboard.myTask.header': 'MINE OPPGAVER',
    'dashboard.myTask.seeMore': 'Se flere oppgaver',
    'dashboard.myTask.customer': 'Kunde',
    'dashboard.myTask.projectStatus': 'Prosjektløp',
    'dashboard.myTask.officer': 'Saksbehandler',
    'dashboard.myTask.team': 'Team',
    'dashboard.myTask.comment': 'Kommentar',
    'dashboard.myTask.certificate': 'Sertifikat',
    'dashboard.myTask.type': 'Type',
    'dashboard.myTask.deadline': 'Behandlingsfrist',
    'dashboard.myTask.processing': 'Behandlingstid',
    'dashboard.myTask.modal.header': 'Bytt saksbehandler',
    'dashboard.myTask.modal.info': 'Hold inne ctrl for å velge flere saksbehandlere',
    'dashboard.mytasks.mySearch': 'Nylige søk',
    'dashboard.mytasks.myshortcut': 'Mine snarveier',
    'dashboard.myshortcut.edit': 'Rediger snarvei',
    'dashboard.myshortcut.title': 'Snarveitittel:',
    'dashboard.myshortcut.update': 'Oppdater snarvei',
    'dashboard.myshortcut.delete': 'Slett snarvei',
    'dashboard.infotile.chart.down.title': 'Manuelt behandlet',
    'dashboard.infotile.chart.down.subtitle': '2 stk økning av stikkprøver fra forrige mnd.',
    'dashboard.infotile.chart.up.title': 'Totalt antall søknader',
    'dashboard.infotile.chart.up.subtitle': '2 stk økning av stikkprøver fra forrige mnd.',
    'dashboard.infotile.chart.semiDonut.subtitle': 'Prosentandel avslag av søknader',
    'dashboard.infotile.chart.semiDonut.title': 'Avslåtte søknader',
    'dashboard.infotile.control.hide': 'Skjul data',
    'dashboard.infotile.control.show': 'Vis data',
    'dashboard.infotile.control.edit': 'Endre datavisning',
    'dashboard.infotile.chart.pcs': 'stk',
    'dashboard.infotile.chart.bubble.new': 'Nye i dag',
    'dashboard.infotile.chart.bubble.total': 'Totalt',
    'dashboard.infotile.chart.bubble.title': 'Til behandling',
    'dashboard.infotile.chart.bubble.subtitle': 'Dine saker som venter på behandling',
    'dashboard.infotile.chart.verticalbar.title': 'Automatisk behandlet',
    'dashboard.infotile.chart.verticalbar.subtitle':
      'Antall saker som har blitt automatisk behandlet per',
    'dashboard.infotile.chart.horizontalbar.title': 'Status på dokument sjekkliste',
    'dashboard.infotile.chart.horizontalbar.subtitle': 'Status på dokument sjekkliste',
    'dashboard.infotile.chart.timelinebartile.title': 'Ulykker',
    'dashboard.infotile.chart.timelinebartile.subtitle': 'Oversikt over antall ulykker',
    'dashboard.infotile.warning.text':
      'PDF generator opplever for tiden problemer med å generere forhåndsvisning av nye dokumenter.',

    'search.customer.preview.button': 'Gå til person',

    'datacard.placeholdet.text': 'Dra og slipp ønsket data her',
    'datacard.certificates.title': 'Sertifikater',
    'datacard.certificates.showall': 'Vis alle',
    'datacard.certificates.showonlyvalid': 'Vis kun gyldige',
    'datacard.courseandeducation.title': 'Kurs og utdanning',
    'datacard.profilevisits.title': 'Profilbesøk',
    'datacard.charges.title': 'Gebyrer',
    'datacard.searches.title': 'Søknader',
    'datacard.seafarer.title': 'Person',
    'datacard.seafarer.signaturetitle': 'Signatur',
    'datacard.seafarer.age': 'år',
    'datacard.seagoing.title': 'Fartstid',
    'datacard.seagoing.hideexpired': 'Skjul fartstid eldre enn 5 år',
    'datacard.seagoing.showexpired': 'Vis fartstid eldre enn 5 år',
    'app.status.Start': 'Start',
    'app.status.Draft': 'Kladd',
    'app.status.Payment': 'Venter betaling',
    'app.status.CheckQualification': 'Sjekker kvalifikasjoner',
    'app.status.Approved': 'Godkjent',
    'app.status.Rejected': 'Avslått',
    'app.status.ManualProcessing': 'Manuell behandling',
    'app.status.QASample': 'Stikkprøve',
    'app.status.RequestMoreInformation': 'Venter på tilleggsinformasjon',
    'app.status.RequestMoreInformationTimedOut': 'Frist for tillegginfo utgått',
    'app.status.CreateCertificate': 'Oppretter sertifikat',
    'app.status.Archive': 'Arkiveres',
    'app.status.Done': 'Behandling ferdigstilt',
    'app.status.Final': 'Slettes',
    'app.type.FirstTime': 'Førstegang',
    'app.type.Duplicate': 'Duplikat',
    'app.type.Renewal': 'Fornyelse',
    'aps.certificate.valid.from': 'Gyldig fra/Valid from',
    'aps.certificate.valid.until': 'Gyldig til/Valid until',
    'aps.certificate.nr': 'Sertifikatnr./Certificate nr.',
    'aps.certificate.issued': 'Utstedet dato/Issued on',
    'aps.certificate.birthdate': 'Fødselsnr./Date of birth',
    'aps.certificate.owner': 'Innehaver/holder',
    'aps.certificate.title': 'Sertifikat',
    'aps.certificate.link': 'Se sertifikat',
    'aps.certificate.status.Valid': 'Gyldig',
    'aps.certificate.status.Expired': 'Utgått',
    'aps.certificate.status.Cancelled': 'Kansellert',
    'aps.certificate.status.Inactive': 'Inaktiv',
    'aps.customerCard.dNumber': 'Fødselsnummer/D-nummer',
    'aps.customerCard.dob': 'Fødselsdato',
    'aps.customerCard.citizenship': 'Statsborgerskap',
    'aps.customerCard.address': 'Adresse',
    'aps.customerCard.name': 'Navn',
    'aps.customerCard.telephone': 'Telefon',
    'aps.customerCard.email': 'Epostadresse',
    'aps.customerCard.photo': 'Bilde',
    'aps.customerCard.signature': 'Signatur',
    'aps.customerCard.firstName': 'Fornavn',
    'aps.customerCard.lastName': 'Etternavn',
    'aps.person.merge.hasexisting': 'Dette kundekort er slått sammen med et nytt',
    'aps.person.merge.gotoexisting': 'Gå til aktivt kundekort',
    'aps.person.merge.title': 'Søk etter duplikat kundekort',
    'aps.person.merge.diff': 'Vis forskjeller',
    'aps.person.merge.current': 'Gjeldende kundekort',
    'aps.person.merge.selected': 'Duplikat kundekort',
    'aps.person.merge.search': 'Søk etter mulig duplikat kundekort',
    'aps.person.merge.search.placeholder': 'Søk etter navn, f-nr, d-nr ...',
    'aps.person.merge.label': 'Slå sammen',
    'aps.person.merge.link': 'Slå sammen duplikate kundekort',
    'aps.person.merge.preview.title': 'Slå sammen kundekort',
    'aps.person.merge.preview.select': 'Velg som kundekort',
    'aps.person.merge.preview.confirm': 'Bekreft valget ditt',
    'aps.person.merge.preview.desc':
      'Du er nå i ferd med å slå sammen to kundekort. Er du sikker på at du vil gjøre dette?',
    'aps.person.merge.previous': 'Tidligere kundekort',
    'aps.person.merge.noprevious': 'Ingen tidligere kundekort',
    'aps.person.merge.merged': 'Dette kundekortet er slått sammen med ett annet kundekort',
    'aps.person.split.title': 'Splitt kundekortene',
    'aps.person.split.link': 'Splitt opp disse kundekortene',
    'aps.person.split.desc': 'Er du sikker på at du vil splitte disse kundekortene?',
    'aps.health.no.limit': ' Egnet uten begrensning ',
    'aps.health.limit': ' Egnet med begrensning ',
    'aps.health.limit.additional': 'Har tilleggsbegrensning',
    'aps.health.fit.for.bridgeguard': 'Ikke skikket for utkikk',
    'aps.health.fit.for.security': 'Ikke skikket for sikkerhetsfunksjon',
    'aps.health.fit.for.other': 'Ikke skikket for annet arbeid ombord',
    'aps.health.colorsight.date': 'Siste sjekk av fargesyn:',
    'aps.health.header': 'Helseattest',
    'aps.health.validTo': 'Gyldig tom.',
    'table.report.date': 'Dato',
    'table.report.createdBy': 'Meldt av',
    'table.report.name': 'Navn',
    'table.report.vessel': 'Fartøy',
    'table.report.period': 'Periode',
    'table.competence.report.competence': 'Tilleggskompetanse',
    'table.assesment.report.assessmentType': 'Bedømmelse',
    'table.report.hours': 'Timer',
    'table.report.completionDate': 'Bestått dato',
    'table.report.expiryDate': 'Utløpsdato',
    'table.report.valid': 'Gyldig',
    'table.report.expired': 'Utgått',
    'table.report.jobTitle': 'Stilling',
    'table.report.organization': 'Organisasjon',
    'table.report.employer': 'Arbeidsgiver',
    'table.report.days': 'Total tid (dag)',
    'table.report.code': 'Kompetansekode',
    'table.report.assessorName': 'Assessoren navn',
    'table.report.assessortype': 'Assessor type',
    'table.report.issuedDate': 'Utstedet',
    'table.showdetails': 'Vis detaljer',
    'table.delete': 'Slett',
    'table.hidedetails': 'Skjul detaljer',
    'table.application.created': 'Opprettet',
    'table.application.status': 'Status',
    'table.application.payment.date': 'Betalt dato',
    'table.application.payment.state': 'Betalt status',
    'table.application.payment.paidby': 'Betaler',
    'table.application.payment.is.paid': 'Betalt',
    'table.application.payment.is.not.paid': 'Ikke betalt',
    'table.application.payment.not.found': 'Ingen data',
    'table.no.data.found': 'Kunne ikke finne noe data',
    'table.no.competence.found': 'Ingen kompetanse registrert',
    'table.certificates.view.certificate': 'Se sertifikat',
    'vessel.activity.status.Accepted': 'Akseptert',
    'vessel.activity.status.NotConsidered': 'Ikke vurdert',
    'vessel.activity.status.NotRelevant': 'Ikke relevant',
    'vessel.activity.status.newreaction': 'Gi reaksjon',
    'vessel.activity.status.draft': 'Reaksjon lagret som utkast',
    'vessel.activity.status.updatedraft': 'Endre utkast',
    'vessel.activity.status.replacereaction': 'Erstatt reaksjon',
    'vessel.activity.action.replacereaction': 'Erstatt reaksjon',
    'vessel.activity.action.closereaction': 'Lukk reaksjon',
    'vessel.activity.action.closereaction.success': 'Reaksjon lukket',
    'vessel.activity.action.deletedraft.success': 'Utkast slettet',
    'vessel.activity.action.savedraft.success': 'Utkast lagret',
    'vessel.activity.action.rejectresponse.success': 'Besvarelse avslått',
    'vessel.activity.action.submitreaction.success': 'Reaksjon sendt til kunde',
    'vessel.activity.action.replacereaction.success': 'Reaksjon erstattet og sendt til kunde',
    'vessel.activity.action.changeassessment': 'Endre vurdering på oppgave',
    'vessel.activity.action.evaluateresponse': 'Vurder besvarelse',
    'vessel.activity.status.application.CaseworkerWarning':
      'vil bli satt som ansvarlig saksbehandler',
    'vessel.activity.action.accept': 'Aksepter',
    'vessel.activity.action.newreaction': 'Gi reaksjon',
    'vessel.activity.action.opendraft': 'Åpne utkast',
    'vessel.activity.reaction.sent.comment': 'Kommentar sendt til kunde',
    'vessel.activity.reaction.sent.mandate': 'Pålegg sendt til kunde',
    'vessel.activity.reaction.draft.comment': 'Utkast til kommentar',
    'vessel.activity.reaction.draft.mandate': 'Utkast til pålegg',
    'vessel.deletemodal.header': 'Er du sikker du vil slette fartstid?',
    'vessel.deletemodal.close': 'Nei',
    'vessel.deletemodal.delete': 'Ja slett',
    'vessel.deletemodal.delete.error': 'Feil ved sletting av fartstid',
    'vessel.deleteassessmentmodal.header': 'Er du sikker du vil slette bedømmelse?',
    'vessel.deleteassessmentmodal.delete.error': 'Feil ved sletting av bedømmelse',
    'vessel.deleteadditionalcompetencemodal.header':
      'Er du sikker du vil slette tilleggskompetanse?',
    'vessel.deleteadditionalcompetencemodal.delete.error':
      'Feil ved sletting av tilleggskompetanse',
    'vessel.deletecoursemodal.header': 'Er du sikker du vil slette kurs/utdanning?',
    'vessel.deletecoursemodal.close': 'Nei',
    'vessel.deletecoursemodal.delete': 'Ja slett',
    'vessel.deletecoursemodal.delete.error': 'Feil ved sletting av kurs/utdanning',
    'vessel.editmodal.close': 'Lukk',
    'vessel.editmodal.save': 'Lagre',
    'vessel.editmodal.hours': 'Total timer',
    'vessel.editmodal.vessel': 'Fartøy',
    'vessel.editmodal.position': 'Stilling',
    'vessel.editmodal.from': 'Fra',
    'vessel.editmodal.to': 'Til',
    'vessel.editmodal.comment': 'Kommentar',
    'vessel.editmodal.form.error.vessel': 'Fartøy er påkrevd',
    'vessel.general.form.error': 'Obligatorisk felt',
    'vessel.editmodal.edit.error': 'Feil ved redigering av fartstid',
    'vessel.editmodalcourse.edit.error': 'Feil ved redigering av kurs/utdanning',
    'vessel.activity.evaluatereaction.title': 'Vurder besvarelse på reaksjon fra kunde',
    'vessel.activity.evaluatereaction.documentcontrol.info':
      'Dere har mottatt et svar på reaksjon som ble gitt på oppgaven %s. Denne oppgaven er knyttet til dokumentet %s. Se gjennom besvarelsen fra kunden og vurder om reaksjonen kan lukkes.',
    'vessel.activity.evaluatereaction.inspection.info':
      'Dere har mottatt et svar på reaksjon som ble gitt på oppgaven %s. Se gjennom besvarelsen fra kunden og vurder om reaksjonen kan lukkes.',
    'vessel.activity.evaluatereaction.response.title': 'Besvarelse på reaksjon',
    'vessel.activity.evaluatereaction.response.uploaded.title': 'Opplastede filer',
    'vessel.activity.evaluatereaction.response.accept.label': 'Lukk reaksjon',
    'vessel.activity.evaluatereaction.response.reject.label': 'Avslå besvarelse',
    'vessel.activity.evaluatereaction.response.deadline.label': 'Frist for besvarelse',
    'vessel.activity.evaluatereaction.response.criticality.label': 'Kritikalitet',
    'vessel.activity.evaluatereaction.response.type.label': 'Type reaksjon',
    'vessel.activity.evaluatereaction.response.rejectmodal.title':
      'Avslå besvarelse på reaksjon på {task}',
    'vessel.activity.evaluatereaction.response.rejectmodal.info':
      'Dersom besvarelse på reaksjon avslås vil reaksjonen bli erstattet med en ny reaksjon.',
    'vessel.activity.evaluatereaction.response.rejectmodal.button.label':
      'Avslå og skriv ny reaksjon',

    'activityDetails.link.colleague': 'Send til kollega',
    'activityDetails.link.gotorequirement': 'Gå til krav',
    'activityDetails.activitylist': 'Aktivitetsliste',
    'activitycard.message.answermesasge': 'Svar på melding',
    'activitycard.exemption.answer': 'Besvar dispensasjon',
    'activitycard.notrelevant.answer': 'Besvar ikke relevant søknad',
    'activitycard.files.docsfromcustomer': 'Dokumentasjon fra kunde',
    'activitycard.status.waitingforsdir': 'Venter på SDIR',
    'signee.modal.title': 'Velg hovedsignerer',
    'caseworker.modal.title': 'Velg saksbehandler',
    'signer.unknown': 'Ukjent bruker',
    'signercaseworker.modal.save': 'Lagre',
    'signercaseworker.modal.savechange': 'Bytt til valgt',
    'riskassessment.givefeedback': 'Gi tilbakemelding på denne vurderingen',
    'riskassessment.nomodel': 'Dette kravet er ennå ikke knyttet til noen risikomodell.',
    'riskassessment.basisdata': 'Grunnlagsdata',
    'riskassessment.thresholdvalue': 'Terskelverdi',
    'riskassessment.noriskmodel': 'Ingen Risikomodell',
    'riskassessment.riskmodels': 'risikomodeller',
    'riskassessment.show': 'Vis risikovurdering',
    'riskassessment.downloadpdf': 'Last ned PDF',
    'common.modal.placeholder.search': 'Søkeord',
    'message.reply.acceptedwithcondition': 'Godkjent med betingelse',
    'common.cancel': 'Avbryt',
    'common.add': 'Legg til valgte',
    'common.ok': 'Ok',
    'common.back': 'Tilbake',
    'common.seemore': 'Se mer',
    'common.seeless': 'Se mindre',
    'common.loading': 'Laster...',
    'common.remove': 'Fjern',
    'common.unsaved': 'Ulagret',
    'common.files': 'Filer',
    'userbar.logout': 'Logg ut',
    'userbar.idporten': 'Du er nå innlogget på alle offentlige tjenester med ID-porten',
    'userbar.otherUser': 'Du er nå logget inn som ',
    'general.errors.microfrontend.failedtoloadapp':
      'Klarte ikke laste inn applikasjon "{name}". {details}',
    'general.errors.api.unexpectedresponse': 'Uventet respons fra server.',
    'general.form.cancel': 'Avbryt',
    'general.form.close': 'Lukk',
    'general.form.save': 'Lagre',
    'general.form.delete': 'Slette',
    'error.noResult': 'Ingen resultater funnet.',
    'error.load': 'Kunne ikke laste {error}',
    'error.update': 'Kunne ikke oppdatere {error}',
    'error.create': 'Kunne ikke opprette {error}',
    'error.save': 'Kunne ikke lagre {error}',
    'error.delete': 'Kunne ikke slette {error}',
    'documentcard.newactivitybuttonlabel': '+ Ny oppgave',
    'seagoing.history.title': 'Endringer av fartstid',
    'seagoing.history.nochanges': 'Lagret uten endringer',
    'seagoing.history.comment': 'Kommentar',
    'seagoing.history.status': 'Status',
    'seagoing.history.status.Approved': 'Godkjent',
    'seagoing.history.status.Rejected': 'Avslått',
    'seagoing.history.status.Unprocessed': 'Ubehandlet',
    'newactivity.createactivitybuttonlabel': 'Opprett oppgave',
    'newactivity.title': 'Opprett ny oppgave på',
    'newactivity.subtitle':
      'Her kan dere opprette en ny oppgave som bare vil gjelde for det valgte fartøyet.',
    'newactivity.input.titleno.placeholder': 'Oppgavetittel (No)',
    'newactivity.input.titleen.placeholder': 'Oppgavetittel (En)',
    'newactivity.input.descriptionno.placeholder': 'Beskrivelse av hva som skal gjøres (No)',
    'newactivity.input.descriptionen.placeholder': 'Beskrivelse av hva som skal gjøres (En)',
    'newactivity.radio.iscritical.label': 'Kritikalitet',
    'newactivity.select.subjectarea.placeholder': 'Fagområde oppgaven tilhører',
    'newactivity.select.projectcode.placeholder': 'Velg arkivkode',
    'newactivity.select.checkpointtype.placeholder': 'Oppgavetype',
    'newactivity.select.documentedin.placeholder': 'Dokumentert i',
    'newactivity.select.certificate.placeholder': 'Velg sertifikater',
    'newactivity.radio.criticality.critical': 'Kritisk',
    'newactivity.radio.criticality.notcritical': 'Ikke kritisk',
    'newactivity.input.referenceToAuthority.placeholder': 'Henvisning til hjemmel',
    'sendreaction.textarea.label': 'på krav',
    ...ETpack.nb,
    ...utilMessages.no,
    ...vesselPreviewMessages.nb,
    ...generalMessages.no,
    ...healthMessages.no,
    ...seagoingMessages.no
  },
  en: {
    'home.mvpplaceholder': 'You can start searching person and vessels in above search bar.',
    'dashboard.myTask.header': 'MY TASKS',
    'dashboard.myTask.seeMore': 'See more tasks',
    'dashboard.myTask.customer': 'Customer',
    'dashboard.myTask.projectStatus': 'Project Status',
    'dashboard.myTask.officer': 'Executive officer ',
    'dashboard.myTask.team': 'Team',
    'dashboard.myTask.comment': 'Comment',
    'dashboard.myTask.certificate': 'Certificate',
    'dashboard.myTask.type': 'Type',
    'dashboard.myTask.deadline': 'Processing deadline',
    'dashboard.myTask.processing': 'Processing time',
    'dashboard.myTask.modal.header': 'Change caseworker',
    'dashboard.myTask.modal.info': 'Hold down ctrl to select multiple caseworkers',
    'dashboard.mytasks.mySearch': 'New link search',
    'dashboard.mytasks.myshortcut': 'My shorcuts',
    'dashboard.myshortcut.edit': 'Edit Shortcuts',
    'dashboard.myshortcut.title': 'Shortcut title:',
    'dashboard.myshortcut.update': 'Update shortcut',
    'dashboard.myshortcut.delete': 'Delete shortcut',
    'dashboard.infotile.chart.down.title': 'Manually processed',
    'dashboard.infotile.chart.down.subtitle': '2 stk decrease av stikkprøver fra forrige mnd.',
    'dashboard.infotile.chart.up.subTitle': '2 pcs increase of random samples from last month.',
    'dashboard.infotile.chart.up.title': 'Totalt antall søknader',
    'dashboard.infotile.chart.semiDonut.subtitle': 'Percentage of rejections of applications',
    'dashboard.infotile.chart.semiDonut.title': 'Rejected applications',
    'dashboard.infotile.control.hide': 'Hide data',
    'dashboard.infotile.control.show': 'Show data',
    'dashboard.infotile.control.edit': 'Edit data presentation',
    'dashboard.infotile.chart.pcs': 'pcs',
    'dashboard.infotile.chart.bubble.new': 'New today',
    'dashboard.infotile.chart.bubble.total': 'Total',
    'dashboard.infotile.chart.bubble.title': 'To be handled',
    'dashboard.infotile.chart.bubble.subtitle': 'Your cases that are waiting to be solved',
    'dashboard.infotile.chart.verticalbar.title': 'Automatically processed',
    'dashboard.infotile.chart.verticalbar.subtitle':
      'Number of cases that have been processed automatically per',
    'dashboard.infotile.chart.horizontalbar.title': 'Status of document checklist',
    'dashboard.infotile.chart.horizontalbar.subtitle': 'Status of document checklist',
    'dashboard.infotile.chart.timelinebartile.title': 'Accidents',
    'dashboard.infotile.chart.timelinebartile.subtitle': 'Overview of the number of accidents',
    'dashboard.infotile.warning.text':
      'PDF generator is currently experiencing problems generating previews of new documents.',

    'datacard.placeholdet.text': 'Drag and drop desired data here',
    'datacard.certificates.title': 'Certificates',
    'datacard.certificates.showall': 'Show all',
    'datacard.certificates.showonlyvalid': 'Show only valid',
    'datacard.courseandeducation.title': 'Courses & Education',
    'datacard.profilevisits.title': 'Profile visits',
    'datacard.charges.title': 'Charges',
    'datacard.searches.title': 'Applications',
    'datacard.seafarer.title': 'Person',
    'datacard.seafarer.signaturetitle': 'Signature',
    'datacard.seafarer.age': 'years',
    'datacard.seagoing.title': 'Seagoing',
    'datacard.seagoing.hideexpired': 'Hide time older than 5 years',
    'datacard.seagoing.showexpired': 'Show time older than 5 years',
    'app.status.Start': 'Start',
    'app.status.Draft': 'Draft',
    'app.status.Payment': 'Waiting for payment',
    'app.status.CheckQualification': 'Checking qualifications',
    'app.status.Approved': 'Approved',
    'app.status.Rejected': 'Rejected',
    'app.status.ManualProcessing': 'Manual processing',
    'app.status.QASample': 'QA sample',
    'app.status.RequestMoreInformation': 'More info requested',
    'app.status.RequestMoreInformationTimedOut': 'Deadline for more info expired',
    'app.status.CreateCertificate': 'Creating certificate',
    'app.status.Archive': 'Archiving',
    'app.status.Done': 'Processing finalized',
    'app.status.Final': 'To be deleted',
    'app.type.FirstTime': 'Firsttime',
    'app.type.Duplicate': 'Duplicate',
    'app.type.Renewal': 'Renewal',
    'aps.certificate.valid.from': 'Gyldig fra/Valid from',
    'aps.certificate.valid.until': 'Gyldig til/Valid until',
    'aps.certificate.nr': 'Sertifikatnr./Certificate nr.',
    'aps.certificate.issued': 'Utstedet dato/Issued on',
    'aps.certificate.birthdate': 'Fødselsnr./Date of birth',
    'aps.certificate.owner': 'Innehaver/holder',
    'aps.certificate.title': 'Certificate',
    'aps.certificate.link': 'See certificate',
    'aps.certificate.status.Valid': 'Valid',
    'aps.certificate.status.Expired': 'Expired',
    'aps.certificate.status.Cancelled': 'Cancelled',
    'aps.certificate.status.Inactive': 'Inactive',
    'aps.customerCard.dNumber': 'BirthNumber/D-number',
    'aps.customerCard.dob': 'Date of birth',
    'aps.customerCard.citizenship': 'Citizenship',
    'aps.customerCard.address': 'Address',
    'aps.customerCard.name': 'Name',
    'aps.customerCard.telephone': 'Telephone',
    'aps.customerCard.email': 'Email Address',
    'aps.customerCard.photo': 'Photo',
    'aps.customerCard.signature': 'Signature',
    'aps.customerCard.firstName': 'First name',
    'aps.customerCard.lastName': 'Last name',
    'aps.person.merge.hasexisting': 'This customer card has been merged with a new one',
    'aps.person.merge.gotoexisting': 'Go to active customer card',
    'aps.person.merge.title': 'Search for duplicate customer card',
    'aps.person.merge.diff': 'Show differences',
    'aps.person.merge.current': 'Current customer card',
    'aps.person.merge.selected': 'Duplicate customer card',
    'aps.person.merge.search': 'Search for possible duplicates',
    'aps.person.merge.search.placeholder': 'Search for name, f-nr, d-nr ...',
    'aps.person.merge.label': 'Merge',
    'aps.person.merge.link': 'Merge duplicate customer cards',
    'aps.person.merge.preview.title': 'Merge customer cards',
    'aps.person.merge.preview.select': 'Select as customer card',
    'aps.person.merge.preview.confirm': 'Confirm your selection',
    'aps.person.merge.preview.desc':
      'You are now in the process of merging two customer cards. Are you sure you want to do this?',
    'aps.person.merge.previous': 'Previous customer cards',
    'aps.person.merge.noprevious': 'No previous customer cards',
    'aps.person.merge.merged': 'This customer card has been merged with another customer card',
    'aps.person.split.title': 'Split customer cards',
    'aps.person.split.link': 'Split these customer cards',
    'aps.person.split.desc': 'Are you sure you want to split these customer cards?',
    'aps.health.no.limit': ' Suitable without limitation ',
    'aps.health.limit': ' Suitable with limitation ',
    'aps.health.limit.additional': 'Has additional limitation',
    'aps.health.fit.for.bridgeguard': 'Not fit for brideguard',
    'aps.health.fit.for.security': 'Not fit for security',
    'aps.health.fit.for.other': 'Not fit for other work on board',
    'aps.health.colorsight.date': 'Last check of color sight:',
    'aps.health.header': 'Health test',
    'aps.health.validTo': 'Valid until',
    'table.report.date': 'Date',
    'table.report.createdBy': 'Created by',
    'table.report.name': 'Name',
    'table.report.vessel': 'Vessel',
    'table.report.period': 'Period',
    'table.competence.report.competence': 'Additional competence',
    'table.assesment.report.assessmentType': 'Assessment rating',
    'table.report.hours': 'Hours',
    'table.report.completionDate': 'Passed date',
    'table.report.expiryDate': 'Expiry date',
    'table.report.valid': 'Valid',
    'table.report.expired': 'Expired',
    'table.report.jobTitle': 'Occupational title',
    'table.report.organization': 'Organization',
    'table.report.employer': 'Employer',
    'table.report.days': 'Total time (days)',
    'table.report.code': 'Competence code',
    'table.report.assessorName': 'Assessor Name',
    'table.report.assessortype': 'Assessor type',
    'table.report.issuedDate': 'Issued date',
    'table.showdetails': 'Show details',
    'table.delete': 'Delete',
    'table.hidedetails': 'Hide details',
    'table.application.created': 'Created',
    'table.application.status': 'Status',
    'table.application.payment.date': 'Paid date',
    'table.application.payment.state': 'Paid state',
    'table.application.payment.paidby': 'Paid by',
    'table.application.payment.is.paid': 'Paid',
    'table.application.payment.is.not.paid': 'Not paid',
    'table.application.payment.not.found': 'No data',
    'table.no.data.found': 'Could not find any data',
    'table.no.competence.found': 'No competence registered',
    'table.certificates.view.certificate': 'View certificate',
    'vessel.activity.status.Accepted': 'Accepted',
    'vessel.activity.status.NotConsidered': 'Not considered',
    'vessel.activity.status.NotRelevant': 'Not relevant',
    'vessel.activity.status.newreaction': 'Give reaction',
    'vessel.activity.status.draft': 'Reaction saved as draft',
    'vessel.activity.status.updatedraft': 'Update draft',
    'vessel.activity.status.replacereaction': 'Replace reaction',
    'vessel.activity.status.application.CaseworkerWarning':
      'will be set as the responsible case handler',
    'vessel.activity.action.accept': 'Accept',
    'vessel.activity.action.newreaction': 'Give reaction',
    'vessel.activity.action.opendraft': 'Open draft',
    'vessel.activity.reaction.sent.comment': 'Comment sent to customer',
    'vessel.activity.reaction.sent.mandate': 'Imposition sent to customer',
    'vessel.activity.reaction.draft.comment': 'Draft comment',
    'vessel.activity.reaction.draft.mandate': 'Draft imposition',
    'vessel.activity.action.replacereaction': 'Replace reaction',
    'vessel.activity.action.closereaction': 'Close reaction',
    'vessel.activity.action.closereaction.success': 'Reaction closed',
    'vessel.activity.action.rejectresponse.success': 'Response rejected',
    'vessel.activity.action.deletedraft.success': 'Draft deleted',
    'vessel.activity.action.savedraft.success': 'Draft saved',
    'vessel.activity.action.submitreaction.success': 'Reaction sent to customer',
    'vessel.activity.action.replacereaction.success': 'Reaction replaced and sent to customer',
    'vessel.activity.action.changeassessment': 'Change assessment on assignment',
    'vessel.activity.action.evaluateresponse': 'Evaluate response',
    'vessel.deletemodal.header': 'Are you sure you want to delete seagoing?',
    'vessel.deletemodal.close': 'No',
    'vessel.deletemodal.delete': 'Yes delete',
    'vessel.deletemodal.delete.error': 'Error deleting seagoing',
    'vessel.deleteassessmentmodal.header': 'Are you sure you want to delete assessment?',
    'vessel.deleteassessmentmodal.delete.error': 'Error when deleting assessment',
    'vessel.deleteadditionalcompetencemodal.header':
      'Are you sure you want to delete additional competence?',
    'vessel.deleteadditionalcompetencemodal.delete.error':
      'Error when deleting additional competence',
    'vessel.deletecoursemodal.header': 'Are you sure you want to delete course/education?',
    'vessel.deletecoursemodal.close': 'Nei',
    'vessel.deletecoursemodal.delete': 'Ja slett',
    'vessel.deletecoursemodal.delete.error': 'Error when deleting course/education',
    'vessel.editmodal.close': 'Close',
    'vessel.editmodal.save': 'Save',
    'vessel.editmodal.hours': 'Total hours',
    'vessel.editmodal.vessel': 'Vessel',
    'vessel.editmodal.position': 'Position',
    'vessel.editmodal.from': 'From',
    'vessel.editmodal.to': 'To',
    'vessel.editmodal.comment': 'Comment',
    'vessel.editmodal.form.error.vessel': 'Vessel is required',
    'vessel.editmodal.edit.error': 'Error editing seagoing',
    'vessel.editmodalcourse.edit.error': 'Error editing course/education',
    'vessel.general.form.error': 'Required field',
    'vessel.activity.evaluatereaction.title': 'Evaluate response to reaction from customer',
    'vessel.activity.evaluatereaction.documentcontrol.info':
      'You have received a response to the reaction that was given on the task %s. This assignment is linked to the document %s. Review the response from the customer and assess whether the reaction can be closed.',
    'vessel.activity.evaluatereaction.inspection.info':
      'You have received a response to the reaction that was given on the task %s. Review the response from the customer and assess whether the reaction can be closed.',
    'vessel.activity.evaluatereaction.response.title': 'Response to reaction',
    'vessel.activity.evaluatereaction.response.uploaded.title': 'Uploaded files',
    'vessel.activity.evaluatereaction.response.accept.label': 'Close reaction',
    'vessel.activity.evaluatereaction.response.reject.label': 'Decline answer',
    'vessel.activity.evaluatereaction.response.deadline.label': 'Deadline for answering',
    'vessel.activity.evaluatereaction.response.criticality.label': 'Criticality',
    'vessel.activity.evaluatereaction.response.type.label': 'Type of reaction',
    'vessel.activity.evaluatereaction.response.rejectmodal.title':
      'Decline response to reaction on {task}',
    'vessel.activity.evaluatereaction.response.rejectmodal.info':
      'If a response to a reaction is rejected, the reaction will be replaced with a new reaction.',
    'vessel.activity.evaluatereaction.response.rejectmodal.button.label':
      'Decline and give a new reaction',

    'search.customer.preview.button': 'Go to person',
    'activityDetails.link.colleague': 'Send to colleague',
    'activityDetails.link.gotorequirement': 'Go to requirement',
    'activityDetails.activitylist': 'Activity list',
    'activitycard.message.answermesasge': 'Reply to message',
    'activitycard.exemption.answer': 'Reply to exemption',
    'activitycard.notrelevant.answer': 'Reply to application for not relevant',
    'activitycard.files.docsfromcustomer': 'Documentation from customer',
    'activitycard.status.waitingforsdir': 'Waiting for SDIR',

    'message.reply.acceptedwithcondition': 'Accepted with condition',
    'signee.modal.title': 'Choose Signee',
    'caseworker.modal.title': 'Choose case worker',
    'signer.unknown': 'Unknown user',
    'signercaseworker.modal.save': 'Save',
    'signercaseworker.modal.savechange': 'Change selected',
    'riskassessment.givefeedback': 'Give feedback on this assessment',
    'riskassessment.nomodel': 'This requirement is not yet linked to any risk model.',
    'riskassessment.basisdata': 'Basis data',
    'riskassessment.thresholdvalue': 'Threshold value',
    'riskassessment.noriskmodel': 'No risk model',
    'riskassessment.riskmodels': 'riskmodels',
    'riskassessment.show': 'Show risk assessment',
    'riskassessment.downloadpdf': 'Download PDF',
    'common.modal.placeholder.search': 'Search',
    'common.cancel': 'Cancel',
    'common.add': 'Add selected',
    'common.ok': 'Ok',
    'common.back': 'Back',
    'common.seemore': 'See more',
    'common.seeless': 'See less',
    'common.loading': 'Loading...',
    'common.remove': 'Remove',
    'common.unsaved': 'Unsaved',
    'common.files': 'Files',
    'userbar.logout': 'Log out',
    'userbar.idporten': 'You are logged in to all public services using ID-porten',
    'userbar.otherUser': 'You are loggen in as ',
    'general.errors.microfrontend.failedtoloadapp':
      'Unable to load application "{name}". {details}',
    'general.errors.api.unexpectedresponse': 'Unexpected response from server.',
    'general.form.cancel': 'Cancel',
    'general.form.close': 'Close',
    'general.form.save': 'Save',
    'general.form.delete': 'Delete',
    'error.noResult': 'No result found.',
    'error.load': 'Could not load {error}',
    'error.update': 'Could not update {error}',
    'error.create': 'Could not create {error}',
    'error.save': 'Could not save {error}',
    'error.delete': 'Could not delete {error}',
    'documentcard.newactivitybuttonlabel': '+ New activity',
    'seagoing.history.title': 'Changes in seagoing time',
    'seagoing.history.nochanges': 'Saved without changes',
    'seagoing.history.comment': 'Comment',
    'seagoing.history.status': 'Status',
    'seagoing.history.status.Approved': 'Approved',
    'seagoing.history.status.Rejected': 'Rejected',
    'seagoing.history.status.Unprocessed': 'Unprocessed',
    'newactivity.createactivitybuttonlabel': 'Create activity',
    'newactivity.title': 'Create new activity for',
    'newactivity.subtitle':
      'Here you can create a new task that will only apply to the selected vessel.',
    'newactivity.input.titleno.placeholder': 'Activity title (No)',
    'newactivity.input.titleen.placeholder': 'Activity title (En)',
    'newactivity.input.descriptionno.placeholder': 'Description of what is to be done (No)',
    'newactivity.input.descriptionen.placeholder': 'Description of what is to be done (En)',
    'newactivity.radio.iscritical.label': 'Criticality',
    'newactivity.select.subjectarea.placeholder': 'Subject area the activity belongs to',
    'newactivity.select.projectcode.placeholder': 'Choose archive code',
    'newactivity.select.checkpointtype.placeholder': 'Activity type',
    'newactivity.select.documentedin.placeholder': 'Documented in',
    'newactivity.select.certificate.placeholder': 'Choose certificates',
    'newactivity.radio.criticality.critical': 'Critical',
    'newactivity.radio.criticality.notcritical': 'Not critical',
    'newactivity.input.referenceToAuthority.placeholder': 'Reference to authority',
    'sendreaction.textarea.label': 'on requirements',
    ...ETpack.en,
    ...utilMessages.en,
    ...vesselPreviewMessages.en,
    ...generalMessages.en,
    ...healthMessages.en,
    ...seagoingMessages.en
  }
};
