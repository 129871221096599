import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorBoundary from './Providers/ErrorBoundary';

interface IContentSection {
  children?: React.ReactNode;
}

const ContentSection: React.FC<IContentSection> = ({ children }) => {
  const location = useLocation();

  return (
    <ErrorBoundary location={location}>
      <section>{children}</section>
    </ErrorBoundary>
  );
};

export default ContentSection;
