/* eslint-disable no-alert */
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  DataCard,
  MarkupTextarea,
  MultiSelect,
  SdirButton,
  SdirInput,
  SdirRadioButtonGroup,
  Select,
  TextLink,
  Textarea
} from '@sdir/sds';
import { OptionDto } from '@sdir/httpclient/lib/clients/core/requirement';
import styled from 'styled-components';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import Page from '../../Templates/Page';
import { Routes } from '../../Templates/Routes';
import {
  checkpointsApi,
  projectApi,
  activityApi,
  plannedCertificatesApi
} from '../../../httpclient';

interface NewActivityForm {
  titleNo: string;
  titleEn: string;
  descriptionNo: string;
  descriptionEn: string;
  subjectAreaUri: string;
  projectCode: string;
  checkpointType: string;
  isCritical: string;
  documentedIn: string;
  certificateUris: string[];
  referenceToAuthority: string;
}

const NewActivity: React.FC = () => {
  const intl = useIntl();

  const [defaultSubjectArea] = useState(localStorage.getItem('newActivityCurrentSubjectarea'));

  const { projectuid } = useParams() as {
    projectuid: string;
  };

  const navigate = useNavigate();

  const onSuccess = () => {
    localStorage.setItem('newActivityCurrentSubjectarea', formik.values.subjectAreaUri);

    navigate(
      generatePath(Routes.vesselWorkspace, {
        tab: 'activitylist',
        projectuid
      })
    );
  };

  const helpWithFormatting = () => (
    <StyledTextLink
      text={intl.formatMessage({
        id: 'activityadmin.button.helptoformating'
      })}
      onClick={() => window.open('https://sdir.atlassian.net/wiki/x/BAAem', '_blank')}
    />
  );

  const criticalityOptions = [
    {
      label: intl.formatMessage({ id: 'newactivity.radio.criticality.critical' }),
      value: 'true'
    },
    {
      label: intl.formatMessage({ id: 'newactivity.radio.criticality.notcritical' }),
      value: 'false'
    }
  ];

  const { result: project, error: projectError, loading: projectLoading } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid),
    callApiOnInit: true
  });

  const { callApi: createActivity, loading: createActivityLoading } = useApi({
    apiFunction: payload => activityApi.v1ActivityCreateprojectactivityCommandPost(payload),
    successCallback: onSuccess
  });

  const {
    result: checkpointOptions,
    error: checkpointOptionsError,
    loading: checkpointOptionsLoading
  } = useApi({
    apiFunction: () => checkpointsApi.v1CheckpointsGetcheckpointoptionsGet(),
    callApiOnInit: true
  });

  const {
    result: plannedVesselCertificates,
    error: plannedVesselCertificatesError,
    loading: plannedCertificatesLoading
  } = useApi({
    apiFunction: () =>
      plannedCertificatesApi.v1PlannedcertificatesGetplannedprojectcertificatesProjectUidGet(
        projectuid
      ),
    callApiOnInit: true
  });

  const certificatesAvailableOnVessel = useMemo(() => {
    if (checkpointOptions?.certificates?.length && plannedVesselCertificates) {
      return checkpointOptions.certificates.filter(
        cert =>
          plannedVesselCertificates.mainCertificates?.some(
            mc => mc.isActive && mc.uri === cert.value
          ) ||
          plannedVesselCertificates.otherCertificates?.some(
            oc => oc.isActive && oc.uri === cert.value
          )
      );
    }

    return [];
  }, [checkpointOptions?.certificates, plannedVesselCertificates]);

  const validationSchema = Yup.object({
    descriptionNo: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    descriptionEn: Yup.string(),
    titleNo: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    titleEn: Yup.string(),
    subjectAreaUri: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    projectCode: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    checkpointType: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    documentedIn: Yup.string().when('checkpointType', {
      is: checkpointType => checkpointType && checkpointType.includes('DocumentControl'),
      then: schema =>
        schema
          .transform((v, o) => (o === '' ? null : v))
          .required(intl.formatMessage({ id: 'common.requiredfield' }))
    }),
    certificateUris: Yup.array().of(Yup.string()),
    isCritical: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    referenceToAuthority: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' }))
  });

  const navigateBack = () => {
    navigate(
      generatePath(Routes.vesselWorkspace, {
        tab: 'activitylist',
        projectuid
      })
    );
  };

  const formik = useFormik<NewActivityForm>({
    initialValues: {
      titleNo: '',
      titleEn: '',
      descriptionNo: '',
      descriptionEn: '',
      subjectAreaUri: defaultSubjectArea || '',
      projectCode: '',
      checkpointType: '',
      isCritical: 'false',
      documentedIn: '',
      certificateUris: [],
      referenceToAuthority: ''
    },
    validationSchema,
    onSubmit: values => {
      const payload = {
        projectUid: projectuid,
        titleNo: values.titleNo === '' ? undefined : values.titleNo,
        titleEn: values.titleEn === '' ? undefined : values.titleEn,
        descriptionNo: values.descriptionNo === '' ? undefined : values.descriptionNo,
        descriptionEn: values.descriptionEn === '' ? undefined : values.descriptionEn,
        subjectAreaUri: values.subjectAreaUri === '' ? undefined : values.subjectAreaUri,
        projectCode: values.projectCode === '' ? undefined : values.projectCode,
        checkpointType: values.checkpointType === '' ? undefined : values.checkpointType,
        isCritical: values.isCritical === 'true',
        documentedIn: values.documentedIn === '' ? undefined : values.documentedIn,
        certificateUris: values.certificateUris,
        referenceToAuthority:
          values.referenceToAuthority === '' ? undefined : values.referenceToAuthority
      };

      createActivity(payload);
    }
  });

  const handleCreateNewActivity = () => {
    formik.handleSubmit();
  };

  const mapDtoToSelectOpts = (opts: OptionDto[] | null | undefined) => {
    if (!opts) return [];

    return opts.map(opt => {
      return {
        name: opt.label || '',
        value: opt.value || ''
      };
    });
  };

  const handleMulitSelectChange = (certificateUri: string) => {
    const excistingCertificateUris = formik.values.certificateUris;
    let newCertificateUriList: string[] = [];
    if (excistingCertificateUris.includes(certificateUri)) {
      newCertificateUriList = excistingCertificateUris.filter(item => item !== certificateUri);
    } else {
      newCertificateUriList = [...excistingCertificateUris, certificateUri];
    }
    formik.setFieldValue('certificateUris', newCertificateUriList);
  };

  const handleCheckpointTypeChange = newValue => {
    if (!newValue.includes('DocumentControl') && formik.values.documentedIn) {
      formik.setFieldValue('documentedIn', '');
    }

    formik.setFieldValue('checkpointType', newValue);
  };

  return (
    <Page
      error={projectError || checkpointOptionsError || plannedVesselCertificatesError}
      loading={projectLoading || checkpointOptionsLoading || plannedCertificatesLoading}
    >
      <VesselContainer>
        <VesselName>{project?.name}</VesselName>
        <AlignmentWrapper>
          <DataCard>
            <Container>
              <Title>{`${intl.formatMessage({ id: 'newactivity.title' })} ${project?.name}`}</Title>
              <p> {intl.formatMessage({ id: 'newactivity.subtitle' })}</p>
              <SelectContainer>
                <Select
                  searchable
                  searchOnlyOnName
                  defaultVal={intl.formatMessage({
                    id: 'newactivity.select.subjectarea.placeholder'
                  })}
                  options={mapDtoToSelectOpts(checkpointOptions?.subjectArea).sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  handleChange={val => formik.setFieldValue('subjectAreaUri', val)}
                  value={formik.values.subjectAreaUri}
                  error={formik.touched.subjectAreaUri ? formik.errors.subjectAreaUri : ''}
                />
                <Select
                  searchable
                  searchOnlyOnName
                  defaultVal={intl.formatMessage({
                    id: 'newactivity.select.checkpointtype.placeholder'
                  })}
                  options={mapDtoToSelectOpts(checkpointOptions?.checkpointType)}
                  handleChange={val => {
                    handleCheckpointTypeChange(val);
                  }}
                  value={formik.values.checkpointType}
                  error={formik.touched.checkpointType ? formik.errors.checkpointType : ''}
                />
                <Select
                  searchable
                  searchOnlyOnName
                  defaultVal={intl.formatMessage({
                    id: 'newactivity.select.projectcode.placeholder'
                  })}
                  options={mapDtoToSelectOpts(checkpointOptions?.projectCode)}
                  handleChange={val => formik.setFieldValue('projectCode', val)}
                  value={formik.values.projectCode}
                  error={formik.touched.projectCode ? formik.errors.projectCode : ''}
                />
                <Select
                  searchable
                  searchOnlyOnName
                  disabled={
                    !formik.values.checkpointType ||
                    !formik.values.checkpointType.includes('DocumentControl')
                  }
                  defaultVal={intl.formatMessage({
                    id: 'newactivity.select.documentedin.placeholder'
                  })}
                  options={mapDtoToSelectOpts(checkpointOptions?.documentedIn)}
                  handleChange={val => formik.setFieldValue('documentedIn', val)}
                  value={formik.values.documentedIn}
                  error={formik.touched.documentedIn ? formik.errors.documentedIn : ''}
                />
                <MultiSelect
                  idPrefix="certificateUris"
                  searchable
                  searchOnlyOnName
                  defaultVal={intl.formatMessage({
                    id: 'newactivity.select.certificate.placeholder'
                  })}
                  options={mapDtoToSelectOpts(certificatesAvailableOnVessel)}
                  handleChange={val => handleMulitSelectChange(val)}
                  value={formik.values.certificateUris}
                  error={
                    formik.touched.certificateUris && formik.errors.certificateUris
                      ? formik.errors.certificateUris.toString()
                      : ''
                  }
                />
                <div>
                  <Label>{intl.formatMessage({ id: 'newactivity.radio.iscritical.label' })}</Label>
                  <SdirRadioButtonGroup
                    as={CustomRadioGroupContainer}
                    options={criticalityOptions}
                    onChange={e => formik.setFieldValue('isCritical', e.target.value)}
                    value={formik.values.isCritical}
                    name="Kritikalitet"
                  />
                  {formik.touched.isCritical && formik.errors.isCritical && (
                    <ErrorLabel mt="1rem">{formik.errors.isCritical}</ErrorLabel>
                  )}
                </div>
              </SelectContainer>
              <InputContainer>
                <SdirInput
                  placeholder={intl.formatMessage({ id: 'newactivity.input.titleno.placeholder' })}
                  value={formik.values.titleNo}
                  onChange={e => formik.setFieldValue('titleNo', e.currentTarget.value)}
                  error={formik.touched.titleNo ? formik.errors.titleNo : ''}
                />
                {helpWithFormatting()}
                <MarkupTextarea
                  id="descriptionNo"
                  placeholder={intl.formatMessage({
                    id: 'newactivity.input.descriptionno.placeholder'
                  })}
                  contentValue={formik.values.descriptionNo}
                  onChange={e => formik.setFieldValue('descriptionNo', e.target.value)}
                  error={formik.touched.descriptionNo ? formik.errors.descriptionNo : ''}
                  rows={10}
                />
                <SdirInput
                  placeholder={intl.formatMessage({ id: 'newactivity.input.titleen.placeholder' })}
                  value={formik.values.titleEn}
                  onChange={e => formik.setFieldValue('titleEn', e.currentTarget.value)}
                  error={formik.touched.titleEn ? formik.errors.titleEn : ''}
                />
                {helpWithFormatting()}
                <MarkupTextarea
                  id="descriptionEn"
                  placeholder={intl.formatMessage({
                    id: 'newactivity.input.descriptionen.placeholder'
                  })}
                  contentValue={formik.values.descriptionEn}
                  onChange={e => formik.setFieldValue('descriptionEn', e.target.value)}
                  error={formik.touched.descriptionEn ? formik.errors.descriptionEn : ''}
                  rows={10}
                />
                <Textarea
                  id="referenceToAuthority"
                  placeholder={intl.formatMessage({
                    id: 'newactivity.input.referenceToAuthority.placeholder'
                  })}
                  contentValue={formik.values.referenceToAuthority}
                  onChange={e => formik.setFieldValue('referenceToAuthority', e.target.value)}
                  error={
                    formik.touched.referenceToAuthority ? formik.errors.referenceToAuthority : ''
                  }
                  rows={5}
                />
              </InputContainer>
              <ButtonContainer>
                <SdirButton
                  text={intl.formatMessage({ id: 'common.cancel' })}
                  onClick={navigateBack}
                />
                <SdirButton
                  disabled={createActivityLoading}
                  type="primary"
                  text={intl.formatMessage({ id: 'newactivity.createactivitybuttonlabel' })}
                  onClick={handleCreateNewActivity}
                />
              </ButtonContainer>
            </Container>
          </DataCard>
        </AlignmentWrapper>
      </VesselContainer>
    </Page>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  margin-bottom: 6prem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  margin: 0;
`;

const AlignmentWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 5rem;
`;

const VesselContainer = styled.section`
  width: 100%;
  margin: 2rem auto 0 auto;
  max-width: 120rem;
`;

const Container = styled.div`
  padding: 5rem;
  width: 100%;

  textarea {
    margin-bottom: 2rem;
  }
`;

const Label = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.font.text1};
  margin-bottom: 1rem;
  opacity: 1;
`;

const CustomRadioGroupContainer = styled.div`
  display: flex;

  [data-id='base-item'] {
    padding-right: 4rem;
  }
`;

const SelectContainer = styled.div`
  margin: 3rem 0 5rem 0;
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-columns: 50% 50%;
  align-items: center;
`;

const VesselName = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.primary5};
  font-size: 2rem;
  margin-bottom: 4rem;
`;

const ErrorLabel = styled.label<{ mt?: string; mb?: string; ml?: string }>`
  display: block;
  color: ${({ theme }) => theme.colors.alert.errorDark};
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 1.75rem;

  ${({ mt }) => mt && `margin-top: ${mt}`};
  ${({ ml }) => ml && `margin-left: ${ml}`};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
`;

const StyledTextLink = styled(TextLink)`
  margin-bottom: 1rem;
  display: inline-block;
  width: 100%;
  text-align: right;
}
`;

export default NewActivity;
