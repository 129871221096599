import React, { useContext, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SdirIconButton, SdirModal, TextLink, SdiTrash, SdiClock } from '@sdir/sds';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Comments from './Comments';
import { AddMoreModal } from '../Organisms';
import ButtonNavigation from './ButtonNavigation';

interface TileProps {
  name: string;
  details: string;
  status: string;
  tileIndex: number;
  expandTileView: boolean;
  setSelectTile: any;
}

const Tile: React.FC<TileProps> = ({
  name,
  details,
  status,
  expandTileView,
  setSelectTile,
  tileIndex
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen, setModalOpen]);

  return (
    <>
      {expandTileView ? (
        <BigTileWrapper>
          <CustomerDetails>
            <InfoBlock>
              <LabelText> {intl.formatMessage({ id: 'dashboard.myTask.customer' })}</LabelText>
              <NameLink>
                <Link to="/person/7">{name}</Link>
              </NameLink>
            </InfoBlock>
            <InfoBlockRight>
              <LabelText> {intl.formatMessage({ id: 'dashboard.myTask.deadline' })}</LabelText>
              <Timer>
                <SdiClock size="s" />
                <TimeDetails>-12t:5m</TimeDetails>
              </Timer>
            </InfoBlockRight>
          </CustomerDetails>
          <CustomerDetails>
            <InfoBlock>
              <LabelText> {intl.formatMessage({ id: 'dashboard.myTask.type' })}</LabelText>
              <TypeDetails>{details}</TypeDetails>
            </InfoBlock>
            <InfoBlockRight>
              <LabelText> {intl.formatMessage({ id: 'dashboard.myTask.processing' })}</LabelText>
              <Timer>
                <SdiClock size="s" />
                <TimeDetails>2d:12t:5m</TimeDetails>
              </Timer>
            </InfoBlockRight>
          </CustomerDetails>

          <InfoBlock>
            <LabelText> {intl.formatMessage({ id: 'dashboard.myTask.projectStatus' })}</LabelText>
            <StatusDetail>{status}</StatusDetail>
          </InfoBlock>
          <InfoBlock>
            <LabelText>{intl.formatMessage({ id: 'dashboard.myTask.officer' })}</LabelText>
            <Officer>
              <StatusDetail>Espen Opsahl </StatusDetail>
              <ButtonLink
                id="changeOfficer"
                text="Bytt sakbehandler"
                size="md"
                click={() => setModalOpen(!modalOpen)}
              />
            </Officer>
          </InfoBlock>
          <InfoBlock>
            <LabelText>{intl.formatMessage({ id: 'dashboard.myTask.team' })}</LabelText>
            <div>
              <SdirIconButton icon={SdiTrash} text="Espen Opsahl" />
            </div>
            <div>
              <SdirIconButton icon={SdiTrash} text="Mona Hansen" color={theme.colors.font.PBM} />
            </div>

            <AddMoreLink id="addTeam" text="Legg til flere" size="md" click={toggleModal} />
          </InfoBlock>
          <InfoBlock>
            <LabelText>{intl.formatMessage({ id: 'dashboard.myTask.comment' })}</LabelText>
            <Comments />
          </InfoBlock>

          <ButtonGroup>
            <ButtonNavigation
              cancelLinkText={intl.formatMessage({ id: 'common.cancel' })}
              nextButtonText="Behandle sak"
              cancelLinkOnClick={() => setSelectTile(-1)}
            />
          </ButtonGroup>
          <SdirModal
            width="520px"
            visible={modalOpen}
            okText={intl.formatMessage({ id: 'common.add' })}
            cancelText={intl.formatMessage({ id: 'common.cancel' })}
            onOK={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
          >
            <AddMoreModal />
          </SdirModal>
        </BigTileWrapper>
      ) : (
        <SmallTileWrapper>
          <div
            id="name"
            role="button"
            tabIndex={0}
            onClick={() => setSelectTile(tileIndex)}
            onKeyPress={() => setSelectTile(tileIndex)}
          >
            <CustomerDetails>
              <Name>{name}</Name>
              <Timer>
                <SdiClock size="s" />
                <TimeDetails>2d:12t:5m</TimeDetails>
              </Timer>
            </CustomerDetails>
            <Detail>{details}</Detail>
            <Status>{status}</Status>
          </div>
        </SmallTileWrapper>
      )}
    </>
  );
};

export default Tile;

const SmallTileWrapper = styled.div`
  display: grid;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 20px;
  background-color: white;
`;

const BigTileWrapper = styled.div`
  display: grid;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 20px;
  background-color: white;
  grid-row-end: span 4;
  grid-column-end: span 2;
`;

const CustomerDetails = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const Timer = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  grid-gap: 1rem;
  font-size: 1.4rem;
  grid-template-columns: auto auto;
`;
const Officer = styled.div`
  display: grid;
  align-items: center;
  justify-content: left;
  grid-gap: 1rem;
  font-size: 1.4rem;
  grid-template-columns: auto auto;
`;

const Name = styled.div`
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
`;

const NameLink = styled.div`
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

const LabelText = styled.label`
  font-size: 1.4rem;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.font.text1};
`;

const Detail = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.background.brand};
  font-weight: 700;
`;

const TypeDetails = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;
const TimeDetails = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const ButtonGroup = styled.div`
  width: 100%;
  font-size: 1.4rem;
`;

const AddMoreLink = styled(TextLink)`
  text-align: left;
  font-size: 1.4rem;
  margin-top: 1.2rem;
  font-weight: bold;
`;
const ButtonLink = styled(TextLink)`
  text-align: left;
  font-size: 1.4rem;
  font-weight: bold;
`;

const InfoBlock = styled.div`
  margin-bottom: 2rem;
`;

const InfoBlockRight = styled.div`
  margin-bottom: 2rem;
  text-align: right;
`;
const Status = styled.div`
  font-size: 1.2rem;
  margin-top: 20px;
`;

const StatusDetail = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;
