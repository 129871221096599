import React from 'react';
import styled from 'styled-components';

export interface InfoInterface {
  text: string | number;
}

const Info: React.FC<InfoInterface> = ({ text }) => {
  return <Container>{text}</Container>;
};

const Container = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.font.text};
  font-weight: bold;
`;

export default Info;
