/* eslint-disable @typescript-eslint/indent */
import React, { useEffect } from 'react';
import { DataCard } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import {
  EventType,
  ReactionCreatedDomainEvent
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { MessageList } from '@sdir/blueprint.et/lib/components';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import ReplyToApplication from '../../Organisms/ReplyBox/ReplyToApplication';
import Page from '../../Templates/Page';
import { activityApi, getDocumentApi, projectApi, reactionApi } from '../../../httpclient';
import { ActivityDetailType } from '../../../types/enums';
import SendReaction from '../../Organisms/ReplyBox/SendReaction';
import EvaluateReaction from '../../Organisms/ReplyBox/EvaluateReaction';
import useETCaseworker from '../../../services/hooks/useETCaseworker';

const ActivityDetails: React.FC = () => {
  const intl = useIntl();
  const { projectuid, activityuid, type } = useParams() as {
    projectuid: string;
    activityuid: string;
    type: string;
  };
  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid });

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  const { getEtCaseWorkers } = useETCaseworker();

  useEffect(() => {
    if (!etCaseWorkers && !etCaseWorkersLoading) {
      getEtCaseWorkers();
    }
  }, []);

  const {
    result: activityDetails,
    error,
    loading: activityDetailsLoading,
    callApi: getActivityDetails
  } = useApi({
    apiFunction: () =>
      activityApi.v2ActivityGetprojectactivitydetailsv2ActivityUidGet(projectuid, activityuid),
    callApiOnInit: true
  });

  const { result: vessel, loading: vesselLoading } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid),
    callApiOnInit: true
  });

  const { result: reaction, loading: reactionLoading, callApi: getReactionDetails } = useApi({
    apiFunction: reactionUid =>
      reactionApi.v2ReactionGetreactionv2ReactionuidGet(projectuid, activityuid, reactionUid)
  });

  const { result: document, loading: documentLoading, callApi: getDocument } = useApi({
    apiFunction: documentId =>
      getDocumentApi.v1GetdocumentGetdocumentDocumentuidGet(projectuid, documentId)
  });

  useEffect(() => {
    if (activityDetails?.events?.length) {
      const event: ReactionCreatedDomainEvent =
        activityDetails.events[activityDetails.events.length - 1];
      event.reactionId && getReactionDetails(event.reactionId);
    }

    if (activityDetails?.documentationId) {
      getDocument(activityDetails?.documentationId);
    }
  }, [activityDetails]);

  const hasMessages = activityDetails?.events && activityDetails.events.length > 0;

  const activityLog = () => (
    <AlignmentWrapper>
      <DataCard spacing="large">
        <CardAccordionContainer>
          <WidthWrapper>
            <DialogTitle>{intl.formatMessage({ id: 'activitycard.activitylog' })} </DialogTitle>
            <MessageList events={activityDetails?.events} downloadAttachment={downloadByFilename} />
          </WidthWrapper>
          {!hasMessages && (
            <Text>
              {intl.formatMessage({ id: 'activitycard.messagesandreactions.nomessages' })}
            </Text>
          )}
        </CardAccordionContainer>
      </DataCard>
    </AlignmentWrapper>
  );

  return (
    <Page
      error={error}
      loading={activityDetailsLoading || vesselLoading || reactionLoading || documentLoading}
    >
      {type === ActivityDetailType.notrelevant || type === ActivityDetailType.exemption ? (
        <VesselContainer>
          <ReplyToApplication
            vesselName={vessel?.name || ''}
            activity={activityDetails}
            document={document}
          >
            {activityLog()}
          </ReplyToApplication>
        </VesselContainer>
      ) : (
        <>
          {type === ActivityDetailType.reaction &&
          vessel &&
          activityDetails?.events?.length &&
          activityDetails?.events[activityDetails?.events?.length - 1].eventType ===
            EventType.ReactionReplied &&
          !activityDetails.reactionDraft ? (
            <EvaluateReaction
              vesselName={vessel.name || ''}
              reaction={reaction}
              activity={activityDetails}
              getActivityDetail={getActivityDetails}
              document={document}
            >
              {activityLog()}
            </EvaluateReaction>
          ) : (
            <>
              <SendReaction
                vesselName={vessel?.name || ''}
                defaultEditMode={
                  Boolean(activityDetails?.reactionDraft) &&
                  Boolean(
                    activityDetails?.events?.length &&
                      activityDetails.events[activityDetails.events.length - 1].eventType ===
                        EventType.ReactionReplied
                  )
                }
                reaction={
                  activityDetails?.events?.length &&
                  activityDetails?.events[activityDetails?.events?.length - 1].eventType ===
                    EventType.ReactionCreated
                    ? reaction
                    : activityDetails?.reactionDraft
                }
                getActivityDetail={getActivityDetails}
                activity={activityDetails}
                document={document}
                downloadByFilename={downloadByFilename}
              >
                {activityLog()}
              </SendReaction>
            </>
          )}
        </>
      )}
    </Page>
  );
};

const VesselContainer = styled.section`
  width: 100%;
  margin: 2rem auto 0 auto;
  max-width: 120rem;
`;

const WidthWrapper = styled.div`
  width: 100%;
`;

const AlignmentWrapper = styled.div`
  position: relative;
  z-index: 1;
  top: -4rem;
  margin-top: 5rem;
`;

const CardAccordionContainer = styled.div`
  min-height: 30rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  padding: 4rem;
`;

const Text = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DialogTitle = styled.h2`
  margin-bottom: 2rem;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
`;

export default ActivityDetails;
