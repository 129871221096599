import { DataCard, Loader } from '@sdir/sds';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { AlertType } from '../../types/enums';
import AlertMessage from './AlertMessage';

const TableCardContainer: React.FC<{
  title: string;
  error: string;
  data: any[];
  linkText?: string;
  emptyText?: string;
  expandable?: boolean;
  onExpandClicked?: () => void;
  loading: boolean;
  children: any;
}> = ({
  title,
  error,
  data,
  expandable = false,
  emptyText,
  linkText,
  loading,
  onExpandClicked,
  children
}) => {
  const intl = useIntl();
  return (
    <Container
      title={title}
      expandable={expandable}
      onExpandClicked={onExpandClicked}
      linkText={linkText}
    >
      {loading ? (
        <NoContentContainer>
          <Loader />
        </NoContentContainer>
      ) : error ? (
        <AlertMessage message={error} alert={AlertType.Error} />
      ) : data?.length > 0 ? (
        children
      ) : (
        data?.length === 0 && (
          <NoContentContainer>
            {emptyText || intl.formatMessage({ id: 'table.no.data.found' })}
          </NoContentContainer>
        )
      )}
    </Container>
  );
};

export default TableCardContainer;

const Container = styled(DataCard)`
  margin-top: 3rem;
`;

const NoContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
`;
