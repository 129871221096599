import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SdirModal, SearchBar, TextLink, SdirButton } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { GetProjectCaseWorkersResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { assignTaskToCaseWorkerApi } from '../../../httpclient';

interface ChangeCasehandlerModalProps {
  activityId: string;
  sdirTeam?: GetProjectCaseWorkersResult;
  display: boolean;
  onCancelClick: () => void;
  onSubmit: () => void;
}

export interface User {
  name: string;
  userId: string;
}

const ChangeCasehandlerModal: React.FC<ChangeCasehandlerModalProps> = ({
  display,
  onCancelClick,
  activityId,
  sdirTeam,
  onSubmit
}) => {
  const intl = useIntl();
  const { setError } = useToast();

  const [users, setUsers] = useState<any>([]);
  const [usersMatched, setUsersMatches] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<User>();

  useEffect(() => {
    const list = [] as User[];
    sdirTeam?.coordinators?.forEach(x => {
      if (x.name && x.caseWorkerUid) {
        list.push({ name: x.name, userId: x.caseWorkerUid });
      }
    });
    sdirTeam?.team?.forEach(x => {
      if (x.name && x.caseWorkerUid) {
        list.push({ name: x.name, userId: x.caseWorkerUid });
      }
    });
    setUsers(list);
  }, [sdirTeam]);

  const { callApi: assignTaskToCaseworker } = useApi({
    apiFunction: userId =>
      assignTaskToCaseWorkerApi.v1AssigntasktocaseworkerAssigntasktocaseworkerUserIdPut(
        activityId,
        userId
      ),
    successCallback: () => {
      formik.resetForm();
      onSubmit();
    },
    errorCallback: err => {
      if (err.length > 0)
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'sdirteam' })}: ${err}`);
    }
  });

  const searchItems = useCallback(
    async searchText => {
      setUsersMatches([]);
      if (searchText) {
        setUsersMatches(
          users.filter(x => x.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
        );
      } else {
        setUsersMatches(users);
      }
    },
    [users]
  );

  const formik = useFormik({
    initialValues: {
      caseWorkerUid: undefined
    },
    onSubmit: val => assignTaskToCaseworker(val.caseWorkerUid),
    validationSchema: Yup.object({
      caseWorkerUid: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
    })
  });

  const setCaseHandler = user => {
    formik.setFieldValue('caseWorkerUid', user.userId);
  };

  const handleClose = useCallback(() => {
    formik.resetForm();
    setSearchValue({ name: '', userId: '' });
    setUsersMatches([]);
    onCancelClick();
  }, [formik, onCancelClick]);

  const modalProps = useMemo(
    () => ({
      visible: display,
      onCancel: handleClose,
      width: '440px',
      closeButton: false
    }),
    [display, handleClose]
  );

  return display ? (
    <SdirModal {...modalProps}>
      <Article>
        <Header>{intl.formatMessage({ id: 'changecaseworker.modal.header' })}</Header>
        <SeachArea>
          <SearchBar
            searchResults={usersMatched.map(match => match.name)}
            searchCallback={searchItems}
            emptyResultsContent={intl.formatMessage({ id: 'common.nosearchresult' })}
            onSearchResultClick={value => {
              setSearchValue(users.find(user => user.name === value));
              setCaseHandler(users.find(user => user.name === value));
            }}
            value={searchValue?.name}
            placeholder={intl.formatMessage({ id: 'changecaseworker.modal.search.placeholder' })}
          />
        </SeachArea>
        <Result>
          <Title>{intl.formatMessage({ id: 'changecaseworker.modal.subjectarea' })}</Title>
          <Info>{intl.formatMessage({ id: 'changecaseworker.modal.nameofsubjectarea' })}</Info>
        </Result>
        <Result>
          <Title>{intl.formatMessage({ id: 'changecaseworker.modal.task' })}</Title>
          <Info>{intl.formatMessage({ id: 'changecaseworker.modal.documentcontrol' })}</Info>
        </Result>
        <ButtonGroup>
          <TextLink
            style={{ fontSize: '2rem' }}
            text={intl.formatMessage({ id: 'common.cancel' })}
            click={() => handleClose()}
          />
          <SdirButton
            htmlType="button"
            type="primary"
            text={intl.formatMessage({ id: 'changecaseworker.modal.confirm' })}
            onClick={formik.handleSubmit as any}
            disabled={!formik.values.caseWorkerUid}
          />
        </ButtonGroup>
      </Article>
    </SdirModal>
  ) : null;
};

const Article = styled.article``;

const Result = styled.div``;

const ButtonGroup = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 0 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.font.text85};
  font-family: ${({ theme }) => theme.fonts.stfMedium};
`;

const Info = styled.div`
  margin-left: 2.5rem;
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBookItalic};
  margin-bottom: 6rem;
`;

const SeachArea = styled.div`
  margin-bottom: 5rem;
  width: 52rem;
`;

const Header = styled.h1`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.font.primary.blue};
`;

export default ChangeCasehandlerModal;
