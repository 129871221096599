import React, { useState, useEffect, useMemo } from 'react';
import {
  MultiSelect,
  SdirCheckbox,
  SdirDatePicker,
  SdirInput,
  SdirRadioButtonGroup
} from '@sdir/sds';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ErrorNotice } from '@sdir/blueprint.et/lib/components';
import { GetCheckpointOptionsResultV2 } from '@sdir/httpclient/lib/clients/core/requirement';

interface Props {
  checkpointOptions: GetCheckpointOptionsResultV2 | undefined;
  vessels: {
    value: string;
    name: string;
  }[];
}

enum ReadWriteAccess {
  READ = 'READ',
  WRITE = 'WRITE'
}

enum ActivitiesSubjectareas {
  ALL = 'ALL',
  LIMITED = 'LIMITED'
}

const UserAdministrationAddModalContent: React.FC<Props> = ({ vessels, checkpointOptions }) => {
  const fm = useIntl().formatMessage;
  const [emailInput, setEmailInput] = useState('');
  const [checkedVesselUids, setCheckedVesselUids] = useState<string[]>([]);
  const [createsurveyChecked, setCreatesurveyChecked] = useState<boolean>(false);
  const [constructionNoticeChecked, setConstructionNoticeChecked] = useState<boolean>(false);
  const [activitiesChecked, setActivitiesChecked] = useState<boolean>(false);
  const [useradminChecked, setUseradminChecked] = useState<boolean>(false);
  const [accidentsChecked, setAccidentsChecked] = useState<boolean>(false);
  const [inspectionChecked, setInspectionChecked] = useState<boolean>(false);
  const [createsurveyAcces, setCreatesurveyAcces] = useState<ReadWriteAccess.WRITE | undefined>();
  const [constructionNoticeAcces, setConstructionNoticeAcces] = useState<
    ReadWriteAccess.READ | ReadWriteAccess.WRITE | undefined
  >();
  const [activitiesAcces, setActivitiesAcces] = useState<
    ReadWriteAccess.READ | ReadWriteAccess.WRITE | undefined
  >();
  const [useradminAcces, setUseradminAcces] = useState<
    ReadWriteAccess.READ | ReadWriteAccess.WRITE | undefined
  >();
  const [accidentsAcces, setAccidentsAcces] = useState<
    ReadWriteAccess.READ | ReadWriteAccess.WRITE | undefined
  >();
  const [inspectionAcces, setInspectionAcces] = useState<
    ReadWriteAccess.READ | ReadWriteAccess.WRITE | undefined
  >();
  const [activitiesSubjectareas, setActivitiesSubjectareas] = useState<
    ActivitiesSubjectareas.ALL | ActivitiesSubjectareas.LIMITED | undefined
  >();
  const [limitedSubjectareas, setLimitedSubjectareas] = useState<string[]>([]);
  const [timeframeActivities, setTimeframeActivities] = useState<boolean>(false);
  const [timeframeFromDate, setTimeframeFromDate] = useState<Date | null | undefined>();
  const [timeframeToDate, setTimeframeToDate] = useState<Date | null | undefined>();

  const readOption = {
    label: fm({ id: 'useradmin.modal.read' }),
    value: ReadWriteAccess.READ
  };
  const writeOption = {
    label: fm({ id: 'useradmin.modal.readandwrite' }),
    value: ReadWriteAccess.WRITE
  };

  useEffect(() => {
    if (createsurveyChecked) {
      setCreatesurveyAcces(ReadWriteAccess.WRITE);
    } else {
      setCreatesurveyAcces(undefined);
    }
  }, [createsurveyChecked]);

  useEffect(() => {
    if (constructionNoticeChecked) {
      setConstructionNoticeAcces(ReadWriteAccess.READ);
    } else {
      setConstructionNoticeAcces(undefined);
    }
  }, [constructionNoticeChecked]);

  useEffect(() => {
    if (activitiesChecked) {
      setActivitiesAcces(ReadWriteAccess.READ);
      setActivitiesSubjectareas(ActivitiesSubjectareas.ALL);
    } else {
      setActivitiesAcces(undefined);
      setActivitiesSubjectareas(undefined);
      setLimitedSubjectareas([]);
      setTimeframeActivities(false);
    }
  }, [activitiesChecked]);

  useEffect(() => {
    if (activitiesSubjectareas === ActivitiesSubjectareas.ALL) {
      setLimitedSubjectareas([]);
    }
  }, [activitiesSubjectareas]);

  useEffect(() => {
    if (!timeframeActivities) {
      setTimeframeFromDate(undefined);
      setTimeframeToDate(undefined);
    }
  }, [timeframeActivities]);

  useEffect(() => {
    if (useradminChecked) {
      setUseradminAcces(ReadWriteAccess.READ);
    } else {
      setUseradminAcces(undefined);
    }
  }, [useradminChecked]);

  useEffect(() => {
    if (accidentsChecked) {
      setAccidentsAcces(ReadWriteAccess.READ);
    } else {
      setAccidentsAcces(undefined);
    }
  }, [accidentsChecked]);

  useEffect(() => {
    if (inspectionChecked) {
      setInspectionAcces(ReadWriteAccess.READ);
    } else {
      setInspectionAcces(undefined);
    }
  }, [inspectionChecked]);

  const handleVesselCheckboxClicked = vesselUid => {
    if (checkedVesselUids.includes(vesselUid)) {
      setCheckedVesselUids(checkedVesselUids.filter(uids => uids !== vesselUid));
    } else {
      setCheckedVesselUids([...checkedVesselUids, vesselUid]);
    }
  };

  const handleAddRemoveAll = () => {
    if (checkedVesselUids.length === vessels.length) {
      setCheckedVesselUids([]);
    } else {
      setCheckedVesselUids(vessels.map(v => v.value));
    }
  };

  const validEmail = useMemo(() => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(emailInput);
  }, [emailInput]);

  return (
    <>
      <Header>{fm({ id: 'useradmin.modal.invite.header' })}</Header>
      <InputWrapper>
        <SdirInput
          placeholder={fm({ id: 'useradmin.modal.invite.email' })}
          value={emailInput}
          onChange={e => setEmailInput(e.currentTarget.value)}
        />
        {emailInput && !validEmail && <ErrorNotice text={fm({ id: 'common.error.email' })} />}
      </InputWrapper>
      <Content>
        <LeftSection>
          <Subheader>{fm({ id: 'useradmin.modal.invite.access.header' })}</Subheader>
          <ScrollWrapper>
            <CheckboxWrapper>
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.createsurvey' })}
                onClick={() => setCreatesurveyChecked(!createsurveyChecked)}
                checked={createsurveyChecked}
              />
              {createsurveyChecked && (
                <IndentWrapper>
                  <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[writeOption]}
                    onChange={e => setCreatesurveyAcces(e.target.value as ReadWriteAccess.WRITE)}
                    value={createsurveyAcces ?? ReadWriteAccess.WRITE}
                    name=""
                  />
                </IndentWrapper>
              )}
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.constructionnotice' })}
                onClick={() => setConstructionNoticeChecked(!constructionNoticeChecked)}
                checked={constructionNoticeChecked}
              />
              {constructionNoticeChecked && (
                <IndentWrapper>
                  <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[writeOption, readOption]}
                    onChange={e => setConstructionNoticeAcces(e.target.value as ReadWriteAccess)}
                    value={constructionNoticeAcces ?? ReadWriteAccess.READ}
                    name=""
                  />
                </IndentWrapper>
              )}
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.activities' })}
                onClick={() => setActivitiesChecked(!activitiesChecked)}
                checked={activitiesChecked}
              />
              {activitiesChecked && (
                <>
                  <IndentWrapper>
                    <SdirRadioButtonGroup
                      as={CustomContainer}
                      options={[writeOption, readOption]}
                      onChange={e => setActivitiesAcces(e.target.value as ReadWriteAccess)}
                      value={activitiesAcces ?? ReadWriteAccess.READ}
                      name=""
                    />
                  </IndentWrapper>
                  <IndentWrapper>
                    <SdirRadioButtonGroup
                      as={CustomContainer}
                      options={[
                        {
                          value: ActivitiesSubjectareas.ALL,
                          label: fm({ id: 'useradmin.modal.subjectarea.all' })
                        },
                        {
                          value: ActivitiesSubjectareas.LIMITED,
                          label: fm({ id: 'useradmin.modal.subjectarea.limited' })
                        }
                      ]}
                      onChange={e =>
                        setActivitiesSubjectareas(e.target.value as ActivitiesSubjectareas)
                      }
                      value={activitiesSubjectareas ?? ActivitiesSubjectareas.ALL}
                      name=""
                    />
                    {activitiesSubjectareas === ActivitiesSubjectareas.LIMITED && (
                      <SelectWrapper>
                        <MultiSelect
                          defaultVal={fm({
                            id: 'useradmin.modal.subjectarea.placeholder'
                          })}
                          handleChange={subjectarea => {
                            const alreadyExists =
                              limitedSubjectareas.findIndex(el => el === subjectarea) >= 0;
                            if (alreadyExists) {
                              const newState = limitedSubjectareas.filter(el => el !== subjectarea);
                              setLimitedSubjectareas(newState);
                            } else {
                              setLimitedSubjectareas([...limitedSubjectareas, subjectarea]);
                            }
                          }}
                          options={(checkpointOptions?.subjectAreasWithSubnodes ?? []).map(
                            subj => ({
                              value: subj.subjectAreaValue ?? '',
                              name: subj.subjectAreaLabel ?? ''
                            })
                          )}
                          value={limitedSubjectareas}
                        />
                      </SelectWrapper>
                    )}
                    <TimeFrameWrapper>
                      <SdirCheckbox
                        title={fm({ id: 'useradmin.modal.subjectarea.timeframe' })}
                        onClick={() => setTimeframeActivities(!timeframeActivities)}
                        checked={timeframeActivities}
                      />
                      {timeframeActivities && (
                        <DatepickerWrapper>
                          <SdirDatePicker
                            placeholder={fm({
                              id: 'useradmin.modal.subjectarea.timeframe.placeholder'
                            })}
                            dateFormat="dd.MM.yyyy"
                            onChange={v => setTimeframeFromDate(v)}
                            value={timeframeFromDate}
                          />
                          <SdirDatePicker
                            placeholder={fm({
                              id: 'useradmin.modal.subjectarea.timeframe.placeholder'
                            })}
                            dateFormat="dd.MM.yyyy"
                            onChange={v => setTimeframeToDate(v)}
                            value={timeframeToDate}
                          />
                        </DatepickerWrapper>
                      )}
                    </TimeFrameWrapper>
                  </IndentWrapper>
                </>
              )}
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.useradmin' })}
                onClick={() => setUseradminChecked(!useradminChecked)}
                checked={useradminChecked}
              />
              {useradminChecked && (
                <IndentWrapper>
                  <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[writeOption, readOption]}
                    onChange={e => setUseradminAcces(e.target.value as ReadWriteAccess)}
                    value={useradminAcces ?? ReadWriteAccess.READ}
                    name=""
                  />
                </IndentWrapper>
              )}
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.accidents' })}
                onClick={() => setAccidentsChecked(!accidentsChecked)}
                checked={accidentsChecked}
              />
              {accidentsChecked && (
                <IndentWrapper>
                  <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[writeOption, readOption]}
                    onChange={e => setAccidentsAcces(e.target.value as ReadWriteAccess)}
                    value={accidentsAcces ?? ReadWriteAccess.READ}
                    name=""
                  />
                </IndentWrapper>
              )}
              <SdirCheckbox
                title={fm({ id: 'useradmin.modal.invite.inspection' })}
                onClick={() => setInspectionChecked(!inspectionChecked)}
                checked={inspectionChecked}
              />
              {inspectionChecked && (
                <IndentWrapper>
                  <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[writeOption, readOption]}
                    onChange={e => setInspectionAcces(e.target.value as ReadWriteAccess)}
                    value={inspectionAcces ?? ReadWriteAccess.READ}
                    name=""
                  />
                </IndentWrapper>
              )}
            </CheckboxWrapper>
          </ScrollWrapper>
        </LeftSection>
        <RightSection>
          <Subheader>{fm({ id: 'useradmin.modal.invite.vessel.header' })}</Subheader>
          <ScrollWrapper>
            <CheckboxWrapper>
              {vessels.length && (
                <SdirCheckbox
                  title={
                    checkedVesselUids.length === vessels.length
                      ? fm({ id: 'useradmin.modal.invite.vessels.selectnone' })
                      : fm({ id: 'useradmin.modal.invite.vessels.selectall' })
                  }
                  onClick={() => handleAddRemoveAll()}
                  checked={checkedVesselUids.length === vessels.length}
                />
              )}
              {vessels.map(vessel => (
                <SdirCheckbox
                  key={vessel.value}
                  title={vessel.name}
                  onClick={() => handleVesselCheckboxClicked(vessel.value)}
                  checked={checkedVesselUids.includes(vessel.value)}
                />
              ))}
            </CheckboxWrapper>
          </ScrollWrapper>
        </RightSection>
      </Content>
    </>
  );
};

const Content = styled.div`
  margin: 5rem 0;
  display: flex;
  gap: 2rem;
`;

const IndentWrapper = styled.div`
  padding-left: 3rem;
`;

const TimeFrameWrapper = styled.div`
  margin-top: 2rem;
`;
const DatepickerWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 1rem;

  & > div {
    width: 20rem;
  }
`;

const SelectWrapper = styled.div`
  width: 30rem;
`;

const InputWrapper = styled.div`
  width: 50rem;
`;

const LeftSection = styled.div`
  width: 50%;
`;

const RightSection = styled.div`
  width: 50%;
`;

const ScrollWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 0.5rem;
`;

const Header = styled.h2`
  margin-top: 0;
`;

const Subheader = styled.h3`
  margin-top: 0;
`;

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;

  [data-id='base-item'] {
    padding-bottom: 1.5rem;
  }
`;

export default UserAdministrationAddModalContent;
