import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ToggleName } from '../../constants/permissions';
import Dashboard from './Dashboard';
import { InfoTilesPanel } from '../Organisms';
import { ToggleContent } from '../Atoms';

const Home: React.FC = () => {
  const intl = useIntl();
  return (
    <Wrapper data-testid="home">
      <ToggleContent
        toggleName={ToggleName}
        alternativeChildren={<Info>{intl.formatMessage({ id: 'home.mvpplaceholder' })}</Info>}
        showLoader
      >
        <div>
          <InfoTilesPanel />
          <Dashboard />
        </div>
      </ToggleContent>
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  display: grid;
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const Info = styled.div`
  font-weight: bold;
  font-size: 3rem;
  margin: 3rem auto 3rem auto;
`;
