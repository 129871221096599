export enum ViewState {
  GRID,
  LIST
}

export enum SortingDirection {
  NONE,
  ASC,
  DESC
}

export enum AlertType {
  None,
  Success,
  Warning,
  Error
}

export enum Size {
  S,
  M,
  L
}

export enum ActivityStatus {
  NotConsidered,
  Accepted,
  Reaction,
  NotRelevant
}

export enum ActivityVerificationMethod {
  NMAAPPROVALREQUIRED = 'nmaapprovalrequired',
  SELFAPPROVAL = 'selfapproval'
}

export enum ActivityDetailType {
  reaction = 'reaction',
  notrelevant = 'notrelevant',
  exemption = 'exemption',
  imposition = 'imposition',
  deviation = 'deviation'
}

export enum TaskFilterOptions {
  MyTasks = 'mytasks',
  MissingDocuments = 'missingdocuments',
  UnansweredByCustomer = 'unansweredbycustomer',
  AwaitingSdir = 'awaitingsdir',
  Reaction = 'reaction',
  Accepted = 'accepted'
}

export enum TaskStatusFilterOptions {
  ShowAll = 'showall',
  MissingDocuments = 'missingdocuments',
  AwaitingShippingCompany = 'awaitingshippingcompany',
  AwaitingSdir = 'awaitingsdir',
  Reaction = 'reaction',
  Accepted = 'accepted',
  NotRelevant = 'notrelevant'
}

export enum TaskTypeFilterOptions {
  ShowAll = 'showall',
  DocumentControl = 'documentcontrol',
  Document = 'document',
  Activity = 'activity',
  Inspection = 'inspection',
  CertificateData = 'certificatedata',
  ISMRevision = 'ismrevision'
}
