import React from 'react';
import styled from 'styled-components';

export interface InfoLabelInterface {
  label: string | number;
}
const InfoLabel: React.FC<InfoLabelInterface> = ({ label }) => {
  return <Label>{label}</Label>;
};

const Label = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.font.text1};
  margin-top: 2rem;
  opacity: 1;
`;

export default InfoLabel;
