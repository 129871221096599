import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Loader, SdirModal } from '@sdir/sds';
import { ErrorMessage } from '@sdir/utilities/lib/components';

type PersonSplitConfirmProps = {
  loading?: boolean;
  error?: any;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const PersonSplitConfirm: React.FC<PersonSplitConfirmProps> = ({
  loading = false,
  error,
  show,
  onClose,
  onConfirm
}) => {
  const intl = useIntl();

  const onOkClick = () => {
    onConfirm();
  };

  const onCloseClick = () => {
    onClose();
  };

  return (
    <StyledModal
      width="600px"
      visible={show}
      onCancel={onCloseClick}
      cancelText={intl.formatMessage({ id: 'common.cancel' })}
      onOK={onOkClick}
      okText={intl.formatMessage({ id: 'aps.person.split.title' })}
    >
      <Wrapper>
        <h2>{intl.formatMessage({ id: 'aps.person.merge.preview.confirm' })}</h2>
        <p>{intl.formatMessage({ id: 'aps.person.split.desc' })}</p>
        {loading && (
          <RequestWrapper>
            <Loader />
          </RequestWrapper>
        )}
        {!loading && error && (
          <RequestWrapper>
            <ErrorMessage error={error} />
          </RequestWrapper>
        )}
      </Wrapper>
    </StyledModal>
  );
};

export default PersonSplitConfirm;

const StyledModal = styled(SdirModal)`
  font-size: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  h2 {
    margin: 0 0 20px 0;
  }

  p {
    margin: 0;
  }
`;

const RequestWrapper = styled.div`
  margin: 30px 0;
`;
