import React, { useState } from 'react';
import styled from 'styled-components';
import {
  SdirModal,
  SdirRadioButtonGroup,
  SdirButton,
  Select,
  TextLink,
  Tooltip,
  SdirInput
} from '@sdir/sds';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  AddTeamMemberPayLoad,
  TeamMemberProjectPermission
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { addTeamMemberToProjectApi } from '../../../httpclient';
import Plus from '../../Atoms/Plus';

interface AddTeamMemberModalProps {
  modalVisible: boolean;
  setVisible: () => void;
  reloadTeamMembers: () => void;
}

const AddTeamMemberModal: React.FC<AddTeamMemberModalProps> = ({
  modalVisible,
  setVisible,
  reloadTeamMembers
}) => {
  const intl = useIntl();
  const { projectuid } = useParams() as { projectuid: string };

  const [addTeamMemberError, setAddTeamMemberError] = useState('');

  const { callApi: addTeamMember } = useApi({
    apiFunction: values => {
      const addTeamMemberPayLoad = {
        permission: TeamMemberProjectPermission.UpdateAccess
      } as AddTeamMemberPayLoad;
      return addTeamMemberToProjectApi.v1AddteammembertoprojectAddteammembertoprojectPayloadPut(
        projectuid!,
        values.userId,
        addTeamMemberPayLoad
      );
    },
    successCallback: () => {
      setAddTeamMemberError('');
      reloadTeamMembers();
      setVisible();
    },
    errorCallback: err => {
      if (err.length > 0) {
        if (err.includes('404') || err.includes('400')) {
          setAddTeamMemberError('Bruker ikke funnet');
        } else setAddTeamMemberError(err);
      }
    }
  });

  const formik = useFormik({
    initialValues: {
      userId: undefined
    },
    onSubmit: val => addTeamMember(val),
    validationSchema: Yup.object({
      userId: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
    })
  });

  const radio = [
    {
      label: intl.formatMessage({ id: 'access.customerteammodal.task.radio.all' }),
      value: 'allTasks'
    },
    {
      label: intl.formatMessage({ id: 'access.customerteammodal.task.radio.choose' }),
      value: 'chooseTass'
    }
  ];

  const [radioValue, setRadioValue] = useState(radio[0]);

  const handleClose = () => {
    setAddTeamMemberError('');
    setVisible();
    formik.resetForm();
  };

  return (
    <>
      <SdirModal visible={modalVisible} onCancel={() => {}} width="91rem" closeButton={false}>
        <Header>{intl.formatMessage({ id: 'access.customerteammodal.title' })}</Header>
        <form onSubmit={formik.handleSubmit}>
          <TooltipContainer>
            <InputContainer>
              <SdirInput
                id="userId"
                onChange={(e: any) => {
                  formik.setFieldValue('userId', e.target.value);
                  setAddTeamMemberError('');
                }}
                placeholder={intl.formatMessage({ id: 'access.customerteammodal.key' })}
                error={addTeamMemberError}
              />
            </InputContainer>
            <Tooltip width="31rem">
              {intl.formatMessage({ id: 'access.customerteammodal.key.helptext' })}
            </Tooltip>
          </TooltipContainer>
          <InputContainer>
            <Title>
              {intl.formatMessage({ id: 'access.customerteammodal.accesssunjectarea.title' })}
            </Title>
            <Select disabled defaultVal="Alle" handleChange={() => {}} />
          </InputContainer>
          <InputContainer>
            <RadioButtonWrapper>
              <SdirRadioButtonGroup
                options={radio}
                value={radioValue.label}
                onChange={e => setRadioValue(e.target.value as any)}
              />
            </RadioButtonWrapper>
          </InputContainer>
          <Wrapper>
            <Select
              disabled
              size="52rem"
              defaultVal={intl.formatMessage({
                id: 'access.customerteammodal.task.choosetasks'
              })}
              handleChange={() => {}}
            />
            <StyledTextLink>
              <Plus />
              <TextLink
                disabled
                text={intl.formatMessage({ id: 'access.customerteammodal.addsubjectarea' })}
              />
            </StyledTextLink>
          </Wrapper>
          <ButtonContainer>
            <SdirButton
              text={intl.formatMessage({ id: 'common.cancel' })}
              onClick={() => handleClose()}
              htmlType="button"
            />
            <SdirButton
              text={intl.formatMessage({ id: 'access.customerteammodal.accept' })}
              type="primary"
              htmlType="submit"
              onClick={formik.handleSubmit as any}
            />
          </ButtonContainer>
        </form>
      </SdirModal>
    </>
  );
};

const Header = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4rem;
`;

const TooltipContainer = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  margin-bottom: 2rem;
`;

const InputContainer = styled.div`
  width: 52rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
`;

const StyledTextLink = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 2rem;
  padding: 3rem 0;

  button {
    display: inline-block;
  }
`;

const RadioButtonWrapper = styled.div`
  /* Styles added for disabling untill connected to backend */
  opacity: 0.5;
  pointer-events: none;

  [data-id='base-item'] {
    label {
      font-family: ${({ theme }) => theme.fonts.stfSemibold};
      color: ${({ theme }) => theme.colors.font.text};
      margin-right: 6rem;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 3rem;
  z-index: 1;

  & > button {
    margin-bottom: 3rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 5rem;
`;

export default AddTeamMemberModal;
