import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataTable,
  Loader,
  MultiSelect,
  SdirButton,
  SdirModal,
  styles,
  Tag,
  TextLink
} from '@sdir/sds';
import { useDataSorting, useToast } from '@sdir/utilities/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ManagedUser } from '@sdir/httpclient/lib/clients/core/authorization';
import { checkpointsApi, userPermissionApi, vesselsApi } from '../../../httpclient';
import UserAdministrationAddModalContent from '../../Organisms/UserAdministration/UserAdministrationAddModalContent';

const UserAdministrationPage = () => {
  const fm = useIntl().formatMessage;
  const { setError } = useToast();
  const { organizationNumber } = useParams<{ organizationNumber }>();
  const [vesselFilters, setVesselFilter] = useState<string[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const { result: checkpointOptions } = useApi({
    apiFunction: () => checkpointsApi.v2CheckpointsGetcheckpointoptionsv2Get(),
    callApiOnInit: true
  });

  useEffect(() => {
    if (organizationNumber) {
      getOrganizationVessels(organizationNumber);
    }
  }, [organizationNumber]);

  const {
    callApi: getOrganizationVessels,
    result: organizationVessels,
    loading: getOrganizationVesselsLoading
  } = useApi({
    apiFunction: payload => vesselsApi.v1VesselsGetorganizationvesselsOrganizationIdGet(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'fartøy' })}: ${err}`);
      }
    },
    successCallback: res => {
      const vesselIds = res.vessels?.map(vessel => vessel.uid) ?? [];

      if (vesselIds.length) {
        getUsers(vesselIds);
        getInvitedUsers(vesselIds);
      }
    }
  });

  const { callApi: getUsers, result: users, loading: getUsersLoading } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionGetentitiesusersEntityUidsPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'brukere' })}: ${err}`);
      }
    }
  });

  const {
    callApi: getInvitedUsers,
    result: invitedUsers,
    loading: getInvitedUsersLoading
  } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionGetentitiesinvitedusersEntityUidsPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'error.load' }, { error: 'inviterte brukere' })}: ${err}`);
      }
    }
  });

  const mergedUsersAndInvitedUsers: ManagedUser[] = useMemo(() => {
    const listOfUsers = (
      invitedUsers?.invitedUsers?.map(iu => ({ ...iu, uid: iu.uid ?? undefined })) ?? []
    ).concat(users?.users?.map(u => ({ ...u, uid: u.uid ?? undefined })) ?? []);

    if (listOfUsers.length) {
      return listOfUsers;
    }

    return [
      {
        uid: '',
        email: 'john.doe@test.no',
        name: '',
        entities: [
          {
            uid: '9ff70665-3952-4d23-2af5-08d9990d12d4',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '987654321',
        email: 'jane.smith@test.no',
        name: 'Jane Smith',
        entities: [
          {
            uid: '63a39f4c-e5bc-4f6d-1ed4-08d9ba3438ec',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '112233445',
        email: 'michael.jones@test.no',
        name: 'Michael Jones',
        entities: [
          {
            uid: '0b9df36d-c2de-43b5-2565-08dad1f8f2a3',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '223344556',
        email: 'lisa.brown@test.no',
        name: 'Lisa Brown',
        entities: [
          {
            uid: '3656492b-2f63-4296-273d-08dad1f8f2a3',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '334455667',
        email: 'peter.wilson@test.no',
        name: 'Peter Wilson',
        entities: [
          {
            uid: '9ff70665-3952-4d23-2af5-08d9990d12d4',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '445566778',
        email: 'karen.white@test.no',
        name: 'Karen White',
        entities: [
          {
            uid: '63a39f4c-e5bc-4f6d-1ed4-08d9ba3438ec',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '556677889',
        email: 'thomas.green@test.no',
        name: 'Thomas Green',
        entities: [
          {
            uid: '0b9df36d-c2de-43b5-2565-08dad1f8f2a3',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '667788990',
        email: 'susan.adams@test.no',
        name: 'Susan Adams',
        entities: [
          {
            uid: '3656492b-2f63-4296-273d-08dad1f8f2a3',
            permissions: ['Unknown']
          },
          {
            uid: '63a39f4c-e5bc-4f6d-1ed4-08d9ba3438ec',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '778899001',
        email: 'robert.johnson@test.no',
        name: 'Robert Johnson',
        entities: [
          {
            uid: '9ff70665-3952-4d23-2af5-08d9990d12d4',
            permissions: ['Unknown']
          },
          {
            uid: '63a39f4c-e5bc-4f6d-1ed4-08d9ba3438e',
            permissions: ['Unknown']
          }
        ]
      },
      {
        uid: '889900112',
        email: 'sophie.anderson@test.no',
        name: 'Sophie Anderson',
        entities: [
          {
            uid: '63a39f4c-e5bc-4f6d-1ed4-08d9ba3438ec',
            permissions: ['Unknown']
          }
        ]
      }
    ];
  }, [users, invitedUsers, vesselFilters]);

  const getVesselName = useCallback(
    entityUid => {
      if (!organizationVessels?.vessels) {
        return '';
      }

      const foundVessel = organizationVessels.vessels.find(vessel => vessel.uid === entityUid);

      if (foundVessel) {
        return foundVessel.vesselName;
      }

      return entityUid;
    },
    [organizationVessels]
  );

  const removeEntitiesNotControlledByUser = useCallback(
    entities => {
      if (!organizationVessels?.vessels) {
        return [];
      }

      return entities.filter(entity =>
        organizationVessels.vessels?.some(vessel => vessel.uid === entity.uid)
      );
    },
    [organizationVessels]
  );

  const vesselFilterOptions = useMemo(() => {
    if (!organizationVessels?.vessels) {
      return [];
    }

    return organizationVessels.vessels
      .map(vessel => ({
        value: vessel.uid ?? '',
        name: vessel.vesselName ?? ''
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [organizationVessels]);

  const filteredUserList = useMemo(() => {
    if (!vesselFilters.length) {
      return mergedUsersAndInvitedUsers;
    }

    return mergedUsersAndInvitedUsers.filter(
      user => user.entities && user.entities.some(ent => vesselFilters.includes(ent.uid ?? ''))
    );
  }, [mergedUsersAndInvitedUsers, vesselFilters]);

  const columns = [
    {
      label: fm({ id: 'useradmin.table.column.name' }),
      key: 'name'
    },
    {
      label: fm({ id: 'useradmin.table.column.email' }),
      key: 'email'
    },
    {
      label: '',
      key: 'resendinvite'
    },
    {
      label: fm({ id: 'useradmin.table.column.vesselaccess' }),
      key: 'entities'
    },
    {
      label: '',
      key: 'temp'
    }
  ];

  const { sortedData, setSortedBy } = useDataSorting<any>(filteredUserList, columns);

  return (
    <Container>
      <Header>
        <Title>{fm({ id: 'useradmin.header' })}</Title>
        <HeaderButtonWrapper>
          <SdirButton
            type="primary"
            disabled={getUsersLoading || getInvitedUsersLoading}
            text={fm({ id: 'useradmin.button.label.newuser' })}
            onClick={() => setShowAddModal(true)}
          />
          <MultiSelect
            size="40rem"
            disabled={getUsersLoading || getInvitedUsersLoading}
            defaultVal={fm({ id: 'useradmin.vessel.filter.label' })}
            value={vesselFilters}
            options={vesselFilterOptions}
            handleChange={vesselUid => {
              const alreadyExists = vesselFilters.findIndex(el => el === vesselUid) >= 0;
              if (alreadyExists) {
                const newState = vesselFilters.filter(el => el !== vesselUid);
                setVesselFilter(newState);
              } else {
                setVesselFilter([...vesselFilters, vesselUid]);
              }
            }}
            searchable
          />
        </HeaderButtonWrapper>
      </Header>
      {getUsersLoading || getOrganizationVesselsLoading ? (
        <Loader />
      ) : (
        <Content>
          <TableWrapper>
            <StyledDataTable
              colWidth={['20vw', '20vw', '8vw', '40vw']}
              columns={columns}
              data={sortedData.map(item => {
                return {
                  ...item,
                  entities: (
                    <VesselTagWrapper>
                      {removeEntitiesNotControlledByUser(item.entities).map(entity => (
                        <TagWrapper key={entity.uid}>
                          <Tag
                            label={getVesselName(entity.uid)}
                            icon={<FontAwesomeIcon icon={faPenToSquare as IconProp} />}
                            onActionClick={() => console.log('edit')}
                          />
                        </TagWrapper>
                      ))}
                    </VesselTagWrapper>
                  ),
                  resendinvite: item.uid ? (
                    ''
                  ) : (
                    <TextLink
                      text={fm({ id: 'useradmin.table.newinvite' })}
                      onClick={() => console.log('resend')}
                    />
                  )
                };
              })}
              onHeaderClick={setSortedBy}
              sortableKeys={['name', 'email']}
            />
          </TableWrapper>
          {showAddModal && (
            <StyledModal
              visible
              onCancel={() => setShowAddModal(false)}
              onOK={() => console.log('ok')}
              cancelText={fm({ id: 'common.cancel' })}
              okText={fm({ id: 'useradmin.modal.invite.header' })}
            >
              <UserAdministrationAddModalContent
                vessels={vesselFilterOptions}
                checkpointOptions={checkpointOptions}
              />
            </StyledModal>
          )}
        </Content>
      )}
    </Container>
  );
};

export default UserAdministrationPage;

const Container = styled.div`
  margin: 5rem 26rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text85};

  @media screen and (max-width: ${({ theme }) => theme.screen.large}) {
    margin: 5rem 10rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.screen.medium}) {
    margin: 5rem 4rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  flex-wrap: wrap;
`;

const VesselTagWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TagWrapper = styled.div`
  & > div {
    gap: 1rem;
  }

  path {
    fill: ${styles.colors.primary};
  }
`;

const StyledDataTable = styled(DataTable)`
  padding: 0;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
`;

const Content = styled.div``;

const StyledModal = styled(SdirModal)`
  font-size: 16px;
  width: 40vw;
  max-width: 1000px;
  max-height: 95vh;
`;
