import { lazy } from 'react';

export const NumberUpInfoTile = lazy(() => import('./Dashboard/NumberUpInfoTile'));
export const TileTitle = lazy(() => import('./Dashboard/TileTitle'));
export const BubbleInfoTile = lazy(() => import('./Dashboard/BubbleInfoTile'));
export const SemiDonutChart = lazy(() => import('./Dashboard/SemiDonutChart'));
export const NumberDownInfoTile = lazy(() => import('./Dashboard/NumberDownInfoTile'));
export const WarningTile = lazy(() => import('./Dashboard/WarningTile'));
export const DonutChart = lazy(() => import('./Dashboard/DonutChart'));
export const VerticalBarChart = lazy(() => import('./Dashboard/VerticalBarChart'));
export const HorizontalBarChart = lazy(() => import('./Dashboard/HorizontalBarChart'));
export const TimeLineBarTile = lazy(() => import('./Dashboard/TimeLineBarTile'));

export { default as AlertMessage } from './AlertMessage';
export { default as Info } from './Info';
export { default as InfoLabel } from './InfoLabel';
export { default as ToggleContent } from './ToggleContent';
