/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { generatePath, redirect, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  FartoyCertificatesSchema,
  FartoyDataSchema,
  DatesAndMilestonesSchema,
  ConstructionStep3VesselSchema,
  ContactInformatinSchema,
  VesselContactInformationSchema
} from '@sdir/blueprint.et/lib/helpers/schemas/VesselSchema';
import {
  IContactPersonInformation,
  IVesselData,
  IVesselIdentification,
  IVessel,
  IContactInformation,
  IDatesAndMilestones,
  constructionNoticeInitialValues
} from '@sdir/blueprint.et/lib/components/Pages/ConstructionNotice/interfaces';
import ConstructionNoticePage from '@sdir/blueprint.et/lib/components/Pages/ConstructionNotice/ConstructionNoticePage';

// eslint-disable-next-line max-len
import useConstruction from '../../../../services/hooks/useConstruction';
import { Routes } from '../../../Templates/Routes';
import Page from '../../../Templates/Page';

const ConstructionPage = () => {
  const { currentStep, constructionnoticeid } = useParams() as {
    currentStep: string;
    constructionnoticeid?: string;
  };
  const step = parseInt(currentStep, 10);
  const navigate = useNavigate();
  const intl = useIntl();

  const handleNavigate = newStep => {
    if (!constructionnoticeid)
      navigate(generatePath(Routes.construction, { currentStep: newStep }));
    else
      navigate(
        generatePath(Routes.constructionUid, { currentStep: newStep, constructionnoticeid })
      );
  };

  const handleRedirect = (isCancelled?: boolean) => {
    if (isCancelled) {
      navigate(Routes.FRES);
      navigate(0);
      return;
    }
    redirect(generatePath(Routes.construction, { currentStep: '1' }));
  };

  /* All form values for the construction progress are saved here.
    The onSubmit function is empty as the submitting is done in multiple parts
    and handled by the useConstruction() hook
  */
  const formik = useFormik<
    IVesselData &
      IVesselIdentification &
      IVessel &
      IContactInformation &
      IDatesAndMilestones &
      IContactPersonInformation
  >({
    initialValues: constructionNoticeInitialValues,
    onSubmit: val => console.log(val),
    validationSchema: () => {
      switch (currentStep) {
        case '1':
          return VesselContactInformationSchema(intl);
        case '2':
          return FartoyCertificatesSchema(intl);
        case '3':
          return FartoyDataSchema(intl);
        case '4':
          return DatesAndMilestonesSchema(intl)
            .concat(ConstructionStep3VesselSchema(intl))
            .concat(ContactInformatinSchema(intl, formik.values));
        default:
          return Yup.object({});
      }
    }
  });

  const {
    getConstructionNotice,
    savedAt,
    updateVesselData,
    updateProjectInfo,
    startNewConstructionNotice,
    updateContactInformation,
    submitConstructionNotice,
    submitConstructionNoticeLoading,
    getConstructionNoticeLoading,
    specificationsOptions,
    isSubmitted,
    uploadGeneralArrangementDrawing,
    uploadProgress,
    downloadGeneralArrangementDrawing,
    disableFileDownload,
    downloadingGA
  } = useConstruction(formik);

  useEffect(() => {
    // This useEffect handles fetching the vessel if it already exists / "Ikke ferdigstilt"
    if (constructionnoticeid) {
      getConstructionNotice(constructionnoticeid);
    }
  }, [constructionnoticeid]);

  return (
    <Page error="" loading={false}>
      <WithWrapper>
        <ConstructionNoticePage
          currentStep={step}
          constructionnoticeid={constructionnoticeid}
          specificationOptions={specificationsOptions}
          navigateToStep={handleNavigate}
          redirect={handleRedirect}
          formik={formik}
          savedAt={savedAt}
          updateVesselData={updateVesselData}
          updateProjectInfo={updateProjectInfo}
          startNewConstructionNotice={startNewConstructionNotice}
          updateContactInformation={updateContactInformation}
          submitConstructionNotice={submitConstructionNotice}
          submitConstructionNoticeLoading={submitConstructionNoticeLoading}
          getConstructionNoticeLoading={getConstructionNoticeLoading}
          isSubmitted={isSubmitted}
          uploadProgress={uploadProgress}
          onFileUpload={uploadGeneralArrangementDrawing}
          onFileClick={downloadGeneralArrangementDrawing}
          downloading={downloadingGA}
          disableFileDownload={disableFileDownload}
        />
      </WithWrapper>
    </Page>
  );
};

const WithWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default ConstructionPage;
