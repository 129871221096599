import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AlertSingleLine } from '@sdir/sds';
import { useCurrentUserHasAccess } from '@sdir/auth/lib/hooks';
import { isToggleOn } from '@sdir/blueprint.aps/lib/helpers/permissionUtilities';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import {
  CoursesAndEducationTableCard,
  SeagoingTableCardNew,
  SeafarerCard,
  AdditionalCompetenceTable,
  AssessmentTable,
  CertificateTable,
  ApplicationTableCard,
  SeagoingTableCard
} from '../../Organisms';
import { AppPermissions, DisableNewSeagoingToggle } from '../../../constants/permissions';
import { useFeatureToggles } from '../../../services/hooks/useFeatureToggles';
import { commonPersonApi } from '../../../httpclient';

const Seafarer: React.FC = () => {
  const intl = useIntl();
  const { commonAddresseeId } = useParams() as { commonAddresseeId: string };
  const personPermission = useCurrentUserHasAccess(AppPermissions.ViewAccessToPerson);
  const featureToggle = useFeatureToggles();
  const enableNewSeagoing = !isToggleOn(featureToggle?.result, DisableNewSeagoingToggle, '');

  const {
    result: personResult,
    loading: personLoading,
    error: personError,
    callApi: callPersonApi
  } = useApi({
    apiFunction: () => commonPersonApi.v1PersonGetpersonextendedbyidIdGet(commonAddresseeId),
    callApiOnInit: true
  });

  const legalEntityId = useMemo(() => {
    if (personResult?.legalEntityId) {
      return `${personResult.legalEntityId}`;
    }
    return undefined;
  }, [personResult?.legalEntityId]);

  useEffect(() => {
    if (personPermission && personPermission?.hasAccess) {
      callPersonApi();
    }
  }, [commonAddresseeId, personPermission?.hasAccess]);

  if (personPermission && !personPermission?.hasAccess) {
    return (
      <AlertSingleLine
        title="Error"
        type="error"
        text={intl.formatMessage({ id: 'utils.errors.insufficientaccess' })}
      />
    );
  }

  return (
    <div>
      <SeafarerContainer>
        <Title>Kundekort</Title>
        <SideSection>
          <SeafarerCard
            commonAddresseeId={commonAddresseeId}
            person={personResult}
            personLoading={personLoading}
            personError={personError}
          />
        </SideSection>
        <DataSection>
          <CertificateTable commonAddresseeId={commonAddresseeId} />
          <ApplicationTableCard commonAddresseeId={commonAddresseeId} />
          {legalEntityId && (
            <>
              <CoursesAndEducationTableCard legalEntityId={legalEntityId} />
              {enableNewSeagoing ? (
                <SeagoingTableCardNew legalEntityId={legalEntityId} />
              ) : (
                <SeagoingTableCard legalEntityId={legalEntityId} />
              )}
              <AdditionalCompetenceTable legalEntityId={legalEntityId} />
              <AssessmentTable legalEntityId={legalEntityId} />
            </>
          )}
        </DataSection>
      </SeafarerContainer>
    </div>
  );
};

export default Seafarer;

const SeafarerContainer = styled.section`
  width: 100%;
  margin: 0 auto 0 auto;
  display: grid;
  grid-template-columns: repeat(16, auto);
  grid-template-rows: repeat(12, auto);
  column-gap: 4rem;
  row-gap: 4rem;
`;

const Title = styled.div`
  display: grid;
  grid-column: 2 / 16;
  grid-row: 2;
  font-size: 3rem;
  font-weight: 700;
  margin: 5px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const SideSection = styled.div`
  grid-column: 2 / 6;
  grid-row: 3;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

const DataSection = styled.div`
  grid-column: 6 / 15;
  grid-row: 3;
  grid-row: 3;
  row-gap: 40px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;
