import React from 'react';
import styled from 'styled-components';
import { DropdownMenu, SdirRadioButtonGroup, SdirButton, Textarea } from '@sdir/sds';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface RiskFeedbackModalProps {
  modalVisible?: boolean;
  setVisible: () => void;
  handleModalCancel: () => void;
  onFeedbackSubmit: (values) => void;
}

const RiskFeedbackModal: React.FC<RiskFeedbackModalProps> = ({
  modalVisible,
  setVisible,
  handleModalCancel,
  onFeedbackSubmit
}) => {
  const intl = useIntl();

  const radioButtons = [
    { label: intl.formatMessage({ id: 'riskassessment.feedback.toohigh' }), value: 'high' },
    { label: intl.formatMessage({ id: 'riskassessment.feedback.toolow' }), value: 'low' }
  ];

  const validationSchema = Yup.object({
    comment: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' })),
    assessment: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
  });

  const formik = useFormik({
    initialValues: {
      assessment: '',
      comment: ''
    },
    validationSchema,
    onSubmit: values => {
      setVisible();
      onFeedbackSubmit(values);
    },
    validateOnMount: true
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <div className="dropdown-wrapper">
          <DropdownMenu
            isOpen={modalVisible}
            arrowPosition="left"
            onCloseRequest={handleModalCancel}
          >
            <Headeer>{intl.formatMessage({ id: 'riskassessment.feedback.title' })}</Headeer>
            <FormWrapper>
              <AlignmentContainer>
                <Label>{intl.formatMessage({ id: 'riskassessment.feedback.subtitle' })}</Label>
                <SdirRadioButtonGroup
                  options={radioButtons}
                  value={formik.values.assessment}
                  onChange={e => {
                    formik.setFieldValue('assessment', e.target.value);
                  }}
                  id="assessment"
                />
              </AlignmentContainer>

              <Wrapper>
                <Label>{intl.formatMessage({ id: 'riskassessment.feedback.comment' })}</Label>
                <Textarea
                  id="comment"
                  contentValue={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </Wrapper>
            </FormWrapper>
            <ButtonContainer>
              <SdirButton
                text={intl.formatMessage({ id: 'common.cancel' })}
                onClick={() => {
                  handleModalCancel();
                  formik.resetForm();
                }}
              />
              <SdirButton
                disabled={!formik.isValid}
                text={intl.formatMessage({ id: 'riskassessment.feedback.send' })}
                type="primary"
                onClick={formik.handleSubmit as any}
              />
            </ButtonContainer>
          </DropdownMenu>
        </div>
      </form>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  .dropdown-wrapper {
    position: absolute;
    z-index: 2;
    width: 80rem;
    max-width: 80vw;
    top: 100%;
    left: 10%;
    transform: translate(-10%, 1rem);
    section {
      padding: 3rem !important;
    }
  }
`;

const AlignmentContainer = styled.div`
  div {
    display: flex;
    flex-direction: column;

    [data-id='base-item'] {
      flex-direction: row;
      margin-top: 1rem;
    }
  }
`;

const Headeer = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 2rem;
  margin-bottom: 3rem;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.font.text85};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  display: grid;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

export default RiskFeedbackModal;
