import { BigError, SdirButton } from '@sdir/sds';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import React from 'react';
import { Routes } from '../Templates/Routes';

const NotFoundPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Container>
      <BigError
        actionButton={
          <SdirButton
            type="primary"
            text={intl.formatMessage({ id: 'notfoundpage.backtofrontpage' })}
            onClick={() => navigate(Routes.home)}
          />
        }
        errorHeader={intl.formatMessage({ id: 'notfoundpage.notfound' })}
        content={
          <>
            {intl.formatMessage({ id: 'notfoundpage.youtriedtoaccess' })}
            <a href={window.location.href}>{window.location.href}</a>
          </>
        }
        errorCode={intl.formatMessage({ id: 'notfoundpage.errorcode' }, { errorcode: '404' })}
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  margin-top: 4rem;
`;

export default NotFoundPage;
