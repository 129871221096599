import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SdiShip } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { ProtectedContent } from '@sdir/auth/lib/components';
import { AppPermissions, ToggleAPS, ToggleName } from '../../constants/permissions';
import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard-icon.svg';
import { ReactComponent as ApsIcon } from '../../assets/img/aps-icon.svg';
import { ReactComponent as GfIcon } from '../../assets/img/gf-icon.svg';
import { ReactComponent as ShortcutIcon } from '../../assets/img/shortcut-icon.svg';
import { ReactComponent as PaymentIcon } from '../../assets/img/payment-icon.svg';
import { ReactComponent as DigCertIcon } from '../../assets/img/digital-certificate.svg';
import { Routes } from '../Templates/Routes';
import { ToggleContent } from '../Atoms';

interface HeaderMenuProps {
  isActive?: boolean;
  onClose?: () => void;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ isActive, onClose }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <HeaderMenuContainer isActive={isActive}>
      {isActive && (
        <HeaderMenuContent>
          <HeaderLinks>
            <LinkContainer>
              <IconContainer>
                <DashboardIcon />
              </IconContainer>
              <StyledLink to={Routes.home} onClick={() => onClose && onClose()}>
                Dashboard
              </StyledLink>
            </LinkContainer>
            <LinkContainer>
              <IconContainer>
                <SdiShip size="s" color="white" />
              </IconContainer>
              <StyledLink to={Routes.FRES} onClick={() => onClose && onClose()}>
                {intl.formatMessage({ id: 'caseworker.page.title' })}
              </StyledLink>
            </LinkContainer>
            <ProtectedContent permission={AppPermissions.ReadAccessToProjects}>
              <LinkContainer
                onClick={() => {
                  navigate(Routes.DigitalCertificates);
                  window.location.reload();
                  return onClose && onClose();
                }}
              >
                <IconContainer>
                  <DigCertIcon />
                </IconContainer>
                Elektroniske sertifikater
              </LinkContainer>
            </ProtectedContent>
            <ToggleContent toggleName={ToggleAPS}>
              <LinkContainer>
                <IconContainer>
                  <ApsIcon />
                </IconContainer>
                <StyledLinkExternal
                  onClick={() => {
                    navigate(Routes.APS);
                    window.location.reload();
                    return onClose && onClose();
                  }}
                >
                  Personlige sertifikater
                </StyledLinkExternal>
              </LinkContainer>
            </ToggleContent>
            <LinkContainer
              onClick={() => {
                navigate(Routes.ActivityAdmin);
                window.location.reload();
                return onClose && onClose();
              }}
            >
              <IconContainer />
              Oppgaveadministrasjon
            </LinkContainer>
            <LinkContainer
              onClick={() => {
                navigate(Routes.vesselSimulator);
                window.location.reload();
                return onClose && onClose();
              }}
            >
              <IconContainer>
                <PaymentIcon />
              </IconContainer>
              Fartøy Simulator
            </LinkContainer>
          </HeaderLinks>
          <ToggleContent toggleName={ToggleName}>
            <HeaderShortcuts>
              <LinkContainer>
                <StyledShortcutIcon />
                Min snarvei i APS
              </LinkContainer>
              <LinkContainer>
                <StyledShortcutIcon />
                Min snarvei i GF
              </LinkContainer>
              <LinkContainer>
                <StyledShortcutIcon />
                En snarvei til &para; 2.12
              </LinkContainer>
              <LinkContainer>
                <StyledShortcutIcon />
                <StyledLink to="/seafarer" onClick={() => onClose && onClose()}>
                  En lenke jeg har laget
                </StyledLink>
              </LinkContainer>
              <br />
              <LinkContainer>
                <IconContainer>
                  <b>+</b>
                </IconContainer>
                Legg til ny snarvei
              </LinkContainer>
            </HeaderShortcuts>
          </ToggleContent>
        </HeaderMenuContent>
      )}
    </HeaderMenuContainer>
  );
};

export default HeaderMenu;

const HeaderMenuContainer = styled.div`
  position: absolute;
  top: 10.5rem; // Same as Header height
  left: 0;
  background: #004176;
  // subtract header height and userbar height from sidebar height
  height: ${(props: HeaderMenuProps) => (props.isActive ? 'calc(100vh - 10.5rem - 40px)' : 0)};
  width: 600px;
  transition: height 0.4s ease;
  text-align: left;
  color: #ffffff99;
  overflow: hidden;
  z-index: 1000;
`;

const HeaderMenuContent = styled.div`
  position: relative;
  padding-left: 200px;
  width: auto;
  text-align: left;
`;

const HeaderLinks = styled.div`
  padding: 40px 0;
  margin-right: 15px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.font.PBM};
`;

const HeaderShortcuts = styled.div`
  padding: 40px 0;
  display: inline-block;
`;

const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #ffffff99;
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 20px;
  margin-bottom: 20px;
  margin-right: 20px;

  path {
    fill: #ffffff99;
  }
`;

const LinkContainer = styled.div`
  font-size: 2rem;
  display: flex;
  cursor: pointer;
  line-height: 22px;
  color: #ffffff99;
`;

const StyledShortcutIcon = styled(ShortcutIcon)`
  margin-right: 20px;
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  font-size: inherit;
  color: inherit;
  text-decoration: none;
`;
const StyledLinkExternal = styled.a`
  font-size: inherit;
  color: inherit;
  text-decoration: none;
`;
