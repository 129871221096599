import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DataTable, SdiTrash, TextLink } from '@sdir/sds';
import { useDataSorting } from '@sdir/utilities/lib/hooks';
import { SeagoingVesselPreviewLink } from '@sdir/blueprint';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { AssessmentsReportByLegalEntityId } from '@sdir/httpclient/lib/clients/aps/assessor';
import moment from 'moment';
import { DisplayDateFormat } from '../../helpers';
import { AssesmentColumns, AssessmentRow } from '../../helpers/TableHeaders';
import TableCardContainer from '../Atoms/TableCardContainer';
import { assessmentApi } from '../../httpclient/index';
import { DeleteAssessmentModal } from './DeleteAssessmentModal';

const AssessmentTable: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [assessmentToDelete, setAssessmentToDelete] = useState<AssessmentsReportByLegalEntityId>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { result, loading, error, callApi } = useApi({
    apiFunction: assessmentApi.v1AssessmentAssessmentsbylegalentityidQueryPost,
    bind: assessmentApi,
    callApiOnInit: false
  });

  const showModalDelete = useCallback(assessment => {
    setAssessmentToDelete(assessment);
    setShowDeleteModal(true);
  }, []);

  const onCancelDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onSuccessDeleteAssessment = useCallback(() => {
    setShowDeleteModal(false);
    callApi({ legalEntityId });
  }, [callApi, legalEntityId]);

  const tableData: AssessmentRow[] = useMemo(() => {
    if (!result) return [];

    return result.map(val => ({
      date: DisplayDateFormat(val.completionDate),
      dateValue: val.completionDate ? moment(val.completionDate) : null,
      assessorName: val.assessorName,
      assessmentType: val.assessmentType,
      vesselName: (
        <SeagoingVesselPreviewLink
          vessel={{ name: val.vesselName, imoNumber: val.imoNumber, callSign: val.vesselCallsign }}
          accumulatedData={null}
        />
      ),
      vesselNameValue: val.vesselName,
      period: `${DisplayDateFormat(val.signOnDate)} - ${DisplayDateFormat(val.signOffDate)}`,
      periodStartValue: val.signOnDate ? moment(val.signOnDate) : null,
      remove: (
        <TextLink
          data-testid="assessment-delete-button"
          icon={<SdiTrash size="s" />}
          text=""
          click={() => showModalDelete(val)}
        />
      )
    }));
  }, [result]);

  const { sortedData, setSortedBy } = useDataSorting<any>(tableData, AssesmentColumns);

  useEffect(() => {
    if (legalEntityId) {
      callApi({
        skip: 0,
        take: 100,
        legalEntityId
      });
    }
  }, [callApi, legalEntityId]);

  return (
    <>
      <TableCardContainer
        title={intl.formatMessage({ id: 'table.assesment.report.assessmentType' })}
        loading={loading}
        error={error}
        emptyText={intl.formatMessage({ id: 'table.no.competence.found' })}
        data={sortedData}
      >
        <DataTable
          columns={AssesmentColumns}
          data={sortedData}
          onHeaderClick={setSortedBy}
          sortableKeys="*"
        />
      </TableCardContainer>
      {showDeleteModal && (
        <DeleteAssessmentModal
          assessment={assessmentToDelete}
          display={showDeleteModal}
          onCancelClick={onCancelDeleteModal}
          onSuccessDelete={onSuccessDeleteAssessment}
        />
      )}
    </>
  );
};
export default AssessmentTable;
