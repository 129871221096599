/* eslint-disable no-alert */
import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DataCard, SdirButton, SdiFile, TextLink, SdirModal, styles } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import moment from 'moment';
import {
  DocumentControlActivityGroup,
  GetActivityDetailsV2Result,
  GetReactionResult,
  ReactionType
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { ToggleButton, ActivityCard } from '@sdir/blueprint.et/lib/components';
// eslint-disable-next-line max-len
import InformationCardPageContainer from '@sdir/blueprint.et/lib/components/Templates/InformationCardPageContainer';
import InformationCard, {
  DataWrapper,
  DataLabel,
  DataValue
} from '@sdir/blueprint.et/lib/components/Molecules/InformationCard';
import { InspectionIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import { Routes } from '../../Templates/Routes';
import FileTable, { FiletableBackground } from '../../Molecules/ActivityCard/FileTable';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import useReaction from './useReaction';

interface Props {
  vesselName: string;
  reaction: GetReactionResult | undefined;
  activity?: GetActivityDetailsV2Result;
  getActivityDetail: () => void;
  document?: DocumentControlActivityGroup | undefined;
  children?: React.ReactNode;
}

const EvaluateReaction: React.FC<Props> = ({
  activity,
  vesselName,
  reaction,
  getActivityDetail,
  document,
  children
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { projectuid, activityuid: activityUid } = useParams() as {
    projectuid: string;
    activityuid: string;
  };
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [taskIsExpanded, setTaskIsExpanded] = useState(false);

  const checkpointType = activity?.checkpointType?.split('#')[1]?.toLowerCase();

  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid } as {
    projectUid: string;
  });

  const navigateBack = () => {
    navigate(
      generatePath(Routes.vesselWorkspaceScrollTo, {
        tab: 'activitylist',
        projectuid,
        scrollToId: activityUid
      })
    );
  };

  const rejectReactionResponseCallback = () => {
    setRejectModalVisible(false);
    getActivityDetail();
  };

  const {
    downloadAttachment,
    rejectReactionResponse,
    closeReaction,
    getReactionType,
    loading
  } = useReaction(reaction, navigateBack, rejectReactionResponseCallback);

  const handleViewRequirement = async eliref => {
    if (eliref) {
      localStorage.setItem('ELIREF_REDIRECT', eliref);
      window.open(
        `${window.location.origin}/#${generatePath(Routes.requirementDetail, {
          projectuid,
          activityuid: activityUid
        })}`,
        '_blank'
      );
    }
  };

  const displayInfo = () => {
    const texts = intl
      .formatMessage({ id: `replytoreaction.${checkpointType}.reactioninfo` })
      .split('%s');

    if (checkpointType === 'documentcontrol')
      return (
        <>
          <span>{texts[0]}</span>
          <ItalicText>{activity?.name}</ItalicText>
          <span>{texts[1]}</span>
          <ItalicText>{document?.documentLabel}</ItalicText>
          <span>{texts[2]}</span>
        </>
      );
    return (
      <>
        <span>{texts[0]}</span>
        <ItalicText>{activity?.name}</ItalicText>
        <span>{texts[1]}</span>
      </>
    );
  };

  const getFilename = blobName => {
    if (!blobName) return '';
    return blobName.split(`/${reaction?.reactionUid}/`)[1];
  };

  const reactionFiles = attachments => {
    if (!attachments) return <div />;
    return attachments.map(file => (
      <FileLink
        key={file.uid}
        text={getFilename(file.blobName)}
        click={() => downloadAttachment(reaction?.reactionUid, file.uid)}
      />
    ));
  };

  const displayCriticalityAndDeadline = reaction?.type === ReactionType.Mandate;

  const leftContent = (
    <InformationCard name={vesselName}>
      <DataWrapper>
        <DataLabel>{intl.formatMessage({ id: 'replytoreaction.data.type.label' })}</DataLabel>
        <DataValue>{getReactionType(reaction?.type || '')}</DataValue>
      </DataWrapper>
      <DataWrapper>
        <DataLabel>{intl.formatMessage({ id: 'replytoreaction.data.critical.label' })}</DataLabel>
        <DataValue>
          {displayCriticalityAndDeadline
            ? reaction.isCritical
              ? intl.formatMessage({ id: 'activity.metadata.criticality.critical' })
              : intl.formatMessage({ id: 'activity.metadata.criticality.notcritical' })
            : ''}
        </DataValue>
      </DataWrapper>
      <DataWrapper>
        <DataLabel>{intl.formatMessage({ id: 'replytoreaction.data.deadline.label' })}</DataLabel>
        <DataValue>
          {displayCriticalityAndDeadline
            ? reaction.deadline
              ? moment(reaction.deadline).format('DD.MM.YYYY')
              : ' '
            : ' '}
        </DataValue>
      </DataWrapper>
    </InformationCard>
  );

  const rightContent = (
    <>
      <DataCard>
        <Container>
          <Title>{intl.formatMessage({ id: 'vessel.activity.evaluatereaction.title' })}</Title>
          <p>{displayInfo()}</p>
          <ReactionReplyContent>
            <ReactionReplyHeader>
              {`${intl.formatMessage({
                id: 'sendreaction.sdir.reaction.header'
              })}:`}
            </ReactionReplyHeader>
            <ReactionContent background={styles.colors.primary10}>
              <ReactionText>{reaction?.text}</ReactionText>
              {reaction?.attachments && reaction.attachments.length > 0 && (
                <ReactionReplyUploadHeader>
                  {intl.formatMessage({
                    id: 'vessel.activity.evaluatereaction.response.uploaded.title'
                  })}
                </ReactionReplyUploadHeader>
              )}
              {reactionFiles(reaction?.attachments)}
            </ReactionContent>
          </ReactionReplyContent>
          <ReactionCustomerReplyContent>
            <ReactionReplyHeader>
              {`${intl.formatMessage({
                id: 'sendreaction.customer.response.header'
              })}:`}
            </ReactionReplyHeader>
            <ReactionContent background={styles.colors.primaryLightBlue10}>
              <ReactionText>{reaction?.reactionReply?.text}</ReactionText>
              {reaction?.reactionReply?.attachments &&
                reaction.reactionReply.attachments.length > 0 && (
                  <ReactionReplyUploadHeader>
                    {intl.formatMessage({
                      id: 'vessel.activity.evaluatereaction.response.uploaded.title'
                    })}
                  </ReactionReplyUploadHeader>
                )}
              {reactionFiles(reaction?.reactionReply?.attachments)}
            </ReactionContent>
          </ReactionCustomerReplyContent>
          <div>
            <CustomToggleButton
              isToggled={taskIsExpanded}
              onClick={() => setTaskIsExpanded(prev => !prev)}
              text={intl.formatMessage({
                id: `replytoreaction.${checkpointType}.toggle.label`
              })}
            />
            {taskIsExpanded && (
              <ToggleContentWrapper>
                <ActivityCard
                  internalComment={activity?.internalComment}
                  title={activity?.name}
                  description={activity?.description}
                  type={intl.formatMessage({
                    id: `checkpointtype.${checkpointType}`
                  })}
                  icon={checkpointType === 'documentcontrol' ? <SdiFile /> : <InspectionIcon />}
                  elirefs={activity?.requirementEliRefs}
                  goToRequirementClick={eliref => handleViewRequirement(eliref)}
                />
                {checkpointType === 'documentcontrol' && (
                  <FileUploadWrapper>
                    <HeaderContainer>
                      <Header>
                        <Type>{intl.formatMessage({ id: 'documentcard.document' })}</Type>
                        <Icon>
                          <SdiFile />
                        </Icon>
                        <ActivityTitle>{document?.documentLabel}</ActivityTitle>
                      </Header>
                    </HeaderContainer>
                    <Subtitle>{intl.formatMessage({ id: 'documentcard.documentdesc' })}</Subtitle>
                    <Description>{document?.documentDescription}</Description>
                    <FiletableBackground>
                      {document?.attachments && document.attachments.length > 0 ? (
                        <FileTable files={document.attachments} downloadFile={downloadByFilename} />
                      ) : (
                        <p>{intl.formatMessage({ id: 'documentcard.nouploadedfiles' })}</p>
                      )}
                    </FiletableBackground>
                  </FileUploadWrapper>
                )}
              </ToggleContentWrapper>
            )}
          </div>
          <ButtonContainer>
            <TextLink
              disabled={loading}
              text={intl.formatMessage({ id: 'common.cancel' })}
              click={() => navigateBack()}
            />
            <div>
              <Button
                disabled={loading}
                type="default"
                text={intl.formatMessage({
                  id: 'vessel.activity.evaluatereaction.response.reject.label'
                })}
                onClick={() => setRejectModalVisible(true)}
              />
              <Button
                disabled={loading}
                type="primary"
                text={intl.formatMessage({
                  id: 'vessel.activity.evaluatereaction.response.accept.label'
                })}
                onClick={() => {
                  reaction?.reactionUid && closeReaction(reaction?.reactionUid);
                }}
              />
            </div>
          </ButtonContainer>
        </Container>
      </DataCard>
      {children}
    </>
  );

  return (
    <>
      <InformationCardPageContainer leftContent={leftContent} rightContent={rightContent} />
      <SdirModal
        visible={rejectModalVisible}
        onCancel={() => setRejectModalVisible(false)}
        closeButton={false}
      >
        <ModalHeader>
          {intl.formatMessage(
            {
              id: 'vessel.activity.evaluatereaction.response.rejectmodal.title'
            },
            { task: activity?.name }
          )}
        </ModalHeader>
        <ModalText>
          {intl.formatMessage({
            id: 'vessel.activity.evaluatereaction.response.rejectmodal.info'
          })}
        </ModalText>
        <ButtonContainer>
          <SdirButton
            text={intl.formatMessage({ id: 'common.cancel' })}
            type="default"
            onClick={() => setRejectModalVisible(false)}
          />
          <SdirButton
            disabled={loading}
            text={intl.formatMessage({
              id: 'vessel.activity.evaluatereaction.response.rejectmodal.button.label'
            })}
            type="primary"
            onClick={() => {
              rejectReactionResponse(reaction?.reactionUid);
            }}
          />
        </ButtonContainer>
      </SdirModal>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
`;

const ToggleContentWrapper = styled.div`
  margin: 2rem;
  margin-bottom: 4rem;
`;

const ReactionReplyHeader = styled.span`
  color: ${({ theme }) => theme.colors.font.text85};
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const ReactionReplyUploadHeader = styled.span`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.font.text85};
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const ReactionText = styled.p`
  white-space: pre-wrap;
  border-radius: 0.75rem;
  margin: 0;
`;

const ReactionReplyContent = styled.div`
  padding: 3rem 2rem;
  border-radius: 6px;
  border-left: 1rem solid #cf837d;
  margin-bottom: 3rem;
  box-shadow: 0px 3px 6px 0px rgba(51, 51, 51, 0.2);
`;

const ReactionCustomerReplyContent = styled.div`
  padding: 3rem 2rem;
  border-radius: 6px;
  margin-bottom: 3rem;
  box-shadow: 0px 3px 6px 0px rgba(51, 51, 51, 0.2);
`;

const ReactionContent = styled.div<{ background: string }>`
  margin-top: 1rem;
  padding: 2rem;
  background-color: ${({ background }) => background};
`;

const Button = styled(SdirButton)`
  margin-left: 5rem;
`;

const Container = styled.div`
  padding: 5rem 4rem;
`;

const CustomToggleButton = styled(ToggleButton)`
  div {
    color: ${({ theme }) => theme.colors.font.text};
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 2rem;
  }
`;

const ActivityTitle = styled.span`
  grid-area: title;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 3rem;
`;

const FileUploadWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid #4e7694;
  padding: 3rem;
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  margin-bottom: 3rem;
`;

const ModalHeader = styled.h1`
  font-size: 3rem;
`;

const ModalText = styled.p`
  font-size: 2rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-areas:
    '. type'
    'icon title';
  justify-content: start;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Type = styled.span`
  grid-area: type;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 1.75rem;
  && {
    line-height: 1;
  }
`;

const Icon = styled.span`
  grid-area: icon;
  margin-right: 2rem;
  svg {
    fill: ${({ theme }) => theme.colors.font.text};
  }
`;

const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.secondary.grey1};
  display: block;
  padding-top: 4rem;
`;

const Description = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 2rem;
  display: block;
  padding-top: 1rem;
  padding-bottom: 5rem;
`;

const ItalicText = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBookItalic};
`;

const FileLink = styled(TextLink)`
  margin-top: 2rem;
`;

export default EvaluateReaction;
