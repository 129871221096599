import { Certificate } from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { useContext } from 'react';
import { Loadable } from '../../types/commonTypes';
import { CertificateCodesContext } from '../contexts/CertificateCodesContext';

export const useCertificateCodes = (): Loadable<Certificate[]> => {
  const context = useContext(CertificateCodesContext);

  return context;
};
