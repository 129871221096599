/* eslint-disable no-alert */
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  DataCard,
  Select,
  Textarea,
  SdirButton,
  SdirDatePicker,
  TextLink,
  SdirRadioButtonGroup,
  SdiTrash,
  SdiFile,
  AlertSingleLine,
  DataTable
} from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {
  AddSignerPayload,
  ChangeSignerPayload,
  DocumentControlActivityGroup,
  EventType,
  FollowUpBy,
  GetActivityDetailsV2Result,
  GetReactionResult,
  ReactionCreatedDomainEvent,
  ReactionType,
  SignStatus,
  SubmitReactionForSigningPayload,
  UpdateReactionDto
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import {
  ActivityCard,
  InformationCard,
  InformationCardPageContainer
} from '@sdir/blueprint.et/lib/components';
import {
  DataLabel,
  DataValue,
  DataWrapper
} from '@sdir/blueprint.et/lib/components/Molecules/InformationCard';
import { InspectionIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { useSelector } from 'react-redux';

import Signerbox from '../../Molecules/Signerbox';
import AddSignerCaseWorkerModal from '../../Molecules/ActivityDetails/AddSignerCaseWorkerModal';

import ReactionFileSection from './ReactionFileSection';
import { TextAreaTitle } from './ReplyToApplication';
import { Signer } from '../../../store/constants/types';
import useReaction from './useReaction';
import { Routes } from '../../Templates/Routes';

interface Props {
  vesselName: string;
  reaction: GetReactionResult | null | undefined;
  getActivityDetail: () => void;
  activity?: GetActivityDetailsV2Result;
  defaultEditMode?: boolean;
  document?: DocumentControlActivityGroup | undefined;
  children?: React.ReactNode;
  downloadByFilename: (name: string) => void;
}

const SendReaction: React.FC<Props> = ({
  vesselName,
  reaction,
  getActivityDetail,
  activity,
  document,
  children,
  downloadByFilename,
  defaultEditMode = false
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { projectuid, activityuid: activityUid } = useParams() as {
    projectuid: string;
    activityuid: string;
  };
  const [isSigneeModalVisible, setSigneeModalVisible] = useState(false);
  const [isCaseWorkerModalVisible, setCaseWorkerModalVisible] = useState(false);
  const [sortDirection, setSortDirection] = useState(1);
  const [draftEditMode, setDraftEditMode] = useState(defaultEditMode);

  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.profile.sub;
  const currentUserName = currentUser?.profile.name;
  const noValueConst = '-';

  const existingDraft = activity?.reactionDraft !== null && !activity?.reactionDraft?.isSubmitted;

  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  const {
    loading,
    submitDraftReaction,
    handleReplaceReaction,
    updateDraftReactionAndUploadAttachments,
    createDraftReactionAndUploadAttachments,
    deleteDraftReaction,
    uploadedAttachments,
    setUploadedAttachments,
    storeFilesForUpload,
    newAttachments,
    isUploading,
    progress,
    deleteFile,
    signer,
    allUsers,
    addSigner,
    addSignerLoading,
    setSigner,
    removeSigner,
    changeSigner,
    submitForSigning,
    submitForSigningLoading,
    downloadAttachment,
    getReactionType
  } = useReaction(reaction);

  const getActionOptions = () => {
    const options: any = [];

    options.push({
      name: intl.formatMessage({ id: 'sendreaction.selectoption.comment' }),
      value: ReactionType.Comment
    });

    options.push({
      name: intl.formatMessage({ id: 'sendreaction.selectoption.mandate' }),
      value: ReactionType.Mandate
    });

    return options;
  };

  const getFollowUpByOptions = () => {
    const options: any = [];

    options.push({
      name: intl.formatMessage({ id: 'sendreaction.selectoption.sdir' }),
      value: FollowUpBy.Sdir
    });

    options.push({
      name: intl.formatMessage({ id: 'sendreaction.selectoption.customer' }),
      value: FollowUpBy.Customer
    });

    return options;
  };

  const actionOptions = getActionOptions();
  const followUpByOptions = getFollowUpByOptions();

  const submittedReaction =
    activity?.events &&
    activity?.events.length > 0 &&
    activity.events[activity.events.length - 1].eventType === EventType.ReactionCreated;

  useEffect(() => {
    const init = async () => {
      if (reaction) {
        if (reaction.text) {
          await formik.setFieldValue('reply', reaction.text);
        }

        if (reaction.deadline) {
          formik.setFieldValue('deadline', new Date(reaction.deadline));
        }

        const type = actionOptions.find(option => option.value === reaction.type);

        if (type && type.value) {
          formik.setFieldValue(
            'action',
            actionOptions.find(option => option.value === reaction.type).value
          );
        }
        setUploadedAttachments(reaction.attachments || []);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reaction]);

  const handleViewRequirement = async eliref => {
    if (eliref) {
      localStorage.setItem('ELIREF_REDIRECT', eliref);
      window.open(
        `${window.location.origin}/#${generatePath(Routes.requirementDetail, {
          projectuid,
          activityuid: activityUid
        })}`,
        '_blank'
      );
    }
  };

  const handleAddSigner = async (newSigner: Signer) => {
    const payload: AddSignerPayload = {
      signerUid: newSigner.signersId
    };
    const result = await addSigner(payload);

    if (result.success) {
      setSigneeModalVisible(false);
      setSigner(newSigner);
    }
  };

  const handleChangeSigner = async (newSigner: Signer) => {
    const payload: ChangeSignerPayload = {
      signerUid: newSigner.signersId
    };

    const result = await changeSigner(payload);
    if (result.success) {
      setSigneeModalVisible(false);
      setSigner(newSigner);
    }
  };

  const handleRemoveSigner = async () => {
    if (signer?.signersId) {
      const res = await removeSigner();
      if (res.success) setSigner(undefined);
    }
  };

  const validationSchema = Yup.object({
    action: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' })),
    followUpBy: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' })),
    deadline: Yup.date()
      .nullable()
      .optional()
      .when(['action', 'isCritical'], {
        is: (action: ReactionType, isCritical: string) => {
          return action === ReactionType.Mandate && isCritical === SdirRadioGroupOptions[1].value;
        },
        then: Yup.date().required(intl.formatMessage({ id: 'common.requiredfield' }))
      }),
    reply: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(intl.formatMessage({ id: 'common.requiredfield' })),
    isCritical: Yup.string().when('action', {
      is: ReactionType.Mandate,
      then: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
    })
  });

  // TODO: Kritikalitet endres fra isCritical. Dette blir fikset opp i senere.
  const initialIsCriticalValue = () => {
    if (reaction !== null) {
      return reaction?.isCritical ? 'true' : 'false';
    }
    return 'true';
  };

  const formik = useFormik({
    initialValues: {
      action: reaction?.type || ReactionType.Comment.toString(),
      reply: reaction?.text || '',
      deadline: reaction?.deadline ? new Date(reaction?.deadline) : null,
      isCritical: initialIsCriticalValue(),
      followUpBy: reaction?.followUpBy,
      caseWorker: reaction?.caseWorker || { name: currentUserName, uid: currentUserId }
    },
    validationSchema,
    onSubmit: values => {
      const payload: UpdateReactionDto = {
        comment: values.reply,
        reactionType: values.action as ReactionType,
        isCritical: values.isCritical === 'true',
        followUpBy: values.followUpBy as FollowUpBy,
        caseWorker: values.caseWorker.uid,
        deadline: values.deadline ? moment(values.deadline).format('YYYY-MM-DD') : null,
        attachments: uploadedAttachments
      };
      if (submittedReaction) {
        if (activity.events?.length) {
          const event: ReactionCreatedDomainEvent = activity.events[activity.events.length - 1];
          handleReplaceReaction(payload, event.reactionId);
        }
      } else {
        submitDraftReaction(payload);
      }
    }
  });

  const handleSendToCustomer = () => {
    formik.handleSubmit();
  };

  const handleSubmitForSigning = async () => {
    const payload: SubmitReactionForSigningPayload = {
      text: formik.values.reply,
      reactionType: formik.values.action as ReactionType,
      deadline: formik.values.deadline
        ? moment(formik.values.deadline).format('YYYY-MM-DD')
        : undefined
    };
    await submitForSigning(payload);
    getActivityDetail();
  };

  const replyType = actionOptions.find(option => option.value === formik.values.action)?.name || '';

  const getSubmitButtonProps = () => {
    if (submittedReaction) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.replacereaction' }),
        disabled: !formik.isValid || loading,
        onClick: () => {
          if (validationSchema.isValidSync(formik.values)) {
            handleSendToCustomer();
          } else {
            formik.setFieldTouched('action');
            formik.setFieldTouched('reply');
            formik.setFieldTouched('followUpBy');
            formik.setFieldTouched('deadline');
          }
        }
      };
    }

    if (reaction?.signStatus === SignStatus.SubmittedForSigning) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.signandsendtocustomer' }),
        disabled: currentUserId !== (signer as Signer)?.signersId || loading,
        onClick: () => {
          try {
            validationSchema.validateSync(formik.values);
          } catch (exception) {
            if (formik.errors.action) formik.setFieldTouched('action');
            if (formik.errors.reply) formik.setFieldTouched('reply');
            if (formik.errors.followUpBy) formik.setFieldTouched('followUpBy');
            if (formik.errors.deadline) formik.setFieldTouched('deadline');
          } finally {
            if (
              !formik.errors.action &&
              !formik.errors.reply &&
              !formik.errors.followUpBy &&
              !formik.errors.deadline
            )
              handleSendToCustomer();
          }
        }
      };
    }

    if (signer) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.sendtosigner' }),
        onClick: () => handleSubmitForSigning(),
        disabled:
          !formik.values.reply ||
          !formik.values.action ||
          !formik.values.followUpBy ||
          (formik.values.action === ReactionType.Mandate && !formik.values.deadline) ||
          loading ||
          submitForSigningLoading
      };
    }

    if (!signer) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.sendtocustomer' }),
        disabled:
          !formik.values.reply ||
          !formik.values.action ||
          !formik.values.followUpBy ||
          (formik.values.action === ReactionType.Mandate && !formik.values.deadline) ||
          loading,
        onClick: () => {
          if (validationSchema.isValidSync(formik.values)) {
            handleSendToCustomer();
          } else {
            formik.setFieldTouched('action');
            formik.setFieldTouched('reply');
            formik.setFieldTouched('followUpBy');
            formik.setFieldTouched('deadline');
          }
        }
      };
    }

    return {};
  };

  const SdirRadioGroupOptions = [
    { label: intl.formatMessage({ id: 'sendreaction.radio.criticality.critical' }), value: 'true' },
    {
      label: intl.formatMessage({ id: 'sendreaction.radio.criticality.notcritical' }),
      value: 'false'
    }
  ];

  const handleReactionTypeChange = val => {
    formik.setFieldValue('action', val);
    if (val !== ReactionType.Mandate) {
      formik.setFieldValue('isCritical', 'true');

      if (formik.values.deadline) {
        formik.setFieldValue('deadline', null);
      }
    }
  };

  const handleIsCriticalChange = val => {
    if (val === SdirRadioGroupOptions[0].value && formik.values.deadline) {
      formik.setFieldValue('deadline', null);
    }
    formik.setFieldValue('isCritical', val);
  };

  const handleDraftButton = () => {
    const payload: UpdateReactionDto = {
      comment: formik.values.reply,
      reactionType: formik.values.action as ReactionType,
      isCritical: formik.values.isCritical === 'true',
      followUpBy: formik.values.followUpBy as FollowUpBy,
      caseWorker: formik.values.caseWorker.uid,
      deadline: formik.values.deadline ? moment(formik.values.deadline).format('YYYY-MM-DD') : null,
      attachments: uploadedAttachments
    };

    if (existingDraft && !draftEditMode) {
      setDraftEditMode(true);
    } else if (existingDraft) {
      updateDraftReactionAndUploadAttachments(payload, getActivityDetail);
    } else if (!existingDraft) {
      createDraftReactionAndUploadAttachments(payload, getActivityDetail);
    }
  };

  const getTitle = () => {
    if (submittedReaction) {
      return intl.formatMessage({ id: 'vessel.activity.status.replacereaction' });
    }

    if (existingDraft) {
      if (draftEditMode) {
        return intl.formatMessage({ id: 'vessel.activity.status.updatedraft' });
      }
      return intl.formatMessage({ id: 'vessel.activity.status.draft' });
    }
    return intl.formatMessage({ id: 'vessel.activity.status.newreaction' });
  };

  const getFollowUpByText = followUpByType => {
    switch (followUpByType) {
      case FollowUpBy.Customer:
        return intl.formatMessage({ id: 'sendreaction.selectoption.customer' });
      case FollowUpBy.Sdir:
        return intl.formatMessage({ id: 'sendreaction.selectoption.sdir' });
      default:
        return '';
    }
  };

  const onCellClicked = e => {
    const found = document?.attachments?.find(el => el.fileName?.includes(e.fileName));
    if (found && found.fileName) {
      downloadByFilename(found.fileName);
    }
  };

  const tableData = useMemo(() => {
    if (!document?.attachments) return [];
    return document?.attachments
      ?.map(file => {
        const filenameParts = file.fileName?.split('/') || '';
        return {
          fileName: filenameParts[filenameParts.length - 1]
        };
      })
      .sort((a, b) =>
        sortDirection === 1
          ? a.fileName.localeCompare(b.fileName)
          : b.fileName.localeCompare(a.fileName)
      );
  }, [document, sortDirection]);

  const leftContent = (
    <>
      {document ? (
        <InformationCard name={vesselName}>
          <ReactionDocumentWrapper>
            <DataWrapper>
              <DataLabel>{intl.formatMessage({ id: 'documentcard.document' })}</DataLabel>
              <DataValue>{document.documentLabel}</DataValue>
            </DataWrapper>
          </ReactionDocumentWrapper>
          {document && document.attachments && (
            <StyledDataTable
              columns={[
                {
                  label: intl.formatMessage({ id: 'common.files' }),
                  key: 'fileName'
                }
              ]}
              data={tableData}
              onHeaderClick={e => setSortDirection(e.direction)}
              onCellClick={onCellClicked}
              sortableKeys="*"
            />
          )}
        </InformationCard>
      ) : (
        <InformationCard name={vesselName} />
      )}
    </>
  );

  const rightContent = (
    <>
      <DataCard>
        <Container>
          <Title>{getTitle()}</Title>
          {submittedReaction && (
            <ReplaceReactionNotice>
              <AlertSingleLine
                title=""
                type="info"
                text={intl.formatMessage({ id: 'sendreaction.replacereaction.notice' })}
                hideClose
              />
            </ReplaceReactionNotice>
          )}
          <ActivityCard
            internalComment={activity?.internalComment}
            title={activity?.name}
            description={activity?.description}
            type={
              activity?.checkpointType &&
              intl.formatMessage({
                id: `checkpointtype.${activity?.checkpointType?.split('#')[1]?.toLowerCase()}`
              })
            }
            icon={
              activity?.checkpointType?.includes('DocumentControl') ? (
                <SdiFile />
              ) : (
                <InspectionIcon />
              )
            }
            elirefs={activity?.requirementEliRefs}
            goToRequirementClick={eliref => handleViewRequirement(eliref)}
          />
          <ColumnContainer>
            <LeftColumn>
              {existingDraft && !draftEditMode ? (
                <div>
                  <Label>
                    {intl.formatMessage({
                      id: 'sendreaction.select.type.placeholder'
                    })}
                  </Label>
                  <ValuePreview>
                    {formik.values.action ? getReactionType(formik.values.action) : noValueConst}
                  </ValuePreview>
                </div>
              ) : (
                <Select
                  defaultVal={intl.formatMessage({ id: 'sendreaction.select.type.placeholder' })}
                  options={actionOptions}
                  handleChange={handleReactionTypeChange}
                  value={formik.values.action}
                  error={formik.touched.action ? formik.errors.action : ''}
                />
              )}
              {existingDraft && !draftEditMode ? (
                <div>
                  <Label>
                    {intl.formatMessage({
                      id: 'sendreaction.select.followupby.placeholder'
                    })}
                  </Label>
                  <ValuePreview>
                    {formik.values.followUpBy
                      ? getFollowUpByText(formik.values.followUpBy)
                      : noValueConst}
                  </ValuePreview>
                </div>
              ) : (
                <Select
                  defaultVal={intl.formatMessage({
                    id: 'sendreaction.select.followupby.placeholder'
                  })}
                  options={followUpByOptions}
                  handleChange={val => formik.setFieldValue('followUpBy', val)}
                  value={formik.values.followUpBy}
                  error={formik.touched.followUpBy ? formik.errors.followUpBy : ''}
                />
              )}
              {existingDraft && !draftEditMode ? (
                <div>
                  <Label>
                    {intl.formatMessage({ id: 'sendreaction.deadlineforresponese.header' })}
                  </Label>
                  <ValuePreview>
                    {formik.values.deadline
                      ? moment(formik.values.deadline).format('DD.MM.YYYY')
                      : noValueConst}
                  </ValuePreview>
                </div>
              ) : (
                <SdirDatePicker
                  disabled={
                    formik.values.action !== ReactionType.Mandate ||
                    formik.values.isCritical === SdirRadioGroupOptions[0].value
                  }
                  locale="no"
                  id="deadline"
                  placeholder={intl.formatMessage({
                    id: 'sendreaction.deadlineforresponese.header'
                  })}
                  dateFormat="dd.MM.yyyy"
                  onChange={v => formik.setFieldValue('deadline', v)}
                  value={formik.values.deadline}
                  error={formik.touched.deadline ? formik.errors.deadline : ''}
                />
              )}
            </LeftColumn>
            <RightColumn>
              <RadioButtonWrapper>
                <Label>{intl.formatMessage({ id: 'sendreaction.radio.criticality.label' })}</Label>
                {existingDraft && !draftEditMode ? (
                  <ValuePreview>
                    {formik.values.isCritical === SdirRadioGroupOptions[0].value
                      ? SdirRadioGroupOptions[0].label
                      : SdirRadioGroupOptions[1].label}
                  </ValuePreview>
                ) : (
                  <SdirRadioButtonGroup
                    disabled={formik.values.action !== ReactionType.Mandate}
                    as={CustomContainer}
                    options={SdirRadioGroupOptions}
                    onChange={e => handleIsCriticalChange(e.target.value)}
                    value={formik.values.isCritical}
                    name={intl.formatMessage({ id: 'sendreaction.radio.criticality.label' })}
                  />
                )}
              </RadioButtonWrapper>
            </RightColumn>
          </ColumnContainer>
          <TextAreaTitle>{`${replyType} ${intl.formatMessage({
            id: 'sendreaction.textarea.label'
          })}`}</TextAreaTitle>
          {existingDraft && !draftEditMode ? (
            <TextAreaPreview>{formik.values.reply || noValueConst}</TextAreaPreview>
          ) : (
            <Textarea
              id="reply"
              contentValue={formik.values.reply}
              onChange={e => formik.setFieldValue('reply', e.target.value)}
              error={formik.touched.reply ? formik.errors.reply : ''}
              rows={8}
            />
          )}
          <ReactionFileSection
            editMode={!existingDraft || (existingDraft && draftEditMode)}
            uploadFiles={storeFilesForUpload}
            uploadedFiles={uploadedAttachments}
            unuploadedFiles={newAttachments}
            isUploading={isUploading}
            progress={progress}
            deleteFile={deleteFile}
            reactionUid={reaction?.reactionUid}
            downloadAttachment={downloadAttachment}
          />
          <Signerbox
            editMode={!existingDraft || (existingDraft && draftEditMode)}
            isOptional
            signer={signer}
            caseWorker={formik.values.caseWorker}
            setSigneeModalVisible={setSigneeModalVisible}
            setCaseWorkerModalVisible={setCaseWorkerModalVisible}
            loading={loading || etCaseWorkersLoading}
            width="auto"
            hideSigner
          />
          <ButtonContainer
            justifyContent={
              (existingDraft && !draftEditMode) || submittedReaction ? 'space-between' : 'flex-end'
            }
          >
            {existingDraft && !draftEditMode && (
              <TextLink
                icon={<SdiTrash size="s" />}
                text={intl.formatMessage({ id: 'caseworker.reply.deletereaction' })}
                click={() => deleteDraftReaction()}
              />
            )}
            {submittedReaction && (
              <TextLink
                text={intl.formatMessage({ id: 'common.cancel' })}
                click={() => {
                  navigate(
                    generatePath(Routes.vesselWorkspaceScrollTo, {
                      tab: 'activitylist',
                      projectuid,
                      scrollToId: activityUid
                    })
                  );
                }}
              />
            )}
            <div>
              {!submittedReaction && (
                <Button
                  type="default"
                  disabled={loading}
                  text={
                    existingDraft && !draftEditMode
                      ? intl.formatMessage({ id: 'caseworker.reply.updatedraft' })
                      : intl.formatMessage({ id: 'caseworker.reply.savedraft' })
                  }
                  onClick={handleDraftButton}
                />
              )}
              <Button type="primary" {...getSubmitButtonProps()} />
            </div>
          </ButtonContainer>
        </Container>
      </DataCard>
      {children}
      <AddSignerCaseWorkerModal
        visible={isSigneeModalVisible}
        setVisible={() => setSigneeModalVisible(!isSigneeModalVisible)}
        handleAddUser={handleAddSigner}
        addUserLoading={addSignerLoading}
        allUsers={allUsers}
        handleRemoveUser={handleRemoveSigner}
        chosenUser={signer}
        handleChangeUser={handleChangeSigner}
      />
      {isCaseWorkerModalVisible && (
        <AddSignerCaseWorkerModal
          visible
          setVisible={() => setCaseWorkerModalVisible(!isCaseWorkerModalVisible)}
          allUsers={allUsers}
          chosenUser={{
            username: formik.values.caseWorker.name || '',
            signersId: formik.values.caseWorker.uid || ''
          }}
          handleChangeUser={caseWorker => {
            formik.setFieldValue('caseWorker', {
              name: caseWorker.username,
              uid: caseWorker.signersId
            });
            setCaseWorkerModalVisible(!isCaseWorkerModalVisible);
          }}
        />
      )}
    </>
  );

  return <InformationCardPageContainer leftContent={leftContent} rightContent={rightContent} />;
};

const StyledDataTable = styled(DataTable)`
  padding: 0;
  margin-top: 1rem;

  table {
    border-spacing: 0 1rem;
    border-collapse: separate;
}
  }

  &&& {
    th {
      padding: 0;
    }

    tr {
      td {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
      }
    }
  }
`;

const ButtonContainer = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const Button = styled(SdirButton)`
  margin-left: 5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  margin-bottom: 3rem;
`;

const Container = styled.div`
  padding: 5rem;

  textarea {
    width: 110rem;
    resize: vertical;
  }
`;

const ValuePreview = styled.p`
  font-weight: bold;
  margin-top: 0;
`;

const ReplaceReactionNotice = styled.div`
  margin-bottom: 4rem;
`;

const TextAreaPreview = styled.p`
  white-space: pre-wrap;
`;

const ColumnContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: space-between;
  height: 26rem;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: space-between;
`;

const RadioButtonWrapper = styled.div`
  padding-left: 14rem;
`;

const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.font.text}CC`};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;

  [data-id='base-item'] {
    padding-top: 2rem;
  }
`;

const ReactionDocumentWrapper = styled.div`
  margin-top: 2rem;
`;

export default SendReaction;
