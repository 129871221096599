/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { activityApi, requirementApi, projectApi, checkpointsApi } from '../../httpclient';
import { SET_ACTIVITIES_OVERVIEW, SET_SUBJECTAREA_LIST } from '../../store/constants/action-types';

const useRequirements = projectuid => {
  const { setError } = useToast();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { scrollToId } = useParams() as { scrollToId?: string };

  const {
    result: vessel,
    loading: vesselLoading,
    errorObject: vesselError,
    callApi: getVessel
  } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid),
    callApiOnInit: false,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'prosjekt' })}: ${err}`);
      }
    }
  });

  const { result: requirementsOverview, loading: requirementsOverviewLoading } = useApi({
    apiFunction: () =>
      requirementApi.v3RequirementGetprojectrequirementsoverviewProjectUidGet(projectuid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    }
  });

  const {
    result: filteredReqList,
    loading: filteredReqListLoading,
    callApi: getFilteredReqs
  } = useApi({
    apiFunction: (eliref = undefined) =>
      requirementApi.v3RequirementGetprojectrequirementsv3ChapterEliReferenceGet(
        projectuid,
        eliref
      ),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    }
  });

  const {
    result: activityList,
    loading: getActivityListLoading,
    callApi: getActivityList
  } = useApi({
    apiFunction: () => activityApi.v3ActivityGetprojectactivities3SubjectAreaUriGet(projectuid),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'aktiviteter' })}: ${err}`);
    }
  });

  const {
    result: subjectAreas,
    loading: getSubjectAreasLoading,
    callApi: getSubjectAreas
  } = useApi({
    apiFunction: () => checkpointsApi.v1CheckpointsGetsubjectareasGet(projectuid),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'fagområder' })}: ${err}`);
    },
    successCallback: data => {
      dispatch({ type: SET_SUBJECTAREA_LIST, payload: data, prop: 'subjectAreas' });
    }
  });

  const { loading: subjectAreaListLoading, callApi: getProjectActivitiesOverview } = useApi({
    apiFunction: () => activityApi.v3ActivityGetprojectactivitiesoverviewv3ProjectIdGet(projectuid),
    callApiOnInit: false,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    },
    successCallback: data => {
      dispatch({ type: SET_ACTIVITIES_OVERVIEW, payload: data });
      const autoselectSubjectarea = localStorage.getItem('newActivityCurrentSubjectarea');
      if (autoselectSubjectarea) {
        getActivityList(autoselectSubjectarea);
        localStorage.removeItem('newActivityCurrentSubjectarea');
      } else if (
        !scrollToId &&
        data.subjectAreaDocuments &&
        data.subjectAreaDocuments[0] &&
        data.subjectAreaDocuments[0].subjectAreaUri
      ) {
        getActivityList(data.subjectAreaDocuments[0].subjectAreaUri);
      } else if (scrollToId && data.subjectAreaDocuments) {
        const subjectArea = data.subjectAreaDocuments.find(el =>
          el.activityOrActivityGroupOverview?.some(al => {
            if (al?.activityDetail?.uid === scrollToId) return true;
            return al?.documentActivitiesOverview?.activityOverview?.some(
              ul => ul?.uid === scrollToId
            );
          })
        );
        getActivityList(subjectArea?.subjectAreaUri);
      } else {
        getActivityList();
      }
    }
  });

  const workspaceData = {
    vesselLoading,
    vesselError,
    vessel,
    getVessel,
    subjectAreaListLoading,
    filteredReqList,
    filteredReqListLoading,
    getFilteredReqs,
    requirementsOverview,
    requirementsOverviewLoading,
    getActivityList,
    activityList,
    getActivityListLoading,
    getProjectActivitiesOverview,
    subjectAreas,
    getSubjectAreasLoading,
    getSubjectAreas
  };

  return workspaceData;
};

export default useRequirements;
