import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import moment from 'moment';
import { SdirModal, SdirButton, Loader, AlertSingleLine } from '@sdir/sds';
import { Detail } from '@sdir/httpclient/lib/clients/aps/seagoing';
import { Field, Formik, Form } from 'formik';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { sortObjects } from '../../../helpers';
import { SortingDirection } from '../../../types/enums';
import { seagoingServicesApi, occupationRegisterApi } from '../../../httpclient';
import FormInput from '../../Molecules/FormInput';
import FormDate from '../../Molecules/FormDate';
import FormSelect from '../../Molecules/FormSelect';

export type ReportingTimeData = {
  legalEntityId: string;
  serviceCode: string | null | undefined;
};

export interface SelectOption {
  name: string;
  value: string;
}

export interface EditModalProps {
  display: boolean;
  row: Detail | null;
  onCancelClick: () => void;
  onSuccessEdit: () => void;
}

// eslint-disable-next-line max-len
const editSeagoingReportApi = seagoingServicesApi?.v1SeagoingservicesUpdateservicereportServiceReportPut.bind(
  seagoingServicesApi
);

const getSeagoingReportApi = seagoingServicesApi?.v1SeagoingservicesGetservicereportUidGet.bind(
  seagoingServicesApi
);

const EditModal: React.FC<EditModalProps> = ({ display, row, onCancelClick, onSuccessEdit }) => {
  const intl = useIntl();

  const requiredText = intl.formatMessage({ id: 'vessel.general.form.error' });

  const { loading, error, callApi: performEdit } = useApi({
    apiFunction: (uidToEdit, payload) => editSeagoingReportApi(uidToEdit, payload),
    callApiOnInit: false
  });

  const { loading: loadingSeagoing, callApi: getSeagoing } = useApi({
    apiFunction: uidToGet => getSeagoingReportApi(uidToGet),
    callApiOnInit: false
  });

  const {
    result: occupations = [],
    errorObject: occupationsError,
    loading: occupationsLoading
  } = useApi({
    apiFunction: () => occupationRegisterApi.v1OccupationregisterGetregisterGet(),
    callApiOnInit: true
  });

  const occupationOptions = useMemo(() => {
    const options = occupations.map<SelectOption>(o => ({
      name: o.title,
      value: o.code,
      group: o.category
    }));
    return sortObjects(options, { key: 'name', direction: SortingDirection.ASC });
  }, [occupations]);

  const DateDisplayFormat = 'DD.MM.YYYY';

  const handleSubmit = useCallback(
    async values => {
      const report = await getSeagoing(row?.uid);
      if (report.success) {
        report.data.activity.occupationCode = values?.occupation;
        report.data.activity.startDate = values?.from.format('YYYY-MM-DD');
        report.data.activity.endDate = values?.to.format('YYYY-MM-DD');
        report.data.activity.totalHours = +values?.hours;
        const apiResult = await performEdit(row?.uid, report.data);
        if (apiResult.success) {
          onSuccessEdit();
        }
      }
    },
    [onSuccessEdit]
  );

  const initialValues = {
    occupation: row?.serviceCode,
    from: moment(row?.signOnDate),
    to: moment(row?.signOffDate),
    hours: row?.hours
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      occupation: Yup.string().required(requiredText),
      from: Yup.string().required(requiredText),
      to: Yup.string().required(requiredText),
      hours: Yup.string().required(requiredText)
    });
  }, [requiredText]);

  return display ? (
    <SdirModal width="300px" height="400px" visible={display} onCancel={onCancelClick}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <Form>
              <FieldWrapperOccupation
                name="occupation"
                component={FormSelect}
                grouped
                searchable
                options={occupationOptions}
                defaultValue={row?.serviceCode}
              />
              <FieldWrapper
                format={DateDisplayFormat}
                maxDate={moment()}
                name="from"
                placeholder={intl.formatMessage({
                  id: 'vessel.editmodal.from'
                })}
                type="number"
                showYearDropdown
                showMonthDropdown
                component={FormDate}
              />
              <FieldWrapper
                format={DateDisplayFormat}
                maxDate={moment()}
                name="to"
                placeholder={intl.formatMessage({
                  id: 'vessel.editmodal.to'
                })}
                type="number"
                showYearDropdown
                showMonthDropdown
                component={FormDate}
              />
              <FieldWrapper
                name="hours"
                component={FormInput}
                label={intl.formatMessage({ id: 'vessel.editmodal.hours' })}
                type="text"
              />
              <Container>
                <ButtonsSection>
                  <SdirButton
                    onClick={onCancelClick}
                    type="default"
                    text={intl.formatMessage({ id: 'vessel.editmodal.close' })}
                    htmlType="button"
                  />
                  <SdirButton
                    onClick={submitForm}
                    type="primary"
                    text={intl.formatMessage({ id: 'vessel.editmodal.save' })}
                    htmlType="button"
                  />
                </ButtonsSection>
              </Container>
              {(loading || loadingSeagoing || occupationsLoading) && <Loader />}
              {(error || occupationsError) && (
                <AlertSingleLine
                  type="error"
                  title="Error"
                  text={intl.formatMessage({ id: 'vessel.editmodal.edit.error' })}
                />
              )}
            </Form>
          )}
        </Formik>
      </Wrapper>
    </SdirModal>
  ) : null;
};

const FieldWrapperOccupation = styled(Field)`
  width: 40em;
  margin-bottom: 16px;
`;

const FieldWrapper = styled(Field)`
  width: 30em;
  margin-bottom: 16px;
`;

const ButtonsSection = styled.div`
  display: flex;
  width: 100%;
  height: 20rem;
  justify-content: space-between;
  margin: 0px 0;
`;

const Container = styled.div`
  display: flex;
  margin-top: 50px;
  height: 7rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 19rem;
  margin: 0px 0;
`;

export default EditModal;
