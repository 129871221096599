import React from 'react';
import { FileUpload, FileLink } from '@sdir/sds';
import {
  GetActivityActionExemptionResult,
  GetActivityActionNotRelevantResult
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { fileExists } from '../../../helpers/fileHelpers';
import useReplyFiles from './useReplyFiles';
import { allowedUploadFileTypes } from '../../../helpers';

interface Props {
  actionMessage:
    | GetActivityActionExemptionResult
    | GetActivityActionNotRelevantResult
    | null
    | undefined;
  uploadedFiles: any[];
  uploadFile: (file: File) => void;
  isUploading: boolean;
  progress: number;
  onDeleteSuccess: (attachmentId) => void;
}

const ReplyFileSection: React.FC<Props> = ({
  actionMessage,
  uploadedFiles,
  uploadFile,
  isUploading,
  progress,
  onDeleteSuccess
}) => {
  const { downloadFile, deleteFile } = useReplyFiles({ onDeleteSuccess });
  const intl = useIntl();

  // Filter out files that are also in local state
  const attachments = actionMessage?.reply?.attachments;
  const lowercasedUploadedFiles = uploadedFiles.map(file => ({
    fileName: file.fileName.toLowerCase()
  }));

  const filtered = attachments?.filter(file => {
    const fileName = file.blobName?.split('/')[3].toLowerCase();
    return !fileExists(fileName || '', lowercasedUploadedFiles);
  });

  return (
    <>
      <FileUpload
        width="32rem"
        allowedTypes={allowedUploadFileTypes}
        buttonLabel={intl.formatMessage({ id: 'filecard.clicktoupload' })}
        onFilesSelected={files => uploadFile(files[0])}
        uploadProgress={+(progress > 0) && +isUploading && progress}
      />
      <FileContainer>
        {uploadedFiles.map(attachment => {
          return (
            <FileLink
              key={attachment.fileName}
              width="45rem"
              text={`${attachment.fileName}`}
              onClick={() =>
                downloadFile({
                  attachmentId: attachment.uid,
                  fileName: attachment.fileName,
                  requestId: actionMessage?.actionUid
                })
              }
              onDelete={() =>
                deleteFile({ requestId: actionMessage?.actionUid, attachmentId: attachment.uid })
              }
            />
          );
        })}
        {filtered?.map(attachment => {
          return (
            <FileLink
              key={attachment.blobName}
              width="45rem"
              text={`${attachment.blobName?.split('/')[3]}`}
              onClick={() =>
                downloadFile({
                  attachmentId: attachment.uid,
                  fileName: attachment.blobName?.split('/')[3],
                  requestId: actionMessage?.actionUid
                })
              }
              onDelete={() =>
                deleteFile({ requestId: actionMessage?.actionUid, attachmentId: attachment.uid })
              }
            />
          );
        })}
      </FileContainer>
    </>
  );
};

const FileContainer = styled.div`
  margin-top: 2rem;
  > * {
    margin-bottom: 2rem;
  }
`;

export default ReplyFileSection;
