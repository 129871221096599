import { AxiosResponse } from 'axios';

class ApiError extends Error {
  response: AxiosResponse<any>;

  constructor(response: AxiosResponse<any>, ...params) {
    super(...params);

    this.name = 'ApiError';
    this.response = response;
  }
}

export default ApiError;
