export const dummyVessel = {
  name: 'MS Mona',
  imo: '41914511',
  callsign: 'MSMNA',
  constructionDate: '04.03.2021',
  length: 5,
  radioCoverage: 'https://www.sdir.no/SDIR_Simulator#A2',
  status: 'Nybygg',
  tradeArea: 'https://www.sdir.no/SDIR_Simulator#InShore',
  uid: '6efa537a-8d98-48ae-d3ce-08d8e5a157da',
  vesselType: 'https://www.sdir.no/SDIR_Simulator#FishingVessel'
};

export const dummyMyTask = {
  columns: [
    {
      label: 'Kunde',
      key: 'customer'
    },
    {
      label: 'Type',
      key: 'type'
    },
    {
      label: 'Prosjektløp',
      key: 'status'
    },
    {
      label: 'Tidsfrist',
      key: 'time'
    },
    {
      label: 'Behandle',
      key: 'handleBy'
    }
  ],
  data: [
    {
      customer: 'Misje Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '04d:12t:05m',
      handleBy: 'ET'
    },
    {
      customer: 'Stord Shipping',
      type: 'Avventer',
      status: 'Under behandling',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Scan Shipping',
      type: 'Påtegning',
      status: 'Klar til behandling',
      time: '02d:12t:05m',
      handleBy: 'ET'
    },
    {
      customer: 'Abc Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Avventer',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Misje Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '03d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Spa Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Klar til behandling',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Dis Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '05d:12t:05m',
      handleBy: 'ET'
    },
    {
      customer: 'Misje Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '04d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Stord Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Scan Shipping',
      type: 'Påtegning',
      status: 'Klar til behandling',
      time: '02d:12t:05m',
      handleBy: 'ET'
    },
    {
      customer: 'Abc Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Avventer',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Misje Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '03d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Spa Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Klar til behandling',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Dis Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '05d:12t:05m',
      handleBy: 'ET'
    },
    {
      customer: 'Spa Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Klar til behandling',
      time: '01d:12t:05m',
      handleBy: 'APS'
    },
    {
      customer: 'Dis Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '05d:12t:05m',
      handleBy: 'ET'
    }
  ]
};

export const dummyCharges = {
  columns: [
    {
      label: 'Paid By',
      key: 'paidBy'
    },
    {
      label: 'Type',
      key: 'type'
    },
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Time',
      key: 'time'
    }
  ],
  data: [
    {
      paidBy: 'Misje Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '04d:12t:05m'
    },
    {
      paidBy: 'Stord Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Under behandling',
      time: '01d:12t:05m'
    },
    {
      paidBy: 'Scan Shipping',
      type: 'Påtegning',
      status: 'Klar til behandling',
      time: '02d:12t:05m'
    },
    {
      paidBy: 'Abc Shipping',
      type: 'Dispensasjon fra nasjonalitetskrav',
      status: 'Avventer',
      time: '01d:12t:05m'
    }
  ]
};

export const DummyProfileVisit = {
  columns: [
    {
      label: 'Visitor',
      key: 'visitor'
    },
    {
      label: 'Time',
      key: 'time'
    }
  ],
  data: [
    {
      visitor: 'Danial Kors',
      time: '04d:12t:05m'
    },
    {
      visitor: 'Jack K',
      time: '01d:12t:05m'
    },
    {
      visitor: 'Kim Lura',
      time: '06d:12t:05m'
    }
  ]
};

export const dummyActivity = {
  id: '001',
  title: 'Kart og nautiske publikasjoner',
  type: 'checklist',
  responsible: 'Kundenavn',
  requirementLink: 'https://localhost:3000/requirementlist?id=1',
  renewalDate: '20.10.2015',
  status: {
    id: 1,
    label: 'Ikke bekreftet'
  },
  data: {
    regulations: [
      {
        id: 'CC333',
        shortDescription: 'Oppdaterte offisielle sjøkart for området båten opererer'
      }
    ]
  }
};

export const dummyVesselTable = {
  columns: [
    {
      label: 'Fartøy navn',
      key: 'name'
    },
    {
      label: 'Fagområde',
      key: 'area'
    },
    {
      label: 'Oppgave',
      key: 'task'
    },
    {
      label: 'Behandlingsfrist',
      key: 'time'
    },
    {
      label: 'Saksbehandler',
      key: 'handleBy'
    },
    {
      label: 'Status',
      key: 'status'
    }
  ],
  data: [
    {
      name: 'Misje Shipping',
      area: 'Navigasjon',
      task: 'Dokumentkontroll',
      time: '04d:12t:05m',
      handleBy: 'Ola Normann',
      status: 'Ny informasjon',
      statusIndicator: 0
    },
    {
      name: 'Knot shore',
      area: 'Maskin',
      task: 'Inspeksjon',
      time: '04d:12t:05m',
      handleBy: 'Sara Watkins',
      status: 'Til behandling',
      statusIndicator: 1
    },
    {
      name: 'Misje Shipping',
      area: 'Navigasjon',
      task: 'Audits',
      time: '04d:12t:05m',
      handleBy: 'Eugene Schneider',
      status: 'Venter på informasjon',
      statusIndicator: 2
    },
    {
      name: 'Misje Shipping',
      area: 'Navigasjon',
      task: 'Dispensasjon',
      time: '04d:12t:05m',
      handleBy: 'Eliza Carrol',
      status: 'Reaksjon',
      statusIndicator: 3
    },
    {
      name: 'Misje Shipping',
      area: 'Navigasjon',
      task: 'Andregangs kontroll',
      time: '04d:12t:05m',
      handleBy: 'Ola Normann',
      status: 'Bekreftet',
      statusIndicator: 4
    }
  ]
};
