import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { DataTable, DataCard, TextLink } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { GetTasksResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { generatePath, useNavigate } from 'react-router-dom';
import { SortableProperty } from '@sdir/utilities/lib/types/sorting';
import { useDataSorting, useToast } from '@sdir/utilities/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { compareDates, DisplayDateFormat } from '../../../helpers';
import { getProjectCaseWorkersApi } from '../../../httpclient';
import { Routes } from '../../Templates/Routes/constants';
import { ChangeCasehandlerModal } from '../../Molecules';

interface TasksTableProps {
  tasksResult: GetTasksResult[];
  reloadTasks: () => void;
}

interface TableRow<T> extends SortableProperty<T> {
  label: string;
}

interface MappedTasks {
  vesselName: string;
  vesselCallSign: string;
  theme: string;
  name: string;
  deadlineDate: string;
  dateValue: string | null;
  caseWorkerName: any;
  vesselUid: string;
  activityUid: string;
  projectUid: string;
}

const TasksTable: React.FC<TasksTableProps> = ({ tasksResult, reloadTasks }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { setError } = useToast();
  const [displayChangeCaseWorkerModal, setDisplayChangeCaseWorkerModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<MappedTasks>();

  const columns: TableRow<MappedTasks>[] = [
    {
      label: intl.formatMessage({ id: 'tasktable.vesselname' }),
      key: 'vesselName'
    },
    {
      label: intl.formatMessage({ id: 'tasktable.callsign' }),
      key: 'vesselCallSign'
    },
    {
      label: intl.formatMessage({ id: 'tasktable.subjectarea' }),
      key: 'theme'
    },
    {
      label: intl.formatMessage({ id: 'tasktable.task' }),
      key: 'name'
    },
    {
      label: intl.formatMessage({ id: 'tasktable.deadline' }),
      key: 'deadlineDate',
      sortFunction: (a, b) => compareDates(a.dateValue, b.dateValue)
    },
    {
      label: intl.formatMessage({ id: 'tasktable.caseworker' }),
      key: 'caseWorkerName'
    }
  ];

  const { result: sdirTeam, callApi: getSdirTeam } = useApi({
    apiFunction: uid =>
      getProjectCaseWorkersApi.v1GetprojectcaseworkersGetprojectteammembersProjectIdGet(uid),
    errorCallback: err => {
      if (err.length > 0)
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'sdirteam' })}: ${err}`);
    }
  });

  const onChangeCaseHandlerClicked = useCallback(
    test => {
      setSelectedTask(test);
      getSdirTeam(test.projectUid);
      setDisplayChangeCaseWorkerModal(true);
    },
    [getSdirTeam]
  );

  const onCancelClick = () => {
    setDisplayChangeCaseWorkerModal(false);
    setSelectedTask(undefined);
  };

  const getMyTasksTabelData = useCallback(
    (data: GetTasksResult[]) =>
      data.map<MappedTasks>(val => {
        return {
          vesselUid: val.vesselUid || '',
          activityUid: val.activityUid || '',
          vesselName: val.vesselName || '',
          vesselCallSign: val.vesselCallSign || '',
          theme: val.theme || '',
          name: val.name || '',
          projectUid: val.projectUid || '',
          deadlineDate: val.deadlineDate ? DisplayDateFormat(val.deadlineDate) : '',
          dateValue: val.deadlineDate ? val.deadlineDate : null,
          caseWorkerName: (
            <CaseHandlerWrapper>
              {val.caseWorkerName || val.caseWorkerUid}
              <TextLink
                click={() => onChangeCaseHandlerClicked(val)}
                text={intl.formatMessage({ id: 'common.change' })}
              />
            </CaseHandlerWrapper>
          )
        };
      }),
    [onChangeCaseHandlerClicked, intl]
  );

  const { sortedData, setSortedBy } = useDataSorting<any>(
    getMyTasksTabelData(tasksResult),
    columns
  );

  const onCellClicked = (e, key) => {
    if (key !== 'caseWorkerName') {
      navigate(
        generatePath(Routes.vesselWorkspaceScrollTo, {
          tab: 'vesselcard',
          projectuid: e.projectUid,
          scrollToId: e.activityUid
        })
      );
    }
  };

  const onCaseWorkerChanged = () => {
    setDisplayChangeCaseWorkerModal(false);
    reloadTasks();
  };

  return (
    <>
      <DataCard>
        <DataTable
          columns={columns}
          data={sortedData}
          onHeaderClick={setSortedBy}
          sortableKeys="*"
          onCellClick={(e, key) => onCellClicked(e, key)}
        />
      </DataCard>
      <ChangeCasehandlerModal
        activityId={selectedTask?.activityUid || ''}
        sdirTeam={sdirTeam}
        display={displayChangeCaseWorkerModal}
        onCancelClick={() => onCancelClick()}
        onSubmit={onCaseWorkerChanged}
      />
    </>
  );
};

const CaseHandlerWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  gap: 1rem;
  align-items: baseline;
  button {
    font-family: ${({ theme }) => theme.fonts.stfMedium};
  }
`;

export default TasksTable;
