import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DataTable } from '@sdir/sds';
import { SortingOption } from '../../types/index.d';
import { sortObjects } from '../../helpers';
import { dummyMyTask } from '../../helpers/dummyData';

const MyTasksTable: React.FC = () => {
  const [sortedBy, setSortedBy] = useState<SortingOption>();
  const [data, setData] = useState(dummyMyTask);

  useEffect(() => {
    if (sortedBy) {
      const sortedData = sortObjects(dummyMyTask.data, sortedBy);
      setData({ columns: dummyMyTask.columns, data: sortedData });
    }
  }, [sortedBy]);

  return (
    <Container>
      <DataTable
        columns={data.columns}
        data={data.data}
        onHeaderClick={setSortedBy}
        sortableKeys="*"
      />
    </Container>
  );
};
export default MyTasksTable;

const Container = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  margin-bottom: ${({ theme }) => theme.card.marginBottom};
`;
