import React, { useCallback } from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SdirButton } from '@sdir/sds';
import { DisplayDateFormat } from '../../helpers';
import { Info } from '../Atoms';
import { InfoField } from '../Molecules';
import { Routes } from '../Templates/Routes';
import { SystemState } from '../../types/index.d';

interface SeafarerCardInterface {
  isSearchActive: boolean;
  setIsSearcActive: (val: boolean) => void;
}

const SeafarerCard: React.FC<SeafarerCardInterface> = ({ isSearchActive, setIsSearcActive }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { appState } = useSelector((state: SystemState) => state);
  const tempCustomer = appState.customer || {};
  const customer = tempCustomer;

  const goToCustomer = useCallback(() => {
    setIsSearcActive(!isSearchActive);
    navigate(generatePath(Routes.person, { commonAddresseeId: customer[0].commonAddresseeId }));
  }, [history, setIsSearcActive, customer, isSearchActive]);

  const Fullname = `${customer[0].firstName} ${customer[0].lastName}`;
  const Address = customer[0].address ? customer[0].address[0] : '';

  return (
    <Container>
      <Title>
        {intl.formatMessage({ id: 'datacard.seafarer.title' })} - {Fullname}
      </Title>
      <MainBody>
        <CustomerDetails>
          <div>
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.name' })}
              text={Fullname}
            />
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.dob' })}
              text={DisplayDateFormat(customer[0].dateOfBirth)}
            />
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.dNumber' })}
              text={customer[0].personalIdentificationNumber}
            />
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.address' })}
              text={Address.streetName ? Address.streetName : ''}
            />
            <Info
              text={Address.city && Address.zipCode ? `${Address.city} ${Address.zipCode}` : ''}
            />
            <Info text={Address.country ? Address.country : ''} />
          </div>
          <div>
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.citizenship' })}
              text={customer[0].citizenship}
            />
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.telephone' })}
              text={customer[0].phonenumber}
            />
            <InfoField
              label={intl.formatMessage({ id: 'aps.customerCard.email' })}
              text={customer[0].email}
            />
          </div>
        </CustomerDetails>
      </MainBody>
      <ButtonsSection>
        <SdirButton
          type="primary"
          onClick={() => goToCustomer()}
          text={intl.formatMessage({
            id: 'search.customer.preview.button'
          })}
        />
      </ButtonsSection>
    </Container>
  );
};

export default SeafarerCard;

const Container = styled.div`
  margin: 0 6rem;
  width: 100%;
`;
const MainBody = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-weight: bold;
`;

const CustomerDetails = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const ButtonsSection = styled.div`
  width: 30%;
  box-sizing: border-box;
  margin-top: 4em;
  float: right;
  margin: 20rem 19rem 4rem 19rem;

  button {
    white-space: nowrap;
  }
`;
