import React, { useCallback, useEffect, useState } from 'react';
import { SdirModal, SearchBar, TextLink, SdirButton, SdiTrash } from '@sdir/sds';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Signer } from '../../../store/constants/types';

export enum UserTypes {
  CaseWorker = 'caseworker',
  Signee = 'signee'
}

interface AddSignerCaseWorkerModalProps {
  visible: boolean;
  setVisible: () => void;
  handleAddUser?: ({ username, signersId }) => void;
  handleChangeUser: ({ username, signersId }) => void;
  addUserLoading?: boolean;
  allUsers: {
    username: string;
    signersId: string;
  }[];
  handleRemoveUser?: () => void;
  chosenUser: Signer | undefined;
  userType?: UserTypes;
}

const AddSignerCaseWorkerModal: React.FC<AddSignerCaseWorkerModalProps> = ({
  visible,
  setVisible,
  handleAddUser,
  addUserLoading,
  allUsers,
  handleRemoveUser,
  chosenUser,
  handleChangeUser,
  userType = UserTypes.Signee
}) => {
  const [searchMatches, setSearchMatches] = useState<Signer[]>([]);
  const [chosen, setChosen] = useState<Signer | undefined>(undefined);
  const intl = useIntl();

  useEffect(() => {
    if (chosenUser) setChosen(chosenUser);
  }, [chosenUser]);

  const handleClick = user => {
    if (!chosenUser) {
      handleAddUser && handleAddUser(user);
    } else {
      handleChangeUser(user);
    }
  };

  const searchUserNames = useCallback(
    async searchText => {
      setSearchMatches([]);
      if (searchText) {
        const words = searchText.split(' ');
        setSearchMatches(
          allUsers.filter(
            user => !words.some(w => user.username.toLowerCase().indexOf(w.toLowerCase()) === -1)
          )
        );
      }
    },
    [allUsers]
  );

  return (
    <SdirModal
      visible={visible}
      onCancel={() => setVisible()}
      width="52rem"
      isFooter={false}
      closeButton={false}
    >
      <Content>
        <Header>{intl.formatMessage({ id: `${userType}.modal.title` })}</Header>
        <SearchContainer>
          <SearchBar
            placeholder={intl.formatMessage({ id: 'common.modal.placeholder.search' })}
            searchResults={searchMatches.map(match => match.username)}
            onSearchResultClick={value => setChosen(allUsers.find(user => user.username === value))}
            emptyResultsContent={intl.formatMessage({ id: 'common.nosearchresult' })}
            searchCallback={searchUserNames}
          />
          {chosen && (
            <ChosenUser>
              {chosen.username}
              {handleRemoveUser && (
                <RemoveUserContainer
                  onClick={() => {
                    handleRemoveUser();
                    setChosen(undefined);
                  }}
                >
                  <SdiTrash size="s" />
                  <TextLink
                    text={intl.formatMessage({ id: 'common.remove' })}
                    onClick={() => {
                      handleRemoveUser();
                      setChosen(undefined);
                    }}
                  />
                </RemoveUserContainer>
              )}
            </ChosenUser>
          )}
        </SearchContainer>

        <ButtonContainer>
          <TextLink text={intl.formatMessage({ id: 'common.cancel' })} click={setVisible} />
          <SdirButton
            disabled={!chosen || addUserLoading}
            text={
              chosenUser
                ? intl.formatMessage({ id: 'signercaseworker.modal.savechange' })
                : intl.formatMessage({ id: 'signercaseworker.modal.save' })
            }
            type="primary"
            onClick={() => chosen && handleClick(chosen)}
          />
        </ButtonContainer>
      </Content>
    </SdirModal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 2rem;

  button:first-child {
    flex-grow: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    flex-grow: 1;
  }

  p {
    color: ${({ theme }) => theme.colors.font};
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 2rem;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 8.1875rem;
  margin-top: 2.5rem;
  width: 52rem;
`;

const ChosenUser = styled.div`
  color: ${({ theme }) => theme.colors.font};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 2rem;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RemoveUserContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    margin-right: 1rem;
  }
`;

const Header = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export default AddSignerCaseWorkerModal;
