/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertSingleLine, Loader } from '@sdir/sds';
import { AppVersionCheck } from '@sdir/utilities/lib/components';
import { fetchAssetManifest } from '@sdir/utilities/lib/helpers/assetmanifest';
import { AssetManifest } from '@sdir/utilities/lib/types/assetmanifest';

const versionCheckInterval = process.env.REACT_APP_VERCHECK_INTERVAL
  ? +process.env.REACT_APP_VERCHECK_INTERVAL
  : null;

const fetchManifestAndLoadScript = (name: string, host: string) => {
  return fetchAssetManifest(host).then(manifest => {
    return new Promise<AssetManifest>((resolve, reject) => {
      const script = document.createElement('script');
      script.id = `micro-frontend-script-${name}`;
      script.crossOrigin = '';
      script.src = `${host}${manifest.files['main.js']}`;
      script.onload = () => resolve(manifest);
      script.onerror = reject;
      document.head.appendChild(script);
      return manifest;
    });
  });
};

const loadedMicrofrontends: { [host: string]: AssetManifest } = {};

const ensureManifestAndScriptLoaded = async (name: string, host: string) => {
  if (!loadedMicrofrontends[host]) {
    loadedMicrofrontends[host] = await fetchManifestAndLoadScript(name, host);
  }
  return loadedMicrofrontends[host];
};

const renderMicroFrontend = (name: string, history: any) => {
  const renderFunctionName = `render${name}`;
  const renderFunction = window[renderFunctionName];
  if (!renderFunction) {
    throw new Error(`Missing expected render function with name: ${renderFunctionName}`);
  }
  renderFunction(`${name}-container`, history);
};

const unmountMicroFrontend = (name: string) => {
  const unmountFunction = window[`unmount${name}`];
  if (unmountFunction) unmountFunction(`${name}-container`);
};

function MicroFrontend({ name, host, history }) {
  const intl = useIntl();
  const [error, setError] = useState<string>();
  const [activeManifest, setActiveManifest] = useState<AssetManifest>();

  useEffect(() => {
    ensureManifestAndScriptLoaded(name, host)
      .then(manifest => {
        setActiveManifest(manifest);
        renderMicroFrontend(name, history);
      })
      .catch(e => {
        setError(
          intl.formatMessage(
            { id: 'general.errors.microfrontend.failedtoloadapp' },
            { name, details: e?.message || '' }
          )
        );
      });

    return () => unmountMicroFrontend(name);
  }, [intl, history, host, name]);

  return (
    <>
      {!activeManifest && !error && <Loader />}
      {error && <AlertSingleLine type="error" title="Error" text={error} />}
      {activeManifest && versionCheckInterval && (
        <AppVersionCheck
          baseUrl={host}
          checkIntervalMs={versionCheckInterval}
          preloadedManifest={activeManifest}
        />
      )}
      <main id={`${name}-container`} />
    </>
  );
}

export default MicroFrontend;
