import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { ButtonNavigation } from '../Molecules';

interface ModalProps {
  toggle: () => void;
  state: any;
}

const UpdateShortcut: React.FC<ModalProps> = ({ toggle, state }) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [title, setTitle] = useState(state.target);

  return (
    <Container>
      <Header>{intl.formatMessage({ id: 'dashboard.myshortcut.edit' })}</Header>
      <InputArea>
        <Label>{intl.formatMessage({ id: 'dashboard.myshortcut.title' })}</Label>
        <Input
          id="shortcutName"
          onChange={e => setTitle(e.target.value)}
          value={title}
          name="shortcutName"
          type="text"
        />
      </InputArea>
      <ButtonNavigation
        cancelLinkText={intl.formatMessage({ id: 'dashboard.myshortcut.delete' })}
        nextButtonText={intl.formatMessage({ id: 'dashboard.myshortcut.update' })}
        cancelLinkOnClick={() => toggle()}
        nextButtonOnClick={() => toggle()}
        cancelLinkColor={theme.colors.error}
      />
    </Container>
  );
};

export default UpdateShortcut;

const Container = styled.div`
  min-height: 25rem;
  width: 100%;
  font-size: 1.75rem;
  padding: 0 auto;
  color: ${({ theme }) => theme.dataTable.color};
`;
const Header = styled.div`
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;
const InputArea = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 6rem;
`;

const Label = styled.label`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.font.text};
  font-weight: bold;
`;
const Input = styled.input`
  font-size: 1.8rem;
  width: 80%;
  padding: 0.2rem 1rem;
`;
