import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SdirModal, SdiEdit } from '@sdir/sds';
import styled from 'styled-components';
import { ReactComponent as ShortcutIconRaw } from '../../assets/img/shortcutIcon.svg';
import { UpdateShortcuts } from '../Organisms';

const Shortcuts: React.FC = () => {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const shortCuts = ['Min snarvei i APS', 'Min snarvei i GF', 'En annen snarvei', 'En snarvei'];
  const [state, setState] = useState({
    list: shortCuts,
    target: ''
  });
  const updateTitle = useCallback(
    (item: string) => {
      setModalOpen(!modalOpen);
      setState((prev: any) => ({ ...prev, target: item }));
    },
    [modalOpen]
  );

  return (
    <Container>
      <Heading>{intl.formatMessage({ id: 'dashboard.mytasks.myshortcut' })}</Heading>
      <Links>
        {shortCuts.map((item, i) => (
          <ShortCut key={i} onClick={() => updateTitle(item)}>
            <ShortcutIcon />
            <span>{item}</span>
            <SdiEdit size="s" />
          </ShortCut>
        ))}
        <SdirModal
          width="520px"
          okText={intl.formatMessage({ id: 'common.ok' })}
          cancelText={intl.formatMessage({ id: 'common.cancel' })}
          visible={modalOpen}
          onOK={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        >
          <UpdateShortcuts state={state} toggle={() => setModalOpen(!modalOpen)} />
        </SdirModal>
      </Links>
    </Container>
  );
};
export default Shortcuts;

const Container = styled.section`
  color: ${({ theme }) => theme.colors.primary};
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  margin-left: 1rem;
  font-weight: bold;
`;
const Heading = styled.div`
  padding: 3rem 3rem 1.5rem 3rem;
  display: grid;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.font.PBM};
`;
const Links = styled.div`
  margin-left: 2rem;
`;
const ShortCut = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 10% auto 10%;
  grid-template-rows: 1fr;
  text-align: left;
  font-size: 1.6rem;
  padding: 0.6rem 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.light};
    border-radius: 3rem;
    & > :last-child {
      display: block;
      padding: unset;
      margin: 0;
      padding: 0;
    }
  }
`;
const ShortcutIcon = styled(ShortcutIconRaw)`
  margin-right: 1rem;
  width: 2rem;
`;
