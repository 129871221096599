import {
  Certificate,
  PersonalCertificateResult
} from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import moment, { Moment } from 'moment';
import { SortingOption } from '../types/index.d';
import { SortingDirection } from '../types/enums';

export const compareMoment = (a: Moment | null | undefined, b: Moment | null | undefined) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return a.isBefore(b) ? -1 : 1;
};

export const compareDates = (a: string | null | undefined, b: string | null | undefined) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return new Date(a).getTime() < new Date(b).getTime() ? -1 : 1;
};

export const makeComparator: (
  key: string,
  autoSortConversion?: boolean
) => (a: any, b: any) => number = (key, autoSortConversion = false) => {
  return (a: any, b: any) => {
    a = a[key];
    b = b[key];

    if (autoSortConversion && (key.includes('Date') || key.includes('date'))) {
      a =
        a
          ?.split('.')
          .reverse()
          .join('') || '';
      b =
        b
          ?.split('.')
          .reverse()
          .join('') || '';
      return a.localeCompare(b);
    }

    const type = typeof a === 'string' || typeof b === 'string' ? 'string' : 'number';
    return type === 'string' ? a.localeCompare(b) : a - b;
  };
};

export const sortObjects = (
  list: any[],
  { key, direction }: SortingOption,
  sortFunctions: { [key: string]: (a: any, b: any) => number } | null = null
) => {
  if (direction === SortingDirection.NONE) return list;
  const comparator =
    sortFunctions && sortFunctions[key] ? sortFunctions[key] : makeComparator(key, true);

  const result = list.sort(comparator);
  return direction === SortingDirection.ASC ? result : result.reverse();
};

export const DisplayDateFormat = (date: string | null | undefined) => {
  return date ? moment(date).format('DD.MM.YYYY') : '';
};

export const DisplayDateTimeFormat = (date: any) => {
  return date ? moment(date).format('DD.MM.YYYY hh:mm:ss') : '';
};

export const allowedUploadFileTypes =
  '.pdf,.jpg,.jpeg,.png,.doc,.docx,.xls.,xlsx,.odt,.rtf,.txt,.ppt.,pptx';

export const CapitalizeString = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const GetCertificateNameByCode = (
  lang: string,
  code?: string | null,
  certificates?: Certificate[] | null
) => {
  if (!code || !certificates) return '';
  const codeName =
    certificates?.find(c => c?.code === code.toUpperCase())?.name?.find(n => n.language === lang)
      ?.value || '';
  return CapitalizeString(codeName);
};

export const GetCertificateName = (lang: string, item: PersonalCertificateResult) => {
  if (!item) return null;
  const name = item?.data?.certificateName?.[lang] || item?.data?.certificateName?.no;
  if (name) {
    return CapitalizeString(name);
  }
  return null;
};

export const sortByDate = (timeA, timeB) => {
  return new Date(timeA).getTime() - new Date(timeB).getTime();
};

export const getFormatedDateString = (date, intl) => {
  return new Date(date).toLocaleDateString(intl.locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

export const getFormatedTimeString = (date, intl) => {
  return new Date(date).toLocaleTimeString(intl.locale, {
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const getFormatedTimeStringWithSeconds = (date, intl) => {
  return new Date(date).toLocaleTimeString(intl.locale, {
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const makeDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};
