import styled from 'styled-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Loader, styles, SdirButton, SdiClose } from '@sdir/sds';
import {
  SurveyScopeSubjectAreasWithActivities,
  SurveyScopeActions,
  getSubjectAreaDocumentsAndActivities,
  getActivitiesBySubjectArea,
  ListMenuV2,
  TaskFiltersV1,
  useTaskFilterSummary,
  useDynamicHeight,
  SurveyScopeCertificateCard
} from '@sdir/blueprint.et/lib/components';
import { ActivityWithRisk } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import {
  SuggestedScopeActivity,
  UpdateScopeActivity
} from '@sdir/httpclient/lib/clients/et/survey';

// eslint-disable-next-line max-len
import useSurveyScope from '../../services/hooks/useSurveyScope';
import { Routes, vesselTabOptions } from '../Templates/Routes/constants';
import useScopeFilter from '../../services/hooks/useScopeFilter';
import { SurveyScopeActivityList } from '../Organisms';
import { ReactComponent as NoActivitySelected } from '../../assets/img/NoActivitySelected.svg';

export type ScopedActivity = ActivityWithRisk &
  UpdateScopeActivity & {
    isPartOfOtherSurveyScope?: boolean;
  };
interface SurveyScopeSectionProps {
  projectUid: string;
  surveyUid: string;
}

const SurveyScopeSection: React.FC<SurveyScopeSectionProps> = ({ projectUid, surveyUid }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedSubjectareaUri, setSelectedSubjectareaUri] = useState<string | null>(null);
  const subjectAreaList = useSelector((state: any) => state.appState.subjectAreas);
  const scopeActivitiesChanged = useSelector(
    (state: any) => state.appState.scopeActivitiesListChanged
  );
  const selectedSurvey = useSelector(
    (state: any) => state.surveyState.selectedSurveyCertificateData
  );
  // Load activities for suggested survey scope
  const {
    subjectAreaListLoading,
    getSubjectAreas,
    activitiesLoading,
    getActivities,
    setSurveyScope,
    setRelevance,
    updateSurveyScope
  } = useSurveyScope(projectUid, surveyUid);

  useEffect(() => {
    if (selectedSurvey.surveyId !== '') {
      getSubjectAreas();
      getActivities();
    }
  }, [selectedSurvey]);

  // Filter Activities in Suggested Survey Scope
  const { ref, height } = useDynamicHeight([activitiesLoading] as any);
  const {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    refreshFiltersAndSelectedActivities,
    availableIntervalsCertificatesAndInspectionTypes
  } = useScopeFilter();

  const subjectAreasWithTasks: SurveyScopeSubjectAreasWithActivities[] = useMemo(() => {
    return getSubjectAreaDocumentsAndActivities(subjectAreaList, filteredActivities);
  }, [subjectAreaList, filteredActivities]);

  const onMenuItemClick = async (
    subjectAreaUri: string | null = null,
    activitiesChanged?: SuggestedScopeActivity[],
    action?: SurveyScopeActions
  ) => {
    if (action !== undefined && activitiesChanged !== undefined)
      await setSurveyScope(activitiesChanged, action);
    if (subjectAreaUri) setSelectedSubjectareaUri(subjectAreaUri);
  };

  useEffect(() => {
    // if no subject area is selected/clicked, first subject area will be selected by default
    if (selectedSubjectareaUri === null && subjectAreaList?.[0]?.subjectAreaValue) {
      setSelectedSubjectareaUri(subjectAreaList?.[0]?.subjectAreaValue);
    }
  }, [subjectAreaList]);

  const selectedSubjectAreaTasks = useMemo(() => {
    if (!selectedSubjectareaUri) return [];
    const listOfActivities = getActivitiesBySubjectArea(
      selectedSubjectareaUri,
      subjectAreasWithTasks
    );
    return listOfActivities.map(activity => {
      return {
        uid: activity.approvedActivityId,
        approvedActivityId: activity.approvedActivityId,
        name: activity.title,
        description: activity.description,
        checkpointUri: activity?.checkpointType?.value,
        checkpointType: activity?.checkpointType?.value,
        subjectAreaUri: activity?.checkpointType?.label,
        certificates: activity?.certificates?.map(certificate => certificate.value),
        lastEvent: activity.activityStatus,
        isPartOfOtherSurveyScope: activity.isPartOfOtherSurveyScope,
        // following fields can be changed in redux state, so check and use that if available
        isMandatory:
          scopeActivitiesChanged?.approvedActivityId?.isMandatory ?? activity.isMandatory,
        isCaseHandledInCurrentSurvey:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isCaseHandeledInCurrentSurvey ??
          activity.isCaseHandeledInCurrentSurvey,
        // responsible: activity.responsible,
        isIncludedInCurrentSurvey:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isIncludedInCurrentSurvey ??
          activity.isIncludedInCurrentSurvey,
        isNotRelevant:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isNotRelevant ??
          activity.isNotRelevant,
        notRelevantReason:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.notRelevantReason ?? ''
      };
    }) as ScopedActivity[];
  }, [selectedSubjectareaUri, subjectAreasWithTasks, scopeActivitiesChanged]);

  const statusAndTypeTotals = useTaskFilterSummary(activeFilters.certificates);
  if (activitiesLoading || subjectAreaListLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <>
      <SectionContainer>
        <Button
          onClick={() =>
            navigate(
              generatePath(Routes.vesselWorkspace, {
                projectuid: projectUid,
                tab: vesselTabOptions.inspection
              })
            )
          }
        >
          {intl.formatMessage({ id: 'common.scope.button.cancel' })}
          <SdiClose size="s" color={styles.colors.primary} />
        </Button>
        <Button onClick={() => updateSurveyScope()}>
          {intl.formatMessage({ id: 'survey.card.edit.scope' })}
        </Button>
      </SectionContainer>
      <SurveyScopeCertificateCard />
      {subjectAreaList && filteredActivities && (
        <>
          <SectionContainer>
            <LeftContainer>
              <TaskFiltersV1
                onFiltersChanged={onFiltersChanged}
                activeFilters={activeFilters}
                statusAndTypeTotals={{ ...statusAndTypeTotals }}
                refreshFiltersAndSelectedActivities={refreshFiltersAndSelectedActivities}
                availableIntervalsCertificatesAndInspectionTypes={
                  availableIntervalsCertificatesAndInspectionTypes
                }
                filterButtonLabel={intl.formatMessage({
                  id: 'common.filter.scope.button.label'
                })}
              />
            </LeftContainer>
            <HeadingContainer>
              <LeftContainer>
                {intl.formatMessage({
                  id: 'common.filter.scope.label'
                })}
                {` (${selectedSubjectAreaTasks.length})`}
              </LeftContainer>
              <SectionContainer>
                <Button onClick={() => setRelevance(selectedSubjectAreaTasks, false)}>
                  {intl.formatMessage({ id: 'survey.section.setallrelevant.button.label' })}
                </Button>
                <Button onClick={() => setRelevance(selectedSubjectAreaTasks, true)}>
                  {intl.formatMessage({ id: 'survey.section.setallnotrelevant.button.label' })}
                </Button>
              </SectionContainer>
            </HeadingContainer>
          </SectionContainer>
          <ListContainer>
            <LeftContainer>
              <div ref={ref}>
                <ListMenuV2
                  title={intl.formatMessage({
                    id: 'common.filter.scope.label'
                  })}
                  onMenuItemClick={onMenuItemClick}
                  subjectAreasWithTasks={subjectAreasWithTasks}
                  activeFilters={activeFilters}
                  availableIntervalsCertificatesAndInspectionTypes={
                    availableIntervalsCertificatesAndInspectionTypes
                  }
                  onFiltersChanged={onFiltersChanged}
                />
              </div>
            </LeftContainer>
            {selectedSubjectAreaTasks.length > 0 && (
              <SurveyScopeActivityList
                height={height}
                loading={activitiesLoading}
                currentSelectedSubjectArea={selectedSubjectareaUri}
                activitiesListOfSubjectArea={selectedSubjectAreaTasks}
                setSurveyScope={setSurveyScope}
                setRelevance={setRelevance}
              />
            )}
            {!activitiesLoading && (!filteredActivities || filteredActivities.length === 0) && (
              <NoActivityContainer>
                <NoActivitySelected />
                <NoActivitySelectedHeader>
                  {intl.formatMessage({ id: 'activityadmin.no.activity.selected' })}
                </NoActivitySelectedHeader>
                <NoActivitySelectedText>
                  {intl.formatMessage({ id: 'activityadmin.msg.select.activity' })}
                </NoActivitySelectedText>
              </NoActivityContainer>
            )}
          </ListContainer>
        </>
      )}
    </>
  );
};

export default SurveyScopeSection;

const LeftContainer = styled.div`
  width: 60rem;
`;

const HeadingContainer = styled.div`
  margin-left: 5rem;
  align-content: center;
  margin-bottom: 4rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
  display: flex;
  float: left;
`;

const SectionContainer = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const ListContainer = styled.div`
  display: flex;
`;

const LoaderContainer = styled.div`
  grid-area: 3 / 7;
`;

const NoActivityContainer = styled.div`
  grid-area: tasks;
  text-align: center;
  flex-grow: 1;
`;

const NoActivitySelectedText = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fonts.stfLight};
`;

const NoActivitySelectedHeader = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const Button = styled(SdirButton)`
  margin-right: 2rem;
  display: flex;
  float: left;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  gap: 1rem;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;
