import React, { useMemo } from 'react';
import CertificateList from '@sdir/blueprint.et/lib/components/Organisms/CertificateList/CertificateList';
import { useSelector } from 'react-redux';

interface Props {
  loading?: boolean;
}

const CertificateSection: React.FC<Props> = ({ loading }) => {
  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const plannedCertificates = useSelector((state: any) => state.appState.plannedVesselCertificates);
  const vesselCertificates = useSelector((state: any) => state.appState.vesselCertificates);

  const filteredPlannedCertificates = useMemo(() => {
    const mainCertificates =
      plannedCertificates?.mainCertificates?.filter(cert => cert.isActive) || [];
    const otherCertificates =
      plannedCertificates?.otherCertificates?.filter(cert => cert.isActive) || [];
    return mainCertificates.concat(otherCertificates);
  }, [plannedCertificates]);

  const certificatesMeta = useMemo(() => {
    const mainCertificates = specificationsOptions?.mainCertificates || [];
    const otherCertificates = specificationsOptions?.otherCertificates || [];
    return mainCertificates.concat(otherCertificates);
  }, [specificationsOptions]);

  return (
    <CertificateList
      plannedCertificates={filteredPlannedCertificates}
      vesselCertificates={vesselCertificates}
      certificatesMeta={certificatesMeta}
      loading={loading}
    />
  );
};

export default CertificateSection;
