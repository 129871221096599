/* eslint-disable @typescript-eslint/indent */
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tagList } from '@sdir/blueprint.et/lib/helpers';
import { ProjectActivityV3 } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { InspectionIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import useTaskCard from './useTaskCard';
import {
  AccordionWrapper,
  CloseReactionModal,
  TaskCard,
  ToggleState,
  ActivityCardLeftColumn,
  ActivityCardRightColumn,
  taskCardAccordion,
  taskMetaCard
} from './ActivityCardHelpers';

interface InspectionTaskCardProps {
  activity: ProjectActivityV3;
  approvalCalBack?: (uri) => void;
  requirementsListTabEnabled?: boolean;
}

const InspectionTaskCard: React.FC<InspectionTaskCardProps> = ({
  activity,
  approvalCalBack,
  requirementsListTabEnabled
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectuid, surveyuid } = useParams() as { projectuid: string; surveyuid: string };
  const [toggleState, setToggleState] = useState<ToggleState>(ToggleState.None);
  const [viewMetadata, setViewMetadata] = useState<boolean>(false);
  const [viewCloseReactionModal, setViewCloseReactionModal] = useState(false);

  const approvalCallback = () => {
    setToggleState(ToggleState.None);
    if (approvalCalBack) approvalCalBack(activity.subjectArea?.value);
  };

  const closeReactionCallback = () => {
    setViewCloseReactionModal(false);
  };

  const {
    sendForApproval,
    approvalLoading,
    getActivityDetails,
    activityDetailsLoading,
    activityDetails,
    numberOfMessages,
    activityMetadata,
    activityMetadataLoading,
    getActivityMetadata,
    activityMeatballMenuItems,
    closeReaction,
    closingReaction,
    submitNotRelevant,
    submitRelevant,
    notRelevantLoading,
    relevantLoading,
    getLawText,
    getLawTextAct,
    regulationTextState,
    regulationTextDispatch
  } = useTaskCard({
    projectUid: projectuid,
    activityUid: activity.uid!,
    surveyUid: surveyuid,
    activityCheckpointUri: activity.checkpointType,
    requirementEliRefs: activity.requirementEliRefs?.[0],
    requirementsListTabEnabled,
    approvalCallback,
    setViewMetadata,
    activity,
    closeReactionCallback
  });

  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid } as {
    projectUid: string;
  });

  const handleToggleState = (state: ToggleState) => {
    if (state === toggleState) setToggleState(ToggleState.None);
    else setToggleState(state);
  };

  const reactionDraft = activityDetails?.reactionDraft || activity.reactionDraft;

  const events = activityDetails?.events;

  return (
    <Wrapper id={activity.uid}>
      <AccordionWrapper width="100%">
        <TaskCard width="100%">
          {ActivityCardLeftColumn(
            intl,
            activity,
            events,
            toggleState,
            getActivityDetails,
            handleToggleState,
            true,
            tagList(activity.certificates),
            false
          )}
          {ActivityCardRightColumn(
            intl,
            activity,
            projectuid,
            handleToggleState,
            events,
            undefined,
            undefined,
            activityMeatballMenuItems,
            downloadByFilename,
            reactionDraft,
            <InspectionIcon />,
            approvalLoading,
            activityDetailsLoading,
            closingReaction,
            setViewCloseReactionModal,
            sendForApproval,
            navigate,
            getLawText,
            getLawTextAct,
            regulationTextState,
            regulationTextDispatch
          )}
        </TaskCard>

        {taskCardAccordion(
          activityDetails,
          activityDetailsLoading,
          toggleState,
          activity.uid,
          projectuid,
          submitNotRelevant,
          submitRelevant,
          setToggleState,
          notRelevantLoading,
          relevantLoading,
          downloadByFilename,
          numberOfMessages,
          getActivityDetails
        )}
        {viewMetadata &&
          taskMetaCard(
            activity.uid,
            activityMetadataLoading,
            activityMetadata,
            getActivityMetadata,
            setViewMetadata
          )}
      </AccordionWrapper>
      {viewCloseReactionModal && (
        <CloseReactionModal
          visible={viewCloseReactionModal}
          loading={closingReaction}
          activity={activity}
          events={events}
          onCancel={() => setViewCloseReactionModal(false)}
          onOk={reactionUid => closeReaction(reactionUid)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export default React.memo(InspectionTaskCard);
