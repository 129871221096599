import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Loader, SdirModal } from '@sdir/sds';
import { ErrorMessage } from '@sdir/utilities/lib/components';
import PersonMergeCard, { PersonData } from './PersonMergeCard';

type PersonMergePreviewProps = {
  loading?: boolean;
  error?: any;
  person?: PersonData;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const PersonMergePreview: React.FC<PersonMergePreviewProps> = ({
  loading = false,
  error,
  person,
  show,
  onClose,
  onConfirm
}) => {
  const intl = useIntl();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const onOkClick = () => {
    if (!showConfirmation) {
      setShowConfirmation(true);
    } else {
      onConfirm();
    }
  };

  const onCloseClick = () => {
    setShowConfirmation(false);
    onClose();
  };

  return (
    <StyledModal
      width={showConfirmation ? '600px' : '800px'}
      visible={show}
      onCancel={onCloseClick}
      cancelText={intl.formatMessage({ id: 'common.cancel' })}
      onOK={onOkClick}
      okText={intl.formatMessage({ id: 'aps.person.merge.preview.title' })}
    >
      <Wrapper>
        {showConfirmation ? (
          <>
            <h2>{intl.formatMessage({ id: 'aps.person.merge.preview.confirm' })}</h2>
            <p>{intl.formatMessage({ id: 'aps.person.merge.preview.desc' })}</p>
          </>
        ) : (
          <>
            <h2>{intl.formatMessage({ id: 'aps.person.merge.preview.select' })}</h2>
            <PersonMergeCard person={person} title="" />
          </>
        )}
        {loading && (
          <RequestWrapper>
            <Loader />
          </RequestWrapper>
        )}
        {!loading && error && (
          <RequestWrapper>
            <ErrorMessage error={error} />
          </RequestWrapper>
        )}
      </Wrapper>
    </StyledModal>
  );
};

export default PersonMergePreview;

const StyledModal = styled(SdirModal)`
  font-size: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  h2 {
    margin: 0 0 20px 0;
  }

  p {
    margin: 0;
  }
`;

const RequestWrapper = styled.div`
  margin: 30px 0;
`;
