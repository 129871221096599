import React from 'react';
import styled from 'styled-components';
import { SdirModal, SdirButton } from '@sdir/sds';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { CaseWorkerResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useIntl } from 'react-intl';
import { Signer } from '../../../store/constants/types';

interface PreviewReplyModalProps {
  visible: boolean;
  setVisible: () => void;
  accept: () => void;
  reply: string;
  replyType: string;
  loading?: boolean;
  vesselName: string;
  signer: Signer | undefined;
  caseWorker?: CaseWorkerResult | undefined;
}

const PreviewReplyModal: React.FC<PreviewReplyModalProps> = ({
  visible,
  setVisible,
  accept,
  reply,
  replyType,
  loading,
  vesselName,
  signer,
  caseWorker
}) => {
  const intl = useIntl();
  const currentUser = useCurrentUser();

  const handleClose = () => {
    setVisible();
  };

  return (
    <SdirModal visible={visible} onCancel={() => handleClose()} width="104rem" closeButton={false}>
      <Header>{intl.formatMessage({ id: 'previewreplymodal.header' }, { replyType })}</Header>
      <HelpText>{intl.formatMessage({ id: 'previewreplymodal.helptext' }, { replyType })}</HelpText>

      <ReplyPreviewContainer>
        <Title>
          {vesselName} - {replyType} - Hjemmel##
        </Title>
        <Text>{reply}</Text>
      </ReplyPreviewContainer>
      {!signer && (
        <Text>
          {`${caseWorker?.name || currentUser?.profile?.name} ${intl.formatMessage({
            id: 'vessel.activity.status.application.CaseworkerWarning'
          })}`}
        </Text>
      )}
      <ButtonContainer>
        <SdirButton
          text={intl.formatMessage({ id: 'common.cancel' })}
          onClick={() => handleClose()}
        />
        <SdirButton
          text={intl.formatMessage({ id: 'previewreplymodal.submit' })}
          type="primary"
          htmlType="submit"
          onClick={() => accept()}
          disabled={loading}
        />
      </ButtonContainer>
    </SdirModal>
  );
};

const Header = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 3rem;
`;

const HelpText = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  margin-bottom: 4rem;
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  margin-bottom: 3rem;
`;

const Text = styled.p`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  white-space: pre-wrap;
`;

const ReplyPreviewContainer = styled.div`
  padding: 3rem 5rem 3rem 3rem;
  background: ${({ theme }) => theme.colors.font.text10};
  border-radius: ${({ theme }) => theme.card.borderRadius};

  max-height: 60vh;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.primary9};
    outline: ${({ theme }) => theme.colors.primary10};
  }

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
    border-radius: 6px;
    margin: 12px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  gap: 5rem;
  margin-top: 5rem;
  justify-content: flex-end;
`;

export default PreviewReplyModal;
