import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Loader, DataCard, TextLink, SdiEdit, SdiTrash } from '@sdir/sds';
import {
  Detail,
  ReportSeafarerSeagoingTimeInDays
} from '@sdir/httpclient/lib/clients/aps/seagoing';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import moment from 'moment';
import { SeagoingVesselPreviewLink } from '@sdir/blueprint';
import { seagoingTimeCalculatorApi } from '../../httpclient';
import { AlertMessage } from '../Atoms';
import {
  SeagoingSubTableHeader,
  SeagoingSubTableRow,
  SeagoingTableHeader,
  SeagoingTableRow
} from '../../helpers/TableHeaders';
import { AlertType } from '../../types/enums';
import { DisplayDateFormat } from '../../helpers';
import ReportingTime, { ReportingTimeData } from './VesselPreviewLink/ReportingTimeModal';
import { EditModal } from './EditModalLink';
import { DeleteModal } from './DeleteModalLink';

// eslint-disable-next-line max-len
const getSeagoingReportApi = seagoingTimeCalculatorApi?.v1SeagoingtimecalculatorGetreportseafarerseagoingtimeQueryPost.bind(
  seagoingTimeCalculatorApi
);

const SeagoingTableCard: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [reportTime, setReportTime] = useState<ReportingTimeData | null>(null);
  const [showReportingModal, setShowReportingModal] = useState(false);
  const [includeExpired, setIncludeExpired] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowUid, setRowUid] = useState('');
  const [editData, setEditData] = useState<Detail | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const OnCancelModal = useCallback(() => {
    setShowReportingModal(false);
    setReportTime(null);
  }, []);

  const showModal = useCallback(
    (seagoing: ReportSeafarerSeagoingTimeInDays, detail: Detail) => {
      setShowReportingModal(true);
      setReportTime({ ...detail, legalEntityId, serviceCode: seagoing.serviceCode });
    },
    [legalEntityId]
  );

  const showModalDelete = useCallback(uid => {
    setRowUid(uid);
    setShowDeleteModal(true);
  }, []);

  const onCancelDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const { result, loading, error, callApi } = useApi({
    apiFunction: getSeagoingReportApi,
    callApiOnInit: false
  });

  const onSuccessDeleteSeagoing = useCallback(() => {
    setShowDeleteModal(false);
    callApi({ legalEntityId });
  }, [callApi, legalEntityId]);

  const onSuccessEditSeagoing = useCallback(() => {
    setShowEditModal(false);
    callApi({ legalEntityId });
  }, []);

  const showModalEdit = useCallback(row => {
    setEditData(row);
    setShowEditModal(true);
  }, []);

  const onCancelEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const toggleExpired = useCallback(() => setIncludeExpired(!includeExpired), [includeExpired]);

  const tableData = useMemo(() => {
    if (!result) return [];

    return result.map<SeagoingTableRow>(val => {
      const childTableData =
        val.details
          ?.sort((a: Detail, b: Detail) => (b.signOnDate || '').localeCompare(a.signOnDate || ''))
          ?.map<SeagoingSubTableRow>(d => {
            return {
              vessel: d.vessel ? (
                <SeagoingVesselPreviewLink
                  vessel={d.vessel}
                  accumulatedData={d.accumulatedData || null}
                />
              ) : (
                ''
              ),
              vesselName: d.vessel?.name || '',
              period:
                d.signOnDate && d.signOffDate
                  ? `${DisplayDateFormat(d.signOnDate)} - ${DisplayDateFormat(d.signOffDate)}`
                  : '-',
              periodStartValue: d.signOnDate ? moment(d.signOnDate) : null,
              organization: d.organizationName,
              days: intl.formatNumber(d.seagoingDays || 0, { maximumFractionDigits: 2 }),
              daysValue: d.seagoingDays,
              attachments: (
                <TextLink
                  text={intl.formatMessage({ id: 'table.showdetails' })}
                  click={() => showModal(val, d)}
                />
              ),
              edit: (
                <TextLink
                  data-testid="seagoing-delete-button"
                  icon={<SdiEdit size="s" />}
                  text=""
                  click={() => showModalEdit(d)}
                />
              ),
              remove: (
                <TextLink
                  data-testid="seagoing-delete-button"
                  icon={<SdiTrash size="s" />}
                  text=""
                  click={() => showModalDelete(d.uid)}
                />
              )
            };
          }) || [];
      return {
        jobTitle: val.serviceDescription,
        days: intl.formatNumber(val.seagoingDays || 0, { maximumFractionDigits: 2 }),
        daysValue: val.seagoingDays,
        childContent: (
          <SortableDataTable
            columns={SeagoingSubTableHeader}
            data={childTableData}
            sortableKeys={['vessel', 'period', 'organization', 'days']}
          />
        )
      };
    });
  }, [intl, result, showModal, showModalDelete]);

  useEffect(() => {
    if (legalEntityId) {
      callApi({
        skip: 0,
        take: 100,
        legalEntityId,
        includeOutdatedReports: includeExpired
      });
    }
  }, [callApi, includeExpired, legalEntityId]);

  return (
    <Container>
      <DataCard
        title={intl.formatMessage({ id: 'datacard.seagoing.title' })}
        expandable
        onExpandClicked={toggleExpired}
        linkText={intl.formatMessage({
          id: includeExpired ? 'datacard.seagoing.hideexpired' : 'datacard.seagoing.showexpired'
        })}
      >
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {error && <AlertMessage message={error} alert={AlertType.Error} />}
        {!loading && !error && tableData?.length > 0 && (
          <SortableDataTable
            columns={SeagoingTableHeader}
            data={tableData}
            collapsable
            collapsableText={[
              intl.formatMessage({ id: 'table.showdetails' }),
              intl.formatMessage({ id: 'table.hidedetails' })
            ]}
            sortableKeys="*"
          />
        )}
      </DataCard>
      {showReportingModal && reportTime ? (
        <ReportingTime
          reportingTime={reportTime}
          display={showReportingModal}
          onCancelClick={OnCancelModal}
        />
      ) : null}
      {showEditModal && (
        <EditModal
          row={editData}
          display={showEditModal}
          onCancelClick={onCancelEditModal}
          onSuccessEdit={onSuccessEditSeagoing}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          uid={rowUid}
          display={showDeleteModal}
          onCancelClick={onCancelDeleteModal}
          onSuccessDelete={onSuccessDeleteSeagoing}
        />
      )}
    </Container>
  );
};
export default SeagoingTableCard;

const Container = styled.div`
  position: relative;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
