import React from 'react';
import { Outlet } from 'react-router-dom';
// eslint-disable-next-line max-len
import { CertificateCodesProvider } from '../../Templates/Providers/CertificateCodesProvider/CertificateCodesProvider';

const APSPagesLayout = () => {
  return (
    <CertificateCodesProvider>
      <Outlet />
    </CertificateCodesProvider>
  );
};

export default APSPagesLayout;
