import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
// eslint-disable-next-line max-len
import { CommonAddresseeServiceContractsV1ResponsesGetReplacementsResponse } from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import {
  formatDateTimeString,
  formatFullName
} from '@sdir/blueprint.aps/lib/helpers/formattingHelpers';
import { TextLink } from '@sdir/sds';
import { ReactComponent as SplitIcon } from '../../../assets/img/split.svg';
import PersonSplitConfirm from './PersonSplitConfirm';
import { personReplacementApi } from '../../../httpclient';

type MergeListProps = {
  replacements?: CommonAddresseeServiceContractsV1ResponsesGetReplacementsResponse;
  onPersonClick: (id: string | number) => void;
};

const MergeList: React.FC<MergeListProps> = ({ replacements, onPersonClick }) => {
  const intl = useIntl();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [splitId, setSplitId] = useState<string>();

  const { loading: splitLoading, error: splitError, callApi: callSplit } = useApi({
    apiFunction: id => personReplacementApi.v1PersonreplacementDeletereplacementIdDelete(id),
    callApiOnInit: false,
    successCallback: () => {
      onCloseConfirmation();
      window.location.reload();
    }
  });

  const onSplit = (id?: string) => {
    setSplitId(id);
    setShowConfirmation(true);
  };

  const onCloseConfirmation = () => {
    setSplitId(undefined);
    setShowConfirmation(false);
  };

  const onSplitConfirm = () => {
    callSplit(splitId);
  };

  const data = useMemo(() => {
    const items: any[] = [];
    replacements?.history?.forEach(r => {
      const fullName = formatFullName(r?.firstname || '', r?.middlename || '', r?.lastname || '');
      items.push({
        name: fullName,
        personalIdentityNumber: r?.personalIdentityNumber,
        commonAddresseeId: r?.commonAddresseeId,
        legalEntityId: r?.legalEntityId,
        connectionId: r?.connection?.id,
        createdBy: r?.connection?.caseWorker,
        createdAt: r?.connection?.createdAt
      });
    });
    return items;
  }, [replacements]);

  return (
    <Wrapper>
      {data && data.length > 0 ? (
        <>
          <Header>{intl.formatMessage({ id: 'aps.person.merge.previous' })}</Header>
          <Message>{intl.formatMessage({ id: 'aps.person.merge.merged' })}</Message>
          <List>
            {data?.map(i => {
              return (
                <ListItem>
                  <ListItemRow>
                    <TextLink
                      text={`${i?.personalIdentityNumber} - ${i?.name}`}
                      onClick={() => onPersonClick(i?.commonAddresseeId)}
                    />
                    <TextLink
                      text={intl.formatMessage({ id: 'aps.person.split.link' })}
                      icon={<SplitIcon />}
                      onClick={() => onSplit(i?.connectionId)}
                    />
                  </ListItemRow>
                  <ListItemRow>
                    <span>{formatDateTimeString(i?.createdAt)}</span>
                    <span>{i?.createdBy}</span>
                  </ListItemRow>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        <Header>{intl.formatMessage({ id: 'aps.person.merge.previous' })}</Header>
      )}
      <PersonSplitConfirm
        error={splitError}
        loading={splitLoading}
        show={showConfirmation}
        onClose={onCloseConfirmation}
        onConfirm={onSplitConfirm}
      />
    </Wrapper>
  );
};

export default MergeList;

const Wrapper = styled.div`
  margin: 15px 0;
`;

const Header = styled.div`
  font-size: 14px;
  color: #333333a6;
  font-weight: bold;
`;

const Message = styled.div`
  font-size: 14px;
  color: #333333a6;
  font-weight: bold;
  font-style: italic;
`;

const List = styled.div``;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const ListItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 12px;
  }
`;
