/* eslint-disable react/jsx-indent */
// eslint-disable-next-line max-len
import { ToggleButton } from '@sdir/blueprint.et/lib/components';
import {
  ActivityDetail,
  DocumentActivitiesOverview,
  GetProjectActivitiesOverviewV3Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ListMenuProps {
  data: GetProjectActivitiesOverviewV3Result;
  onMenuItemClick: (subjectAreaUri: string) => void;
  title?: string;
  handleOuterMenuClick?: (e) => void;
  handleActivitySelected?: () => void;
  defaultSelected?: string;
}

const scrollToSection = targetId => {
  const targetEl = document.getElementById(targetId);
  targetEl?.scrollIntoView({ behavior: 'smooth' });
};

const Listmenu: React.FC<ListMenuProps> = ({
  data,
  onMenuItemClick,
  title,
  handleOuterMenuClick,
  handleActivitySelected,
  defaultSelected
}) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<number | null>(0);
  const [selectedDocumentGroups, setSelectedDocumentGroups] = useState<string[] | undefined>();
  const [selectedSubItem, setselectedSubItem] = useState<string | undefined>();
  const { scrollToId } = useParams() as { scrollToId?: string };

  useEffect(() => {
    const index = data?.subjectAreaDocuments?.findIndex(d => d.subjectAreaUri === defaultSelected);
    if (index && index >= 0) {
      setSelected(index);
    }
  }, [defaultSelected, data]);

  useEffect(() => {
    const handleMenuClickWhenScrollToIdIsDefined = () => {
      let foundActivity: boolean = false;
      if (scrollToId && !defaultSelected) {
        data.subjectAreaDocuments?.some((subjectArea, i) => {
          subjectArea.activityOrActivityGroupOverview?.some(activityOrActivityGroup => {
            if (
              activityOrActivityGroup.activityDetail &&
              activityOrActivityGroup.activityDetail.uid === scrollToId
            ) {
              setSelected(i);
              setselectedSubItem(activityOrActivityGroup.activityDetail.uid);
              foundActivity = true;
            }
            // For document group, we need to loop through activities grouped into it

            activityOrActivityGroup.documentActivitiesOverview?.activityOverview?.some(
              activityDetail => {
                if (activityDetail.uid === scrollToId) {
                  setSelected(i);
                  setselectedSubItem(activityDetail.uid);
                  foundActivity = true;
                }
                return foundActivity;
              }
            );
            // To break loop when required activity is found
            return foundActivity;
          });
          return foundActivity;
        });
      }
    };
    handleMenuClickWhenScrollToIdIsDefined();
  }, [scrollToId, data.subjectAreaDocuments]);

  const handleClick = (index: any, subjectAreaUri: string) => {
    setSelected(index);
    setselectedSubItem(undefined);
    if (selected === index) {
      setSelected(null);
    } else {
      onMenuItemClick(subjectAreaUri);
    }
  };

  const handleActivityLabelClicked = activityId => {
    if (handleActivitySelected) handleActivitySelected();
    setselectedSubItem(activityId);
    setTimeout(() => {
      // Timeout to ensure activites without filter is loaded befor scroll
      scrollToSection(activityId);
    }, 10);
  };

  const handleDocumentControlToggleClicked = documentId => {
    if (documentId) {
      if (!selectedDocumentGroups) {
        setSelectedDocumentGroups([documentId]);
      } else if (selectedDocumentGroups.includes(documentId)) {
        setSelectedDocumentGroups(selectedDocumentGroups.filter(e => e !== documentId));
      } else if (!selectedDocumentGroups.includes(documentId)) {
        setSelectedDocumentGroups([...selectedDocumentGroups, documentId]);
      }
    }
  };

  const DisplayMenuItem = (activityDetail: ActivityDetail) => {
    return (
      <div key={activityDetail.uid}>
        <MenuItemLabel
          className={selectedSubItem === activityDetail.uid ? 'active' : ''}
          onClick={() => {
            handleActivityLabelClicked(activityDetail?.uid);
          }}
          isActivityGroup={false}
        >
          {activityDetail.name}
        </MenuItemLabel>
      </div>
    );
  };

  const DisplayActivityGroupMenuItem = (
    activityGroupDetail: DocumentActivitiesOverview,
    key: number
  ) => {
    return (
      <div key={activityGroupDetail.documentId! + key}>
        <MenuItemLabel
          onClick={() => {
            handleDocumentControlToggleClicked(activityGroupDetail.documentId);
          }}
          isActivityGroup
        >
          <HeadingContainer>{activityGroupDetail.documentLabel}</HeadingContainer>
          <ToggleButton
            isToggled={Boolean(
              activityGroupDetail?.documentId &&
                selectedDocumentGroups &&
                selectedDocumentGroups.includes(activityGroupDetail.documentId)
            )}
            text=""
            onClick={() => {}}
          />
        </MenuItemLabel>
        {activityGroupDetail.documentId &&
          selectedDocumentGroups?.includes(activityGroupDetail.documentId) && (
            <MenuItem>
              {activityGroupDetail.activityOverview?.map(activity => {
                return DisplayMenuItem(activity);
              })}
            </MenuItem>
          )}
      </div>
    );
  };

  return (
    <Container data-testid="listmenu-container">
      <Title>{title || intl.formatMessage({ id: 'access.customerteam.subjectareas' })}</Title>
      {data.subjectAreaDocuments?.map((subjectArea, index: any) => (
        <div key={subjectArea.subjectAreaUri}>
          <Outer onClick={handleOuterMenuClick}>
            <ListTitle
              onClick={() => {
                handleClick(index, subjectArea.subjectAreaUri || '');
              }}
              className={selected === index ? 'active' : ''}
            >
              {subjectArea.subjectAreaLabel}
            </ListTitle>
          </Outer>
          {selected === index ? (
            <MenuItem>
              {subjectArea.activityOrActivityGroupOverview &&
                subjectArea.activityOrActivityGroupOverview.map(
                  (activityOrActivityGroup, i: any) => {
                    if (activityOrActivityGroup.activityDetail) {
                      return DisplayMenuItem(activityOrActivityGroup.activityDetail);
                    }
                    return DisplayActivityGroupMenuItem(
                      activityOrActivityGroup.documentActivitiesOverview!,
                      i
                    );
                  }
                )}
            </MenuItem>
          ) : (
            ''
          )}
        </div>
      ))}
    </Container>
  );
};

const Outer = styled.div``;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.font.white};
  padding: 4rem 4rem 4rem 2rem;
  width: 44rem;
  box-shadow: 0 0.375rem 0.75rem 0 rgb(0 0 0 / 29%);
`;

const Title = styled.div`
  font-size: 3rem;
  margin-left: 1.75rem;
  margin-bottom: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text85};
`;

const ListTitle = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 2.5rem;
  padding: 0.75rem 0 0.75rem 1.5rem;
  padding-left: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  text-align: left;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.font.white};
  width: 100%;
  line-height: 1.5;

  &:focus {
    color: ${({ theme }) => theme.colors.font.white};
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary};
  }

  &::after {
    content: '+';
    margin-right: 2rem;
    margin-left: 2rem;
    float: right;
    align-self: center;
  }
  &.active {
    color: ${({ theme }) => theme.colors.font.white};
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary};

    &::after {
      content: '-';
      margin-right: 2rem;
      float: right;
    }
  }
`;

const MenuItem = styled.div`
  margin-left: 2.5rem;

  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  cursor: pointer;
`;

const MenuItemLabel = styled.div<{ isActivityGroup?: boolean }>`
  margin-bottom: 1.5rem;
  padding: 2px 0 0 8px;
  font-size: 2rem;
  width: 96%;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.font.white};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primary};

  ${({ isActivityGroup }) =>
    isActivityGroup &&
    css`
      justify-content: space-between;
      &:hover,
      &.active {
        color: ${({ theme }) => theme.colors.primary};
        border-radius: 20px;
        background: ${({ theme }) => theme.colors.primary10};
      }
    `};
  ${({ isActivityGroup }) =>
    !isActivityGroup &&
    css`
      gap: 1.5rem;
      &.active,
      &:hover {
        background: ${({ theme }) => theme.colors.primary9};
        color: ${({ theme }) => theme.colors.font.primary.blue};
        border-radius: 20px;
        svg {
          fill: ${({ theme }) => theme.colors.font.primary.blue};
        }
      }
    `};
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  div.checkMark {
    float: right;
    margin-top: 0.3rem;
    margin-left: 0.7rem;
  }
  div.lightGreyBG {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary10};
    border-radius: 10px;
    min-width: 60px;
    text-align: center;
    margin-top: -0.3rem !important;
    padding: 0.3rem 2rem 0.3rem 2rem;
    max-height: 6rem;
    text-wrap: nowrap;
  }
`;

export default Listmenu;
