import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useAuthenticatedCheck } from '@sdir/auth/lib/hooks';
import React, { useEffect } from 'react';
import { Certificate } from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { CertificateCodesContext } from '../../../../services/contexts/CertificateCodesContext';
import { Loadable } from '../../../../types/commonTypes';
import { certificateApi } from '../../../../httpclient';

interface ICertificateCodesProvider {
  children?: React.ReactNode;
}

export const CertificateCodesProvider: React.FC<ICertificateCodesProvider> = ({ children }) => {
  const { initialized, isAuthenticated } = useAuthenticatedCheck(true);

  const {
    result: codeResult,
    loading: codeLoading,
    error: codeError,
    callApi: getCertificateCodes
  } = useApi({
    apiFunction: () => certificateApi.v1CertificateGetcertificatesGet(),
    callApiOnInit: false
  });

  useEffect(() => {
    if (isAuthenticated && initialized && !codeLoading && !codeError && !codeResult) {
      getCertificateCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeError, codeLoading, codeResult, initialized, isAuthenticated]);

  let value: Loadable<Certificate[]>;

  if (codeResult) {
    value = {
      result: codeResult
    };
  } else if (codeError) {
    value = {
      error: codeError
    };
  } else {
    value = {
      loading: true
    };
  }

  return (
    <CertificateCodesContext.Provider value={value}> {children} </CertificateCodesContext.Provider>
  );
};
