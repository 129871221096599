import React, { useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import { SdirDatePicker } from '@sdir/sds';
import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';

interface FormDateProps extends FieldProps {
  field: any;
  label: string;
  type: any;
  hidenumberstyling?: number;
  tab: number;
  disabled: boolean;
  className?: string;
  onChangeCallback?: (val: Moment | null) => void;
  onBlurField?: () => void;
  valueAsString?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  format?: string;
  maxDate?: Moment | string;
  minDate?: Moment | string;
  placeholder?: string;
}

const convertToDate = (value: Moment | string | undefined): Date | null => {
  if (!value) return null;
  if (typeof value === 'string' || value instanceof String) return moment(value).toDate();
  if (value && value.toDate) return value.toDate();
  return null;
};

const FormDate: React.FC<FormDateProps> = ({
  field,
  label,
  type,
  tab,
  disabled,
  className = '',
  valueAsString = false,
  showYearDropdown = false,
  showMonthDropdown = false,
  onChangeCallback,
  form,
  onBlurField,
  format,
  maxDate,
  minDate,
  placeholder
}) => {
  const meta = form.getFieldMeta(field.name);
  const intl = useIntl();

  const handleChange = useCallback(
    (dateValue: Moment) => {
      const momentValue = dateValue ? moment(dateValue) : null;
      form.setFieldValue(field.name, valueAsString ? momentValue?.format() : momentValue);
      onChangeCallback?.(momentValue);
    },
    [field.name, form, valueAsString, onChangeCallback]
  );

  const handleBlur = useCallback(() => {
    form.setFieldTouched(field.name, true);
    return onBlurField?.();
  }, [field, form, onBlurField]);

  const valueNative = useMemo(() => convertToDate(field.value), [field.value]);
  const minDateNative = useMemo(() => convertToDate(minDate), [minDate]);
  const maxDateNative = useMemo(() => convertToDate(maxDate), [maxDate]);

  return (
    <SdirDatePicker
      {...field}
      className={className}
      disabled={disabled}
      error={meta && meta.touched && meta.error}
      format={format}
      id={field.name}
      label={label}
      locale="no"
      maxDate={maxDateNative}
      minDate={minDateNative}
      showYearDropdown={showYearDropdown}
      showMonthDropdown={showMonthDropdown}
      name={field.name}
      placeholder={placeholder}
      tabIndex={tab}
      type={type}
      value={valueNative}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default FormDate;
