/* eslint-disable react/destructuring-assignment */
import { BigError, SdirButton } from '@sdir/sds';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import ErrorWithRecovery from '../../../types/ErrorWithRecovery';
import ApiError from '../../../types/ApiError';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { response: '', action: null, title: '' }
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError = (_: Error) => {
    return { hasError: true };
  };

  componentDidUpdate(prevProps) {
    if (this.state.hasError && prevProps.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch = error => {
    this.setState({ error });
  };

  render() {
    const { response } = this.state.error;

    if (this.state.hasError && response && this.state.error instanceof ApiError) {
      return (
        <FallbackPage
          message={response.data.Message || ''}
          statusCode={response.status}
          details={response.data}
        />
      );
    }

    if (this.state.hasError && response && this.state.error instanceof ErrorWithRecovery) {
      return (
        <FallbackPage
          message={response.data.Message || ''}
          statusCode={response.status}
          details={response.data}
          title={this.state.error.recovery.title}
          actionButton={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <SdirButton
              type="primary"
              text={this.state.error.recovery.text}
              onClick={this.state.error.recovery.action}
            />
          }
        />
      );
    }

    return this.props.children;
  }
}

interface FallbackPageProps {
  message: string;
  statusCode: string;
  details: string;
  actionButton?: any;
  title?: string;
}

const FallbackPage: React.FC<FallbackPageProps> = ({
  message,
  statusCode,
  details,
  actionButton,
  title
}) => {
  const intl = useIntl();
  return (
    <Container>
      <BigError
        actionButton={
          actionButton || (
            // eslint-disable-next-line react/jsx-wrap-multilines
            <SdirButton
              type="primary"
              text={intl.formatMessage({ id: 'notfoundpage.backtofrontpage' })}
              onClick={() => {
                window.location.href = '/';
              }}
            />
          )
        }
        errorHeader={title || intl.formatMessage({ id: 'notfoundpage.notfound' })}
        content={<>{message}</>}
        errorCode={intl.formatMessage({ id: 'notfoundpage.errorcode' }, { errorcode: statusCode })}
        secondarySolutionsContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <h2>Detaljer:</h2>
            <Pre>
              {details
                ? JSON.stringify(details, undefined, 2)
                : intl.formatMessage({ id: 'general.errors.api.unexpectedresponse' })}
            </Pre>
          </>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  margin-top: 4rem;
`;

const Pre = styled.pre`
  max-width: 60rem;
  white-space: pre-wrap;
`;

export default ErrorBoundary;
