import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { DataCard, Loader, TextLink, DataTable } from '@sdir/sds';
import { User } from '@sdir/httpclient/lib/clients/core/authorization';
import { useIntl } from 'react-intl';
import { CaseWorkersResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { SortableProperty } from '@sdir/utilities/lib/types/sorting';
import { useDataSorting } from '@sdir/utilities/lib/hooks';
import AddSdirTeamMemberModal from './AddSdirTeamMemberModal';

interface CustomerTeamProps {
  loading: boolean;
  etCaseWorkers: User[];
  coordinator: CaseWorkersResult[];
  reloadSdirTeam: () => void;
}

interface TableRow<T> extends SortableProperty<T> {
  label: string;
}

interface MappedTeamMember {
  name: string;
  subjectAreas: string;
  caseWorkerUid: string;
}

const SdirCoordinator: React.FC<CustomerTeamProps> = ({
  etCaseWorkers,
  loading,
  reloadSdirTeam,
  coordinator
}) => {
  const intl = useIntl();
  const [onboardCaseWorkerModalOpen, setOnboardCaseWorkerModalOpen] = useState(false);

  const columns: TableRow<MappedTeamMember>[] = [
    {
      key: 'name',
      label: intl.formatMessage({ id: 'access.customerteam.name' })
    },
    {
      key: 'caseWorkerUid',
      label: intl.formatMessage({ id: 'access.sdirteam.modal.coordinator.uid' })
    },
    {
      key: 'subjectAreas',
      label: intl.formatMessage({ id: 'access.customerteam.subjectareas' })
    }
  ];

  const getTableData = useCallback(
    (data: CaseWorkersResult[]) =>
      data.map<MappedTeamMember>(details => {
        return {
          name: details.name || '',
          caseWorkerUid: details.caseWorkerUid || '',
          subjectAreas: details.subjectAreas?.join(', ').toString() || ''
        };
      }),
    []
  );

  const { sortedData, setSortedBy } = useDataSorting<any>(getTableData(coordinator), columns);

  return (
    <>
      <DataCard customHeader>
        <CardHeader>
          <Title>{intl.formatMessage({ id: 'access.sdirteam.coordinator.title' })}</Title>
          <TextLink
            click={() => setOnboardCaseWorkerModalOpen(true)}
            text={intl.formatMessage({ id: 'access.sdirteam.coordinator.change' })}
          />
        </CardHeader>
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {coordinator && (
          <DataTable
            columns={columns}
            data={sortedData}
            sortableKeys="*"
            onHeaderClick={setSortedBy}
          />
        )}
      </DataCard>
      <AddSdirTeamMemberModal
        modalVisible={onboardCaseWorkerModalOpen}
        setVisible={() => setOnboardCaseWorkerModalOpen(!onboardCaseWorkerModalOpen)}
        reloadTeamMembers={reloadSdirTeam}
        etCaseWorkers={etCaseWorkers}
        isCoordinator
      />
    </>
  );
};

const LoaderContainer = styled.div``;

const CardHeader = styled.div`
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 3rem;
  border-radius: 6px 6px 0 0;
  button {
    font-family: ${({ theme }) => theme.fonts.stfMedium};
  }
`;

const Title = styled.div`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  margin-right: 3rem;
`;

export default SdirCoordinator;
