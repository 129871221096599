/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useToast } from '@sdir/utilities/lib/hooks';
import {
  setSpecificationOptions,
  setPlannedVesselCertificates,
  setVesselCertificates
} from '../../store/actions/action';
import {
  specificationsApi,
  plannedCertificatesApi,
  getVesselCertificatesApi
} from '../../httpclient';

const useCertificate = (projectuid: string) => {
  const intl = useIntl();
  const { setError } = useToast();
  const dispatch = useDispatch();

  const { callApi: getSpecificationsOptions, loading: getSpecificationsOptionsLoading } = useApi({
    apiFunction: () => specificationsApi.v1SpecificationsGetvesselspecificationoptionsGet(),
    successCallback: res => dispatch(setSpecificationOptions(res))
  });

  const {
    callApi: getPlannedVesselCertificates,
    loading: getPlannedVesselCertificatesLoading
  } = useApi({
    apiFunction: () =>
      plannedCertificatesApi.v1PlannedcertificatesGetplannedprojectcertificatesProjectUidGet(
        projectuid
      ),
    successCallback: res => dispatch(setPlannedVesselCertificates(res)),
    errorCallback: err => {
      err.length > 0 &&
        setError(
          `${intl.formatMessage({ id: 'error.load' }, { error: 'planlagte sertifikater' })}`
        );
    }
  });

  const { callApi: getVesselCertificates, loading: getVesselCertificatesLoading } = useApi({
    apiFunction: vesselUid =>
      getVesselCertificatesApi.v1GetvesselcertificatesGetvesselcertificatesVesselUidGet(vesselUid),
    successCallback: res => dispatch(setVesselCertificates(res)),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: ' sertifikater' })}`);
    }
  });

  const loading =
    getPlannedVesselCertificatesLoading ||
    getVesselCertificatesLoading ||
    getSpecificationsOptionsLoading;

  const certificateData = {
    getSpecificationsOptions,
    getPlannedVesselCertificates,
    getVesselCertificates,
    loading
  };

  return certificateData;
};

export default useCertificate;
