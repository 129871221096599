import React, { useContext, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { SdirIconButton, TextLink, SdiGrid, SdiList } from '@sdir/sds';
import { ViewState } from '../../types/enums';
import { Tile } from '../Molecules';
import { dummyMyTask } from '../../helpers/dummyData';
import MyTaskTableCard from './MyTaskTableCard';

const MyTasks: React.FC = () => {
  const theme = useContext(ThemeContext);
  const [activeView, setActiveView] = useState<ViewState>(ViewState.GRID);
  const [selectTile, setSelectTile] = useState(-1);
  const [state, setState] = useState({
    items: dummyMyTask,
    visible: 5,
    error: false
  });

  const intl = useIntl();

  const loadMore = useCallback(() => {
    setState((prev: any) => ({ ...prev, visible: prev.visible + 5 }));
  }, []);

  return (
    <Wrapper>
      <HeaderRow>
        <Title>{intl.formatMessage({ id: 'dashboard.myTask.header' })}</Title>
        <ButtonGroup>
          <span className="gridbtn">
            <SdirIconButton
              type={activeView === ViewState.GRID ? 'primary' : 'default'}
              icon={SdiGrid}
              color={theme.colors.primary}
              onClick={() => setActiveView(ViewState.GRID)}
            />
          </span>
          <span className="listbtn">
            <SdirIconButton
              type={activeView === ViewState.LIST ? 'primary' : 'default'}
              icon={SdiList}
              onClick={() => setActiveView(ViewState.LIST)}
            />
          </span>
        </ButtonGroup>
      </HeaderRow>
      <ContentView>
        {activeView === ViewState.GRID && (
          <GridView>
            {state.items?.data.slice(0, state.visible).map((ele, i) => (
              <Tile
                expandTileView={selectTile === i}
                setSelectTile={setSelectTile}
                key={i}
                name={ele.customer}
                details={ele.type}
                status={ele.status}
                tileIndex={i}
              />
            ))}
            {state.items?.data.length >= state.visible && (
              <ButtonLink
                id="seeMore"
                text={intl.formatMessage({ id: 'dashboard.myTask.seeMore' })}
                size="lg"
                click={() => loadMore()}
              />
            )}
          </GridView>
        )}
        {activeView === ViewState.LIST && (
          <ListView>
            <MyTaskTableCard />
          </ListView>
        )}
      </ContentView>
    </Wrapper>
  );
};

export default MyTasks;

const Wrapper = styled.section``;

const Title = styled.div`
  display: grid;
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.PBM};
  opacity: 1;
`;

const HeaderRow = styled.div`
  margin-top: 3rem;
  display: grid;
  width: 100%;
  grid-template-columns: auto 12rem;
  grid-template-rows: auto;
`;

const ButtonGroup = styled.div`
  .gridbtn button {
    border-radius: 4px 0 0 4px;
  }
  .listbtn button {
    border-radius: 0 4px 4px 0;
  }
`;

const ContentView = styled.div``;

const GridView = styled.div`
  display: grid;
  margin: 4rem 0;
  grid-template-columns: auto auto auto;
  column-gap: 3rem;
  row-gap: 4rem;
`;

const ListView = styled.div`
  display: grid;
  margin: 4rem 0;
  grid-template-columns: auto;
`;

const ButtonLink = styled(TextLink)`
  font-size: 1.6rem;
  font-weight: 700;
  justify-self: center;
`;
