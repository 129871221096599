import React from 'react';
import styled from 'styled-components';
import { MyTasks } from '../Organisms';
import { SearchHistory, ShortcutLinks } from '../Molecules';

const Dashboard = () => (
  <Container>
    <MainSection>
      <MyTasks />
    </MainSection>
    <SideSection>
      <SearchHistory />
      <ShortcutLinks />
    </SideSection>
  </Container>
);
export default Dashboard;
const Container = styled.div`
  max-width: 195rem;
  width: 90%;
  margin: 3rem auto 3rem auto;
  display: grid;
  grid-template-columns: 75% 25%;
  color: ${({ theme }) => theme.colors.font.text};
`;
const MainSection = styled.div`
  margin-right: 3rem;
  margin-left: 3rem;
`;

const SideSection = styled.div``;
