import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SdirModal, SdirButton, Select, SearchBar, TextLink } from '@sdir/sds';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useToast } from '@sdir/utilities/lib/hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { OnboardCaseWorkerCommand } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';

import { User } from '@sdir/httpclient/lib/clients/core/authorization';

import { onboardCaseWorkerApi } from '../../../httpclient';

interface AddTeamMemberModalProps {
  modalVisible: boolean;
  setVisible: () => void;
  reloadTeamMembers: () => void;
  etCaseWorkers: User[];
  isCoordinator: boolean;
}

const AddSdirTeamMemberModal: React.FC<AddTeamMemberModalProps> = ({
  modalVisible,
  setVisible,
  reloadTeamMembers,
  etCaseWorkers,
  isCoordinator
}) => {
  const intl = useIntl();
  const { setError } = useToast();
  const { projectuid } = useParams() as { projectuid: string };

  const [userNames, setUserNames] = useState<any>([]);
  const [itemMatches, setItemMatches] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const { callApi: onboardCaseWorker } = useApi({
    apiFunction: values => {
      const onnoardCaseWorkerPayLoad = {
        projectUid: projectuid,
        caseWorkerUid: values.caseWorkerUid,
        isCoordinator
      } as OnboardCaseWorkerCommand;
      return onboardCaseWorkerApi.v1OnboardcaseworkerOnboardcaseworkerCommandPut(
        onnoardCaseWorkerPayLoad
      );
    },
    successCallback: () => {
      reloadTeamMembers();
      setVisible();
    },
    errorCallback: err => {
      if (err.length > 0) {
        setError(
          `${intl.formatMessage(
            { id: 'error.create' },
            { error: 'Gi bruker tilgang til fartøy' }
          )}: ${err}`
        );
      }
    }
  });

  const formik = useFormik({
    initialValues: {
      caseWorkerUid: undefined
    },
    onSubmit: val => onboardCaseWorker(val),
    validationSchema: Yup.object({
      caseWorkerUid: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
    })
  });

  const handleClose = () => {
    setVisible();
    formik.resetForm();
    setSearchValue('');
    setItemMatches([]);
  };

  useEffect(() => {
    const list = [] as string[];
    if (userNames.length === 0) {
      etCaseWorkers.forEach(x => {
        if (x.username) {
          list.push(x.username);
        }
      });
      setUserNames(list);
    }
  }, [etCaseWorkers, userNames]);

  const setCaseHandler = userName => {
    const caseworker = etCaseWorkers.find(x => x.username === userName);
    formik.setFieldValue('caseWorkerUid', caseworker?.uid);
  };

  const searchItems = useCallback(
    async searchText => {
      setItemMatches([]);
      if (searchText) {
        setItemMatches(
          userNames.filter(x => x.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
        );
      } else {
        setItemMatches(userNames);
      }
    },
    [userNames]
  );

  const getHeader = () => {
    if (isCoordinator) {
      return intl.formatMessage({ id: 'access.sdirteam.modal.coordinator.title' });
    }
    return intl.formatMessage({ id: 'access.sdirteam.modal.team.title' });
  };

  const getConfirmText = () => {
    if (isCoordinator) {
      return intl.formatMessage({ id: 'access.sdirteam.modal.addorswitchselected' });
    }
    return intl.formatMessage({ id: 'access.sdirteam.modal.addselected' });
  };

  return (
    <>
      <SdirModal visible={modalVisible} width="71rem" closeButton={false}>
        <Header>{getHeader()}</Header>
        <form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <SearchBar
              searchResults={itemMatches}
              searchCallback={searchItems}
              emptyResultsContent={intl.formatMessage({ id: 'common.nosearchresult' })}
              onSearchResultClick={value => {
                setSearchValue(value);
                setCaseHandler(value);
              }}
              value={searchValue}
              placeholder={intl.formatMessage({ id: 'projectinformation.contact.searchforname' })}
            />
          </InputContainer>
          <InputContainer>
            <Title>
              {intl.formatMessage({ id: 'access.sdirteam.modal.resposiblesubejectarea.title' })}
            </Title>
            <Select
              disabled
              defaultVal={intl.formatMessage({ id: 'access.sdirteam.modal.choosesubjectarea' })}
              handleChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <Select
              disabled
              defaultVal={intl.formatMessage({ id: 'access.sdirteam.modal.choosetask' })}
              handleChange={() => {}}
            />
          </InputContainer>
          <ButtonContainer>
            <TextLink
              text={intl.formatMessage({ id: 'common.cancel' })}
              onClick={() => handleClose()}
            />
            <SdirButton
              text={getConfirmText()}
              type="primary"
              htmlType="submit"
              onClick={formik.handleSubmit as any}
            />
          </ButtonContainer>
        </form>
      </SdirModal>
    </>
  );
};

const Header = styled.h1`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4rem;
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  margin-bottom: 2rem;
`;

const InputContainer = styled.div`
  width: 52rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 5rem;
  font-size: 2rem;
`;

export default AddSdirTeamMemberModal;
