/* eslint-disable-next-line max-len */

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CertificateChecklistCard from '@sdir/blueprint.et/lib/components/Organisms/CertificateChecklist/CertificateChecklistCard';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import {
  AddPlannedCertificatePayload,
  RemovePlannedCertificatePayload
} from '@sdir/httpclient/lib/clients/et/construction';
import { useManageCertificates } from '@sdir/blueprint.et/lib/components';
import useCertificate from '../../../services/hooks/useCertificate';
import { plannedCertificatesApi, projectApi } from '../../../httpclient';
import { Routes } from '../../Templates/Routes';
import Page from '../../Templates/Page';
import { vesselTabOptions } from '../../Templates/Routes/constants';

const ManageCertificates: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setError } = useToast();
  const { projectuid } = useParams() as { projectuid: string };
  const [selectedCertificates, setSelectedCertificates] = useState<{
    mainCertificates: string[];
    otherCertificates: string[];
  }>({ mainCertificates: [], otherCertificates: [] });
  const [updatingCertificates, setUpdatingCertificates] = useState<boolean | undefined>(undefined);

  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const plannedCertificates = useSelector((state: any) => state.appState.plannedVesselCertificates);

  const { getPlannedVesselCertificates, getSpecificationsOptions } = useCertificate(projectuid);

  useEffect(() => {
    navigate(
      generatePath(Routes.vesselWorkspace, { projectuid, tab: vesselTabOptions.certificate })
    );
  }, []);

  useEffect(() => {
    if (plannedCertificates === undefined) {
      (async () => {
        await getPlannedVesselCertificates();
      })();
    }

    if (specificationsOptions === undefined) {
      (async () => {
        await getSpecificationsOptions();
      })();
    }
  }, [projectuid, plannedCertificates, specificationsOptions]);

  useEffect(() => {
    if (plannedCertificates !== undefined) {
      setSelectedCertificates({
        mainCertificates:
          plannedCertificates.mainCertificates
            ?.filter(cer => cer.uri && cer.isActive)
            ?.map(c => c.uri || '') || [],
        otherCertificates:
          plannedCertificates.otherCertificates
            ?.filter(cer => cer.uri && cer.isActive)
            ?.map(c => c.uri || '') || []
      });
    }
  }, [plannedCertificates]);

  const { result: projectData, loading: projectDataLoading } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'sertifikater' })}: ${err}`);
    }
  });

  const { callApi: addCertificate } = useApi({
    apiFunction: (payload: AddPlannedCertificatePayload | undefined) => {
      return plannedCertificatesApi.v1PlannedcertificatesAddplannedprojectcertificatePayloadPost(
        projectuid,
        payload
      );
    },
    errorCallback: err => {
      err.length > 0 &&
        setError(
          `${intl.formatMessage({ id: 'error.update' }, { error: 'sertifikater' })}: ${err}`
        );
    }
  });

  const { callApi: removeCertificate } = useApi({
    apiFunction: (payload: RemovePlannedCertificatePayload | undefined) => {
      return plannedCertificatesApi.v1PlannedcertificatesRemoveplannedprojectcertificatePayloadPost(
        projectuid,
        payload
      );
    },
    errorCallback: err => {
      err.length > 0 &&
        setError(
          `${intl.formatMessage({ id: 'error.update' }, { error: 'sertifikater' })}: ${err}`
        );
    }
  });

  const {
    availableMainCertificates,
    availableOtherCertificates,
    addAndRemoveCallback,
    onCertificateClick,
    seeAllCertificatesCallback,
    seeAllCertificates
  } = useManageCertificates(
    specificationsOptions,
    projectData,
    selectedCertificates,
    setSelectedCertificates,
    setUpdatingCertificates,
    addCertificate,
    removeCertificate,
    generatePath(Routes.vesselWorkspace, { tab: 'certificate', projectuid })
  );
  const plannedCertificatesError = '';
  return (
    <Page
      error={plannedCertificatesError}
      loading={projectDataLoading || updatingCertificates === true}
    >
      <WidthWrapper>
        <HeaderWrapper>
          <Title>{intl.formatMessage({ id: 'managecertificates.title' })}</Title>
        </HeaderWrapper>
        <CertificateChecklistCard
          onCertificateClick={onCertificateClick}
          addAndRemoveCallback={addAndRemoveCallback}
          availableMainCertificates={availableMainCertificates}
          availableOtherCertificates={availableOtherCertificates}
          mainCertificates={selectedCertificates.mainCertificates}
          otherCertificates={selectedCertificates.otherCertificates}
          seeAllCertificatesCallback={seeAllCertificatesCallback}
          seeAllCertificates={seeAllCertificates}
          plannedMainCertificates={plannedCertificates?.mainCertificates?.filter(
            cert => cert.isActive
          )}
          plannedOtherCertificates={plannedCertificates?.otherCertificates?.filter(
            cert => cert.isActive
          )}
        />
      </WidthWrapper>
    </Page>
  );
};

const WidthWrapper = styled.div`
  width: 80%;
  margin: 2rem auto 0 auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.primary5};
  font-size: 2rem;
`;

export default ManageCertificates;
