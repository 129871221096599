import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

interface PlaceholderTileInterface {
  placeholderFor?: number;
}

const PlaceholderTile: React.FC<PlaceholderTileInterface> = ({ placeholderFor }) => {
  const intl = useIntl();

  return (
    <Placeholder className="placeholder-dropzone" data-placeholderfor={placeholderFor}>
      {intl.formatMessage({ id: 'datacard.placeholdet.text' })}
    </Placeholder>
  );
};

const Placeholder = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.card.borderRadius};
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
`;

export default PlaceholderTile;
