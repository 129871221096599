import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import React from 'react';
import { useParams } from 'react-router-dom';
import ViewRequirement from '@sdir/blueprint.et/lib/components/Pages/ViewRequirements';

import { VesselRequirementSection } from '@sdir/httpclient/lib/clients/et/construction';
import { requirementApi } from '../../../httpclient';

interface ViewRequirementProps {
  reqList?: VesselRequirementSection[] | undefined;
}

const ViewRequirementWrapper: React.FC<ViewRequirementProps> = () => {
  const { projectuid } = useParams() as { projectuid: string };
  const { setError } = useToast();
  const intl = useIntl();

  const {
    result: filteredReqList,
    loading: filteredReqListLoading,
    callApi: getFilteredReqs
  } = useApi({
    apiFunction: eliref =>
      requirementApi.v3RequirementGetprojectrequirementsv3ChapterEliReferenceGet(
        projectuid,
        eliref
      ),
    callApiOnInit: false,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
      }
    }
  });

  return (
    <ViewRequirement
      filteredReqList={filteredReqList}
      filteredReqListLoading={filteredReqListLoading}
      getFilteredReqs={getFilteredReqs}
    />
  );
};

export default ViewRequirementWrapper;
