import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { DataCard, Loader, DataTable, TextLink } from '@sdir/sds';
import { useDataSorting } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { GetProjectTeamMembersResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { SortableProperty } from '@sdir/utilities/lib/types/sorting';
import AddTeamMemberModal from './AddTeamMemberModal';

interface CustomerTeamProps {
  teamMembers: GetProjectTeamMembersResult[];
  loading: boolean;
  reloadTeamMembers: () => void;
}

interface TableRow<T> extends SortableProperty<T> {
  label: string;
}

interface MappedTeamMember {
  name: string;
  subjectAreas: string;
  permission: string;
}

const CustomerTeam: React.FC<CustomerTeamProps> = ({ teamMembers, loading, reloadTeamMembers }) => {
  const [addTeamMemberModalOpen, setAddTeamMemberModalOpen] = useState(false);
  const intl = useIntl();
  const columns: TableRow<MappedTeamMember>[] = [
    {
      label: intl.formatMessage({ id: 'access.customerteam.name' }),
      key: 'name'
    },
    {
      key: 'subjectAreas',
      label: intl.formatMessage({ id: 'access.customerteam.subjectareas' })
    },
    {
      key: 'permission',
      label: intl.formatMessage({ id: 'access.customerteam.permissions' })
    }
  ];

  const getTableData = useCallback(
    (data: GetProjectTeamMembersResult[]) =>
      data.map<MappedTeamMember>(details => {
        return {
          name: details.name || '',
          subjectAreas: details.subjectAreas?.join(', ').toString() || '',
          permission: details.permission?.toString() || ''
        };
      }),
    []
  );

  const { sortedData, setSortedBy } = useDataSorting<any>(getTableData(teamMembers), columns);

  return (
    <>
      <DataCard customHeader>
        <CardHeader>
          <Title>{intl.formatMessage({ id: 'access.customerteam.title' })}</Title>
          <TextLink
            click={() => setAddTeamMemberModalOpen(true)}
            text={intl.formatMessage({ id: 'access.customerteam.giveaccess' })}
          />
        </CardHeader>
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {teamMembers && (
          <StyledTable>
            <DataTable
              columns={columns}
              data={sortedData}
              sortableKeys="*"
              onHeaderClick={setSortedBy}
            />
          </StyledTable>
        )}
      </DataCard>
      <AddTeamMemberModal
        modalVisible={addTeamMemberModalOpen}
        setVisible={() => setAddTeamMemberModalOpen(!addTeamMemberModalOpen)}
        reloadTeamMembers={reloadTeamMembers}
      />
    </>
  );
};

const LoaderContainer = styled.div``;

const CardHeader = styled.div`
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 3rem;
  border-radius: 6px 6px 0 0;
  button {
    font-family: ${({ theme }) => theme.fonts.stfMedium};
  }
`;

const Title = styled.div`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  margin-right: 3rem;
`;

const StyledTable = styled.div`
  thead {
    background-image: linear-gradient(#ccd9e3, #ccd9e3), linear-gradient(white, white);
    background-repeat: no-repeat;
    background-size: 91% 4%;
    background-position: bottom;
  }
`;

export default CustomerTeam;
