import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { FileImage } from '@sdir/blueprint';
import { DataCard, Loader, SdiClose, SdirButton, styles } from '@sdir/sds';
// eslint-disable-next-line max-len
import { CommonAddresseeServiceContractsV1TypesAttachmentType as AddresseeAttachmentType } from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { addresseeAttachmentV2Api } from '../../../httpclient';
import { downloadAddresseeAttachment } from '../../../helpers/addresseeAttachmentApiHelpers';

export type PersonData = {
  photo?: any;
  signature?: any;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  personalIdentificationNumber?: string;
  nationality?: string;
  address?: any;
  legalEntityId?: string;
  commonAddresseeId?: string;
};

type PersonMergeCardProps = {
  differences?: string[];
  person?: PersonData;
  title: string;
  onMerge?: () => void;
  onClose?: () => void;
};

const PersonMergeCard: React.FC<PersonMergeCardProps> = ({
  differences,
  person,
  title,
  onMerge,
  onClose
}) => {
  const intl = useIntl();
  const [photoLoading, setPhotoLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [signatureFile, setSignatureFile] = useState<File | null>(null);

  const {
    result: attachmentsResult,
    loading: attachmentsLoading,
    callApi: callAttachmentsApi
  } = useApi({
    apiFunction: id => addresseeAttachmentV2Api.v2Attachmentv2GetattachmentsCommonAddresseeIdGet(id)
  });

  useEffect(() => {
    const commonAddresseeId = person?.commonAddresseeId || '';
    const photos = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Photo
    );
    const signatures = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Signature
    );
    if (photos?.length) {
      setPhotoLoading(true);
      downloadAddresseeAttachment(commonAddresseeId, photos[photos.length - 1])
        .then(f => {
          setPhotoFile(f);
          setPhotoLoading(false);
        })
        .catch(() => setPhotoLoading(false));
    }
    if (signatures?.length) {
      setSignatureLoading(true);
      downloadAddresseeAttachment(commonAddresseeId, signatures[signatures.length - 1])
        .then(f => {
          setSignatureFile(f);
          setSignatureLoading(false);
        })
        .catch(() => setSignatureLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsResult, person?.commonAddresseeId]);

  useEffect(() => {
    callAttachmentsApi(person?.commonAddresseeId);
  }, [person?.commonAddresseeId]);

  return (
    <DataCard title={title} customHeader={!!(onMerge || onClose)}>
      {onMerge && (
        <CustomHeader>
          <h2>{title}</h2>
          <SdirButton
            type="primary"
            text={intl.formatMessage({ id: 'aps.person.merge.label' })}
            onClick={onMerge}
          />
        </CustomHeader>
      )}
      {onClose && (
        <CustomHeader>
          <h2>{title}</h2>
          <CloseButton type="button" onClick={onClose}>
            <SdiClose size="m" color={styles.colors.primary} />
          </CloseButton>
        </CustomHeader>
      )}
      <Container>
        <InfoRow>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.photo' })}</Header>
          <PhotoWrapper>
            {photoLoading && <Loader />}
            {photoFile && !photoLoading && <FileImage file={photoFile} />}
          </PhotoWrapper>
        </InfoRow>
        <InfoRow>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.signature' })}</Header>
          <SignatureWrapper>
            {signatureLoading && <Loader />}
            {signatureFile && !signatureLoading && <FileImage file={signatureFile} />}
          </SignatureWrapper>
        </InfoRow>
        <InfoRow className={differences?.includes('firstName') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.firstName' })}</Header>
          <Info>{person?.firstName}</Info>
        </InfoRow>
        <InfoRow className={differences?.includes('lastName') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.lastName' })}</Header>
          <Info>{person?.lastName}</Info>
        </InfoRow>
        <InfoRow className={differences?.includes('email') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.email' })}</Header>
          <Info>{person?.email}</Info>
        </InfoRow>
        <InfoRow className={differences?.includes('personalIdentificationNumber') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.dNumber' })}</Header>
          <Info>{person?.personalIdentificationNumber}</Info>
        </InfoRow>
        <InfoRow className={differences?.includes('dob') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.dob' })}</Header>
          <Info>{person?.dob}</Info>
        </InfoRow>
        <InfoRow className={differences?.includes('nationality') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.citizenship' })}</Header>
          <Info>{person?.nationality}</Info>
        </InfoRow>
        <InfoRow minHeight="100px" className={differences?.includes('address') ? 'diff' : ''}>
          <Header>{intl.formatMessage({ id: 'aps.customerCard.address' })}</Header>
          <Info>
            <Info>{person?.address?.streetName}</Info>
            <Info>
              {person?.address?.zipCode} {person?.address?.city}
            </Info>
            <Info>{person?.address?.country}</Info>
          </Info>
        </InfoRow>
      </Container>
    </DataCard>
  );
};

export default PersonMergeCard;

const Container = styled.div`
  padding: 16px 24px;
`;

const CustomHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary10};
  position: relative;
  overflow: hidden;
  height: 10rem;
  padding: 0 3rem;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;

  h2 {
    font-family: ${({ theme }) => theme.fonts.stfMedium};
    padding: 2rem 0;
    color: ${({ theme }) => theme.colors.font.text};
    margin: 0;
    font-weight: normal;
  }
`;

const InfoRow = styled.div<{ minHeight?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid grey;
  min-height: ${({ minHeight }) => minHeight || '36px'};
  padding: 0 10px;
  background-color: transparent;
  transition: background-color 0.2s ease-in;

  &.diff {
    background-color: #efeb97;
  }
`;

const PhotoWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0;
  img {
    height: 160px;
  }
`;

const SignatureWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0;
  img {
    height: 50px;
  }
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const Header = styled.div`
  font-size: 16px;
  color: #333333a6;
  font-weight: bold;
  flex: 1;
`;

const Info = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.font.text};
  font-weight: bold;
  flex: 1;
`;
