/* eslint-disable @typescript-eslint/indent */
import {
  ActivityWithRisk,
  GetActivityDetailsV2ResultEventsInner,
  NotRelevantRequestedDomainEvent
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { DataCard } from '@sdir/sds';
import { ToggleButton, DataCardContent, Preview } from '@sdir/blueprint.et/lib/components';
import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';

interface ActionMessageProps {
  activity: ActivityWithRisk;
  events: GetActivityDetailsV2ResultEventsInner[] | null | undefined;
}

const ActionMessage: React.FC<ActionMessageProps> = ({ activity, events }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  const event: NotRelevantRequestedDomainEvent | undefined | null = events?.length
    ? events[events.length - 1]
    : activity.lastEvent;

  if (!event) return <div />;

  const title = intl.formatMessage({ id: 'actionmessage.notrelevant' });

  return (
    <Wrapper>
      <DataCard>
        <DataCardContent>
          <Preview barColor={theme.colors.alert.warningDark}>
            <span>
              {isExpanded ? (
                <>
                  <b>{title}</b>
                </>
              ) : (
                <>
                  <b>{title}</b>: {event.message}
                </>
              )}
            </span>

            <ToggleButton
              isToggled={isExpanded}
              text=""
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Preview>
          {isExpanded && <p>{event.message}</p>}
        </DataCardContent>
      </DataCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem 0 0;

  section {
    div {
      line-height: 1;
    }
  }
`;

export default ActionMessage;
