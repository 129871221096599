import React from 'react';
import styled from 'styled-components';

const Plus = () => {
  return <Span>+</Span>;
};

export default Plus;

const Span = styled.span`
  && {
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    text-align: center;
  }
`;
