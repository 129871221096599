import { TableColumn, BaseTableRow } from '@sdir/utilities/lib/types/tables';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { compareDates, compareMoment } from '.';
import messages from '../localization/localization';

const cache = createIntlCache();
const lang = window.location.pathname?.split('/')?.includes('en') ? 'en' : 'nb';

const intl = createIntl(
  {
    // This is a work around to make the test run
    locale: lang === 'nb' ? 'no' : 'en',
    messages: messages[lang as keyof typeof messages]
  },
  cache
);

export interface SeagoingTableRowNew extends BaseTableRow {
  vessel: any;
  vesselName: string;
  period: string;
  periodStartValue: Moment | null;
  days: string;
  daysValue: number | undefined;
  actions: any;
}
export const SeagoingTableHeaderNew: TableColumn<SeagoingTableRowNew>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.vessel' }),
    key: 'vessel',
    sortKey: 'vesselName'
  },
  {
    label: intl.formatMessage({ id: 'table.report.period' }),
    key: 'period',
    sortFunction: (a, b) => compareMoment(a.periodStartValue, b.periodStartValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.days' }),
    key: 'days',
    sortKey: 'daysValue'
  },
  {
    label: '',
    key: 'actions'
  }
];

export interface SeagoingTableRow extends BaseTableRow {
  jobTitle: string | null | undefined;
  days: string;
  daysValue: number | undefined;
}
export const SeagoingTableHeader: TableColumn<SeagoingTableRow>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.jobTitle' }),
    key: 'jobTitle'
  },
  {
    label: intl.formatMessage({ id: 'table.report.days' }),
    key: 'days',
    sortKey: 'daysValue'
  }
];

export interface SeagoingSubTableRow extends BaseTableRow {
  vessel: any;
  vesselName: string;
  period: string;
  periodStartValue: Moment | null;
  organization: string | null | undefined;
  days: string;
  daysValue: number | null | undefined;
  attachments: any;
  edit: any;
  remove: any;
}
export const SeagoingSubTableHeader: TableColumn<SeagoingSubTableRow>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.vessel' }),
    key: 'vessel',
    sortKey: 'vesselName'
  },
  {
    label: intl.formatMessage({ id: 'table.report.period' }),
    key: 'period',
    sortFunction: (a, b) => compareMoment(a.periodStartValue, b.periodStartValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.employer' }),
    key: 'organization'
  },
  {
    label: intl.formatMessage({ id: 'table.report.days' }),
    key: 'days',
    sortKey: 'daysValue'
  },
  {
    label: '',
    key: 'attachments'
  },
  {
    label: '',
    key: 'edit'
  },
  {
    label: '',
    key: 'remove'
  }
];

export interface ReportTimeRow extends BaseTableRow {
  createdBy: string | null | undefined;
  date: string | null | undefined;
  dateValue: Moment | null;
  period: string;
  periodStartValue: Moment | null;
  organization: string | null | undefined;
  hours: number | null | undefined;
}
export const ReportTimeHeader: TableColumn[] = [
  {
    label: intl.formatMessage({ id: 'table.report.createdBy' }),
    key: 'createdBy'
  },
  {
    label: intl.formatMessage({ id: 'table.report.date' }),
    key: 'date',
    sortFunction: (a, b) => compareMoment(a.dateValue, b.dateValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.period' }),
    key: 'period',
    sortFunction: (a, b) => compareMoment(a.periodStartValue, b.periodStartValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.employer' }),
    key: 'organization'
  },
  {
    label: intl.formatMessage({ id: 'table.report.hours' }),
    key: 'hours'
  }
];

export interface CompetenceRow extends BaseTableRow {
  vessel: any;
  period: string;
  periodStartValue: Moment | null;
  additionalCompetence: string | null | undefined;
  code: string | null | undefined;
  remove: any;
}
export const CompetenceColumns: TableColumn<CompetenceRow>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.vessel' }),
    key: 'vessel'
  },
  {
    label: intl.formatMessage({ id: 'table.report.period' }),
    key: 'period',
    sortFunction: (a, b) => compareMoment(a.periodStartValue, b.periodStartValue)
  },
  {
    label: intl.formatMessage({ id: 'table.competence.report.competence' }),
    key: 'additionalCompetence'
  },
  {
    label: intl.formatMessage({ id: 'table.report.code' }),
    key: 'code'
  },
  {
    label: '',
    key: 'remove'
  }
];

export interface AssessmentRow extends BaseTableRow {
  date: string;
  dateValue: Moment | null;
  assessorName: string | null | undefined;
  assessmentType: string | null | undefined;
  vesselName: ReactElement | null | undefined;
  vesselNameValue: string | null | undefined;
  period: string;
  periodStartValue: Moment | null;
  remove: any;
}
export const AssesmentColumns: TableColumn<AssessmentRow>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.completionDate' }),
    key: 'date',
    sortFunction: (a, b) => compareMoment(a.dateValue, b.dateValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.assessorName' }),
    key: 'assessorName'
  },
  {
    label: intl.formatMessage({ id: 'table.report.assessortype' }),
    key: 'assessmentType'
  },
  {
    label: intl.formatMessage({ id: 'table.report.vessel' }),
    key: 'vesselName',
    sortKey: 'vesselNameValue'
  },
  {
    label: intl.formatMessage({ id: 'table.report.period' }),
    key: 'period',
    sortFunction: (a, b) => compareMoment(a.periodStartValue, b.periodStartValue)
  },
  {
    label: '',
    key: 'remove'
  }
];

export interface CourseRow extends BaseTableRow {
  organization: string | null | undefined;
  name: string | null | undefined;
  status: string;
  date: string | null | undefined;
  dateValue: Moment | null;
  expiry: string | null | undefined;
  expiryValue: Moment | null;
  edit: any;
  remove: any;
}
export const CourseTableColumns: TableColumn<CourseRow>[] = [
  {
    label: intl.formatMessage({ id: 'table.report.name' }),
    key: 'name'
  },
  {
    label: intl.formatMessage({ id: 'table.report.organization' }),
    key: 'organization'
  },
  {
    label: 'Status',
    key: 'status'
  },
  {
    label: intl.formatMessage({ id: 'table.report.completionDate' }),
    key: 'date',
    sortFunction: (a, b) => compareMoment(a.dateValue, b.dateValue)
  },
  {
    label: intl.formatMessage({ id: 'table.report.expiryDate' }),
    key: 'expiry',
    sortFunction: (a, b) => compareMoment(a.expiryValue, b.expiryValue)
  },
  {
    label: '',
    key: 'edit'
  },
  {
    label: '',
    key: 'remove'
  }
];

export interface PersonalCertificateRow extends BaseTableRow {
  certificateType: string | null | undefined;
  issuedDate: string;
  issuedDateValue: Moment | null;
  validToDate: string;
  validToDateValue: Moment | null;
  certificateStatus: string | null | undefined;
  validBtn: ReactElement;
}
export const PersonalCertificateColums: TableColumn<PersonalCertificateRow>[] = [
  {
    label: intl.formatMessage({ id: 'dashboard.myTask.type' }),
    key: 'certificateType'
  },
  {
    label: 'Status',
    key: 'certificateStatus'
  },
  {
    label: intl.formatMessage({ id: 'table.report.issuedDate' }),
    key: 'issuedDate',
    sortFunction: (a, b) => compareMoment(a.issuedDateValue, b.issuedDateValue)
  },
  {
    label: intl.formatMessage({ id: 'aps.health.validTo' }),
    key: 'validToDate',
    sortFunction: (a, b) => compareMoment(a.validToDateValue, b.validToDateValue)
  },
  {
    label: '',
    key: 'validBtn'
  }
];

export interface PersonalApplicationRow extends BaseTableRow {
  certificate: any;
  certificateValue: any;
  applicationType: any;
  applicationTypeSortValue: string | null | undefined;
  status: string | null | undefined;
  paidAt: string;
  paidAtValue: Moment | null;
}

export const PersonalApplicationColumns: TableColumn<PersonalApplicationRow>[] = [
  {
    label: intl.formatMessage({ id: 'dashboard.myTask.certificate' }),
    key: 'certificate',
    sortKey: 'certificateValue'
  },
  {
    label: intl.formatMessage({ id: 'dashboard.myTask.type' }),
    key: 'applicationType',
    sortKey: 'applicationTypeSortValue'
  },
  {
    label: intl.formatMessage({ id: 'table.application.status' }),
    key: 'status'
  },
  {
    label: intl.formatMessage({ id: 'table.application.payment.date' }),
    key: 'paidAt',
    sortFunction: (a, b) => compareMoment(a.paidAtValue, b.paidAtValue)
  }
];

export const TableHeader = {
  columns: [
    {
      label: 'Fartøy navn',
      key: 'name'
    },
    {
      label: 'Lengde i meter',
      key: 'length'
    },
    {
      label: 'Radiodekningsområde',
      key: 'radioCoverage'
    },
    {
      label: 'Byggedato',
      key: 'time',
      sortFunction: (a, b) => compareDates(a.timeValue, b.timeValue)
    },
    {
      label: 'Fartøystype',
      key: 'vesselType'
    },
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Fartøysområde',
      key: 'tradeArea'
    },
    {
      label: 'Saksbehandler',
      key: 'caseWorker'
    }
  ]
};

export const MyTasksTableHeader = {
  columns: [
    {
      label: 'Fartøy navn',
      key: 'vesselName'
    },
    {
      label: 'Kjenningssignal',
      key: 'vesselCallSign'
    },
    {
      label: 'Fase',
      key: 'fase'
    },
    {
      label: 'Fagområde',
      key: 'theme'
    },
    {
      label: 'Oppgave',
      key: 'name'
    },
    {
      label: 'Behandlingsfrist',
      key: 'deadlineDate'
    },
    {
      label: 'Saksbehandler',
      key: 'caseWorkerName'
    }
  ]
};
