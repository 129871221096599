import {
  GetProjectRequirementsOverviewResult,
  VesselRequirementSection
} from '@sdir/httpclient/lib/clients/et/construction';
import { Loader } from '@sdir/sds';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SectionList, useDynamicHeight } from '@sdir/blueprint.et/lib/components';
import RegulationsListMenu from '../Molecules/Requirements/RegulationsListMenu';
import { Routes } from '../Templates/Routes';
import { vesselTabOptions } from '../Templates/Routes/constants';

interface RegulationsSectionProps {
  listMenuRequirements: GetProjectRequirementsOverviewResult | undefined;
  filteredRequirements: VesselRequirementSection[] | undefined;
  getFilteredRequirements: (eliref: string) => void;
  loading: boolean;
}

const scrollToSection = targetId => {
  const targetEl = document.getElementById(targetId);
  targetEl?.scrollIntoView({ behavior: 'smooth' });
};

const RegulationsSection: React.FC<RegulationsSectionProps> = ({
  listMenuRequirements,
  getFilteredRequirements,
  filteredRequirements,
  loading
}) => {
  const [chapterTitle, setChapterTitle] = useState('');
  const { projectuid } = useParams() as { projectuid: string };
  const navigate = useNavigate();
  const location = useLocation();
  const { ref, height } = useDynamicHeight(undefined);

  useEffect(() => {
    // Load first chapter on load, if eliref_redirect is not set
    if (localStorage.getItem('ELIREF_REDIRECT')) return;
    const regulations = listMenuRequirements?.regulations;
    if (regulations && regulations[0]) {
      const { chapters } = regulations[0];
      if (chapters && chapters[0]) {
        getFilteredRequirements(chapters[0].eliReference || '');
        setChapterTitle(chapters[0].title || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* Handle "Go to requirement" - feature */
    const eliref = localStorage.getItem('ELIREF_REDIRECT');
    const getRequirementAndGoTochapter = async () => {
      const chapter = eliref
        ?.split('/')
        .slice(0, 9)
        .join('/');

      if (chapter) {
        await getFilteredRequirements(chapter);
        /* Get index of regulation */
        let regulationIdx;
        listMenuRequirements?.regulations?.forEach((reg, i) => {
          if (eliref?.includes(reg.eliReference || ' ')) regulationIdx = i;
        });

        /* Get index of chapter */
        let chapterIdx;
        if (listMenuRequirements?.regulations) {
          listMenuRequirements?.regulations[regulationIdx].chapters?.forEach((el, i) => {
            if (eliref?.includes(el.eliReference || ' ')) chapterIdx = i;
          });
        }

        /* add indices to uri to trigger RegulationListMenu selection */
        navigate(
          generatePath(Routes.vesselWorkspaceScrollToChapter, {
            tab: vesselTabOptions.requirementlist,
            projectuid,
            scrollToId: regulationIdx,
            chapterIdx
          })
        );

        /* remove from url and localstorage */
        localStorage.removeItem('ELIREF_REDIRECT');
        setTimeout(() => {
          navigate(location.pathname, { replace: true });
        }, 5);

        scrollToSection(eliref);
      }
    };

    if (eliref) getRequirementAndGoTochapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ListMenuContainer ref={ref}>
        <RegulationsListMenu
          data={listMenuRequirements}
          handleChapterClick={chapter => getFilteredRequirements(chapter.eliReference)}
          setChapterTitle={setChapterTitle}
        />
      </ListMenuContainer>
      <SectionListWrapper>
        {loading && <Loader />}
        <span id="top-scroll-target" />
        {!loading && (
          <SectionList list={filteredRequirements} chapterTitle={chapterTitle} height={height} />
        )}
      </SectionListWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 4rem;
  display: flex;
`;

const SectionListWrapper = styled.div`
  flex-grow: 1;
`;

const ListMenuContainer = styled.div`
  width: 52rem;
  margin-bottom: 4rem;
  margin-right: 16rem;
`;

export default RegulationsSection;
