/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Loader } from '@sdir/sds';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { SuggestedScopeActivity } from '@sdir/httpclient/lib/clients/et/survey';
import { SurveyScopeActions } from '@sdir/blueprint.et/lib/components';
import { ScopedActivity } from '../Molecules/SurveyScopeSection';
import ScopeTaskCard from '../Molecules/ActivityCard/ScopeTaskCard';

interface Props {
  loading: boolean;
  activitiesListOfSubjectArea: ScopedActivity[];
  height: string;
  currentSelectedSubjectArea: string | null;
  setSurveyScope: (
    activitiesChanged: SuggestedScopeActivity[],
    action?: SurveyScopeActions
  ) => void;
  setRelevance: (
    scopedActivities: ScopedActivity[],
    isNotRelevant: boolean,
    relevanceComment?: string
  ) => void;
}

const SurveyScopeActivityList: React.FC<Props> = ({
  activitiesListOfSubjectArea,
  loading,
  height,
  setSurveyScope,
  setRelevance
}) => {
  const { scrollToId } = useParams() as { scrollToId?: string; projectuid?: string };
  const navigate = useNavigate();
  const location = useLocation();
  const [activitiesList, setActivitiesList] = useState<ScopedActivity[]>(
    activitiesListOfSubjectArea
  );

  useEffect(() => {
    /* Use optional scroll path param to scroll to activity when loaded */
    if (scrollToId && activitiesListOfSubjectArea) {
      setTimeout(() => {
        const activityEl = document.getElementById(scrollToId);
        activityEl?.scrollIntoView({ behavior: 'smooth' });
        /* remove scroll path param after */
        const newpathname = location.pathname
          .split('/')
          .slice(0, -1)
          .join('/');
        navigate(newpathname, { replace: true });
      }, 300);
    }
    setActivitiesList(activitiesListOfSubjectArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToId, activitiesListOfSubjectArea]);
  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <Container height={height}>
      <Wrapper>
        {activitiesList?.map(activity => {
          return (
            <div key={activity.uid}>
              <ScopeTaskCard
                activity={activity}
                setSurveyScope={setSurveyScope}
                setRelevance={setRelevance}
              />
            </div>
          );
        })}
      </Wrapper>
    </Container>
  );
};

const Container = styled.article<{ height: string }>`
  width: 100%;
  margin-bottom: 4rem;
  margin-left: 2rem;

  overflow-y: scroll;
  padding: 0 2rem;
  height: ${({ height }) => `calc(${height} - 14rem)`}; // minus margin top

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.primary9};
    outline: ${({ theme }) => theme.colors.primary10};
  }

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
    border-radius: 6px;
    margin: 12px 0;
  }
`;

const LoaderContainer = styled.div`
  display: grid;
  justify-self: center;
  z-index: 1000;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: center;
  grid-column-start: 6;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
`;

export default React.memo(SurveyScopeActivityList);
