import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VesselRequirementsSearchResult } from '@sdir/httpclient/lib/clients/core/requirement';
import {
  RequirementChapterOverview,
  RequirementSectionOverview
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

const scrollToSection = targetId => {
  const targetEl = document.getElementById(targetId);
  targetEl?.scrollIntoView({ behavior: 'smooth' });
};
interface ListMenuProps {
  data: VesselRequirementsSearchResult | undefined;
  title?: string;
  handleOuterMenuClick?: (e) => void;
  handleChapterClick: (chapter) => void;
  setChapterTitle?: (title: string) => void;
}

const RegulationsListMenu: React.FC<ListMenuProps> = ({
  data,
  title,
  handleOuterMenuClick,
  handleChapterClick,
  setChapterTitle
}) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<number | null>(0);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(0);

  const { scrollToId, chapterIdx } = useParams() as { scrollToId?: string; chapterIdx?: string };

  /* Handle load of specified regulation / index for the "Go to requirement"-feature  */
  useEffect(() => {
    if (scrollToId && chapterIdx) setSelected(Number(scrollToId));
    if (chapterIdx) setSelectedChapter(Number(chapterIdx));
  }, [scrollToId, chapterIdx]);

  const handleChapterSelect = (index, chapterTitle) => {
    setSelectedChapter(index);
    if (setChapterTitle) setChapterTitle(chapterTitle);
  };

  const handleClick = (index: any) => {
    setSelected(index);
    const chapters = data && data.regulations && data.regulations[index].chapters;

    if (selected === index) {
      setSelected(null);
    } else if (chapters && chapters[0]) {
      handleChapterClick(chapters[0]);
      handleChapterSelect(0, chapters[0].title || '');
      scrollToSection('top-scroll-target');
    }
  };

  return (
    <Container data-testid="listmenu-container">
      <Title>{title || intl.formatMessage({ id: 'activitycard.regulations' })}</Title>
      {data &&
        data.regulations?.map((regulation, index: any) => (
          <div key={regulation.title + index}>
            <Outer onClick={handleOuterMenuClick}>
              <RegulationLabel
                aria-controls={regulation.title + index}
                aria-expanded={Boolean(selected === index)}
                onClick={() => {
                  handleClick(index);
                }}
                className={selected === index ? 'active' : ''}
              >
                {regulation.title || 'Forskrift'}
              </RegulationLabel>
            </Outer>
            {selected === index ? (
              <MenuItem id={regulation.title + index}>
                {regulation.chapters &&
                  regulation.chapters.map((chapter, i: any) => (
                    <Chapter
                      handleChapterClick={handleChapterClick}
                      key={chapter.eliReference + i}
                      i={i}
                      chapter={chapter}
                      setSelectedChapter={newIndex => handleChapterSelect(newIndex, chapter.title)}
                      selectedChapter={selectedChapter}
                    />
                  ))}
              </MenuItem>
            ) : (
              ''
            )}
          </div>
        ))}
    </Container>
  );
};

interface ChapterProps {
  chapter: RequirementChapterOverview;
  setSelectedChapter: (i) => void;
  selectedChapter: number | null;
  i: number;
  handleChapterClick: (chapter) => void;
}

const Chapter: React.FC<ChapterProps> = ({
  chapter,
  setSelectedChapter,
  selectedChapter,
  i,
  handleChapterClick
}) => {
  const handleSelect = () => {
    if (selectedChapter === i) {
      setSelectedChapter(null);
    } else {
      setSelectedChapter(i);
      handleChapterClick(chapter);
      scrollToSection('chapter-title');
    }
  };

  return (
    <>
      <ChapterLabel
        onClick={() => {
          handleSelect();
        }}
        className={selectedChapter === i ? 'active' : ''}
        aria-expanded={Boolean(selectedChapter === i)}
        aria-controls={`${chapter.eliReference}${i}`}
      >
        {chapter.title}
      </ChapterLabel>
      <div id={`${chapter.eliReference}${i}`}>
        {selectedChapter === i && <SectionList sections={chapter.sections} />}
      </div>
    </>
  );
};

interface SectionListProps {
  sections: RequirementSectionOverview[] | undefined | null;
}

const SectionList: React.FC<SectionListProps> = ({ sections }) => {
  if (sections) {
    return (
      <>
        {sections.map(section => (
          <SectionLabel
            key={section.eliReference}
            onClick={() => {
              scrollToSection(section.eliReference);
            }}
          >
            {section.title || 'Uten tittel'}
          </SectionLabel>
        ))}
      </>
    );
  }
  return null;
};

const Outer = styled.div``;

const Container = styled.nav`
  background: ${({ theme }) => theme.colors.font.white};
  padding: 4rem 4rem 4rem 2rem;
  width: 44rem;
  box-shadow: 0 0.375rem 0.75rem 0 rgb(0 0 0 / 29%);
`;

const Title = styled.div`
  font-size: 3rem;
  margin-left: 1.75rem;
  margin-bottom: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text85};
`;

const RegulationLabel = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 2.5rem;
  padding: 0.75rem 0 0.75rem 1.5rem;
  padding-left: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  text-align: left;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.font.white};
  width: 100%;
  line-height: 1.5;

  &:focus {
    color: ${({ theme }) => theme.colors.font.white};
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary};
  }

  &::after {
    content: '+';
    margin-right: 2rem;
    margin-left: 2rem;
    float: right;
    align-self: center;
  }
  &.active {
    color: ${({ theme }) => theme.colors.font.white};
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary};

    &::after {
      content: '-';
      margin-right: 2rem;
      float: right;
    }
  }
`;

const MenuItem = styled.div`
  margin-left: 2.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  cursor: pointer;
`;

const SectionLabel = styled.button`
  margin-left: 2.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  padding-left: 1rem;
  padding-right: 1rem;
  width: 90%;

  font-family: ${({ theme }) => theme.fonts.stfBook};

  font-size: 2rem;
  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.font.white};
  width: 100%;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primary};

  &:focus {
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary10};
  }

  &:hover {
    cursor: pointer;
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary10};
  }
`;

const ChapterLabel = styled.button`
  margin-bottom: 1.5rem;
  padding: 2px 8px;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  font-family: ${({ theme }) => theme.fonts.stfBook};

  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.font.white};
  width: 100%;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primary};

  &:focus {
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary10};
  }

  &.active {
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.primary10};
  }

  &::after {
    content: '+';
    margin-right: 2rem;
    margin-left: 2rem;
    float: right;
    align-self: center;
  }
  &.active {
    &::after {
      content: '-';
      margin-right: 2rem;
      float: right;
    }
  }
`;

export default RegulationsListMenu;
