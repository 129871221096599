/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { InfoTile, PlaceholderTile } from '../../Molecules';
import { PanelSizeInterface } from './InfoTilesPanel';
import {
  NumberUpInfoTile,
  TileTitle,
  BubbleInfoTile,
  SemiDonutChart,
  NumberDownInfoTile,
  VerticalBarChart,
  HorizontalBarChart,
  TimeLineBarTile
} from '../../Atoms';

interface MainTilesInterface {
  data: any[];
  editing?: boolean;
}

export const renderTile = (tile, intl, options?) => {
  const { editing, onTileRemoveClick, isDraggable } = options;
  let titleTxt;
  let subTitleTxt;

  switch (tile.tileType) {
    case 0:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.bubble.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.bubble.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          editing={editing}
          isDraggable={isDraggable}
          onRemoveTileClick={onTileRemoveClick}
          tileTitle={<TileTitle title={titleTxt} />}
          tileBody={<BubbleInfoTile subTitle={subTitleTxt} newCases={3} totalCases={17} />}
        />
      );
    case 1:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.up.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.up.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          isDraggable={isDraggable}
          onRemoveTileClick={onTileRemoveClick}
        >
          <NumberUpInfoTile subTitle={subTitleTxt} number="5.441" />
        </InfoTile>
      );
    case 2:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.down.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.down.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          isDraggable={isDraggable}
          onRemoveTileClick={onTileRemoveClick}
        >
          <NumberDownInfoTile subTitle={subTitleTxt} number="220" />
        </InfoTile>
      );
    case 3:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.semiDonut.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.semiDonut.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          onRemoveTileClick={onTileRemoveClick}
        >
          <SemiDonutChart detailText={subTitleTxt} />
        </InfoTile>
      );
    case 5:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.horizontalbar.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.horizontalbar.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          onRemoveTileClick={onTileRemoveClick}
        >
          <HorizontalBarChart detailText={subTitleTxt} data={tile.data} />
        </InfoTile>
      );
    case 14:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.verticalbar.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.verticalbar.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          onRemoveTileClick={onTileRemoveClick}
        >
          <VerticalBarChart detailText={subTitleTxt} data={tile.data} />
        </InfoTile>
      );
    case 6:
      titleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.timelinebartile.title' });
      subTitleTxt = intl.formatMessage({ id: 'dashboard.infotile.chart.timelinebartile.subtitle' });
      return (
        <InfoTile
          key={tile.id}
          tileTitle={<TileTitle title={titleTxt} />}
          editing={editing}
          onRemoveTileClick={onTileRemoveClick}
        >
          <TimeLineBarTile detailText={subTitleTxt} data={tile.data} />
        </InfoTile>
      );
    default:
      return null;
  }
};

const MainTiles: React.FC<MainTilesInterface> = ({ data, editing }) => {
  const intl = useIntl();
  const [tileData, setTileData] = useState(data);

  const reduceTiles = (id: number) => {
    const updated = tileData.map(t => {
      return t.id !== id ? t : null;
    });
    setTileData(updated);
  };

  return (
    <TilesContainer>
      {tileData.map((tile, i) => {
        if (tile) {
          const options = {
            editing,
            onTileRemoveClick: () => reduceTiles(tile.id)
          };
          return renderTile(tile, intl, options);
        }
        return <PlaceholderTile key={i} />;
      })}
    </TilesContainer>
  );
};

const TilesContainer = styled.div<PanelSizeInterface>`
  display: grid;
  grid-template-columns: repeat(4, 44rem);
  grid-gap: ${({ theme }) => theme.overlay.gridgap};
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 32rem;
  max-height: 56.38%;
`;

export default MainTiles;
