import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import moment from 'moment';
import { SdiTrash, TextLink } from '@sdir/sds';
import { SeagoingVesselPreviewLink } from '@sdir/blueprint';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import { additionalCompetenceApiApi } from '../../httpclient';
import { DisplayDateFormat } from '../../helpers';
import { CompetenceColumns, CompetenceRow } from '../../helpers/TableHeaders';
import TableCardContainer from '../Atoms/TableCardContainer';
import { DeleteAdditionalCompetenceModal } from './DeleteAdditionalCompetenceModal';

// eslint-disable-next-line max-len
const getAdditionalCompetenceApi = additionalCompetenceApiApi?.v1AdditionalcompetenceapiGetallQueryPost.bind(
  additionalCompetenceApiApi
);

const AdditionalCompetenceTable: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uidToDelete, setUidToDelete] = useState('');

  const { result, loading, error, callApi } = useApi({
    apiFunction: getAdditionalCompetenceApi,
    callApiOnInit: false
  });

  const showModalDelete = useCallback(uid => {
    setUidToDelete(uid);
    setShowDeleteModal(true);
  }, []);

  const onSuccessDeleteAdditionalCompetence = useCallback(() => {
    setShowDeleteModal(false);
    callApi({ legalEntityId });
  }, [callApi, legalEntityId]);

  const onCancelDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const tableData = useMemo(() => {
    if (!result) return [];

    return result.map<CompetenceRow>(item => ({
      vessel: (
        <SeagoingVesselPreviewLink
          vessel={{ name: item.vessel, imoNumber: item.imoNumber, callSign: item.vesselCallsign }}
          accumulatedData={null}
        />
      ),
      period: `${DisplayDateFormat(item.periodStartDate)} - ${DisplayDateFormat(
        item.periodStopDate
      )}`,
      periodStartValue: item.periodStartDate ? moment(item.periodStartDate) : null,
      additionalCompetence: item.competenceDescription,
      code: item.competenceCode,
      remove: (
        <TextLink
          data-testid="course-delete-button"
          icon={<SdiTrash size="s" />}
          text=""
          click={() => showModalDelete(item.uid)}
        />
      )
    }));
  }, [result]);

  useEffect(() => {
    if (legalEntityId) {
      callApi({
        skip: 0,
        take: 100,
        legalEntityId
      });
    }
  }, [callApi, legalEntityId]);

  return (
    <>
      <TableCardContainer
        title={intl.formatMessage({ id: 'table.competence.report.competence' })}
        loading={loading}
        error={error}
        emptyText={intl.formatMessage({ id: 'table.no.competence.found' })}
        data={tableData}
      >
        <SortableDataTable columns={CompetenceColumns} data={tableData} sortableKeys="*" />
      </TableCardContainer>
      {showDeleteModal && (
        <DeleteAdditionalCompetenceModal
          uid={uidToDelete}
          display={showDeleteModal}
          onCancelClick={onCancelDeleteModal}
          onSuccessDelete={onSuccessDeleteAdditionalCompetence}
        />
      )}
    </>
  );
};
export default AdditionalCompetenceTable;
