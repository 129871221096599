import React from 'react';
import styled from 'styled-components';
import { SdirButton } from '@sdir/sds';

interface FormNavigationProps {
  cancelLinkText: string;
  nextButtonText: string;
  cancelLinkColor?: string;
  cancelLinkOnClick?: (e?: any) => void;
  nextButtonOnClick?: (e?: any) => void;
}

const Navigation: React.FC<FormNavigationProps> = props => {
  const {
    cancelLinkText,
    nextButtonText,
    cancelLinkOnClick,
    nextButtonOnClick,
    cancelLinkColor
  } = props;

  return (
    <ButtonsSection>
      <SdirButton
        text={cancelLinkText}
        color={cancelLinkColor}
        onClick={() => {
          cancelLinkOnClick?.();
        }}
      />
      <SdirButton
        text={nextButtonText}
        type="primary"
        onClick={() => {
          nextButtonOnClick?.();
        }}
      />
    </ButtonsSection>
  );
};
const ButtonsSection = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1em;
`;

export default Navigation;
