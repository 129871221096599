/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';

import { activityApi, projectApi } from '../../httpclient';

const useRisk = uid => {
  const { setError } = useToast();
  const intl = useIntl();

  const { result: activites, loading: activitiesLoading, callApi: getActivityList } = useApi({
    apiFunction: (subjectAreaUri = undefined) =>
      activityApi.v2ActivityGetprojectactivities2SubjectAreaUriGet(uid, subjectAreaUri),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'aktiviteter' })}: ${err}`);
    }
  });

  const { result: activitiesOverview, loading: activitiesOverviewLoading } = useApi({
    apiFunction: () => activityApi.v3ActivityGetprojectactivitiesoverviewv3ProjectIdGet(uid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'fagområder' })}: ${err}`);
    },
    successCallback: data => {
      if (
        data.subjectAreaDocuments &&
        data.subjectAreaDocuments[0] &&
        data.subjectAreaDocuments[0].subjectAreaUri
      ) {
        getActivityList(data.subjectAreaDocuments[0].subjectAreaUri);
      } else {
        getActivityList();
      }
    }
  });

  const { result: vessel, loading: vesselLoading } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(uid),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'prosjekt' })}: ${err}`);
      }
    }
  });

  const riskData = {
    activitiesOverview,
    activitiesOverviewLoading,
    activites,
    activitiesLoading,
    getActivityList,
    vessel,
    vesselLoading
  };

  return riskData;
};

export default useRisk;
