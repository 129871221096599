import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SuggestedScopeActivity } from '@sdir/httpclient/lib/clients/et/survey';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  FocusAreaFilterOptions,
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import { SortingDirection } from '@sdir/utilities/lib/types/sorting';
import {
  ActiveFilters,
  IntervalsCertificatesAndInspectionTypes,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { isEmptyArray } from 'formik';

const useScopeFilter = (resetSelectedActivities?: () => void) => {
  const activities = useSelector((state: any) => state.appState.scopeActivitiesList);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(defaultActiveFilters);

  const filterByType = activitiesByStatus => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType.value.includes('DocumentControl')
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType.value.includes('Inspection')
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities || isEmptyArray(activities)) return [];
    if (
      activeFilters.status.length === 0 ||
      activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (
        (activity.status && selectedStatusList.includes(activity.lastEvent.status)) ||
        (!activity.status &&
          activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.certificates?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.certificates.some(ai =>
          activity?.certificates.some(certificate => ai === certificate.label)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        (activeFilters.scope === ScopeFilterOptions.Mandatory && activity?.scope === true) ||
        (activeFilters.scope === ScopeFilterOptions.Expanded && activity?.scope === false)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByFocusArea = filteredActivitiesList => {
    if (!filteredActivitiesList || activeFilters.focusArea === FocusAreaFilterOptions.No) return [];
    return [...filteredActivitiesList];
  };

  const filterTaskByIntervals = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.intervals.some(ai =>
          activity?.intervals.some(interval => ai === interval.label)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: SuggestedScopeActivity[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai =>
          activity?.inspectionTypes.some(inspectionType => ai === inspectionType.label)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const availableIntervalsCertificatesAndInspectionTypes: IntervalsCertificatesAndInspectionTypes = useMemo(() => {
    const uniqueIntervals = new Set();
    const uniqueInspectionTypes = new Set();
    const uniqueCertificates = new Set();
    if (activities) {
      activities?.forEach(activity => {
        activity.intervals?.forEach(i => uniqueIntervals.add(i.label));
        activity.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i.label));
        activity.certificates?.forEach(i => uniqueCertificates.add(i.label));
      });
    }
    const intervals = Array.from(uniqueIntervals).sort() as string[];
    const inspectionTypes = Array.from(uniqueInspectionTypes).sort() as string[];
    const certificates = Array.from(uniqueCertificates).sort() as string[];
    return { intervals, inspectionTypes, certificates };
  }, [activities]);

  const filteredActivities = useMemo(() => {
    let newFilteredActivities: Array<SuggestedScopeActivity> = [];
    if (activities) {
      newFilteredActivities = filterTasksByStatus();
      newFilteredActivities = filterByType(newFilteredActivities);
      newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
      newFilteredActivities = filterTasksByScope(newFilteredActivities);
      newFilteredActivities = filterTasksByFocusArea(newFilteredActivities);
      newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
      newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
    }
    return newFilteredActivities;
  }, [activities, activeFilters]);

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    setActiveFilters(newActiveFilters);
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const scopeFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableIntervalsCertificatesAndInspectionTypes
  };

  return scopeFilterData;
};

export default useScopeFilter;
