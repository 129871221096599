import { AxiosResponse } from 'axios';

interface Recovery {
  text: string;
  action: () => any;
  title: string;
}

class ErrorWithRecovery extends Error {
  response: AxiosResponse<any>;

  recovery: Recovery;

  constructor(response: AxiosResponse<any>, recovery: Recovery, ...params) {
    super(...params);

    this.name = 'ApiErrorWithAction';
    this.recovery = recovery;
    this.response = response;
  }
}

export default ErrorWithRecovery;
