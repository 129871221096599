import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-cycle
import { renderTile } from './MainTiles';
import { PlaceholderTile } from '../../Molecules';
import { dummyTiles } from '../../../helpers/dummyInfoTiles';
import { InfoTileInterface } from '../../../types/index.d';

const TilesExtensionPanel: React.FC = () => {
  const intl = useIntl();
  const [tileData, setTileData] = useState<{
    mainTiles: InfoTileInterface[];
    extensions: InfoTileInterface[];
  }>(dummyTiles);
  const [updatedData, setUpdatedData] = useState<{
    mainTiles: InfoTileInterface[];
    extensions: InfoTileInterface[];
  }>(dummyTiles);
  const [tilePlaceholders, setTilePlaceholders] = useState<number[]>([]);

  const panelRef = useRef<HTMLDivElement>(null);

  const replaceWithPlaceholder = (tileId: number) => {
    setTilePlaceholders([...tilePlaceholders, tileId]);
  };

  const dropPlaceholder = (tileId: number) => {
    setTilePlaceholders(tilePlaceholders.filter(pid => pid !== tileId));
  };

  const swapTilePlaceholder = (swapeeId: number, swapWith: InfoTileInterface) => {
    const swapeeTile = tileData.mainTiles.find(tile => tile.id === +swapeeId);

    const updatedMainTiles =
      tileData.mainTiles.map(tile => {
        return tile.id === swapeeId ? swapWith : tile;
      }) || [];

    const updatedExtraTiles = tileData.extensions.map(tile => {
      return tile.id === swapWith.id && swapeeTile ? swapeeTile : tile;
    });
    dropPlaceholder(swapeeId);
    setUpdatedData({ ...tileData, mainTiles: updatedMainTiles, extensions: updatedExtraTiles });
  };

  const replaceTileAtIndex = (index: number | undefined, newTile: InfoTileInterface) => {
    const updatedMainTiles = index
      ? tileData.mainTiles.map((tile, i) => (i === index ? newTile : tile))
      : [...tileData.mainTiles];
    const updatedExtraTiles = tileData.extensions.filter(tile => tile.id !== newTile.id);
    setUpdatedData({ ...tileData, mainTiles: updatedMainTiles, extensions: updatedExtraTiles });
  };

  const findDropzone = (x: number, y: number) => {
    const dropzones = panelRef?.current?.querySelectorAll('.placeholder-dropzone') || [];
    let dropzone: HTMLDivElement | undefined;
    let index: number | undefined;
    Array.from(dropzones).forEach((dzone, i) => {
      const rect = dzone.getBoundingClientRect();
      if (x > rect.left && x < rect.right && y > rect.top && y < rect.bottom) {
        dropzone = dzone as HTMLDivElement;
        index = i;
      }
    });

    return { dropzone, index };
  };

  const onDraggingStop = (e, tile) => {
    const x = (e as MouseEvent).clientX;
    const y = (e as MouseEvent).clientY;

    const { dropzone, index } = findDropzone(x, y);
    const placeholderTileId = dropzone?.dataset.placeholderfor;
    if (placeholderTileId) {
      swapTilePlaceholder(+placeholderTileId, tile);
    } else {
      replaceTileAtIndex(index, tile);
    }
  };

  useEffect(() => {
    if (JSON.stringify(tileData.mainTiles) !== JSON.stringify(updatedData.mainTiles)) {
      setTileData(updatedData);
    }
  }, [tileData.mainTiles, updatedData]);

  return (
    <Container ref={panelRef}>
      {tileData.mainTiles.map((tile, i) => {
        return tilePlaceholders.includes(tile.id) ? (
          <PlaceholderTile key={i} placeholderFor={tile.id} />
        ) : (
          renderTile(tile, intl, {
            editing: true,
            onTileRemoveClick: () => replaceWithPlaceholder(tile.id)
          })
        );
      })}
      <Divider />
      {tileData.extensions.map(tile => {
        return (
          <Draggable key={tile.id} onStop={e => onDraggingStop(e, tile)} position={{ x: 0, y: 0 }}>
            <div>
              {renderTile(tile, intl, {
                isDraggable: true
              })}
            </div>
          </Draggable>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto-fill, 44rem));
  grid-template-rows: 32rem auto;
  grid-gap: 4rem;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 90vh;
  padding-top: ${({ theme }) => theme.overlay.gap};
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const Divider = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: ${({ theme }) => theme.colors.primaryLightBlue1};
  border-radius: 25%;
  grid-column: span 4;
  margin: 4rem 0;
`;

export default TilesExtensionPanel;
