import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SdirButton, Loader } from '@sdir/sds';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Listmenu from '../../Molecules/Requirements/ListMenu';
import useRisk from '../../../services/hooks/useRisk';
import RiskItem from '../../Organisms/RiskAssessment/RiskItem';

const RiskAssessment: React.FC = () => {
  const intl = useIntl();
  const { projectuid } = useParams() as { projectuid: string };

  const {
    activitiesOverview,
    activitiesOverviewLoading,
    activites,
    activitiesLoading,
    getActivityList,
    vessel,
    vesselLoading
  } = useRisk(projectuid);

  const onMenuItemClick = (subjectAreaUri: string) => {
    getActivityList(subjectAreaUri);
  };

  const [selectedTheme, setSelectedTheme] = useState(
    activitiesOverview &&
      activitiesOverview.subjectAreaDocuments &&
      activitiesOverview.subjectAreaDocuments[0].subjectAreaUri &&
      activitiesOverview.subjectAreaDocuments[0].subjectAreaUri
  );

  useEffect(() => {
    if (
      !selectedTheme &&
      activitiesOverview &&
      activitiesOverview.subjectAreaDocuments &&
      activitiesOverview.subjectAreaDocuments[0]
    ) {
      setSelectedTheme(activitiesOverview.subjectAreaDocuments[0].subjectAreaUri);
    }
  }, [activitiesOverview, selectedTheme]);

  const getRiskItem = activity => {
    if (activity.activity) {
      return (
        <RiskItem
          verificationMethod={activity.activity?.verificationMethod}
          id={activity?.activity?.uid}
          title={activity?.activity?.name}
          key={activity?.activity?.uid}
          projectUid={projectuid}
          requirementText={activity?.activity?.description}
          risks={activity?.activity?.risks}
          onFeedBackSubmit={() => {}}
        />
      );
    }
    if (activity.documentControlActivityGroup && activity.documentControlActivityGroup.activities) {
      return activity.documentControlActivityGroup.activities.map(docActivity => {
        return (
          <RiskItem
            verificationMethod={docActivity.verificationMethod}
            id={docActivity.uid}
            title={docActivity.name}
            key={docActivity.uid}
            projectUid={projectuid}
            requirementText={docActivity.description}
            risks={docActivity.risks}
            onFeedBackSubmit={() => {}}
          />
        );
      });
    }
    return '';
  };
  return (
    <Container>
      <Content>
        <HeaderContainer>
          <h1>{intl.formatMessage({ id: 'riskassessment.modal.title' })}</h1>
          {!vesselLoading && vessel && <h2>{vessel?.name}</h2>}
        </HeaderContainer>
        <GridContainer>
          <ListMenyContainer>
            {activitiesOverview && (
              <Listmenu
                onMenuItemClick={e => {
                  setSelectedTheme(e);
                  onMenuItemClick(e);
                }}
                data={activitiesOverview}
              />
            )}
          </ListMenyContainer>
          {activitiesLoading || activitiesOverviewLoading ? (
            <LoadingContainer data-testid="loader">
              <Loader />
            </LoadingContainer>
          ) : (
            <ActivitesContainer>
              {activites &&
                activites.map(activity => {
                  return getRiskItem(activity);
                })}
              <ButtonWrapper>
                <SdirButton disabled onClick={() => {}} text="Avbryt" htmlType="button" />
                <SdirButton
                  onClick={() => {}}
                  text="Lagre endringer"
                  type="primary"
                  htmlType="submit"
                  disabled
                />
              </ButtonWrapper>
            </ActivitesContainer>
          )}
        </GridContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 188rem;
  row-gap: 5rem;
  margin: 2rem auto 0 auto;
  display: grid;
  column-gap: 4rem;
`;

const Content = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 4rem;
  justify-content: space-between;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
`;

const LoadingContainer = styled.div`
  position: relative;
  left: -15%;
  top: 10%;
`;

const HeaderContainer = styled.div`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.primary5};
  grid-template-columns: 25% 75%;
  display: inline-grid;

  h1,
  h2 {
    all: unset;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
`;

const ActivitesContainer = styled.div`
  background: ${({ theme }) => theme.colors.font.white};
  padding: 4rem 4rem 4rem 2rem;
  box-shadow: 0 0.375rem 0.75rem 0 rgb(0 0 0 / 29%);
  height: fit-content;
`;

const ListMenyContainer = styled.div`
  height: fit-content;
`;

export default RiskAssessment;
