import React, { useState } from 'react';
import { Select } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const AddMoreModal: React.FC = () => {
  const intl = useIntl();
  const [selectedTask, setSelectedTask] = useState(null);
  const onSelectChange = (val: any) => {
    setSelectedTask(val);
  };
  // TODO: API call and update options
  const options = [
    { value: 'chocolate', name: 'Chocolate' },
    { value: 'strawberry', name: 'Strawberry' },
    { value: 'vanilla', name: 'Vanilla' }
  ];
  return (
    <Container>
      <Header>{intl.formatMessage({ id: 'dashboard.myTask.modal.header' })}</Header>
      <Select
        id="team-select"
        options={options}
        value={selectedTask}
        handleChange={onSelectChange}
        disabled={options.length === 0}
        defaultVal="Search"
      />
      <InfoDiv>{intl.formatMessage({ id: 'dashboard.myTask.modal.info' })}</InfoDiv>
    </Container>
  );
};

export default AddMoreModal;

const Container = styled.div`
  min-height: 200px;
  width: 100%;
  font-size: 1.75rem;
  padding: 0 auto;
  color: ${({ theme }) => theme.dataTable.color};
`;
const Header = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;
const InfoDiv = styled.div`
  margin: 30px;
  font-size: 1.6rem;
  font-style: italic;
`;
