import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SdirButton } from '@sdir/sds';

const Comments: React.FC = () => {
  const [commentText, setCommentText] = useState('');
  const SendComments = useCallback(() => {
    setCommentText('');
  }, []);
  return (
    <Wrapper>
      <CommentSection>
        <textarea
          id="comment-area"
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
          name="comment-area"
          placeholder="comment"
        />
        <SdirButton
          id="send-comment"
          text="OK"
          onClick={() => {
            SendComments();
          }}
        />
      </CommentSection>
    </Wrapper>
  );
};

export default Comments;

const Wrapper = styled.div`
  display: grid;
`;
const CommentSection = styled.div`
  button {
    height: 10rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.background.light};
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 240px));
  width: 100%;
  box-sizing: border-box;

  & > :last-child {
    align-self: end;
  }
`;
