import { SET_SELECTED_PROJECT_DATA } from '../constants/action-types';
import { projectState } from '../constants/types';

const initialProjectState: projectState = {};

export default function projectReducer(state = initialProjectState, action) {
  switch (action.type) {
    case SET_SELECTED_PROJECT_DATA:
      return { ...action.payload };
    default:
      return state;
  }
}
