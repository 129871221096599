import {
  GetActivityDetailsV2Result,
  SetActivityNotRelevantCommand,
  SetActivityRelevantPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { MessageList } from '@sdir/blueprint.et/lib/components';
import { Loader } from '@sdir/sds';
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { ToggleState } from './ActivityCardHelpers';
import NotRelevantCard from '../../Organisms/NotRelevantCard';

interface AccordionProps {
  toggleState: ToggleState;
  activityDetailsLoading: boolean;
  numberOfMessages: number;
  activityDetails: GetActivityDetailsV2Result | undefined;
  projectuid: string;
  activityUid: string;
  downloadByFilename: (filename: string) => void;
  submitNotRelevant: (args: SetActivityNotRelevantCommand) => void;
  submitRelevant: (args?: SetActivityRelevantPayload) => void;
  setToggleState: (newState: ToggleState) => void;
  fetchMessages: () => void;
  notRelevantLoading: boolean;
  relevantLoading: boolean;
}

const TaskCardAccordion: React.FC<AccordionProps> = ({
  toggleState,
  activityDetailsLoading,
  numberOfMessages,
  activityDetails,
  projectuid,
  activityUid,
  downloadByFilename,
  submitNotRelevant,
  submitRelevant,
  setToggleState,
  fetchMessages,
  notRelevantLoading,
  relevantLoading
}) => {
  const intl = useIntl();

  return (
    <Accordion isOpen={toggleState !== ToggleState.None} data-testid="Accordion">
      {activityDetailsLoading && <Loader />}

      {numberOfMessages === 0 && !activityDetailsLoading && toggleState === ToggleState.Messages ? (
        <Text>
          {intl.formatMessage({
            id: 'activitycard.messagesandreactions.nomessages'
          })}
        </Text>
      ) : (
        <>
          {toggleState === ToggleState.Messages && (
            <MessageList events={activityDetails?.events} downloadAttachment={downloadByFilename} />
          )}
        </>
      )}

      {(toggleState === ToggleState.NotRelevant || toggleState === ToggleState.Relevant) && (
        <NotRelevantCard
          toggleState={toggleState}
          submitNotRelevant={submitNotRelevant}
          submitRelevant={submitRelevant}
          activityUid={activityUid}
          projectUid={projectuid}
          onAction={() => {
            setToggleState(ToggleState.Messages);
            fetchMessages();
          }}
          onCancel={() => setToggleState(ToggleState.None)}
          loading={relevantLoading || notRelevantLoading}
        />
      )}
    </Accordion>
  );
};

export default TaskCardAccordion;

const Accordion = styled.div<{ isOpen: boolean }>`
  width: 107rem !important;
  box-shadow: 0 3px 3px #00000029;
  border-radius: 6px;
  background-color: white;
  position: relative;
  top: -4rem;
  width: 93%;
  z-index: 1;
  padding: 4rem;

  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: scaleY(1);
          visibility: inherit;
        `
      : css`
          visibility: hidden;
          height: 0;
          margin-bottom: -4rem;
        `}
`;

export const Text = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10rem;
  height: 100%;
`;
