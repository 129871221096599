import React from 'react';
import styled from 'styled-components';
import { AppVersionCheck } from '@sdir/utilities/lib/components';
import { isMobileOnly } from 'react-device-detect';
import { FeedbackButton } from '@sdir/blueprint';
import { RouteComponent } from './components/Templates/Routes';
import Header from './components/Templates/Header';
import ContentSection from './components/Templates/ContentSection';
import Providers from './components/Templates/Providers';
import styles from './global.module.css';

const baseUrl = window.location.href.split('#')[0];
const versionCheckInterval = process.env.REACT_APP_VERCHECK_INTERVAL
  ? +process.env.REACT_APP_VERCHECK_INTERVAL
  : null;

function App() {
  return (
    <Providers>
      {versionCheckInterval && (
        <AppVersionCheck baseUrl={baseUrl} checkIntervalMs={versionCheckInterval} />
      )}
      <AppContainer id="internalportal-app" className={styles.global}>
        <RouteComponent Header={Header} ContentSection={ContentSection} />
      </AppContainer>
      {!isMobileOnly && <FeedbackButton />}
    </Providers>
  );
}

const AppContainer = styled.main`
  position: relative;
  width: 100%;

  font-size: 2rem;
  text-decoration: none;
  line-height: 1.5;

  font-family: 'Stag Sans Book', 'Helvetica Neue', sans-serif;
  div,
  span,
  p,
  a,
  section,
  input,
  article,
  li {
    text-decoration: none;
    line-height: 1.5;
  }
  h1 {
    margin-block-start: 0;
    margin-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
`;

export default App;
