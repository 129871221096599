/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { VesselRequirementChapter } from '@sdir/httpclient/lib/clients/core/requirement';
import { VesselRequirementSection } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getSimulatorRegulationLawTextApi } from '../../httpclient';

export interface ILawText {
  lawText: string;
  eliRef: string;
}

const useLawText = () => {
  const intl = useIntl();

  const [sectionsWithLawTexts, setSectionsWithLawText] = useState<VesselRequirementSection[]>();
  const [sectionsLoading, setSectionsLoading] = useState(false);

  const { callApi: callLawTextApi } = useApi({
    apiFunction: eliRef => {
      const eliReference = eliRef || '';
      if (eliReference.includes('list')) {
        const [
          year,
          month,
          day,
          regulation,
          part,
          chapter,
          section,
          subsection,
          list
        ] = eliReference.split('/').filter(item => !isNaN(parseFloat(item)));

        return getSimulatorRegulationLawTextApi.regulationGetregulationlawtextGetlawtextlistListVersionGet(
          parseInt(year, 10),
          parseInt(month, 10),
          parseInt(day, 10),
          regulation,
          part,
          chapter,
          section,
          subsection,
          list
        );
      }
      const [year, month, day, regulation, part, chapter, section, subsection] = eliReference
        .split('/')
        .filter(item => !isNaN(parseFloat(item)));
      // eslint-disable-next-line max-len
      return getSimulatorRegulationLawTextApi.regulationGetregulationlawtextGetlawtextsubsectionSubsectionVersionGet(
        parseInt(year, 10),
        parseInt(month, 10),
        parseInt(day, 10),
        regulation,
        part,
        chapter,
        section,
        subsection
      );
    }
  });

  const handleChapterClick = async (chapter: VesselRequirementChapter) => {
    const elirefs: string[] = [];
    setSectionsLoading(true);

    chapter.sections?.forEach(section => {
      section.subsections?.forEach(subsection => {
        elirefs.push(subsection.eliReference || '');
      });
    });

    const sections: VesselRequirementSection[] = [];

    if (chapter.sections) {
      for (const section of chapter.sections) {
        const newSubsections: any = [];

        if (section.subsections) {
          for (const subsection of section.subsections) {
            let lawText = intl.formatMessage({ id: 'requirements.lawtext.notfound.error' });
            const response = await callLawTextApi(subsection.eliReference);
            if (response.success) {
              lawText = response.data as string;
            }
            newSubsections.push({ ...subsection, lawText });
          }
        }
        sections.push({ ...section, subsections: newSubsections });
      }
    }
    setSectionsLoading(false);
    setSectionsWithLawText(sections);
  };

  return {
    callLawTextApi,
    handleChapterClick,
    sectionsWithLawTexts,
    sectionsLoading
  };
};

export default useLawText;
