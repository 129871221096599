import React, { useCallback } from 'react';
import { SdirButton, Textarea } from '@sdir/sds';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  SetActivityNotRelevantCommand,
  SetActivityRelevantPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';

import { ToggleState } from '../Molecules/ActivityCard/ActivityCardHelpers';

interface NotRelevantCardProps {
  onAction: () => void;
  onCancel: () => void;
  activityUid: string;
  projectUid: string;
  submitNotRelevant: (payload: SetActivityNotRelevantCommand) => void;
  submitRelevant?: (payload?: SetActivityRelevantPayload) => void;
  loading?: boolean;
  toggleState: ToggleState.NotRelevant | ToggleState.Relevant;
  buttonLabel?: string;
}

const NotRelevantCard: React.FC<NotRelevantCardProps> = ({
  onAction,
  onCancel,
  activityUid,
  projectUid,
  submitNotRelevant,
  submitRelevant,
  loading = false,
  toggleState,
  buttonLabel
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    text: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' }))
  });

  const handleNotRelevantSubmit = useCallback(
    async values => {
      const payload: SetActivityNotRelevantCommand = {
        projectUid,
        activityUid,
        reason: values.text
      };
      await submitNotRelevant(payload);
      onAction();
    },
    [activityUid, projectUid, submitNotRelevant]
  );

  const handleRelevantSubmit = useCallback(
    async values => {
      const payload: SetActivityRelevantPayload = {
        reason: values.text
      };
      await submitRelevant?.(payload);
      onAction();
    },
    [activityUid, projectUid, submitRelevant]
  );

  const formik = useFormik({
    initialValues: {
      text: ''
    },
    validationSchema,
    onSubmit: values => {
      if (toggleState === ToggleState.Relevant) {
        handleRelevantSubmit(values);
      } else {
        handleNotRelevantSubmit(values);
      }
    }
  });

  const getTexts = () => {
    switch (toggleState) {
      case ToggleState.NotRelevant:
        return {
          title: intl.formatMessage({ id: 'notrelevantcard.title' }),
          info: intl.formatMessage({ id: 'notrelevantcard.description' })
        };
      case ToggleState.Relevant:
        return {
          title: intl.formatMessage({ id: 'relevantcard.title' }),
          info: intl.formatMessage({ id: 'relevantcard.description' })
        };
      default:
        return { title: '', info: '' };
    }
  };

  return (
    <Container data-testid="NotRelevantCard">
      <h1>{getTexts().title}</h1>
      <p>{getTexts().info}</p>
      <form onSubmit={formik.handleSubmit}>
        <TextareaContainer>
          <Textarea
            placeholder={intl.formatMessage({ id: 'notrelevantcard.textarea.placeholder' })}
            onChange={formik.handleChange}
            contentValue={formik.values.text}
            id="text"
          />
          <ErrorLabel>{formik.touched.text && formik.errors.text}</ErrorLabel>
        </TextareaContainer>
        <CardFooter>
          <SdirButton
            text={intl.formatMessage({ id: 'dispensationcard.form.cancel' })}
            type="default"
            onClick={onCancel}
          />
          <SdirButton
            disabled={loading}
            text={buttonLabel ?? intl.formatMessage({ id: 'notrelevantcard.button.send' })}
            type="primary"
            onClick={formik.handleSubmit as any}
          />
        </CardFooter>
      </form>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  h1 {
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.stfMedium};
    color: ${({ theme }) => theme.colors.font};
  }
  p {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 1.75rem;
    margin: 3rem 0;
  }
`;

const TextareaContainer = styled.div`
  margin-bottom: 3rem;
  textarea {
    width: 100%;
    height: 25.5rem;
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 2rem;
  }
`;

const ErrorLabel = styled.span`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.error};
`;

export default NotRelevantCard;
