import { useContext } from 'react';
import { Toggle } from '../../types/toggles';
import { Loadable } from '../../types/commonTypes';
import { FeatureToggleContext } from '../contexts/FeatureToggleContext';

export const useFeatureToggles = (): Loadable<Toggle[]> => {
  const context = useContext(FeatureToggleContext);

  return context;
};
